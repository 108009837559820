import React, {useContext, useState} from 'react';
import {
    Button,
    Checkbox,
    ClickAwayListener,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PrintIcon from '@material-ui/icons/Print';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {DateRangePickerComponent} from "materialui-daterange-picker";
import {UserContext} from "../../App";
import Show from "./Show";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const useStyles = makeStyles((theme) => ({
    gridItem: {
        flexGrow: 0,
        marginBottom: 25,
        textAlign: `center`,
        marginTop: 25,
        fontSize: 14,
        justifyContent: `center`,
    },
    formContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'end',
        justifyContent: "space-evenly",
        flexWrap: 'wrap'
    },
    formRow: {
        flexGrow: 0,
        marginBottom: 16,
        textAlign: `center`,
        marginTop: theme.spacing(1),
        fontSize: 14,
        justifyContent: `center`,
        // display: `block`,
    },
    formControl: {
        minWidth: 150,
        width: `auto`,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    formControlDate: {
        minWidth: 150,
        width: `auto`,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        float: "left",
        paddingLeft: theme.spacing(1)
    },
    formControlLabel: {
        minWidth: 150,
        width: `auto`,
        margin: `-${theme.spacing(2)}px ${theme.spacing(3)}px 0`
    },
    formControlAutocomplete: {
        minWidth: 250,
        width: `auto`,
        margin: `-13px ${theme.spacing(3)}px 0`
    },
    formControlPrint: {
        minWidth: 250,
        width: `auto`,
        margin: `0`
    },
    dateRange: {
        position: `absolute`,
        marginTop: `58px`,
        zIndex: `999`
    },
}));

// const getDefaultRanges = (date: Date): DefinedRange[] => [
//     {
//       label: 'Today',
//       startDate: date,
//       endDate: date,
//     },
//     {
//       label: 'Yesterday',
//       startDate: addDays(date, -1),
//       endDate: addDays(date, -1),
//     },
//     {
//       label: 'This Week',
//       startDate: startOfWeek(date),
//       endDate: endOfWeek(date),
//     },
//     {
//       label: 'Last Week',
//       startDate: startOfWeek(addWeeks(date, -1)),
//       endDate: endOfWeek(addWeeks(date, -1)),
//     },
//     {
//       label: 'Last 7 Days',
//       startDate: addWeeks(date, -1),
//       endDate: date,
//     },
//     {
//       label: 'This Month',
//       startDate: startOfMonth(date),
//       endDate: endOfMonth(date),
//     },
//     {
//       label: 'Last Month',
//       startDate: startOfMonth(addMonths(date, -1)),
//       endDate: endOfMonth(addMonths(date, -1)),
//     },
//   ];

export default function ReportHeader(props) {

    const classes = useStyles();
    const user = useContext(UserContext);

    const [filterState, setFilterState] = useState({
        filters: props.filters,
        group: props.group,
        plt: props.plt,
        subTotal: props.subTotal ? props.subTotal : [],
        selectedDate: new Date(props.vars.date),
        selectedDateFrom: new Date(props.vars.dateFrom),
        selectedDateTo: new Date(props.vars.dateTo)
    });

    const [openDateRange, setOpenDateRange] = useState(false);
    const [receivingId, setReceivingId] = useState(props.receivingId || undefined);
    const dateRange = {
        startDate: filterState.selectedDateFrom,
        endDate: (filterState.selectedDateTo && filterState.selectedDateFrom) && new Date(Math.max(filterState.selectedDateTo.getTime(), filterState.selectedDateFrom.getTime()))
    };

    let filters = [];

    if (props.tableInfo.filters.length > 0) {
        filters = Object.assign(props.tableInfo.filters);
        filters = filters.map(filter => {

            let options = Object.assign([], filter.options);
            if (options.length > 0 && options.length < 50) {
                options.unshift({
                    __typename: "ReportFilterOptions",
                    _id: `null_${user.translate("all")}`,
                    id: "null",
                    title: user.translate("all"),
                });
            }
            return {
                ...filter,
                options: options
            }
        })
    }

    const filterOptions = (options, params) => options.filter(option =>
        option.title && option.title.cirilicToLatin().indexOf(params.inputValue.cirilicToLatin()) > -1
    );

    const toggleDateRangeOpen = () => setOpenDateRange(!openDateRange);
    const closeDateRange = () => setOpenDateRange(false);

    const handleChangeFilter = (event, value, name) => {

        if (value.length > 0) {
            let _filters = Object.assign(filterState.filters);
            let all = value.find(c => c.id === "null");

            if (all) {
                let _filter = filters.find(f => f.name === name);
                _filters[name] = _filter.options.filter(f => f.id !== "null");
            } else {
                _filters[name] = value;
            }

            setFilterState({...filterState, filters: _filters});
            props.setQuery(filterState);
        } else {
            let _filters = Object.assign(filterState.filters);
            _filters[name] = [];
            setFilterState({...filterState, filters: _filters});
            props.setQuery(filterState);
        }
    }

    const setGroups = (event) => {


        if (event.target.value !== 0) {
            setFilterState({...filterState, group: event.target.value});
            props.setQuery(filterState, "group", event.target.value);
        } else {
            setFilterState({...filterState, group: ""});
            props.setQuery(filterState, "group", "");
        }
    }

    const setPlt = (event) => {


        if (event.target.value !== 0) {
            setFilterState({...filterState, plt: event.target.value});
            props.setQuery(filterState, "plt", event.target.value);
        } else {
            setFilterState({...filterState, plt: "", group: "", subTotal: []});
            props.setQuery(filterState, "plt", "");
        }
    }

    const setSubTotals = (event, value) => {

        if (value.length > 0) {
            let subTotals = [];
            subTotals = value;

            setFilterState({...filterState, subTotal: subTotals});
            props.setQuery(filterState, "subTotal", subTotals);
        } else {
            setFilterState({...filterState, subTotal: []});
            props.setQuery(filterState, "subTotal", "");
        }
    }

    const handleDateChange = (date) => {
        if (date != "Invalid Date") {
            let filState = Object.assign(filterState);
            let dateFrom = new Date(date);
            if (!props.datetime) {
                dateFrom.setHours(5, 0, 0, 0);
            }

            filState.selectedDate = dateFrom;
            setFilterState(filState);
            // props.setQuery(filterState, "date");
        }
    }
    const handleDateFromChange = (date) => {
        if (date != "Invalid Date") {
            let dateFrom = new Date(date);
            if (!props.datetime) {
                dateFrom.setHours("00");
                dateFrom.setMinutes("00")
                dateFrom.setSeconds("00");
            }
            let filState = Object.assign(filterState);
            filState.selectedDateFrom = dateFrom;
            if (filState.selectedDateTo.getTime() < dateFrom.getTime() && dateFrom.getTime() < 4102441200000) {
                filState.selectedDateTo = new Date(dateFrom);
            }
            setFilterState(filState);
            // let _range = {
            //     startDate: dateFrom,
            //     endDate: filState.selectedDateTo,
            // }
            // setDateRange(_range);
            // props.setQuery(filState, "dateRange");
        }
    }
    const handleDateToChange = (date) => {
        if (date != "Invalid Date") {
            let dateTo = new Date(date);
            if (!props.datetime) {
                dateTo.setHours(5, 0, 0, 0);
            }
            let filState = Object.assign(filterState);
            filState.selectedDateTo = dateTo;
            if (filState.selectedDateFrom.getTime() > dateTo.getTime() && dateTo.getTime() < 4102441200000) {
                filState.selectedDateFrom = new Date(dateTo);
            }
            setFilterState(filState);
            // let _range = {
            //     startDate: filState.selectedDateFrom,
            //     endDate: dateTo
            // }
            // setDateRange(_range);
            // props.setQuery(filState, "dateRange");
        }
    }

    const handleDateRangeAccept = (range) => {
        let filState = Object.assign(filterState);
        let dateFrom = new Date(range.startDate);
        let dateTo = new Date(range.endDate.getTime() + 86400000);
        dateFrom.setHours(5, 0, 0, 0);
        dateTo.setHours(5, 0, 0, 0);
        filState.selectedDateFrom = dateFrom;
        filState.selectedDateTo = dateTo;
        setFilterState(filState);
        // setDateRange({
        //     startDate: dateFrom,
        //     endDate: dateTo
        // });
        props.setQuery(filState, "dateRange");
        closeDateRange();
    }

    if (receivingId) {
        return <div className="no_print">
            <FormGroup row className={classes.formRow}>
                {props.displayPrint && props.plt && (
                    <FormControl className={classes.formControlPrint}>
                        <label htmlFor="contained-button-file" className={classes.labelUpload}>
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                onClick={props.onPrintPlt}
                                startIcon={<PrintIcon/>}
                            >
                                {user.translate("print")}
                            </Button>
                        </label>
                    </FormControl>
                )}

            </FormGroup>
        </div>
    }

    return (
        <div className="no_print">
            <div className={classes.formContainer}>
                <Show if={filters.length > 0}>
                    <FormGroup row className={classes.formRow}>
                        {
                            filters.map(filter => {
                                return (
                                    <FormControl className={classes.formControl} key={"filter_" + filter.name}>
                                        <Autocomplete
                                            selectOnFocus
                                            handleHomeEndKeys
                                            multiple
                                            limitTags={2}
                                            size="small"
                                            options={filter.options}
                                            filterOptions={filterOptions}
                                            name={filter.name}
                                            value={filterState.filters[filter.name]}
                                            onChange={(event, value) => handleChangeFilter(event, value, filter.name)}
                                            getOptionSelected={(option, value) => option.title === value.title}
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(option, {selected}) => {
                                                const selectFilmIndex = filterState.filters[filter.name]?.findIndex(
                                                    filter => filter.title.toLowerCase() === user.translate("all").toLowerCase()
                                                ) ?? -1;
                                                if (selectFilmIndex > -1) {
                                                    selected = true;
                                                }
                                                if (option.title !== "") {
                                                    return <div>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{marginRight: 8}}
                                                            checked={selected}
                                                        />
                                                        {option.title}
                                                    </div>
                                                }
                                            }}
                                            renderInput={(params) => {
                                                params.inputProps.autoComplete = "off";
                                                return <TextField
                                                    {...params}
                                                    fullWidth
                                                    label={filter.title}
                                                    variant="standard"
                                                />
                                            }}
                                        />
                                    </FormControl>
                                )
                            })
                        }
                    </FormGroup>
                </Show>
                <Show
                    if={Object.keys(props.tableInfo.group).length > 0 || Object.keys(props.tableInfo.subTotals).length > 0}>
                    <FormGroup row className={classes.formRow}>
                        {Object.keys(props.tableInfo.group).length > 0 && (
                            <FormControl className={classes.formControlLabel}>
                                <InputLabel id={"group"}>{user.translate("group")}</InputLabel>
                                <Select
                                    id={"filter_group"}
                                    name={"filter_group"}
                                    fullWidth
                                    onChange={setGroups}
                                    value={filterState.group}
                                >
                                    <MenuItem key={"none"} value={0}>
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        props.tableInfo.group.map(group => group.title !== "" && (
                                            <MenuItem key={group.name} value={group.name}>
                                                {group.title}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        )}

                        {Object.keys(props.tableInfo.subTotals).length > 0 && (
                            <FormControl className={classes.formControlAutocomplete}>
                                <Autocomplete
                                    selectOnFocus
                                    handleHomeEndKeys
                                    multiple
                                    limitTags={4}
                                    size="small"
                                    options={props.tableInfo.subTotals}
                                    value={filterState.subTotal}
                                    filterOptions={filterOptions}
                                    onChange={(event, value) => setSubTotals(event, value)}
                                    getOptionLabel={(option) => option.title}
                                    getOptionSelected={(option, value) => option.title === value.title}
                                    renderOption={(option, {selected}) => option.title !== "" && (
                                        <div>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{marginRight: 8}}
                                                checked={selected}
                                            />
                                            {option.title}
                                        </div>
                                    )}
                                    renderInput={(params) => {
                                        params.inputProps.autoComplete = "off";
                                        return <TextField
                                            {...params}
                                            fullWidth
                                            label={user.translate("sub_total")}
                                            variant="standard"
                                        />
                                    }}
                                />
                            </FormControl>
                        )}
                    </FormGroup>
                </Show>

                {!props.vars.date && (
                    <ClickAwayListener onClickAway={closeDateRange}>
                        <div>
                            <FormGroup row className={classes.formRow}>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="flex-start" className={classes.formControlDate}>
                                        {!props.datetime && (
                                            <KeyboardDatePicker
                                                disableToolbar={false}
                                                animateYearScrolling={true}
                                                autoOk={true}
                                                open={false}
                                                variant="inline"
                                                format={user.dateFormat}
                                                id={"filter_date_from"}
                                                label={user.translate("date_from")}
                                                value={filterState.selectedDateFrom}
                                                onClick={toggleDateRangeOpen}
                                                onChange={handleDateFromChange}
                                                onClose={(e) => props.setQuery(filterState, "dateRange")}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") props.setQuery(filterState, "dateRange")
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        )}
                                        {props.datetime && (
                                            <KeyboardDateTimePicker
                                                animateYearScrolling={true}
                                                ampm={false}
                                                autoOk={true}
                                                open={false}
                                                variant="inline"
                                                format={user.dateTimeFormat}
                                                id={"filter_date_from"}
                                                label={user.translate("date_from")}
                                                value={filterState.selectedDateFrom}
                                                onClick={toggleDateRangeOpen}
                                                onClose={(e) => props.setQuery(filterState, "dateRange")}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") props.setQuery(filterState, "dateRange")
                                                }}
                                                onChange={handleDateFromChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="flex-start" className={classes.formControlDate}>
                                        {!props.datetime && (
                                            <KeyboardDatePicker
                                                disableToolbar={false}
                                                animateYearScrolling={true}
                                                autoOk={true}
                                                open={false}
                                                variant="inline"
                                                format={user.dateFormat}
                                                id={"filter_date_to"}
                                                label={user.translate("date_to")}
                                                value={filterState.selectedDateTo}
                                                onClick={toggleDateRangeOpen}
                                                onChange={handleDateToChange}
                                                onClose={(e) => props.setQuery(filterState, "dateRange")}
                                                onKeyPress={(e) => {
                                                    if (e.key == "Enter") props.setQuery(filterState, "dateRange")
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                InputProps={{position: "start"}}
                                            />
                                        )}
                                        {props.datetime && (
                                            <KeyboardDateTimePicker
                                                animateYearScrolling={true}
                                                ampm={false}
                                                autoOk={true}
                                                open={false}
                                                variant="inline"
                                                format={user.dateTimeFormat}
                                                id={"filter_date_to"}
                                                label={user.translate("date_to")}
                                                value={filterState.selectedDateTo}
                                                onClick={toggleDateRangeOpen}
                                                onChange={handleDateToChange}
                                                onClose={(e) => props.setQuery(filterState, "dateRange")}
                                                onKeyPress={(e) => {
                                                    if (e.key == "Enter") props.setQuery(filterState, "dateRange")
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                InputProps={{position: "start"}}
                                            />
                                        )}
                                    </Grid>
                                </MuiPickersUtilsProvider>

                                <div className={classes.dateRange}>
                                    <DateRangePickerComponent
                                        open={openDateRange}
                                        toggle={toggleDateRangeOpen}
                                        initialDateRange={dateRange}
                                        // definedRanges={definedRanges}
                                        onChange={(range) => handleDateRangeAccept(range)}
                                    />
                                </div>

                            </FormGroup>
                        </div>
                    </ClickAwayListener>
                )}

                {props.vars.date && !props.datetime && (
                    <FormGroup row className={classes.formRow}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="flex-start" className={classes.formControl}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    animateYearScrolling
                                    autoOk={true}
                                    variant="inline"
                                    format={user.dateFormat}
                                    id={"filter_date"}
                                    label={user.translate("date")}
                                    value={filterState.selectedDate}
                                    onChange={handleDateChange}
                                    onClose={(e) => props.setQuery(filterState, "date")}
                                    onKeyPress={(e) => {
                                        if (e.key == "Enter") props.setQuery(filterState, "date")
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>

                    </FormGroup>
                )}

                {props.vars.date && props.datetime && (
                    <FormGroup row className={classes.formRow}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="flex-start" className={classes.formControl}>
                                <KeyboardDateTimePicker
                                    animateYearScrolling
                                    ampm={false}
                                    autoOk={true}
                                    variant="inline"
                                    format={user.dateTimeFormat}
                                    id={"filter_date"}
                                    label={user.translate("date")}
                                    value={filterState.selectedDate}
                                    onChange={handleDateChange}
                                    onClose={(e) => props.setQuery(filterState, "date")}
                                    onKeyPress={(e) => {
                                        if (e.key == "Enter") props.setQuery(filterState, "date")
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>

                    </FormGroup>
                )}
            </div>
        </div>
    );
}
