import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useContext } from 'react';
import { UserContext } from '../../../App';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-grid',
        margin: theme.spacing(1),
        minWidth: '20ch',
        flexGrow: 1,
    },
}));

export default function EditTextDateEpoch(props) {
    const classes = useStyles();

    const user = useContext(UserContext);

    let srcDate = new Date(parseInt(props.value));
    if (srcDate == "Invalid Date") srcDate = new Date(parseInt(props.default));
    if (srcDate == "Invalid Date") srcDate = new Date();

    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            className={classes.root}
            fullWidth={props.fullWidth}
            disableToolbar
            autoOk
            variant="inline"
            size={props.size}
            format={user.dateFormat}
            label={props.label || user.translate(props.name)}
            inputVariant="outlined"
            value={srcDate}
            error={props.error}
            disabled={props.disabled}
            onSubmit={props.onSubmit}
            inputProps={{ style: { fontSize: props.size == "small" ? 14 : 16 } }}
            onChange={date => {
                if (!date || date == "Invalid Date") return;
                return props.onChange(date.getTime().toString());
            }}
        />
    </MuiPickersUtilsProvider>

}

EditTextDateEpoch.gql = "Long";