import React, { useContext, useState } from 'react';
import {
    TableCell,
    TableRow,
    IconButton
} from '@material-ui/core';
import { UserContext } from '../../../App';
import FormInvoice from '../../common/FormInvoice';
import InvoiceItemReceiptList from './InvoiceItemReceiptList';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import DescriptionIcon from '@material-ui/icons/Description';
import IconDelete from '@material-ui/icons/Delete';
import helper from "../../common/Helper";
import Show from '../../common/Show';

export default function InvoiceItemView(props) {
    // Get current user preferences (language)
    const user = useContext(UserContext);
    const invoice = props.invoice;
    const invoiceItem = props.invoiceItem;

    let receipts = invoiceItem.invoiceItemReceipts && invoiceItem.invoiceItemReceipts.length > 0 ? true : false;
    let products = invoiceItem.invoiceItemProducts && invoiceItem.invoiceItemProducts.length > 0 && !receipts ? true : false;
    let invoiceReceivingItems = props.invoiceReceivingItems.length > 0 && props.invoiceReceivingItems.find((c) => c.invoiceItemId === invoiceItem.id) ? true : false;

    const [openReceiptView, setOpenReceiptView] = useState(false);

    const [fieldListItems, setFieldListItems] = useState([
        {
            required: true,
            type: "text",
            fieldType: "text",
            fieldName: "article",
            fieldLabel: user.translate("article"),
            fieldValue: invoiceItem.article ? invoiceItem.article[user.lang] : "",
        },
        {
            required: true,
            type: "hidden",
            fieldType: "text",
            fieldName: "articleLangId",
            fieldLabel: "articleLangId",
            fieldValue: invoiceItem.article ? invoiceItem.article.id : "",
        },
        {
            required: false,
            type: "text",
            fieldType: "text",
            fieldName: "description",
            fieldLabel: user.translate("description"),
            fieldValue: invoiceItem.description ? invoiceItem.description[user.lang] : "",
        },
        {
            required: true,
            type: "hidden",
            fieldType: "text",
            fieldName: "descriptionLangId",
            fieldLabel: "descriptionLangId",
            fieldValue: invoiceItem.description ? invoiceItem.description.id : "",
        },
        {
            required: true,
            type: "number",
            fieldType: "text",
            fieldName: "quantity",
            fieldLabel: user.translate("quantity"),
            fieldValue: invoiceItem.quantity ? invoiceItem.quantity : 0,
            decimalScale: 3
        },
        {
            required: true,
            type: "number",
            fieldType: "text",
            fieldName: "price",
            fieldLabel: user.translate("price"),
            fieldValue: invoiceItem.price ? invoiceItem.price : 0,
        },
        {
            required: true,
            type: "number",
            fieldType: "text",
            fieldName: "discount",
            fieldLabel: user.translate("discount"),
            fieldValue: invoiceItem.discount ? invoiceItem.discount * 100 : 0,
            decimalScale: 4
        },
        {
            required: true,
            type: "text",
            fieldType: "select",
            fieldName: "tax",
            fieldLabel: user.translate("vat"),
            fieldValue: invoiceItem.tax ? "" + invoiceItem.tax * 100 : "0",
            fieldOptions: props.taxTypes,
            noNone: true
        },
    ]);

    const setItems = (event, fieldName, fieldValue) => {

        let items = {}, field = {};
        let langId = fieldListItems.find(c => c.fieldName === `${fieldName}LangId`);

        if (fieldName === "article" || fieldName === "description") {
            field = {
                fieldType: `$${fieldName}: String!`,
                field: fieldName
            };
        }
        else {
            field = {
                fieldType: `$${fieldName}: Float!`,
                field: fieldName
            };
        }

        if (typeof langId !== "undefined") {
            items = {
                id: langId.fieldValue,
                [fieldName]: fieldValue,
            }
            props.onEditInvoiceItem(items, field, true);
        }
        else {
            items = {
                id: invoiceItem.id,
                [fieldName]: fieldName === "tax" || fieldName === "discount" ? fieldValue / 100 : fieldValue,
            }
            props.onEditInvoiceItem(items, field, false);
        }

        if (fieldName !== "article" && fieldName !== "description") {
            props.setUpdatedAmount(false);
        }

        user.consoleLog(items);
    }

    const onDeleteClick = () => {
        props.setDeleteState({
            name: invoiceItem.article[user.lang],
            variables: { id: invoiceItem.id } //, receiptId: invoiceItem.invoiceItemReceipts.length > 0 ? invoiceItem.invoiceItemReceipts[0].receipt.id : ""
        })
        props.setOpenDeleteBox(true);
    }

    const onOpenReceipt = () => {
        if (invoiceItem.invoiceItemReceipts.length > 0)
            setOpenReceiptView(true);
    }

    //styles={classes.billingStyles}
    let tabIndex = 27;

    return (
        <>
            <TableRow key={invoiceItem.id}>
                <TableCell className={"no_print"}>
                    {receipts && (
                        <IconButton size="small" color="inherit" onClick={onOpenReceipt}>
                            <ReceiptIcon />
                        </IconButton>
                    )}
                    {products && (
                        <IconButton size="small" color="inherit">
                            <CardGiftcardIcon />
                        </IconButton>
                    )}
                    {invoiceReceivingItems && (
                        <IconButton size="small" color="inherit">
                            <DescriptionIcon />
                        </IconButton>
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    <FormInvoice fieldList={fieldListItems} item={0} tabIndex={tabIndex} setFieldList={setFieldListItems} setItems={setItems} width={"91%"} left={true} table={true} />
                    <FormInvoice fieldList={fieldListItems} item={1} tabIndex={tabIndex} setFieldList={setFieldListItems} setItems={setItems} width={"91%"} left={true} table={true} />
                </TableCell>
                <TableCell>
                    <FormInvoice fieldList={fieldListItems} item={2} tabIndex={tabIndex} setFieldList={setFieldListItems} setItems={setItems} width={"91%"} left={true} table={true} />
                    <FormInvoice fieldList={fieldListItems} item={3} tabIndex={tabIndex} setFieldList={setFieldListItems} setItems={setItems} width={"91%"} left={true} table={true} />
                </TableCell>
                <TableCell align="right">
                    <FormInvoice fieldList={fieldListItems} item={4} tabIndex={tabIndex} setFieldList={setFieldListItems} setItems={setItems} width={"91%"} left={true} table={true} />
                </TableCell>
                <TableCell align="right">
                    <FormInvoice fieldList={fieldListItems} item={5} tabIndex={tabIndex} setFieldList={setFieldListItems} setItems={setItems} width={"91%"} left={true} table={true} />
                </TableCell>
                <TableCell align="right">
                    <FormInvoice fieldList={fieldListItems} item={6} tabIndex={tabIndex} setFieldList={setFieldListItems} setItems={setItems} width={"91%"} left={true} table={true} />
                </TableCell>
                <TableCell align={!props.devizna ? "right" : "right"}>
                    {invoiceItem.price ? user.formatNumber(invoiceItem.price * (1 - invoiceItem.discount)) : 0}
                </TableCell>
                {!props.devizna && (
                    <TableCell align="right">
                        <FormInvoice fieldList={fieldListItems} item={7} tabIndex={tabIndex} setFieldList={setFieldListItems} setItems={setItems} width={"91%"} left={true} table={true} />
                    </TableCell>
                )}
                <TableCell align="right">
                    {invoiceItem.price ? user.formatNumber(invoiceItem.price * invoiceItem.quantity * (1 - invoiceItem.discount)) : 0}
                </TableCell>
                <TableCell className={"no_print"}>
                    <IconButton size="small" color="primary" onClick={(e) => onDeleteClick()}>
                        <IconDelete />
                    </IconButton>
                </TableCell>
            </TableRow>

            <Show if={openReceiptView}>
                <InvoiceItemReceiptList
                    invoice={invoice}
                    itemReceipts={invoiceItem.invoiceItemReceipts}
                    openReceiptView={openReceiptView}
                    setOpenReceiptView={setOpenReceiptView}
                />
            </Show>

        </>

    );

}
