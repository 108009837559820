import { useMutation } from '@apollo/client';
import {
    AppBar, makeStyles, Tab, Tabs
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Menu from '@material-ui/icons/Menu';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { RestaurantContext, UserContext } from "../../App";
import useDragableItem from '../common/Dragable';
import Show from '../common/Show';
import { useEntityRelationQuery } from '../common/useEntityQuery';
import RestaurantMenu from './RestaurantMenu';

// CSS
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        // backgroundColor: theme.palette.background.paper,
    },
    children: {
        padding: 24,
    },
    tabs: {
        display: `grid`,
    },
    gridList: {
        flexGrow: 1,
        width: `100%`
    },
    tab: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        alignItems: 'center'
    },
    dragIcon: {
        height: 24,
        cursor: 'grab',
        touchAction: 'none',
        paddingRight: theme.spacing(1),
        paddingBottom: 0
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const GQL_UPDATE_DISPLAY_INDEX = gql`
    mutation($indices: [InputDisplayIndex!]!)  {
        extras_updateMenusDisplayIndex(indices: $indices)
    }
`;

export default function RestaurantMenuList() {

    const classes = useStyles();

    // Get current restaurant from context
    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const [selectedMenu, setSelectedMenu] = useState(0);

    const [updateDisplayIndex] = useMutation(GQL_UPDATE_DISPLAY_INDEX);


    const FIELDS = [
        { name: "id", gql: "String!", id: true, hidden: true },
        { name: "name", gql: "String!", subSelection: { [user.gqlFetchName()]: true } },
        { name: "displayIndex", gql: "Long!", hidden: true },
        { name: "active", gql: "Boolean", hidden: true },
    ];

    const query = useEntityRelationQuery("RestaurantMenu", FIELDS, "restaurantId", restaurant.id);

    const items = query.items?.orderBy(item => item.displayIndex) || [];

    let selectedIndex = items.findIndex(item => item.id == selectedMenu);
    if (selectedIndex == -1) selectedIndex = 0;
    const currentMenu = items[selectedIndex];

    const onReorder = (item, newIndex) => {
        if (item.displayIndex == newIndex) return;
        return updateDisplayIndex({
            variables: {
                indices: items.move(item.displayIndex, newIndex).map((it, index) => ({ id: it.id, index: index }))
            }
        }).then(() => query.refresh());
    }

    return <div className={classes.root}>
        <AppBar className={classes.tabs} position="static" color="default">
            <Tabs
                value={selectedIndex}
                onChange={(e, value) => setSelectedMenu(items[value]?.id)}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {
                    items.map((menu, index) => (
                        <MenuTab
                            key={menu.id}
                            item={menu}
                            onReorder={onReorder}
                            label={menu.name[user.lang]}
                            style={{ backgroundColor: menu.active === 0 ? 'rgba(0, 0, 0, 0.05)' : 'transparent' }}
                            {...a11yProps(index)} />
                    ))
                }
                <Tab key="new" label={<Add style={{ color: '#648da1' }} fontSize="small" />} {...a11yProps(items.length)} />
            </Tabs>
        </AppBar>
        <Show if={currentMenu}>
            <RestaurantMenu key={currentMenu?.id} menuId={currentMenu?.id} />
        </Show>

    </div>


}

export function MenuTab(props) {
    const classes = useStyles();

    const dragableItem = useDragableItem("horizontal", (index) => props.onReorder(props.item, index));

    return <Tab {...props} style={{ backgroundColor: '#FAFAFA' }} ref={dragableItem.ref} label={
        <div className={classes.tab}>
            <dragableItem.Handle className={classes.dragIcon}>
                <Menu />
            </dragableItem.Handle>
            {props.label}
        </div>
    } />

}

