import { Divider, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useWindowSize from './useWindowSize';
var hash = require('object-hash');


const useStyles = makeStyles((theme) => ({
    listRoot: {
        overflow: 'scroll',
        maxHeight: '100%'
    },
    listItem: {
        position: 'absolute',
        // transition: 'opacity 0.3s ease 0s'
    }
}));


const isScrollable = function (ele) {
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

    const overflowYStyle = window.getComputedStyle(ele).overflowY;
    const isOverflowScroll = overflowYStyle.indexOf('scroll') !== -1;
    const isOverflowAuto = overflowYStyle.indexOf('auto') !== -1;

    return hasScrollableContent && (isOverflowScroll || isOverflowAuto);
};

const getScrollableParent = function (ele) {
    return (!ele || ele === document.body)
        ? window
        : (isScrollable(ele) ? ele : getScrollableParent(ele.parentNode));
};

export default function VirtualList(props) {
    const items = props.items;
    const throttle = Boolean(props.eventsThrottle != undefined ? props.eventsThrottle : true);
    const classes = useStyles();

    const size = useWindowSize(false);

    var [listSize, setListSize] = useState({ parentHeight: 100 });

    const [scrollPosition, setScrollPosition] = useState(0);

    const listScrollRef = useRef();

    const itemSizesRef = useRef({});
    var [itemSizesCounter, setItemSizesCounter] = useState(0);

    const scrollTimer = useRef();
    const resizeTimer = useRef();
    const itemsCache = useRef({});

    const handleScroll = useCallback((e) => {
        if (scrollTimer.current) return;
        const el = e.target;

        setScrollPosition(el.scrollTop != undefined ? el.scrollTop : window.pageYOffset);

        if (throttle) {
            scrollTimer.current = setTimeout(() => {
                setScrollPosition(el.scrollTop != undefined ? el.scrollTop : window.pageYOffset);
                scrollTimer.current = undefined;
            }, 100);
        }
    });

    useEffect(() => {
        let el = listScrollRef.current;
        if (el == undefined) return;
        el.addEventListener('scroll', handleScroll, { passive: throttle });

        return () => {
            el.removeEventListener('scroll', handleScroll);
        }
    }, [listScrollRef.current])



    const resizeListObserver = React.useRef(new ResizeObserver((entries) => {
        if (entries.length == 0) return;
        let entry = entries[0];
        let rect = entry.contentRect;
        let size = {
            width: rect.width,
            height: rect.height,
            parentHeight: entry.target.parentElement.clientHeight,
            top: listSize.top || (entry.target.getBoundingClientRect().top - entry.target.offsetParent.getBoundingClientRect().top)
        };

        if (size.height > listSize.height) {
            listScrollRef.current = getScrollableParent(entry.target);
        }

        if (size.width != listSize.width || size.height != listSize.height || size.top != listSize.top) {
            listSize = size;
            setListSize(size);
        }

    }));

    const resizedListContainerRef = React.useCallback((container) => {
        if (container !== null) {
            resizeListObserver.current.observe(container);
        } else if (resizeListObserver.current) {
            resizeListObserver.current.disconnect();
        }
    }, [resizeListObserver.current]);


    const onItemResize = (item, entry) => {
        let newSize = { width: entry.contentRect.width, height: entry.contentRect.height };

        if (entry.target.style.opacity == 0) {
            entry.target.style.opacity = 1;
        }

        let currentSize = itemSizesRef.current[item.id];

        if (currentSize?.isMeasured == true && (currentSize.width == newSize.width && currentSize.height == newSize.height)) {
            return
        }

        newSize.isMeasured = newSize.height > 0;
        itemSizesRef.current[item.id] = newSize;

        if (currentSize?.height != undefined) {
            let deltaHeight = newSize.height - (currentSize?.height || 0);

            if (deltaHeight != 0) {
                var nextEl = entry.target.nextSibling;
                while (nextEl != null && nextEl.style) {
                    nextEl.style.top = (parseFloat(nextEl.style.top) + deltaHeight) + 'px';
                    nextEl = nextEl.nextSibling;
                }
            }
        }

        clearTimeout(resizeTimer.current);
        resizeTimer.current = setTimeout(() => {
            resizeTimer.current = undefined;
            setItemSizesCounter(++itemSizesCounter);
        }, 100);
    }

    const cachedDependencies = itemsCache.current?.dependencies || '';
    const propsDependencies = (props.dependencies || []).join();

    if (cachedDependencies !== propsDependencies) {
        itemsCache.current = { items, dependencies: propsDependencies };
    } else if (itemsCache.current.items !== items) {
        items.forEach(item => {
            let itemHash = props.staticItems ? item.id : JSON.stringify(item);
            if (itemsCache.current[item.id]?.itemHash == itemHash) return;

            itemsCache.current[item.id] = { itemHash }
        });
        itemsCache.current.items = items;
        itemsCache.current.dependencies = propsDependencies;
    }

    let filteredItems = useMemo(() => {
        if (props.search == undefined || props.search.length == 0) return items;

        if (props.searchWarmup && itemsCache.current.searchWarmedUp != true) {
            items.forEach(props.searchWarmup);
            itemsCache.current.searchWarmedUp = true;
        }

        let search = props.search;
        if (props.searchMatch) {
            return items.filter(item => props.searchMatch(item, search));
        }

        return items;

    }, [items, props.search])

    const maxRenderItems = props.maxRenderItems || 30;
    const offScreenOffset = props.offScreenOffset || 100;

    var minItemSize = 0;
    var maxItemSize = 0;

    Object.values(itemSizesRef.current).forEach(item => {
        let height = item.height || 0;
        if (height && height > 0) {
            if (minItemSize == 0 || height < minItemSize)
                minItemSize = height;

            if (height > maxItemSize)
                maxItemSize = height;

        }
    });
    if (minItemSize == 0) minItemSize = 60;

    var offset = listSize.top || 0;

    let listTop = scrollPosition - offScreenOffset - offset;
    let listBottom = scrollPosition + Math.min(listSize?.parentHeight || size.height, size.height) + offset + offScreenOffset;

    var renderedItems = 0

    let itemsCount = filteredItems.length;

    var listMinHeight = Math.max(filteredItems.length * minItemSize, itemSizesRef.current.listHeight) || (filteredItems.length * maxItemSize);

    var listItems = [];

    const getOrCreateItemCache = (item, idx, size) => {
        // TODO: props changes not detected. pass props and component and compare props
        const cache = itemsCache.current[item.id] || {};
        if (cache.itemRender) return cache.itemRender;

        let itemRender = props.renderItem(item, idx, size);

        cache.itemRender = itemRender;
        itemsCache.current[item.id] = cache;

        return itemRender;
    }

    for (var idx = 0; idx < itemsCount; idx++) {
        let item = filteredItems[idx];
        let itemSize = itemSizesRef.current[item.id];
        let itemHeight = itemSize?.height;
        let isMeasured = itemSize?.isMeasured == true;
        if (!isMeasured) {
            itemHeight = minItemSize;
            itemSizesRef.current[item.id] = { width: listSize.width, height: itemHeight };
        }
        let itemTop = offset;
        let itemBottom = offset + itemHeight;

        if (itemTop > listBottom) break;

        if (!isMeasured || itemBottom >= listTop) {
            if (renderedItems > 0 && props.renderDivider) {
                listItems.push({ divider: true, idx: idx, width: listSize.width, top: itemTop });
                itemTop = itemTop + 1;
                itemBottom = itemBottom + 1;
            }
            offset = itemBottom;

            listItems.push({
                item: item,
                id: item.id || idx,
                itemId: item.id,
                itemIndex: idx,
                top: itemTop,
                width: listSize.width,
                isMeasured: isMeasured
            });
            renderedItems++;
            if (renderedItems >= maxRenderItems) break;
        } else {
            offset = itemBottom;
        }
        if (idx == itemsCount - 1) {
            listMinHeight = itemBottom;
            itemSizesRef.current.listHeight = listMinHeight;
        } else if (itemBottom > listMinHeight) {
            listMinHeight = itemBottom;
        }
    }

    const preRenderedItems = useMemo(() => {
        return listItems.map(listItem => {
            if (listItem.divider) {
                return <Divider
                    key={`divider_${listItem.idx}`}
                    style={{ position: 'absolute', width: listItem.width, top: listItem.top }}
                />
            }

            const listItemHash = props.staticItems ? listItem.id + listItem.top + listItem.isMeasured + listItem.itemIndex : JSON.stringify(listItem);
            const cache = itemsCache.current[listItem.id] || {};
            if (cache.listItemRender && cache.listItemHash == listItemHash) return cache.listItemRender;

            let item = filteredItems[listItem.itemIndex];
            let listItemRender = (
                <ListItem
                    item={item}
                    top={listItem.top}
                    width={listItem.width}
                    isMeasured={listItem.isMeasured}
                    key={listItem.id}
                    classes={classes}
                    id={listItem.id}
                    onResize={onItemResize}>
                    {getOrCreateItemCache(item, listItem.itemIndex, size)}
                </ListItem>
            );

            cache.listItemRender = listItemRender;
            cache.listItemHash = listItemHash;
            itemsCache.current[listItem.id] = cache;

            return listItemRender;

            // if (listItem.divider) {
            //     return <Divider
            //         key={`divider_${listItem.idx}`}
            //         style={{ position: 'absolute', width: listItem.width, top: listItem.top }}
            //     />
            // }
            // let item = filteredItems[listItem.itemIndex];
            // return <ListItem
            //     item={item}
            //     top={listItem.top}
            //     width={listItem.width}
            //     isMeasured={listItem.isMeasured}
            //     key={listItem.id}
            //     classes={classes}
            //     id={listItem.id}
            //     onResize={onItemResize}>
            //     {getOrCreateItemCache(item, listItem.itemIndex, size)}
            // </ListItem>
        })
    }, [props.staticItems ? listItems.map(item => item.id).join() : JSON.stringify(listItems)]);

    return <div ref={resizedListContainerRef} style={{ minHeight: listMinHeight }} className={props.className}>
        {preRenderedItems}
    </div>
}


export function ListItem(props) {
    const item = props.item;
    const id = item.id || props.idx;

    const currentSize = useRef();

    const resizeObserver = React.useRef(new ResizeObserver((entries) => {
        if (entries.length == 0) return;
        let { width, height } = entries[0].contentRect;
        if (currentSize.current && currentSize.current.width == width && currentSize.current.height == height) return;
        currentSize.current = { width, height };
        props.onResize(item, entries[0]);
    }));

    const resizedContainerRef = React.useCallback(function (container) {
        if (container !== null)
            resizeObserver.current.observe(container);
        else if (resizeObserver.current)
            resizeObserver.current.disconnect();
    }, [resizeObserver.current]);

    return <div
        id={id}
        key={id}
        ref={resizedContainerRef}
        className={props.classes.listItem}
        style={{
            width: props.width,
            top: props.top,
            opacity: props.isMeasured ? 1 : 0,
        }}>
        {props.children}
    </div>
}
// style={{
//     width: props.width,
//     top: props.isMeasured ? props.top : (props.top + 500),
//     opacity: (props.isMeasured && !firstRender) ? 1 : 0,
//     transition: ((props.isMeasured && !props.isItemResize) ? 'top 0.3s ease 0s, ' : '') + 'opacity 0.3s cubic-bezier(1, 0.01, 1, 1) 0s'
// }}>