import React, {useContext, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {gql, useMutation, useQuery} from '@apollo/client';
import {RestaurantContext, UserContext} from "../../App";
import {CircularProgress, IconButton, makeStyles, Tooltip,} from '@material-ui/core';
import Table from '../common/Table';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SnackBarDelete from '../common/SnackBarDelete';
import SnackBarWarning from '../common/SnackBarWarning';
import KomitentBilling from './KomitentBilling';
import helper from '../common/Helper';
import tableComponents from '../common/TableComponents';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: 3
        }
    },
    hidden: {
        visibility: "hidden"
    },
    show: {
        visibility: "inherit"
    }
}));

// React Function Component
export default function KomitentList(props) {

    const classes = useStyles();

    let path = new URLSearchParams(useLocation().search);
    let _rowData = {};
    path.forEach(function (value, key) {
        if (key === "id") _rowData.id = value;
        if (key === "name") _rowData.name = value;
    });

    let history = useHistory();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const [refetchIt, setRefetchIt] = useState(false);
    const [page, setPage] = useState(0);

    const [rowData, setRowData] = useState(_rowData.id ? _rowData : false);
    if (rowData && !_rowData.id) {
        if (!props.handleSelectAccept) setRowData(false);
    }

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false);
    const [deleteState, setDeleteState] = useState({
        variables: {},
        name: "",
        state: {}
    });

    const ADD_ITEM = gql`
      mutation ($id: String!, $restaurantId: String!, $cityId: String!, $city: String!, $countryId: String!, $country: String!, ${user.me.profile && user.me.profile.isAdmin ? "$nameMk: String!, $nameEn: String!" : "$name: String!"}, $imageLogo: String!, $imageLogoBg: String!, $locationLat: Float!, $locationLng: Float!, $locationRadius: Float!, $address: String!, $phone: String!, $website: String!, $workingHours: String!, $defOrderType: Long, $type: String!) {
            createCity(input: {
                id: $cityId
                ${user.gqlCreateName("$city")}
                    createCountry: {
                        id: $countryId
                        ${user.gqlCreateName("$country")}
                    }
            }) {
                id
            }
            createCommissioner(input:{
                createCommissioner: {
                    id: $id
                    ${user.me.profile && user.me.profile.isAdmin ? user.gqlCreateNameTr("$nameMk", "$nameEn") : user.gqlCreateName("$name")}
                    city: $cityId
                    imageLogo: $imageLogo
                    imageLogoBg: $imageLogoBg
                    locationLat: $locationLat
                    locationLng: $locationLng
                    locationRadius: $locationRadius
                    address: $address
                    phone: $phone
                    website: $website
                    workingHours: $workingHours
                    version: 0
                    active: 0
                }
                restaurantId: $restaurantId
                defOrderType: $defOrderType
                type: $type
            }){
                commissionerId
            }
      }`;
    const [addItem, {loading: loading_add}] = useMutation(ADD_ITEM, {
        skip: !refetchIt
    });

    const EDIT_ITEM = gql`
      mutation ($id: String!, $cityId: String!, $city: String!, $countryId: String!, $country: String!, ${user.me.profile && user.me.profile.isAdmin ? "$nameMk: String!, $nameEn: String!" : "$name: String!"}, $langId: String!, $imageLogo: String!, $imageLogoBg: String!, $locationLat: Float!, $locationLng: Float!, $locationRadius: Float!, $address: String!, $phone: String!, $website: String!, $workingHours: String!, $defOrderType: Long, $type: String!, $discount: Float!) {
            createCity(input: {
                id: $cityId
                ${user.gqlCreateName("$city")}
                    createCountry: {
                        id: $countryId
                        ${user.gqlCreateName("$country")}
                    }
            }) {
                id
            }
            createRestaurant(input:{
                id: $id
                ${user.me.profile && user.me.profile.isAdmin ? user.gqlEditNameTr("$nameMk", "$nameEn", "$langId") : user.gqlEditName("$name", "$langId")}
                city: $cityId
                imageLogo: $imageLogo
                imageLogoBg: $imageLogoBg
                locationLat: $locationLat
                locationLng: $locationLng
                locationRadius: $locationRadius
                address: $address
                phone: $phone
                website: $website
                workingHours: $workingHours
                version: 0
            }){
                id
            }
            editCommissioner(input: {
                commissionerId: $id
                restaurantId: "${restaurant.id}"
                defOrderType: $defOrderType
                type: $type
                discount: $discount
            }) {
                commissionerId
            }
      }`;
    const [editItem, {loading: loading_edit}] = useMutation(EDIT_ITEM, {
        skip: !refetchIt
    });

    const DELETE_ITEM = gql`
      mutation ($id: String!) {
        editRestaurant(input:{
            id: $id, 
            active: 0
        }){
            id
        }
        deleteCommissioner(commissionerId: $id)
    }`;
    const [deleteItem, {loading: loading_delete}] = useMutation(DELETE_ITEM, {
        skip: !refetchIt
    });
    const UNDELETE_ITEM = `
      mutation ($id: String!) {
        editRestaurant(input:{
            id: $id, 
            active: 1
        }){
            id
        }
        undeleteCommissioner(commissionerId: $id)
    }`;
    // const [unDeleteItem] = useMutation(UNDELETE_ITEM);

    // GraphQL API request definition (local variables: restaurantId)
    const GET_KOMITENT_BY_RESTAURANT = gql`
      query ($restaurantId: String!){
        getCommissionersByRestaurantId(restaurantId: $restaurantId) {
            defOrderType
            type
            discount
            commissioner {
                id
                name {
                    ${user.gqlFetchName()}
                }
                city {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                    country {
                        id 
                        name {
                            ${user.gqlFetchName()}
                        }
                    }
                }
                imageLogo
                imageLogoBg
                locationLat
                locationLng
                locationRadius
                address
                phone
                website
                workingHours
                payerCompanyInvoicesCount
                payeeCompanyInvoicesCount
            }
        }
        getAllCitys {
            id
            name{
                ${user.gqlFetchName()}
            }
            country {
                id
                name{
                    ${user.gqlFetchName()}
                }
            }
        }
        getAllCountrys {
            id
            name{
                ${user.gqlFetchName()}
            }
        }
    }`;

    // Make the api request or get cached.
    // This makes the componnet to refresh when new data is available i.e. api finished.
    const {data, loading, error, refetch} = useQuery(GET_KOMITENT_BY_RESTAURANT, {
        variables: {restaurantId: restaurant.id},
        pollInterval: 3000,
    });

    if (loading) {
        return (<div className="App AppLoading"><CircularProgress/></div>);
    }

    // In case there is an error, just show it for now
    // if (!data) { user.consoleLog(error); return <p>Error</p> }

    let data_arr = {};
    data_arr = Object.values(data.getCommissionersByRestaurantId);

    let alertMessage = `${user.translate("cant_delete_komitent")} `,
        autoHideDuration = 3000, linkTo = ``, linkText = "";

    let fieldList = {}, cities, countries;

    cities = data.getAllCitys.map(_city => (
        {
            ..._city,
            name: _city.name[user.lang],
            countryId: _city.country.id
        }
    ));

    countries = data.getAllCountrys.map(result => (
        {
            ...result,
            name: result.name[user.lang],
        }
    ));

    let firstCity = cities.slice(0, 1);
    let firstCountry = countries.slice(0, 1);

    let defOrderTypes = [
        {val: "null", label: user.translate("auto")},
        {val: "0", label: user.translate("in_restaurant")},
        {val: "1", label: user.translate("take_away")},
        {val: "2", label: user.translate("catering")},
    ];
    let commissionerTypes = [
        {val: "REGULAR", label: user.translate("regular")},
        {val: "VOID", label: user.translate("void")},
        {val: "EXPENSES", label: user.translate("expenses")},
        {val: "MISC", label: user.translate("misc")},
    ];

    fieldList['columns'] = [
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'Id', field: 'id', hidden: true, export: false},
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'cityId', field: 'cityId', hidden: true, export: false},
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'countryId', field: 'countryId', hidden: true, export: false},
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'langId', field: 'langId', hidden: true, export: false},
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'defOrderType', field: 'defOrderType', hidden: true, export: false},
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'type', field: 'type', hidden: true, export: false},
        // {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'commissionerReceiptsCount', field: 'commissionerReceiptsCount', hidden: true, export: false},
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'payerCompanyInvoicesCount', field: 'payerCompanyInvoicesCount', hidden: true, export: false},
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'payeeCompanyInvoicesCount', field: 'payeeCompanyInvoicesCount', hidden: true, export: false},
        {title: "", field: 'count', editable: "never", cellStyle: {width: "1%", padding: `0 5px 0 0`, fontSize: 12, whiteSpace: 'pre-line'}},
        {
            title: user.translate("title"), field: 'name', cellStyle: {width: "30%", padding: 0, fontSize: 14, whiteSpace: 'pre-line'},
            customSort: (a, b) => a.name.cirilicLatinCompare(user.lang).localeCompare(b.name.cirilicLatinCompare(user.lang), user.lang)
        },
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'extra_empty', field: 'extra_empty', hidden: true, export: false},
        {
            title: user.translate("order_type"), field: 'defOrderTypeLabel',
            editComponent: (props) => tableComponents.Select(props, defOrderTypes, "defOrderType"),
            customSort: (a, b) => a.defOrderType.cirilicLatinCompare(user.lang).localeCompare(b.defOrderType.cirilicLatinCompare(user.lang), user.lang)
        },
        {
            width: '1%', title: user.translate('discount'), field: 'discount',
            render: (rowData) => rowData.discount ? user.formatNumber(rowData.discount, true) : "",
            editComponent: (props) => tableComponents.NumericEditComponent(props, "discount", true, 0, 0, (item, value, isInput) => (isInput) ? value / 100 : value * 100),
            hidden: false, export: false
        },
        {
            title: user.translate("address"),
            field: 'address',
            customSort: (a, b) => a.address.cirilicLatinCompare(user.lang).localeCompare(b.address.cirilicLatinCompare(user.lang), user.lang)
        },
        {
            title: user.translate("country"), field: 'country', editComponent: (props) => {
                return tableComponents.AutoCompleteAdd(props, countries, "countryId", "country", firstCountry);
            }, customSort: (a, b) => a.country.cirilicLatinCompare(user.lang).localeCompare(b.country.cirilicLatinCompare(user.lang), user.lang)
        },
        {
            title: user.translate("city"), field: 'city', editComponent: (props) => {
                return tableComponents.AutoCompleteAdd(props, cities, "cityId", "city", firstCity);
            }, customSort: (a, b) => a.city.cirilicLatinCompare(user.lang).localeCompare(b.city.cirilicLatinCompare(user.lang), user.lang)
        },
        {title: user.translate("phone"), field: 'phone'}, {
            title: user.translate("type"), field: 'typeLabel',
            editComponent: (props) => tableComponents.Select(props, commissionerTypes, "type"),
            customSort: (a, b) => a.type.cirilicLatinCompare(user.lang).localeCompare(b.type.cirilicLatinCompare(user.lang), user.lang),
            hidden: !user.me.profile.isAdmin
        },
        {title: 'Website', field: 'website', hidden: true, export: false},
        {title: 'Working Hours', field: 'workingHours', hidden: true, export: false},
        {title: 'Image Logo', field: 'imageLogo', hidden: true, export: false}, //, render: rowData => <img src={rowData.imageLogo} style={{width: 50}}/>
        {title: 'Image Logo Bg', field: 'imageLogoBg', hidden: true, export: false},
        {title: 'Location Latitude', field: 'locationLat', hidden: true, export: false},
        {title: 'Location Longitude', field: 'locationLng', hidden: true, export: false},
        {title: 'Location Radius', field: 'locationRadius', hidden: true, export: false},
        {
            width: `65px`, title: "", field: 'billings', editable: 'never',
            render: rowData => (
                <Tooltip title={`${user.translate("billings")}`} placement="top">
                    <IconButton
                        color="primary"
                        onClick={(event) => onRowClick(rowData)}
                    >
                        <ArrowForwardIcon/>
                    </IconButton>
                </Tooltip>
            ),
            sorting: false, export: false, disableClick: true, cellStyle: {padding: `2px 0`, whiteSpace: 'nowrap'},
            customFilterAndSearch: (filterValue, row) =>
                (row.name && row.name.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                (row.nameMk && row.nameMk.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                (row.nameEn && row.nameEn.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                (row.address && row.address.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                (row.defOrderTypeLabel && row.defOrderTypeLabel.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                (row.typeLabel && row.typeLabel.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                (row.city && row.city.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1),
        },
    ];

    if (user.me.profile && user.me.profile.isAdmin) {
        fieldList['columns'][9] = {
            title: user.translate("titleMk"), field: 'nameMk', cellStyle: {width: "30%", padding: "0 5px 0 0", fontSize: 14, whiteSpace: 'pre-line'},
            customSort: (a, b) => a.name.cirilicLatinCompare("mk").localeCompare(b.name.cirilicLatinCompare("mk"), "mk")
        }
        fieldList['columns'][10] = {
            title: user.translate("titleEn"), field: 'nameEn', cellStyle: {width: "30%", padding: "0 5px 0 0", fontSize: 14, whiteSpace: 'pre-line'},
            customSort: (a, b) => a.name.cirilicLatinCompare("en").localeCompare(b.name.cirilicLatinCompare("en"), "en")
        }
    }

    fieldList['data'] = data.getCommissionersByRestaurantId.map((result, index) => (
        {
            ...result.commissioner,
            count: (index + 1) + ".",
            langId: result.commissioner.name.id,
            name: result.commissioner.name[user.lang],
            nameMk: result.commissioner.name["mk"],
            nameEn: result.commissioner.name["en"],
            city: result.commissioner.city.name[user.lang],
            cityId: result.commissioner.city.id,
            countryId: result.commissioner.city.country.id,
            country: result.commissioner.city.country.name[user.lang],
            defOrderTypeLabel: result.defOrderType !== null ? defOrderTypes.find(c => c.val === "" + result.defOrderType)?.label : user.translate("auto"),
            defOrderType: result.defOrderType !== null ? "" + result.defOrderType : "null",
            typeLabel: result.type !== null ? commissionerTypes.find(c => c.val === "" + result.type)?.label : user.translate("auto"),
            discount: result.discount,
        }
    ));

    const setItems = (dataObj, action) => {
        let items = {};

        items = {
            ...dataObj,
            id: dataObj.id ? dataObj.id : helper.uid(),
            name: dataObj.name ? dataObj.name : '',
            nameMk: dataObj.nameMk ? dataObj.nameMk : '',
            nameEn: dataObj.nameEn ? dataObj.nameEn : '',
            address: dataObj.address,
            phone: dataObj.phone,
            website: dataObj.website,
            workingHours: dataObj.workingHours,
            imageLogo: dataObj.imageLogo,
            imageLogoBg: dataObj.imageLogoBg,
            locationLat: dataObj.locationLat ? dataObj.locationLat : "0",
            locationLng: dataObj.locationLng ? dataObj.locationLng : "0",
            locationRadius: dataObj.locationRadius ? dataObj.locationRadius : "0",
            restaurantId: restaurant.id,
            city: dataObj.city ? dataObj.city : firstCity[0].name,
            cityId: dataObj.cityId ? dataObj.cityId : firstCity[0].id,
            countryId: dataObj.countryId ? dataObj.countryId : firstCountry[0].id,
            country: dataObj.country ? dataObj.country : firstCountry[0].name,
            defOrderType: dataObj.defOrderType && dataObj.defOrderType !== "null" ? dataObj.defOrderType : null,
        };

        for (var key in items) {
            if (typeof items[key] == "undefined") {
                items[key] = "";
            }
        }

        return items;
    };

    const onAddItem = (newData) => {
        let items = setItems(newData, 'add');
        user.consoleLog(items);
        setPage(Math.round((data.getCommissionersByRestaurantId.length + 1) / 50));
        addItem({
            variables: {type: "REGULAR", ...items}
        });
        setRefetchIt(true);
    };

    const onEditItem = (newData) => {
        const data = {type: 'REGULAR', ...newData}

        let items = setItems(data, 'edit');
        user.consoleLog(items);
        editItem({variables: items});
        setRefetchIt(true);
    };

    const onDeleteItem = (oldData, state) => {

        let items = {};
        items['id'] = oldData.id;

        user.consoleLog(items);
        if (oldData.payerCompanyInvoicesCount === 0 && oldData.payeeCompanyInvoicesCount === 0) {
            deleteItem({variables: items});

            setDeleteState({
                variables: items,
                name: oldData['name'],
                state: state
            })

            setOpenSnackBar(true);
            setRefetchIt(true);
        } else {
            setOpenSnackBarWarning(true);
        }
    };

    const onRowClick = (_rowData) => {
        if (!props.handleSelectAccept)
            history.push(`/restaurant/${restaurant._id}/komitent/?id=${_rowData.id}&name=${_rowData.name.replaceAll("/", "_")}`);
        setRowData(_rowData);
    }

    if (!loading && !loading_add && !loading_edit && !loading_delete && refetchIt) {
        refetch();
        user.consoleLog("Refetch Done!");
        setRefetchIt(false);
    }

    return (
        <div className={classes.root}>
            <Table
                key={helper.hash(user.lang + page)}
                tableName={user.translate("komitents")}
                exportFileName={user.translate("komitents")}
                fieldList={fieldList}
                pageSize="50"
                pageSizeOptions={[50, 250, 500]}
                onAddItem={onAddItem}
                onEditItem={onEditItem}
                onDeleteItem={onDeleteItem}
                page={page}
                onRowClick={onRowClick}
                hidden={rowData}
                refetch={refetch}
            />


            {!rowData && openSnackBar && (
                <SnackBarDelete
                    message={`${deleteState.name} 
                    ${user.translate("deleted")}!`}
                    variables={deleteState.variables}
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                    unDeleteItem={UNDELETE_ITEM}
                    onSuccess={refetch}
                />
            )}

            {openSnackBarWarning && (
                <SnackBarWarning
                    message={alertMessage}
                    linkTo={linkTo}
                    linkText={linkText}
                    autoHideDuration={autoHideDuration}
                    openSnackBar={openSnackBarWarning}
                    setOpenSnackBar={setOpenSnackBarWarning}
                />
            )}

            <KomitentBilling
                key={helper.hash(JSON.stringify(rowData))}
                handleSelectAccept={props.action}
                rowData={rowData}
                setRowData={setRowData}
            />

        </div>
    )

}
