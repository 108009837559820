import React, { useContext, useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Link, useParams, useHistory } from "react-router-dom";
import { RestaurantContext, UserContext } from "../../App";
import { 
    IconButton, 
    makeStyles,
} from '@material-ui/core';
import Table from '../common/Table';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SnackBarDelete from '../common/SnackBarDelete';
import helper from '../common/Helper';
import tableComponents from '../common/TableComponents';

const useStyles = makeStyles((theme) => ({
	roots: {
		// display: 'inline-flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
        overflow: 'hidden',
        position: "relative"
    },
    back: { 
        color: "inherit", 
        position: "absolute",
        top: 7,
        left: 6,
        zIndex: 1
    },
}));

// React Function Component
export default function ProductItemList(props) {

	const classes = useStyles();

	const restaurant = useContext(RestaurantContext);
	const user = useContext(UserContext);
	let productId, productName, history = useHistory();

	if (props.rowData) {
		productName = props.rowData.name;
		productId = props.rowData.id;
	}

	const [refetchIt, setRefetchIt] = useState(false);
	const [page, setPage] = useState(0);

	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [deleteState, setDeleteState] = useState({
		variables: {},
		name: ""
	});

	const ADD_ITEM = gql`
    mutation ($productId: String!, $stockTypeId: String!, $quantity: Float!, $minQuantity: Float, $maxQuantity: Float, $quantityStep: Float ) {
        deleteProductItem(productId: $productId, stockTypeId: $stockTypeId)
        createProductItem(input:{
            productId: $productId
            stockTypeId: $stockTypeId
            quantity: $quantity
            minQuantity: $minQuantity
            maxQuantity: $maxQuantity
            quantityStep: $quantityStep
        }){
          productId
        }
    }`;
	const [addItem, { loading: loading_item_add }] = useMutation(ADD_ITEM, {
        skip: !refetchIt
    });

	const EDIT_ITEM = gql`
    mutation ($productId: String!, $oldStockTypeId: String!, $stockTypeId: String!, $quantity: Float!, $minQuantity: Float, $maxQuantity: Float, $quantityStep: Float ) {
        deleteProductItem(productId: $productId, stockTypeId: $oldStockTypeId)
        createProductItem(input:{
            productId: $productId
            stockTypeId: $stockTypeId
            quantity: $quantity
            minQuantity: $minQuantity
            maxQuantity: $maxQuantity
            quantityStep: $quantityStep
        }){
          productId
        }
    }`;
	const [editItem, { loading: loading_item_edit }] = useMutation(EDIT_ITEM, {
        skip: !refetchIt
    });

	const DELETE_ITEM = gql`
        mutation ($productId: String!, $stockTypeId: String!) {
            deleteProductItem(productId: $productId, stockTypeId: $stockTypeId)
    }`;
	const [deleteItem, { loading: loading_item_delete }] = useMutation(DELETE_ITEM, {
        skip: !refetchIt
    });

	const UNDELETE_ITEM = `
        mutation ($productId: String!, $stockTypeId: String!) {
            undeleteProductItem(productId: $productId, stockTypeId: $stockTypeId)
    }`;

	// GraphQL API request definition (local variables: restaurantId)
	const GET_PRODUCT_ITEM_BY_RESTAURANT = gql`
    query ($productId: String!, $restaurantId: String!){
        getProductItemsByProductId(productId: $productId) {
            stockType {
                id
                uom
                name {
                    ${user.gqlFetchName()}
                }
            }
            quantity
            minQuantity
            maxQuantity
            quantityStep
        }
        getStockTypesByRestaurantId(restaurantId: $restaurantId) {
            id
            name{
                ${user.gqlFetchName()}
            }
            uom
        }
    }`;

	// Make the api request or get cached.
	// This makes the componnet to refresh when new data is available i.e. api finished.
	const { data: data_item, loading: loading_item, error: error_item, refetch } = useQuery(GET_PRODUCT_ITEM_BY_RESTAURANT, {
		variables: { productId: productId, restaurantId: restaurant.id },
		skip: !props.rowData,
		errorPolicy: "ignore",
		fetchPolicy: "network-only"
    });
    
	// if(loading_item) {
	//     return (<div className="App AppLoading"><CircularProgress /></div>);
	// }

	// In case there is an error, just show it for now
	if (!data_item) { return <div></div> }

	let fieldList = {}, lookup = {}, stockTypes = [];

	let lookupUom = {
		0: user.translate("liter"),
		1: user.translate("kilogram"),
		2: user.translate("piece"),
	};

	stockTypes = data_item.getStockTypesByRestaurantId.map(_stockType => {

		lookup[_stockType.id] = _stockType.name[user.lang];
		stockTypes.push({
			name: _stockType.name[user.lang] + " (" + lookupUom[_stockType.uom] + ")",
			id: _stockType.id,
		});

		return (
			{
				..._stockType,
				name: _stockType.name[user.lang] + " (" + lookupUom[_stockType.uom] + ")",
			}
		)
	});

	fieldList['columns'] = [
		{ width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'productId', field: 'productId', hidden: true },
		{ width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'oldStockTypeId', field: 'oldStockTypeId', hidden: true },
		{ width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'stockTypeId', field: 'stockTypeId', hidden: true },
        { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'uom', field: 'uom', hidden: true },
        { title: "", field: 'count', editable: "never", cellStyle: { width: "1%", padding: `0 5px 0 0`, fontSize: 12, whiteSpace: 'pre-line' } },
        { title: user.translate("stock_type"), field: 'stockType', 
            cellStyle: { width: "30%", padding: 0, fontSize: 14, whiteSpace: 'pre-line' },
            editComponent: (props) => tableComponents.AutoComplete(props, stockTypes, "stockTypeId", "stockType"), 
			customFilterAndSearch: (filterValue, row) => {
				return (row.stockType && row.stockType.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                (row.quantity && user.formatQuantity(row.quantity).indexOf(filterValue) > -1) ||
                (row.minQuantity && user.formatQuantity(row.minQuantity).indexOf(filterValue) > -1) ||
                (row.maxQuantity && user.formatQuantity(row.maxQuantity).indexOf(filterValue) > -1) ||
                (row.quantityStep && user.formatQuantity(row.quantityStep).indexOf(filterValue) > -1);
            },
            customSort: (a, b) => a.stockType.cirilicLatinCompare(user.lang).localeCompare(b.stockType.cirilicLatinCompare(user.lang), user.lang)
		},
        { title: user.translate("quantity"), field: 'quantity', 
            render: (rowData) => user.formatQuantity(rowData.quantity),
            editComponent: (props) => tableComponents.NumericEditComponent(props, "quantity", false, 3), 
        },
        { title: user.translate("min_quantity"), field: 'minQuantity', 
            render: (rowData) => user.formatQuantity(rowData.minQuantity),
            editComponent: (props) => tableComponents.NumericEditComponent(props, "minQuantity", false, 3), 
        },
        { title: user.translate("max_quantity"), field: 'maxQuantity', 
            render: (rowData) => user.formatQuantity(rowData.maxQuantity),
            editComponent: (props) => tableComponents.NumericEditComponent(props, "maxQuantity", false, 3), 
        },
        { title: user.translate("quantity_step"), field: 'quantityStep', 
            render: (rowData) => user.formatQuantity(rowData.quantityStep),
            editComponent: (props) => tableComponents.NumericEditComponent(props, "quantityStep", false, 3), 
        },
	];

	fieldList['data'] = data_item.getProductItemsByProductId.map((result, index) => (
		{
            ...result,
            count: (index+1)+".",
			oldStockTypeId: result.stockType.id,
			stockTypeId: result.stockType.id,
			stockType: result.stockType.name[user.lang],
			quantity: result.quantity ? (result.stockType.uom === 2 ? result.quantity : result.quantity / 1000) : 1,
			minQuantity: result.minQuantity ? result.minQuantity : 0,
			maxQuantity: result.maxQuantity ? result.maxQuantity : 0,
			quantityStep: result.quantityStep ? result.quantityStep : 0,
			uom: result.stockType.uom,
		}
	));

	const setItems = (dataObj, action) => {
		let items = {};

		let stockType = stockTypes.find((c) => c.id === dataObj.stockTypeId);
		let quantity = stockType.uom === 2 ? 1 : 1000;

		items = {
			productId: productId,
			oldStockTypeId: dataObj.oldStockTypeId,
			stockTypeId: dataObj.stockTypeId,
			stockType: lookup[dataObj.stockTypeId],
			quantity: dataObj.quantity ? (stockType.uom === 2 ? dataObj.quantity : dataObj.quantity * 1000) : quantity,
			minQuantity: dataObj.minQuantity ? dataObj.minQuantity : 0,
			maxQuantity: dataObj.maxQuantity ? dataObj.maxQuantity : 0,
			quantityStep: dataObj.quantityStep ? dataObj.quantityStep : 0,
		};

		return items;
	};

	const onAddItem = (newData, state) => {
		let items = setItems(newData, 'add');
		user.consoleLog(items);
		setPage(Math.round((data_item.getProductItemsByProductId.length + 1) / 50));
		addItem({ variables: items });
		setRefetchIt(true);
	};

	const onEditItem = (newData, state) => {

		let items = setItems(newData, 'edit');
		user.consoleLog(items);
		editItem({ variables: items });
		items.oldStockTypeId = items.stockTypeId;
		setRefetchIt(true);
	};

	const onDeleteItem = (oldData, state) => {

		user.consoleLog(oldData);

		let items = {};
		items['stockTypeId'] = oldData.stockTypeId;
		items['productId'] = productId;

		user.consoleLog(items);
		deleteItem({ variables: items });

		setDeleteState({
			variables: items,
			name: oldData.stockType,
			state: state
		});

		setOpenSnackBar(true);
		setRefetchIt(true);
	};

	if (!loading_item && !loading_item_add && !loading_item_edit && !loading_item_delete && refetchIt) {
		refetch();
		user.consoleLog("Refetch Done!")
		setRefetchIt(false);
	}

	const closeItem = () => {
		if (!props.handleSelectAccept) {
			history.push(history.location.pathname, {
				search: ""
			})
		}
		props.setRowData(false);
	}

	let tableName = `${productName} ${user.translate("product_items")}`;
        
	return (
		<div className={classes.roots}>

            <IconButton className={classes.back} onClick={closeItem} >
                <KeyboardBackspaceIcon />
            </IconButton>

            {!loading_item && !loading_item_add && !loading_item_edit && !loading_item_delete && (
                <Table
                    key={helper.hash(user.lang + page)}
                    tableTitleId={"table-title1"}
                    tableName={tableName}
                    exportFileName={`${productName}${user.translate("productitems")}`}
                    fieldList={fieldList}
                    pageSize="50"
                    pageSizeOptions={[50, 100, 200]}
                    onAddItem={onAddItem}
                    onEditItem={onEditItem}
                    onDeleteItem={onDeleteItem}
                    page={page}
                />
            )}

			{openSnackBar && (
				<SnackBarDelete
					hideUndo={true}
					message={`${deleteState.name} 
                    ${user.translate("deleted")}!`}
					variables={deleteState.variables}
					openSnackBar={openSnackBar}
					setOpenSnackBar={setOpenSnackBar}
					unDeleteItem={UNDELETE_ITEM}
					onSuccess={refetch}
				/>
			)}

		</div>
	)

}
