import {gql, useMutation, useQuery} from '@apollo/client';
import {Button, Card, CardContent, Fab, IconButton, makeStyles, Tooltip} from '@material-ui/core';
import IconAdd from '@material-ui/icons/Add';
import IconDelete from '@material-ui/icons/Delete';
import IconEdit from '@material-ui/icons/Edit';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import React, {useContext, useLayoutEffect, useState} from 'react';
import {RestaurantContext, UserContext} from "../../App";
// import DragHandleIcon from '@material-ui/icons/DragHandle';
import Add from '../common/Add';
import Delete from '../common/Delete';
import helper from '../common/Helper';
import SnackBarDelete from '../common/SnackBarDelete';
import './style.menu.css';

// CSS
const useStyles = makeStyles((theme) => ({
    speedDial: {
        position: "absolute",
        right: 0,
        top: 0,
    },
    itemCard: {
        display: 'block',
        overflow: 'unset',
        textAlign: 'left',
    },
    cardContent: {
        '&:last-child': {
            paddingBottom: `${theme.spacing(2)}px`
        }
    },
    cardHeader: {
        width: `inherit`,
        padding: `30px`,
        marginTop: '-30px',
        borderRadius: `10px`,
        boxShadow: `0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)`,
        backgroundColor: `#3f51b5`,
        color: `#fff`,
    },
    cardHeaderTitle: {
        width: `inherit`,
        padding: `30px`,
        margin: '-30px',
    },
    menuTitle: {
        padding: `5px 0`,
        fontSize: 12,
        display: 'block'
    },
    menuTitleWidth: {
        width: `77%`,
        textTransform: "initial"
    },
    dragIcon: {
        float: `left`,
        paddingRight: `${theme.spacing(1)}px`,
        marginTop: `-2px`
    },
    cardHeaderIcons: {
        float: `right`,
        padding: '10px',
        marginTop: '-10px',
        width: '40px'
    },
    cardMenuIcons1: {
        right: `32px`,
        position: `absolute`,
        zIndex: '99',
        padding: '3px',
        marginTop: '-33px',
        width: '33px',
    },
    cardMenuIcons2: {
        right: `0`,
        position: `absolute`,
        zIndex: '99',
        padding: '3px',
        marginTop: '-33px',
        width: '33px',
    },
    itemName: {
        margin: 0,
        position: 'relative',
        flexGrow: 1
    },
    itemNameDisabled: {
        margin: 0,
        position: 'relative',
        flexGrow: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    titleName: {
        fontVariant: "all-small-caps",
        fontSize: 18,
        marginBottom: theme.spacing(2),
    }
}));


// React Function Component
export default function MenuItems(props) {
    const classes = useStyles();

    // Get current restaurant from context
    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const [openAddBox, setOpenAddBox] = useState(false);
    const [openEditBox, setOpenEditBox] = useState(false);
    const [openDeleteBox, setOpenDeleteBox] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    const [deleteState, setDeleteState] = useState({
        name: "",
        deleteItem: ``,
        undeleteItem: ``,
        variables: {},
    });

    const [editState, setEditState] = useState({
        menuItem: {},
    });

    const onAddClick = (event) => {
        setOpenAddBox(true);
        setEditState({
            menuItem: {},
        });
    }
    const onEditClick = (event, menuItem) => {

        setEditState({
            menuItem: {...menuItem, discount: menuItem.discount * 100},
        });

        setOpenEditBox(true);
    }
    const onDeleteClick = (event, menuItem) => {

        let variables = {id: menuItem.id};

        setDeleteState({
            name: menuItem.name[user.lang],
            variables: variables,
        });

        setOpenDeleteBox(true);
    }

    let menuItems = {}, maxDisplayIndex = 0;

    useLayoutEffect(() => {

        let isMounted = true;  // note this flag denote mount status

        let placeholderHeight, placeholderAnimatorHeight;

        let slides = $('.slides.slidesMenuItems');
        if (isMounted) {
            $(slides).sortable({
                placeholder: 'slide-placeholder',
                axis: "y",
                revert: 150,
                start: function (event, ui) {

                    placeholderHeight = ui.item.outerHeight();
                    ui.placeholder.height(placeholderHeight + 15);
                    $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder);

                },
                change: function (event, ui) {

                    ui.placeholder.stop().height(0).animate({
                        height: ui.item.outerHeight() + 15
                    }, 300);

                    placeholderAnimatorHeight = parseInt($(".slide-placeholder-animator").attr("data-height"));

                    $(".slide-placeholder-animator").stop().height(placeholderAnimatorHeight + 15).animate({
                        height: 0
                    }, 300, function () {
                        $(this).remove();
                        placeholderHeight = ui.item.outerHeight();
                        $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder);
                    });

                },
                stop: function (event, ui) {

                    let sortable = $('.slides div.ui-sortable-handle.menuItems');
                    let count = sortable.length;
                    let indices = [];

                    let min = 0;
                    for (var i = 0; i <= count - 1; i++) {
                        min = min > i ? min : i;
                        sortable[i].setAttribute('displayIndex', min);

                        indices.push({
                            id: sortable[i].getAttribute('id'),
                            index: min,
                        })
                    }
                    bundleEditItem({variables: {indices: indices}});

                    $(".slide-placeholder-animator").remove();

                },
            });
        }

        return () => {
            isMounted = false
        }; // use effect cleanup to set flag false, if unmounted
    });

    let printable = [
        {
            val: "0",
            label: user.translate("default"),
        },
        {
            val: "1",
            label: user.translate("receipt"),
        },
        {
            val: "2",
            label: user.translate("marker"),
        },
        {
            val: "3",
            label: user.translate("both"),
        },
    ];

    let offer = [
        {
            val: "0",
            label: user.translate("none"),
        },
        {
            val: "1",
            label: user.translate("discount"),
        },
        {
            val: "2",
            label: user.translate("promo"),
        },
        {
            val: "3",
            label: user.translate("popup"),
        },
    ];

    const fieldList = [
        {
            required: false,
            type: "file",
            fieldType: "imageUpload",
            fieldName: "image",
            fieldLabel: user.translate("image"),
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.image : "",
        },
        {
            required: false,
            type: "file",
            fieldType: "imageUpload",
            fieldName: "popupImage",
            fieldLabel: user.translate("popupImage"),
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.popupImage ?? "" : "",
        }
    ];

    if (user.me.profile && user.me.profile.isAdmin) {
        fieldList.push({
                required: true,
                type: "text",
                fieldType: "text",
                fieldName: "nameMk",
                fieldLabel: user.translate("titleMk"),
                fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.name["mk"] : "",
            },
            {
                required: true,
                type: "text",
                fieldType: "text",
                fieldName: "nameEn",
                fieldLabel: user.translate("titleEn"),
                fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.name["en"] : "",
            });
    } else {
        fieldList.push({
            required: true,
            type: "text",
            fieldType: "text",
            fieldName: "name",
            fieldLabel: user.translate("title"),
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.name[user.lang] : "",
        });
    }

    fieldList.push(
        {
            required: true,
            type: "hidden",
            fieldType: "text",
            fieldName: "menuCategoryId",
            fieldLabel: "menuCategoryId",
            fieldValue: props.menuCategoryId,
        },
        {
            required: false,
            type: "hidden",
            fieldType: "text",
            fieldName: "displayIndex",
            fieldLabel: "displayIndex",
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.displayIndex : "",
        },
        {
            required: true,
            type: "number",
            fieldType: "text",
            fieldName: "discount",
            fieldLabel: user.translate("discount") + ' %',
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.discount : "0",
        },
        {
            required: false,
            type: typeof editState.menuItem.id === "undefined" ? "hidden" : "disabled",
            fieldType: "text",
            fieldName: "price",
            fieldLabel: user.translate("price"),
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.price : "0",
            line: true
        },
        {
            required: false,
            type: "select",
            fieldType: "select",
            fieldName: "printable",
            fieldLabel: user.translate("printable"),
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.printable : "0",
            fieldOptions: printable,
            line: true,
            noNone: true,
        },
        {
            required: false,
            type: "select",
            fieldType: "select",
            fieldName: "offer",
            fieldLabel: user.translate("offer"),
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.offer : "0",
            fieldOptions: offer,
            line: true,
            noNone: true,
        },
        {
            required: false,
            type: "checkbox",
            fieldType: "checkbox",
            fieldName: "webOrder",
            fieldLabel: user.translate("webOrder"),
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.webOrder : 0,
            line: true,
            noNone: true,
        },
        {
            required: false,
            type: "textarea",
            fieldType: "richText",
            fieldName: "description",
            fieldLabel: user.translate("description"),
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.description[user.lang] : "",
        },
        {
            required: true,
            type: "hidden",
            fieldType: "text",
            fieldName: "id",
            fieldLabel: "id",
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.id : helper.uid(),
        },
        {
            required: false,
            type: "hidden",
            fieldType: "text",
            fieldName: "langId",
            fieldLabel: "langId",
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.name.id : "",
        },
        {
            required: false,
            type: "hidden",
            fieldType: "text",
            fieldName: "descriptionId",
            fieldLabel: "descriptionId",
            fieldValue: typeof editState.menuItem.id !== "undefined" ? editState.menuItem.description.id : "",
        }
    );


    const ADD_ITEM = gql`
        mutation ($id: String!, $menuCategoryId: String!, $description: String!, ${user.me.profile && user.me.profile.isAdmin ? "$nameMk: String!, $nameEn: String!" : "$name: String!"}, $displayIndex: Long!, $code: Long, $image: String!, $popupImage: String, $discount: Float!, $price: Float!, $printable: Long!, $offer: Long!, $webOrder: Long!) {
            createMenuItem(input:{
                id: $id
                ${user.me.profile && user.me.profile.isAdmin ? user.gqlCreateNameTr("$nameMk", "$nameEn") : user.gqlCreateName("$name")}
                menuCategoryId: $menuCategoryId
                displayIndex: $displayIndex
                code: $code
                image: $image
                popupImage: $popupImage
                discount: $discount
                ${user.gqlCreateName("$description", false, "Description")}
                price: $price
                printable: $printable
                offer: $offer
                webOrder: $webOrder
        }){
            id
        }
    }`;
    const EDIT_ITEM = gql`
        mutation ($id: String!, $menuCategoryId: String!, $langId: String!, $descriptionId: String!, $description: String!, ${user.me.profile && user.me.profile.isAdmin ? "$nameMk: String!, $nameEn: String!" : "$name: String!"}, $displayIndex: Long!, $code: Long, $image: String!, $popupImage: String, $discount: Float!, $price: Float!, $printable: Long!, $offer: Long!, $webOrder: Long!) {
            createMenuItem(input:{
                id: $id
                ${user.me.profile && user.me.profile.isAdmin ? user.gqlEditNameTr("$nameMk", "$nameEn", "$langId") : user.gqlEditName("$name", "$langId")}
                menuCategoryId: $menuCategoryId
                displayIndex: $displayIndex
                code: $code
                image: $image
                popupImage: $popupImage
                discount: $discount
                ${user.gqlEditName("$description", "$descriptionId", "Description")}
                price: $price
                printable: $printable
                offer: $offer
                webOrder: $webOrder
        }){
            id
        }
    }`;

    const BUNDLE_EDIT = gql`
        mutation($indices: [InputDisplayIndex!]!)  {
            extras_updateMenuItemsDisplayIndex(indices: $indices)
        }`;
    const [bundleEditItem] = useMutation(BUNDLE_EDIT);

    const DELETE_ITEM = `
        mutation ($id: String!) {
            deleteMenuItem(id: $id)
        }`;

    const UNDELETE_ITEM = `
        mutation ($id: String!) {
            undeleteMenuItem(id: $id)
        }`;

    const DEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editMenuItem(input: {
                id: $id,
                active: 0
            }) {
                id
            }
        }`;
    const UNDEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editMenuItem(input: {
                id: $id,
                active: 1
            }) {
                id
            }
        }`;

    // GraphQL API request definition (local variables: restaurantId)
    let GET_MENU_ITEM_BY_RESTAURANT = gql`
    query($menuCategoryId: String!) {
        getMenuCategoryById(id: $menuCategoryId) {
            name {
                ${user.gqlFetchName()}
            }
            menuItems {
                id
                name {
                    ${user.gqlFetchName()}
                }
                code
                displayIndex
                image
                popupImage
                discount
                price
                description {
                    ${user.gqlFetchName()}
                }
                active
                webOrder
                printable
                offer
            }
        }
    }`;

    // Make the api request or get cached.
    // This makes the componnet to refresh when new data is available i.e. api finished.
    const {data, loading, refetch} = useQuery(GET_MENU_ITEM_BY_RESTAURANT, {
        variables: {menuCategoryId: props.menuCategoryId},
        pollInterval: 3000,
        errorPolicy: "ignore"
    });


    // If it is loading, show progress bar
    // if (loading) return <CircularProgress />
    // if (loading) {
    //     return (<div className="App AppLoading"><CircularProgress /></div>);
    // }

    // In case there is an error, just show it for now
    if (!data) {
        // user.consoleLog(error);
        return <p>&nbsp;</p>
    }

    let category = {}, selectedMenuItem = {};

    if (data) {
        category = data.getMenuCategoryById;
        menuItems = category ? category.menuItems.slice().sort((a, b) => a.displayIndex - b.displayIndex) : [];
        maxDisplayIndex = menuItems.length;

        selectedMenuItem = menuItems.find(t => t.id === props.menuItemId);
    }


    if (!selectedMenuItem) {
        if (menuItems.length > 0) {
            props.setMenuItemId(menuItems[0].id);
        } else if (props.menuCategoryId) {
            props.setMenuItemId(false);
        }
    }

    const renderAdd = (fieldList, maxDisplayIndex) => {
        fieldList.find(it => it.fieldName === 'displayIndex').fieldValue = maxDisplayIndex

        return (
            <Add
                fieldList={fieldList}
                openManipulateBox={setOpenAddBox}
                actionType={user.translate("add")}
                name={user.translate("menu_item")}
                restaurant={restaurant}
                manipulateItem={ADD_ITEM}
                onSuccess={refetch}
                line={true}
            />
        )
    }

    const renderEdit = (fieldList) => {

        return (
            <Add
                fieldList={fieldList}
                openManipulateBox={setOpenEditBox}
                actionType={user.translate("edit")}
                restaurant={restaurant}
                name={editState.menuItem.name[user.lang]}
                manipulateItem={EDIT_ITEM}
                deactivate={editState.menuItem.active ? true : false}
                deactivateItem={DEACTIVATE_ITEM}
                unDeactivateItem={UNDEACTIVATE_ITEM}
                onSuccess={refetch}
                line={true}
            />
        )
    }

    return (
        <React.Fragment>
            <Card className={classes.itemCard}>
                <CardContent className={classes.cardContent}>

                    <div className={classes.titleName}>
                        {user.translate("menu_items")} <b>{category && typeof category.name !== "undefined" ? category.name[user.lang] : ""}</b>:

                        <Fab
                            ariaLabel="Add Menu Item"
                            color="primary"
                            className={classes.speedDial}
                            onClick={onAddClick}
                        ><IconAdd/></Fab>
                    </div>

                    <div className="slides slidesMenuItems">
                        {/* {loading && (
                            <div className="App AppLoading"><CircularProgress /></div>
                        )} */}
                        {!loading && menuItems.length > 0 &&
                            menuItems.map((menuItem, index) => {
                                return (
                                    <div
                                        className={`${menuItem.active ? classes.itemName : classes.itemNameDisabled} menuItems`}
                                        // displayIndex={menuItem.displayIndex} 
                                        id={menuItem.id}
                                        key={menuItem.id}
                                    >
                                        <Tooltip title={`${user.translate("click_to_open_drag")} ` + menuItem.name[user.lang]} placement="bottom-start"
                                                 className={classes.menuTitle}>
                                            <Button color="inherit" component="subtitle1" onClick={(event) => props.setMenuItemId(menuItem.id)}>
                                                {/* <DragHandleIcon className={`${classes.dragIcon}`} /> */}
                                                <div className={classes.menuTitleWidth}>
                                                    {menuItem.name[user.lang]}
                                                </div>
                                            </Button>
                                        </Tooltip>

                                        <Tooltip title={`${user.translate("edit")} ` + menuItem.name[user.lang]} placement="bottom-start"
                                                 className={classes.cardMenuIcons1}>
                                            <IconButton size="small" color="primary" onClick={(e) => onEditClick(e, menuItem)}>
                                                <IconEdit/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title={`${user.translate("delete")} ` + menuItem.name[user.lang]} placement="bottom-start"
                                                 className={classes.cardMenuIcons2}>
                                            <IconButton size="small" color="primary" onClick={(e) => onDeleteClick(e, menuItem)}>
                                                <IconDelete/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                    </div>

                </CardContent>

            </Card>

            {openDeleteBox && (
                <Delete
                    name={deleteState.name}
                    variables={deleteState.variables}
                    setOpenSnackBar={setOpenSnackBar}
                    setOpenDeleteBox={setOpenDeleteBox}
                    deleteItem={DELETE_ITEM}
                    onSuccess={refetch}
                />
            )}

            {openSnackBar && (
                <SnackBarDelete
                    message={deleteState.name + ` ${user.translate("deleted")}!`}
                    variables={deleteState.variables}
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                    unDeleteItem={UNDELETE_ITEM}
                    onSuccess={refetch}
                />
            )}

            {openAddBox && renderAdd(fieldList, maxDisplayIndex)}

            {openEditBox && renderEdit(fieldList)}

        </React.Fragment>
    )

}
