import { useMutation } from '@apollo/client';
import { CircularProgress, makeStyles } from '@material-ui/core';
import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { RestaurantContext, UserContext } from "../../App";
import { useEntityRelationQuery } from '../common/useEntityQuery';
import MenuItem from './MenuItem';

// CSS
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        margin: theme.spacing(1),

        width: 540,
        [theme.breakpoints.down(1200)]: {
            width: '100%',
        },
        paddingBottom: theme.spacing(2),
        background: '#FFF',
        marginTop: theme.spacing(2),
        boxShadow: '3px 3px 6px #0000007a',
        borderRadius: theme.spacing(0.5),

        // padding-bottom: 16px;
        // background: #FFF;
        // margin-top: 12px;
        // box-shadow: 3px 5px 10px #0000007a;
        // border-radius: 4px;
        // backgroundColor: theme.palette.background.paper,
    },
    children: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    },
    tabs: {
        display: `grid`,
    },
    gridList: {
        flexGrow: 1,
        width: `100%`
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        // paddingLeft: theme.spacing(1)
    },
    icon: {

    },
    name: {
        fontSize: 14,
        fontWeight: 'bold',
        flexGrow: 1,
        textAlign: 'left',
        borderBottom: '#e1e1e1 1px solid',
        marginLeft: theme.spacing(1),
    },
    button: {

    },
    iconButton: {
        boxShadow: "0 0 2px #9d9d9d",
        // border: "#e3e3e3 1px solid",
        width: 50,
        height: 50,
        margin: 4
    }
}));

const GQL_UPDATE_DISPLAY_INDEX = gql`
    mutation($indices: [InputDisplayIndex!]!)  {
        extras_updateMenuItemsDisplayIndex(indices: $indices)
    }
`;


export default function MenuItemsList(props) {
    const classes = useStyles();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const FIELDS = [
        { name: "id", gql: "String!", id: true, hidden: true },
        { name: "name", gql: "String!", subSelection: { [user.gqlFetchName()]: true } },
        { name: "displayIndex", gql: "Long!" },
        { name: "price", gql: "Float!" },
        { name: "active", gql: "Boolean!" },
    ];

    const query = useEntityRelationQuery("MenuItem", FIELDS, "menuCategoryId", props.categoryId, {
        skip: !props.show
    });

    const items = query.items?.orderBy(item => item.displayIndex) || [];

    const [updateDisplayIndex] = useMutation(GQL_UPDATE_DISPLAY_INDEX);

    const onMenuItemReorder = (item, newIndex) => {
        if (item.displayIndex == newIndex) return;
        return updateDisplayIndex({
            variables: {
                indices: items.move(item.displayIndex, newIndex).map((it, index) => ({ id: it.id, index: index }))
            }
        }).then(() => query.refresh());
    }

    if (!props.show) return null
    if (query.isLoading) return <CircularProgress />
    return <div className={classes.children}>
        {items.map(item => <MenuItem key={item.id} item={item} onReorder={onMenuItemReorder} />) || null}
    </div>
}