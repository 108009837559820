import React from 'react';
import { 
    TableCell,
    TableRow,
 } from '@material-ui/core';

export default function InvoiceItemVatView(props) {
    const taxItem = props.taxItem;
    
	return (
        <>
            <TableRow>
                <TableCell align="left">
                    {taxItem.tax + "%"}
                </TableCell>
                <TableCell align="right">
                    {taxItem.basis}
                </TableCell>
                <TableCell align="right">
                    {taxItem.vatVal}
                </TableCell>
                <TableCell align="right">
                    {taxItem.total}
                </TableCell>
            </TableRow>

        </>
		
	);

}
