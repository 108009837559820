import { IconButton, TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Delete from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../App';
import useConfirmDialog from '../common/ConfirmDialog';
import ItemsChooserDialog from '../common/ItemsChooserDialog';
import { QuantityInputFieldToggle } from '../common/QuantityInputFieldToggle';
import StockSupplyAction from './StockSupplyAction';
import { useSupply } from './useSupply';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading1: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.primary,
    },
    secondaryHeading2: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.secondary,
        textAlign: 'right'
    },
    secondaryHeading3: {
        fontSize: theme.typography.pxToRem(11),
        color: theme.palette.text.secondary,
        textAlign: 'right'
    },
    secondaryHeading4: {
        fontSize: theme.typography.pxToRem(11),
        color: theme.palette.text.secondary,
        textAlign: 'left'
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    summary: {
        margin: 0,
        '& > *': {
            margin: '0px !important',
            alignItems: 'center'
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    supplierOptions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    supplier: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        alignItems: 'baseline'
        // alignSelf: 'stretch'
    },
    orderOptions: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),

    },
    columnHalf: {
        flexBasis: '50%',
        textAlign: 'left',
        paddingRight: theme.spacing(1)
    },
    columnQuater: {
        alignSelf: 'baseline',
        flexBasis: '25%',
        textAlign: 'left',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    quantity: {
        marginLeft: 4,
        paddingLeft: 8,
        paddingRight: 8,
        width: 'auto !important',
        borderRadius: 14,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#76dedb !important',
        color: '#236d6c !important',
        fontWeight: 'bold'
    },
    inputOtherQuantity: {
        maxWidth: 160,
        marginLeft: theme.spacing(1)
    },
    actions: {
        justifyContent: 'space-between'
    },
    itemsCount: {
        backgroundColor: '#EEE',
        width: 22,
        height: 22,
        borderRadius: '50%',
        fontSize: 13,
        textAnchor: 'start',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 8,
        color: '#545454',
        fontWeight: 700
    }
}));
const uomUnit = ["L", "Kg", "p"];

const stateColors = {
    OPEN: '#FFF',
    CLOSED: '#fbfdf4',
    SENT: '#f4fdf8',
    RECEIVED: '#f2fdfd',
    CANCELED: '#f4f4f4',

}

export default function NewStockSupplingOrderItem(props) {
    const classes = useStyles();

    const item = props.item;

    const user = useContext(UserContext);
    const [showSupplierChooser, setShowSupplierChooser] = useState(false);

    const manageSupply = useSupply({
        orders: props.supplies?.getStockSupplysByContextRestaurantId || [],
        refetch: props.refresh
    });

    const confirmDialog = useConfirmDialog();

    const onCustomSupplier = (supplier) => {

    }

    const deleteItem = (orderItem) => {
        confirmDialog.show({
            title: user.translate('delete'),
            message: user.translate('delete_accept'),

            positiveAction: {
                title: user.translate('delete')
            },
            cancelButton: true
        }).then(() => {
            return manageSupply.setItemSupply(item.supplierId, orderItem.stockTypeId, 0)
        }).catch(() => console.info("canceled"));
    }

    const deleteOrder = () => {
        confirmDialog.show({
            title: user.translate('delete'),
            message: user.translate('delete_accept'),

            positiveAction: {
                title: user.translate('delete')
            },
            cancelButton: true
        }).then(() => {
            return manageSupply.deleteSupply(item)
        }).catch(() => console.info("canceled"));
    }


    return (
        <div className={classes.root, props.className}>
            <Accordion style={{ backgroundColor: stateColors[item.state] }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                    style={{ margin: 0 }}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <div className={classes.itemsCount}>{item.stockSupplyItems.length}</div>
                    <div>
                        <Typography className={classes.heading}>{props.komitents[item.supplierId]?.name}</Typography>
                    </div>
                </AccordionSummary>
                <Divider />
                <AccordionDetails className={classes.details}>
                    {
                        item.stockSupplyItems.map((orderItem, index) => {
                            if (item.state != 'OPEN') {
                                return (
                                    <div key={`item_${orderItem.id}`} className={classes.supplierOptions}>
                                        <Typography key={`title_${orderItem.id}`} variant="body2" color="secondary">{props.stockTypesById[orderItem.stockTypeId]?.stockType || '...'}</Typography>
                                        <TextField
                                            disabled={true}
                                            type="number"
                                            value={orderItem.quantity}
                                            margin="none"
                                            variant="standard"
                                            className={classes.inputOtherQuantity}
                                        />
                                    </div>
                                );
                            }
                            return (
                                <div key={`item_${orderItem.id}`} className={classes.supplierOptions}>
                                    <Typography key={`title_${orderItem.id}`} variant="body2" color="secondary">{props.stockTypesById[orderItem.stockTypeId]?.stockType || '...'}</Typography>
                                    <QuantityInputFieldToggle
                                        key={`quantity_${orderItem.id}_${orderItem.quantity}_${orderItem.uomOpts}`}
                                        label={user.translate('quantity')}
                                        value={orderItem.quantity}
                                        uomOpts={orderItem.uomOpts}
                                        stockTypeId={orderItem.stockTypeId}
                                        className={classes.inputOtherQuantity}
                                        onSubmit={(quantity, uomOpts) => manageSupply.setItemSupply(item.supplierId, orderItem.stockTypeId, quantity, uomOpts)}
                                        enableClear
                                        enableAccept
                                    />
                                </div>
                            );
                        })
                    }
                </AccordionDetails>
                <Divider />
                <AccordionActions className={classes.actions}>
                    <IconButton size="small" color="default" onClick={deleteOrder}>
                        <Delete />
                    </IconButton>

                    <StockSupplyAction item={item} manageSupply={manageSupply} />
                </AccordionActions>
            </Accordion>

            {
                showSupplierChooser != undefined && <ItemsChooserDialog show={showSupplierChooser} items={Object.values(props.komitents)} onSelect={onCustomSupplier} onClose={setShowSupplierChooser} />
            }
            {confirmDialog.render()}
        </div>
    );
}
