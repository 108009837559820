import { InputBase } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React, { useMemo, useState } from 'react';
import useWindowSize from './useWindowSize';
import VirtualList from './VirtualList';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paper': {
            // minHeight: '80vh'
        }
    },
    content: {
        [theme.breakpoints.up('sm')]: {
            height: '80vh'
        },
    },
    appBar: {
        position: 'relative',
        minWidth: 460,
        [theme.breakpoints.down("xs")]: {
            minWidth: 320
        }
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    search: {
        position: 'absolute',
        right: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        maxWidth: '80%'
    },
    searchIcon: {
        paddingTop: theme.spacing(1),
        width: theme.spacing(5),
        height: 20,
        position: 'absolute',
        zIndex: 99999,
        opacity: 0.5
    },
    closeIcon: {
        width: theme.spacing(5),
        height: 35,
        position: 'absolute',
        zIndex: 99999,
        right: 0
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(5),
        width: '100%',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ItemsChooserDialog(props) {
    const classes = useStyles();

    const size = useWindowSize();

    const [showSearch, setShowSearch] = useState(false);

    const handleClose = () => props.onClose(false);

    const items = useMemo(() => {
        if (!showSearch || showSearch.length == 0) return props.items;

        let filter = showSearch.cirilicToLatin();
        return props.items.filter(item => item.search?.cirilicToLatin()?.indexOf(filter) != -1 || item.name.toString().cirilicToLatin()?.indexOf(filter) != -1 || item.description?.toString()?.cirilicToLatin()?.indexOf(filter) != -1);

    }, [props.items, showSearch]);

    if (props.show == undefined) return null;
    return (
        <Dialog open={props.show} fullScreen={size.isCompact} onClose={handleClose} TransitionComponent={Transition} className={classes.root} scroll="body">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>

                    {showSearch === false && <Typography variant="h6" className={classes.title}>{props.title || 'Select'}</Typography>}
                    {showSearch === false &&
                        <IconButton edge="end" color="inherit" onClick={() => setShowSearch('')}>
                            <SearchIcon />
                        </IconButton>
                    }
                    {showSearch !== false &&
                        <div className={classes.search}>
                            <SearchIcon className={classes.searchIcon} />
                            <InputBase
                                autoFocus={true}
                                onChange={e => setShowSearch(e.target.value)}
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                            />
                            <IconButton className={classes.closeIcon} onClick={() => setShowSearch(false)}>
                                <CloseIcon />
                            </IconButton>

                        </div>
                    }
                </Toolbar>
            </AppBar>
            <div className={classes.content}>
                <VirtualList
                    items={items}
                    renderDivider={true}
                    staticItems={true}
                    renderItem={item => (
                        <ListItem key={item.id} button onClick={() => { props.onSelect(item); props.onClose(); }}>
                            <ListItemText primary={item.name} secondary={item.description} />
                        </ListItem>
                    )}
                />
            </div>

        </Dialog>
    );
}
// props.onClose