import { makeStyles, Typography } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import React, { useContext, useState } from 'react';
import { RestaurantContext, UserContext } from "../../App";
import useDragableItem from '../common/Dragable';
import RippleTouch from '../common/RippleTouch';
import Show from '../common/Show';
import MenuItemEditDialog from './MenuItemEditDialog';

// CSS
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#FFF',
    },
    row: {
        width: '100%',
        height: 45,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        width: '100%',
        height: 45,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        flexGrow: 1,
        // lineHeight: 'unset',
        textAlign: 'left',
        userSelect: "none"
    },
    price: {
        // lineHeight: 'unset',
        paddingRight: theme.spacing(2)
    },
    dragIcon: {
        cursor: 'grab',
        touchAction: 'none',
        marginTop: 'auto',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    ripple: {
        left: "unset",
        top: "unset",
        bottom: "unset",
        right: "unset",
        maxWidth: "calc(100% - 45px)",
        width: 540,
        height: 45
    }
}));


export default function MenuItem(props) {
    const classes = useStyles();

    const menuItem = props.item;

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const [showEdit, setShowEdit] = useState();
    const dragableItem = useDragableItem("vertical", (index) => props.onReorder(menuItem, index));


    const itemInactiveStyle = menuItem.active ? {} : { textDecoration: 'line-through', opacity: 0.5 };
    return <div key={menuItem.id} className={classes.root} ref={dragableItem.ref}>
        <div className={classes.row} type="button">
            <dragableItem.Handle className={classes.dragIcon}>
                <Menu />
            </dragableItem.Handle>
            <RippleTouch
                className={classes.title}
                onClick={() => setShowEdit(true)}>
                <Typography variant="body2" className={classes.name} style={itemInactiveStyle}>{menuItem.name[user.lang]}</Typography>

                <Typography variant="subtitle2" color="textSecondary" className={classes.price} style={itemInactiveStyle}>{user.formatNumber(menuItem.price)} д.</Typography>
            </RippleTouch>
        </div>

        <Show if={showEdit}>
            <MenuItemEditDialog show={showEdit} menuItemId={menuItem.id} name={menuItem.name[user.lang]} onClose={() => setShowEdit()} />
        </Show>
    </div>

}