import { Link, makeStyles, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import React, { useContext } from 'react';
import { RestaurantContext, UserContext } from "../../App";
var hash = require('object-hash');

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#eceef5',
        paddingBottom: theme.spacing(2),
        padding: theme.spacing(1),
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: theme.spacing(1)
        }
    },
    actions: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexFlow: 'wrap'
    },
    actionsButton: {
        flex: 1,
        width: '100%',
        minWidth: 230,
        maxWidth: 320,
        margin: 'auto',
        marginTop: theme.spacing(2),
    },
    splitButton: {
        flex: 1,
    },
    actionsDivider: {
        [theme.breakpoints.up(480)]: {
            width: theme.spacing(1)
        }
    },
    detail: {
        paddingBottom: theme.spacing(2)
    },
    footerCreators: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down("xs")]: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexDirection: 'column'
        },
        flexWrap: 'wrap',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontStyle: 'italic'
    },
    creator: {
        fontWeight: 600,
        color: '#4e6e6f'
    },
    paymentDetails: {

    },
    list: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 8,
        '& .MuiButton-label': {
            justifyContent: 'start'
        }
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#666'
    },
    status: {
        display: 'flex',
        fontSize: 12,
        color: '#0A0',
        alignItems: 'center'
    },
    button: {
        flex: 0.95,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(0.5)
    },
    buttonIcon: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(1)
    },
    buttonContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        whiteSpace: 'nowrap'

    },
    buttonFirst: {
        display: 'flex',
        alignItems: 'baseline',
        '& > span': {
            lineHeight: 'unset',
            fontSize: 12
        }
    },
    buttonSecond: {
        width: '50vw',
        maxWidth: 320,
        fontSize: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: 'unset',
        color: '#454545'
    },
    amountField: {
        maxWidth: 120,
        [theme.breakpoints.down("xs")]: {
            maxWidth: 100,
        },
        marginLeft: 4
    }
}));


export default function InvoiceLinkButton(props) {

    const invoice = props.item;

    const classes = useStyles();

    const user = useContext(UserContext)
    const restaurant = useContext(RestaurantContext);

    const invoiceCurrency = restaurant.currencies.find(opt => opt.val == invoice.currency).label;

    const komitent = props.isExpense ? invoice.payeeCompany : invoice.payerCompany;

    return <div className={classes.button}>
        <DescriptionIcon className={classes.buttonIcon} />
        <Link href={`/restaurant/${restaurant._id}/invoice_output/${invoice.id}`}>
            <span className={classes.buttonContent}>
                <span className={classes.buttonFirst}>
                    <Typography variant="overline">{invoice.invoiceNumber}</Typography>
                    &#8901;
                    <Typography variant="caption">{user.formatDate(invoice.issuingDate)}</Typography>
                    &#8901;
                    <Typography variant="caption">{user.formatQuantity(invoice.amount)} {invoiceCurrency}</Typography>
                </span>
                <Typography variant="caption" className={classes.buttonSecond}>{komitent.name[user.lang]}</Typography>
            </span>
        </Link>
    </div>;
}