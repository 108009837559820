import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useContext } from 'react';
import { UserContext } from "../../../App";
import Show from '../Show';
import { callOrGet, ifNull, preventPropagation } from './utils';

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'inline-grid',
        margin: theme.spacing(1),
        flexGrow: 1,
        minWidth: 80,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function SelectCurrency(props) {
    const classes = useStyles();
    const user = useContext(UserContext);

    let options = [
        { val: "MKD", name: user.translate("mkd") },
        { val: "EUR", name: user.translate("eur") },
        { val: "USD", name: user.translate("usd") },
    ];

    const value = ifNull(props.value, callOrGet(props.default, props.item));

    return <FormControl className={props.showLabel && classes.formControl} style={props.shrink ? { flexGrow: 0 } : { flexGrow: 1 }} size={props.size}>
        <Show if={props.showLabel}>
            <InputLabel htmlFor={`select-${props.name}`} variant={props.variant || "outlined"}>{props.label || user.translate(props.name)}</InputLabel>
        </Show>
        <Select
            fullWidth={props.fullWidth}
            style={{ fontSize: props.size == "small" ? 12 : 14 }}
            value={value}
            label={(props.label || user.translate(props.name)) + "="}
            variant={props.variant || "outlined"}
            onKeyDown={preventPropagation}
            inputProps={{
                id: `select-${props.name}`
            }}
            error={props.error}
            disabled={props.disabled}
            onChange={(event) => {
                const option = options.find(opts => opts.val == event.target.value);
                return props.onChange(option?.val, option?.name);
            }}>
            {options.map(option => (
                <MenuItem key={option.val} value={option.val} onKeyDown={preventPropagation}>
                    {option.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>

}
SelectCurrency.gql = "String";

SelectCurrency.renderView = function (props, user) {
    let options = [
        { val: "MKD", name: user.translate("mkd") },
        { val: "EUR", name: user.translate("eur") },
        { val: "USD", name: user.translate("usd") },
    ];

    const value = ifNull(props.value, callOrGet(props.default, props.item));
    const option = options.find(opts => opts.val.toLowerCase() == value.toLowerCase());

    return option.name;
}
