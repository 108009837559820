import "core-js"
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache} from "@apollo/client"
import {createTheme, ThemeProvider} from "@material-ui/core/styles"
import "fontsource-roboto"
import React from "react"
import ReactDOM from "react-dom"
import config from "./config"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import App from "./views/App"

let transitionCyrLat = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    ѓ: "g",
    е: "e",
    ж: "z",
    з: "z",
    ѕ: "z",
    и: "i",
    ј: "j",
    к: "k",
    л: "l",
    љ: "l",
    м: "m",
    н: "n",
    њ: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    ќ: "k",
    у: "u",
    ф: "f",
    х: "h",
    ц: "c",
    ч: "c",
    џ: "dz",
    ш: "s",
    lj: "l",
    sh: "s",
    nj: "n",
    gj: "g",
}

const Str = String
Str.prototype.cirilicToLatin = function () {
    var self = this.toLowerCase()
    for (var letter in transitionCyrLat) {
        self = self.replace(new RegExp(letter, "g"), transitionCyrLat[letter])
    }
    return self
}
Str.prototype.cirilicLatinCompare = function (lang) {
    var self = this.toLowerCase()
    if (lang !== "mk") {
        for (var letter in transitionCyrLat) {
            self = self.replace(new RegExp(letter, "g"), transitionCyrLat[letter])
        }
    } else {
        for (var letter in transitionCyrLat) {
            self = self.replace(new RegExp(transitionCyrLat[letter], "g"), letter)
        }
    }

    return self
}

const Num = Number
Num.prototype.switch = function (options) {
    return options[this] || options["default"]
}

Num.prototype.round = function (decimals, defNaN) {
    let multiplier = Math.pow(10, decimals)
    let value = Math.round(this * multiplier) / multiplier
    return isNaN(value) ? defNaN || value : value
}

Num.prototype.roundUpBy = function (step) {
    return Math.ceil(this / step) * step
}
Num.prototype.roundDownBy = function (step) {
    return Math.floor(this / step) * step
}

const Arr = Array

Arr.prototype.plus = function (...arrays) {
    if (arrays == undefined || arrays.length == 0 || (arrays.length == 1 && arrays[0] == undefined)) return this
    const result = [...this]
    arrays.forEach((array) => array && result.push(array))
    return result
}

Arr.prototype.get = function (key, defValue) {
    return this[key] || defValue
}
Arr.prototype.groupBy = function (keyFunction) {
    var groups = {}
    this.forEach(function (el) {
        var key = keyFunction(el)
        let keyArr = groups[key]
        if (keyArr === undefined) {
            groups[key] = [el]
        } else {
            keyArr.push(el)
        }
    })
    return groups
}

Arr.prototype.toMapBy = function (keyFunction, valFunction) {
    var result = {}
    this.forEach(function (el) {
        result[keyFunction(el)] = valFunction ? valFunction(el) : el
    })
    return result
}

Arr.prototype.orderBy = function (keyFunction) {
    return [...this].sort((a, b) => keyFunction(a) - keyFunction(b))
}

Arr.prototype.orderByDesc = function (keyFunction) {
    return [...this].sort((a, b) => keyFunction(b) - keyFunction(a))
}

Arr.prototype.sumOf = function (valFunction) {
    var sum = 0
    this.forEach((item) => {
        sum = sum + valFunction(item)
    })
    return sum
}

Arr.prototype.maxOf = function (valFunction, initialMax) {
    var max = initialMax
    this.forEach((item) => {
        let val = valFunction(item)
        if (max === undefined || val > max) {
            max = val
        }
    })
    return max
}

Arr.prototype.maxBy = function (valFunction) {
    var max = undefined
    var maxItem = undefined
    this.forEach((item) => {
        let val = valFunction(item)
        if (max === undefined || val > max) {
            max = val
            maxItem = item
        }
    })
    return maxItem
}

Arr.prototype.minOf = function (valFunction, initialMax) {
    var max = initialMax
    this.forEach((item) => {
        let val = valFunction(item)
        if (max === undefined || val < max) {
            max = val
        }
    })
    return max
}

Arr.prototype.minBy = function (valFunction) {
    var max = undefined
    var maxItem = undefined
    this.forEach((item) => {
        let val = valFunction(item)
        if (max === undefined || val < max) {
            max = val
            maxItem = item
        }
    })
    return maxItem
}

Arr.prototype.mapRange = function (start, end, valFunction) {
    if (start < 0) throw Error("start should be greater or equal to zero", start)
    if (end > this.length) throw Error("end should be less or equal to the length", start)
    let results = []
    for (var i = start; i < end; i++) {
        results.push(valFunction(this[i], i))
    }
    return results
}

Arr.prototype.reduceRange = function (start, end, callback, initialVal) {
    if (start < 0) throw Error("start should be greater or equal to zero", start)
    if (end > this.length) throw Error("end should be less or equal to the length", start)
    let acc = initialVal
    for (var i = start; i < end; i++) {
        acc = callback(acc, this[i], i)
    }
    return acc
}

Arr.prototype.reducePromise = function (callback, start = 0, end) {
    if (start < 0) throw Error("start should be greater or equal to zero", start)
    if (end === undefined) end = this.length
    if (end > this.length) throw Error("end should be less or equal to the length", start)
    var acc = undefined
    for (var i = start; i < end; i++) {
        let item = this[i]
        if (acc === undefined) {
            acc = callback(item, i)
            continue
        }
        acc = acc.then(() => callback(item, i))
    }
    return acc
}

Arr.prototype.removeBy = function (predicate) {
    let index = this.findIndex(predicate)
    if (index < 0) return undefined
    return this.splice(index, 1)[0]
}

Arr.prototype.distinct = function () {
    return this.filter((v, i, self) => self.indexOf(v) === i)
}

Arr.prototype.distinctBy = function (keyFunction) {
    let set = new Set()
    return this.filter((v) => {
        let key = keyFunction(v)
        if (set.has(key)) return false
        set.add(key)
        return true
    })
}

Arr.prototype.move = function (from, to) {
    if (from < 0 || from >= this.length) return this
    if (to < 0 || to >= this.length) return this
    this.splice(to, 0, this.splice(from, 1)[0])
    return this
}
Arr.prototype.count = function (predicate) {
    if (this.length === 0) return 0
    let count = 0
    this.forEach((item) => {
        if (predicate(item)) count++
    })
    return count
}

const Dat = Date
Dat.prototype.toISODate = function () {
    return this.toISOString().split("T")[0]
}

if (String.prototype.replaceAll === undefined) {
    Str.prototype.replaceAll = function (search, replace) {
        return this.replace(new RegExp(search.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"), "g"), replace)
    }
}

Str.prototype.capitalize = function (forceCamelCase = true) {
    if (this.length <= 1) return this
    if (forceCamelCase) {
        return this.charAt(0).toLocaleUpperCase() + this.slice(1).toLocaleLowerCase()
    } else {
        return this.charAt(0).toLocaleUpperCase() + this.slice(1)
    }
}
Str.prototype.capitalizeAll = function () {
    return this.split(/(\W+)/)
        .map((w) => w.capitalize())
        .join("")
}
Str.prototype.capitalizeSentence = function () {
    return this.split(/([\.\?\!]\s*)/)
        .map((w) => w.capitalize())
        .join("")
}

Str.prototype.wordsUpTo = function (length) {
    if (this.length <= length) return this
    var result = ""
    let words = this.split(/([\.\?\!\s]+)/)
        .map((w) => w.capitalize())
        .forEach((word) => {
            if (result.length + word.length <= length) {
                result += word
            }
        })
    return result.trim()
}

if (!Str.prototype.padStart) {
    Str.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0 //truncate if number or convert non-number to 0;
        padString = String(typeof padString !== "undefined" ? padString : " ")
        if (this.length > targetLength) {
            return String(this)
        } else {
            targetLength = targetLength - this.length
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length) //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this)
        }
    }
}

if (!Str.prototype.repeat) {
    Str.prototype.repeat = function (count) {
        "use strict"
        if (this == null) throw new TypeError("can't convert " + this + " to object")

        var str = "" + this
        // To convert string to integer.
        count = +count
        // Check NaN
        if (count != count) count = 0

        if (count < 0) throw new RangeError("repeat count must be non-negative")

        if (count == Infinity) throw new RangeError("repeat count must be less than infinity")

        count = Math.floor(count)
        if (str.length == 0 || count == 0) return ""

        // Ensuring count is a 31-bit integer allows us to heavily optimize the
        // main part. But anyway, most current (August 2014) browsers can't handle
        // strings 1 << 28 chars or longer, so:
        if (str.length * count >= 1 << 28) throw new RangeError("repeat count must not overflow maximum string size")

        var maxCount = str.length * count
        count = Math.floor(Math.log(count) / Math.log(2))
        while (count) {
            str += str
            count--
        }
        str += str.substring(0, maxCount - str.length)
        return str
    }
}

function now() {
    return new Date().getTime()
}

const theme = createTheme({
    palette: {
        primary: {
            light: "rgba(8, 46, 45, 0.2)", // will be calculated from palette.primary.main,
            medium: "rgba(8, 46, 45, 0.7)", main: "rgba(8, 46, 45, 0.9)", //'#003366'//'#970297' //"rgb(151, 2, 151)" //'rgb(105, 1, 105, 0.9)', //#690169
            dark: "rgba(8, 46, 45, 1)", //will be calculated from palette.primary.main,
            background: "rgba(0,0,0,0.05)", //will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }, secondary: {
            light: "#ffc6ff", main: "#b75cb7", // dark: will be calculated from palette.secondary.main,
            contrastText: "#ffcc00",
        }, tertiary: {
            light: "#fff2cc",
            main_light: "rgba(171, 153, 101, 0.5)",
            main: "#ff0000",
            main_dark: "#ab9965",
            dark: "#4a3a0a", // dark: will be calculated from palette.secondary.main,
            contrastText: "#b75cb7",
        }, // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3, // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
})

const cache = new InMemoryCache({
    typePolicies: {
        ReportFilterOptions: {
            keyFields: ["_id"],
        }, ProfileByEmail: {
            keyFields: ["_id"],
        },
    },
})
const link = new HttpLink({
    uri: `${config.apiUrl}/graphql/restaurant`, credentials: "include", withCredentials: true, fetchOptions: {
        credentials: "include",
    },
})

const client = new ApolloClient({
    cache, link,
})

ReactDOM.render(<ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
        <App/>
    </ApolloProvider>
</ThemeProvider>, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
