
import { Button, IconButton } from '@material-ui/core';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import Done from '@material-ui/icons/Done';
import Send from '@material-ui/icons/Send';
import Mail from '@material-ui/icons/Mail';
import Cancel from '@material-ui/icons/Cancel';
import React, { useContext } from 'react';
import Switch, { Case } from 'react-switch-case';
import { UserContext } from '../../App';
import useConfirmDialog from '../common/ConfirmDialog';


export default function StockSupplyAction(props) {

    let item = props.item;
    let manageSupply = props.manageSupply;

    const user = useContext(UserContext);

    const confirmDialog = useConfirmDialog();

    const finishOrder = () => {
        confirmDialog.show({
            title: user.translate('finish'),
            message: user.translate('finish_supply_accept'),

            positiveAction: {
                title: user.translate('finish')
            },
            cancelButton: true
        }).then(() => {
            return manageSupply.saveSupply({ ...item, state: 'CLOSED' });
        }).catch(() => console.info("canceled"));
    }

    const sendOrder = () => {
        confirmDialog.show({
            title: user.translate('send'),
            message: user.translate('send_supply_accept'),

            positiveAction: {
                title: user.translate('send')
            },
            cancelButton: true
        }).then(() => {
            return manageSupply.saveSupply({ ...item, state: 'SENT' });
        }).catch(() => console.info("canceled"));
    }
    const receiviedOrder = () => {
        confirmDialog.show({
            title: user.translate('receiving'),
            message: user.translate('receive_supply_accept'),

            positiveAction: {
                title: user.translate('receive')
            },
            cancelButton: true
        }).then(() => {
            return manageSupply.saveSupply({ ...item, state: 'RECEIVED' });
        }).catch(() => console.info("canceled"));
    }
    const cancelOrder = () => {
        confirmDialog.show({
            title: user.translate('cancel'),
            message: user.translate('cancel_supply_accept'),

            positiveAction: {
                title: user.translate('cancel')
            },
            cancelButton: true
        }).then(() => {
            return manageSupply.saveSupply({ ...item, state: 'CANCELED' });
        }).catch(() => console.info("canceled"));
    }

    let iconButtonProps = {
        className: props.className,
        size: props.size || "small",
        color: props.color || "primary"
    }

    if (props.noText) {
        return <>
            <Switch condition={item.state}>
                <Case value="OPEN">
                    <IconButton {...iconButtonProps} onClick={finishOrder}><Done color="secondary" /></IconButton>
                </Case>
                <Case value="CLOSED">
                    <IconButton {...iconButtonProps} onClick={sendOrder}><Send color="secondary" /></IconButton>
                </Case>
                <Case value="SENT">
                    <IconButton {...iconButtonProps} onClick={receiviedOrder}><Mail color="secondary" /></IconButton>
                </Case>
                <Case value="RECEIVED">
                    <IconButton {...iconButtonProps} onClick={cancelOrder}><Cancel style={{ opacity: 0.7 }} /></IconButton>
                </Case>
            </Switch>

            {confirmDialog.render()}
        </>
    } else {
        const buttonProps = { ...iconButtonProps, variant: props.outlined ? "outlined" : "text" };
        return <>
            <Switch condition={item.state}>
                <Case value="OPEN">
                    <Button startIcon={<Done />} {...buttonProps} onClick={finishOrder}>{user.translate('ready')}</Button>
                </Case>
                <Case value="CLOSED">
                    <Button startIcon={<Send />} {...buttonProps} onClick={sendOrder}>{user.translate('send')}</Button>
                </Case>
                <Case value="SENT">
                    <Button startIcon={<Mail />} {...buttonProps} onClick={receiviedOrder}>{user.translate('receive')}</Button>
                </Case>
                <Case value="RECEIVED">
                    <Button startIcon={<Cancel />} {...buttonProps} onClick={cancelOrder}>{user.translate('cancel')}</Button>
                </Case>
            </Switch>

            {confirmDialog.render()}
        </>
    }
}