

export const preventPropagation = (event, isOpen) => {
    if (isOpen == false) return;
    if (event.key == "Enter") {
        event.stopPropagation();
    }
}

export const ifNull = (...params) => {
    return params.find(param => param != undefined || param != null);
}

export const callOrGet = (value, ...args) => {
    if (value instanceof Function) return value(...args);
    return value;
}

export const parseInteger = (value) => {
    const result = parseInt(value);
    if (isNaN(result)) return "";
    return result;
}

export const parseDecimal = (value) => {
    const result = parseFloat(value);
    if (isNaN(result)) return "";
    return result;
}

export const switchCase = (...cases) => {
    const count = cases.length;
    for (let i = 0; i < count; i++) {
        const caseStatement = cases[i];
        if (i == (count - 1)) return caseStatement;

        const outcomes = cases[i + 1];
        if (outcomes == undefined) return;

        if (outcomes.hasOwnProperty(caseStatement)) {
            return outcomes[caseStatement];
        }
    }
}