import { Button, makeStyles } from '@material-ui/core';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import React, { useContext, useRef, useState } from 'react';
import { UserContext } from '../../App';
import useConfirmDialog from '../common/ConfirmDialog';
import GqlSimpleTable from '../common/GqlSimpleTable';
import MobileDialog from '../common/MobileDialog';
import Show from './Show';

const useStyles = makeStyles((theme) => ({

    deleted: {
        width: 140,
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            bottom: 44,
            left: theme.spacing(1)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        }
    }
}));

export default function DeletedItemsDialog(props) {
    const classes = useStyles();

    const user = useContext(UserContext);

    const [showDeleted, setShowDeleted] = useState(false);
    const confirmDialog = useConfirmDialog();

    const apiRef = useRef();

    const onOpen = () => {
        apiRef.current?.gql?.refresh();
        setShowDeleted(true);
    }

    const onItemClick = (item) => {
        confirmDialog.showConfirm('undelete', props.title, () => {
            apiRef.current.gql.unDeleteItem(item).then(() => props.onChange());
        });
    }

    return <>
        <Button startIcon={<RestoreFromTrashIcon />} className={classes.deleted} onClick={onOpen} >
            {user.translate('deleted_items')}
        </Button>
        <MobileDialog open={showDeleted} onClose={setShowDeleted} title={user.translate("deleted_items") + " " + user.translate(props.title)}>
            <Show if={showDeleted}>
                <GqlSimpleTable
                    pathName={props.title}
                    entityName={props.entityName}
                    relFieldName={props.relFieldName}
                    relFieldValue={props.relFieldValue}
                    apiRef={apiRef}
                    fields={props.fields}
                    paging={false}
                    onRowClick={onItemClick}
                    filter={{ by: '_del', eq: 1 }}
                    nowrap
                    noEdit
                />
                {confirmDialog.render()}
            </Show>
        </MobileDialog>
    </>
}