import React, {useState, useContext} from 'react';
import reactStringReplace from 'react-string-replace';
import {
    makeStyles,
    Input, 
    FormLabel,
    TextField,
    Grid,
    ClickAwayListener,
    InputAdornment,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import '@fortawesome/fontawesome-svg-core/styles.css'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { UserContext } from "../../App";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    formControl: {
        minWidth: 120,
        clear: `both`
    },
    textField: {
        width: "auto",
        float: "right",
        fontSize: 12,
    },
    textFieldLabel: {
        float: "right",
        position: `relative`,
        top: theme.spacing(2),
        marginRight: theme.spacing(1),
        fontSize: 12,
    },
    textFieldTable: {
        width: "auto",
    },
    textareaFieldLabel: {
        float: "right",
        position: `relative`,
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    fieldClosed: {
        cursor: "pointer",
    },
    textFieldClosed: {
        width: "auto",
        float: "right",
        fontSize: 12,
        minWidth: 140,
        maxWidth: 140,
        position: `relative`,
        whiteSpace: "pre-line",

        "& span": { 
            minWidth: 50,
            borderBottom: "1px solid",
            float: "right",
        }
    },
    textFieldLabelClosed: {
        float: "right",
        top: "2px",
        position: `relative`,
        // marginRight: theme.spacing(1),
        fontSize: 12,
        minWidth: 140,
        maxWidth: 140,
    },
    textFieldTableClosed: {
        width: "auto",
        float: "right",
        fontSize: 12,
        position: `relative`,

        "& span": { 
            minWidth: 50,
            borderBottom: "1px solid",
            float: "right",
        }
    },
    textFieldTableLabelClosed: {
        float: "right",
        top: "2px",
        position: `relative`,
        marginRight: theme.spacing(1),
        fontSize: 12,
    },
    textFieldFooterClosed: {
        width: "auto",
        float: "right",
        fontSize: 10,
        minWidth: 140,
        position: `relative`,

        "& span": { 
            minWidth: 50,
            borderBottom: "1px solid",
            float: "right",
        }
    },
    textFieldLabelFooterClosed: {
        float: "right",
        top: "2px",
        marginRight: theme.spacing(1),
        fontSize: 12,
        position: `relative`,
    },
    inputHidden: {
        margin: 0,
        padding: 0,
        border: 0,
        width: `0%`,
        whiteSpace: 'nowrap',
        visibility: `hidden`,
        display: `block`,
    },
    helperText: {
        fontStyle: "italic"
    },
    changeFieldIcon: {
        cursor: "pointer",
        padding: 5,
    },
}));

function NumberFormatCustom(props) {

    const user = useContext(UserContext);
   
    const { inputRef, onChange, decimalScale, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onKeyDown={(event) => {
                switch (event.key) {
					case 'ArrowDown':
						onChange({
                            target: {
                                name: props.name,
                                value: user.parseStringNumber(event.target.value || "0") - 1,
                            },
                        });
						break;
					case 'ArrowUp':
						onChange({
                            target: {
                                name: props.name,
                                value: user.parseStringNumber(event.target.value || "0") + 1,
                            },
                        });
						break;
					default:
						break;
				}
            }}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={user.restLang === 'mk' ? '.' : ','}
            decimalSeparator={user.restLang === 'mk' ? ',' : '.'}
            decimalScale={decimalScale}
            isNumericString
            // prefix={props.name === 'price' || props.name === 'amount' ? user.icu.getCurrencySymbol() : undefined}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    decimalScale: PropTypes.number.isRequired,
};

export default function FormInvoice(props) {
    
    const classes = useStyles();
    const user = useContext(UserContext);
    
    let fieldList = [...props.fieldList];

    const [fieldItem, setFieldItem] = useState({...props.fieldList[props.item]});
    const [openField, setOpenField] = useState(props.openField);

    const [errorObj, setErrorObj] = useState({
        error: props.fieldList[props.item].error,
        helperText: ""
    });

    const [selectedDate, setSelectedDate] = useState(fieldItem.fieldValue);

    const handleDateChange = (date) => {
        
        if(date != `Invalid Date` && fieldItem.fieldValue !== date)
        {
            setSelectedDate(date);

            setErrorObj({
                error: false,
                // helperText: ""
            });

            props.setItems(date, fieldItem.fieldName, new Date(date).getTime());
            setFieldItem({...fieldItem, fieldValue: date});
            fieldList[props.item] = {...fieldItem, fieldValue: date};
            props.setFieldList([...fieldList]);
            closeField();
        }
    };

    const checkErrorsFunction = (fieldItem, event) => {

        let error = false;

        if(event.target.required && event.target.value === "") {
            fieldItem.error = true;
            error = true;

            setErrorObj({
                error: true,
                // helperText: "Please fill out this field"
            });
        }

        if(event.target.type === 'email')
        {
            let re = /\S+@\S+\.\S+/;

            if(event.target.value !== "" && !re.test(String(event.target.value).toLowerCase())) {
                fieldItem.error = true;
                error = true;

                setErrorObj({
                    error: true,
                    // helperText: "Please enter a valid email address"
                });
            }
        }

        if(!error) {
            fieldItem.error = false;

            setErrorObj({
                error: false,
                // helperText: ""
            });
        }

        if(props.addRow)
        {
            props.setItems(event, fieldItem.fieldName, event.target.value);
            fieldList[props.item] = {...fieldItem};
            props.setFieldList([...fieldList]);
        }
        if(fieldItem.fieldTextValue)
        {
            setFieldItem({...fieldItem, fieldValue: event.target.value, fieldTextValue: event.target.value});
        }
        else setFieldItem({...fieldItem, fieldValue: event.target.value});
    }

    const checkErrorsFunctionSelect = (fieldItem, event) => {

        let error = false;

        if(event.target.required && event.target.value === "") {
            fieldItem.error = true;
            error = true;

            setErrorObj({
                error: true,
                // helperText: "Please fill out this field"
            });
        }

        if(event.target.type === 'email')
        {
            let re = /\S+@\S+\.\S+/;

            if(event.target.value !== "" && !re.test(String(event.target.value).toLowerCase())) {
                fieldItem.error = true;
                error = true;

                setErrorObj({
                    error: true,
                    // helperText: "Please enter a valid email address"
                });
            }
        }

        if(!error) {
            fieldItem.error = false;

            setErrorObj({
                error: false,
                // helperText: ""
            });
        }

        if(props.addRow)
        {
            props.setItems(event, fieldItem.fieldName, event.target.value);
            fieldList[props.item] = {...fieldItem};
            props.setFieldList([...fieldList]);
        }
        setFieldItem({...fieldItem, fieldValue: event.target.value});
        changeField({...fieldItem, fieldValue: event.target.value}, event);
    }

    const changeField = (fieldItem, event) => {

        if(!errorObj.error) {
            props.setItems(event, fieldItem.fieldName, fieldItem.fieldValue);
            fieldList[props.item] = {...fieldItem};
            props.setFieldList([...fieldList]);
            toggleField();
        }
        else {
            fieldList = [...props.fieldList];
            setFieldItem({...props.fieldList[props.item]});
            props.setFieldList([...props.fieldList]);
        }
    }

    const toggleCloseField = (fieldItem, event) => {
        switch (event.key) {
            case 'Enter':
                changeField(fieldItem, event);
                break
            case 'Escape':
                closeField();
                break
            default: break
        }
    }
    const toggleCloseFieldSelect = (fieldItem, event) => {
        closeField();
    }

    const toggleField = () => {
        setOpenField(!openField);
    }
    const enterField = (event) => {
        switch (event.key) {
            case 'Enter':
                toggleField();
                break
            default: break
        }
    }
    const closeField = (event) => {
        fieldList = [...props.fieldList];
        setFieldItem({...props.fieldList[props.item]});
        props.setFieldList([...props.fieldList]);
        setErrorObj({
            error: false,
            // helperText: ""
        });
        toggleField();
    }

    let style = props.left ? {float: "left"} : {float: "right"};
    if(props.table)
    {
        style = props.left ? {float: "left", width: "100%"} : {float: "right", width: "100%"};
    }

    const renderField = (fieldItem) => {

        let fieldValue = fieldItem.type === "date" ? 
            user.formatDate(selectedDate) : (fieldItem.fieldTextValue ? 
                <>{reactStringReplace(fieldItem.fieldValue, '\n', (match, i) => <br key={fieldItem.fieldValue+i}/>)}</> :
                (fieldItem.type === 'number' ? (fieldItem.decimalScale && fieldItem.decimalScale === 3 ? user.formatQuantity(parseFloat(fieldItem.fieldValue)) :  user.formatNumber(parseFloat(fieldItem.fieldValue))) :  fieldItem.fieldValue));

        if(typeof fieldValue === "string") fieldValue = fieldValue.trim()
        if(fieldItem.fieldName === "discount" || fieldItem.fieldName === "tax") fieldValue = fieldValue+"%";
        let classTextFieldClosed = props.table ? classes.textFieldTableClosed : props.footer ? classes.textFieldFooterClosed : classes.textFieldClosed;
        let classTextFieldLabelClosed = !props.footer ? classes.textFieldLabelClosed : classes.textFieldLabelFooterClosed;

        let params = {};
        if(props.tabIndex != undefined)
        {
            params = {
                tabIndex: props.tabIndex+props.item,
                onKeyDown: enterField
            }
        }
        
        if(fieldItem.type !== "hidden")
        {
            return (
                <div className={`${props.styles} ${classes.fieldClosed}`} onClick={toggleField} {...params}>
                    {props.left && !props.table && (<FormLabel className={`${classTextFieldLabelClosed} ${props.noprint ? props.noprint : ""} ${props.styles}`} style={style} component="span">{fieldItem.fieldLabel}: </FormLabel>)}
                    <div className={`${classTextFieldClosed}`} style={{float: props.left ? "left" : "right", width: props.width ? props.width : ""}}>
                        {fieldValue !== "" ? fieldValue : <span className={`${props.noprint ? props.noprint : ""}`} style={{float: props.left ? "left" : "right", width: "100%"}}>&nbsp;</span>}
                    </div>
                    {!props.left && !props.table && (<FormLabel className={`${classTextFieldLabelClosed} ${props.noprint ? props.noprint : ""} ${props.styles}`} style={style} component="span">{fieldItem.fieldLabel}: </FormLabel>)}
                </div>
            )
        }
        else return (
            <div className={props.styles}>
                <Input
                    required={fieldItem.required}
                    type={fieldItem.type}
                    className={classes.inputHidden}
                    name={fieldItem.fieldName}
                    label={fieldItem.fieldLabel}
                    fullWidth
                    value={fieldItem.fieldValue}
                    error={errorObj.error}
                    // helperText={errorObj.helperText}
                />
            </div>
        )
    }

    const renderFormInvoice = (fieldItem) => {

        let fieldId= "id_" + fieldItem.fieldName;
        let labelId = "label_" + fieldItem.fieldLabel;

        if (fieldItem.fieldType === 'text')
        {
            if(fieldItem.type !== "hidden")
            {
                if(props.addRow) {
                    if (fieldItem.type === 'text') {
                        return (
                            <div className={props.styles}>
                                <TextField
                                    required={fieldItem.required}
                                    type={fieldItem.type}
                                    name={fieldItem.fieldName}
                                    label={fieldItem.fieldLabel}
                                    className={classes.textFieldTable}
                                    fullWidth
                                    defaultValue={fieldItem.fieldValue}
                                    onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                    onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                    error={errorObj.error}
                                    // helperText={errorObj.helperText}
                                />
                            </div>
                        )
                    } else if (fieldItem.type === 'number') {
                        return (
                            <div className={props.styles}>
                                <TextField
                                    required={fieldItem.required}
                                    name={fieldItem.fieldName}
                                    label={fieldItem.fieldLabel}
                                    fullWidth
                                    className={classes.textFieldTable}
                                    value={fieldItem.fieldValue}
                                    onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                    error={errorObj.error}
                                    // helperText={errorObj.helperText}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                        autoComplete: 'off',
                                    }}
                                    inputProps={{decimalScale: fieldItem.decimalScale || 2}}
                                />
                            </div>
                        )
                    }
                } else if (fieldItem.type === 'number') {
                    return (
                        <ClickAwayListener onClickAway={closeField}>
                        <div className={props.styles}>
                            {props.left && !props.table && (<FormLabel className={classes.textFieldLabel} style={style} component="span">{fieldItem.fieldLabel}: </FormLabel>)}
                            <TextField
                                style={{float: props.left ? "left" : "right",width: props.width ? props.width : ""}}
                                required={fieldItem.required}
                                name={fieldItem.fieldName}
                                fullWidth
                                autoFocus
                                tabIndex={props.item}
                                className={classes.textField}
                                value={fieldItem.fieldValue}
                                onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                onKeyDown={(event) => toggleCloseField(fieldItem, event)}
                                error={errorObj.error}
                                // helperText={errorObj.helperText}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    autoComplete: 'off',
                                    endAdornment: 
                                    <InputAdornment position="end">
                                        <CheckIcon className={classes.changeFieldIcon} onClick={(event) => changeField(fieldItem, event)} />
                                        <ClearIcon className={classes.changeFieldIcon} onClick={closeField}/>
                                    </InputAdornment>
                                }}
                                inputProps={{decimalScale: fieldItem.decimalScale || 2}}
                            />
                            {!props.left && !props.table && (<FormLabel className={classes.textFieldLabel} style={style} component="span">{fieldItem.fieldLabel}: </FormLabel>)}
                        </div>
                        </ClickAwayListener>
                    )
                } else {
                    return (
                        <ClickAwayListener onClickAway={closeField}>
                        <div className={props.styles}>
                            {props.left && !props.table && (<FormLabel className={classes.textFieldLabel} style={style} component="span">{fieldItem.fieldLabel}: </FormLabel>)}
                            <TextField
                                style={{float: props.left ? "left" : "right",width: props.width ? props.width : ""}}
                                required={fieldItem.required}
                                type={fieldItem.type}
                                name={fieldItem.fieldName}
                                fullWidth
                                autoFocus
                                tabIndex={props.item}
                                className={classes.textField}
                                defaultValue={fieldItem.fieldValue}
                                onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                onKeyDown={(event) => toggleCloseField(fieldItem, event)}
                                error={errorObj.error}
                                // helperText={errorObj.helperText}
                                InputProps={{
                                    autoComplete: 'off',
                                    endAdornment: 
                                    <InputAdornment position="end">
                                        <CheckIcon className={classes.changeFieldIcon} onClick={(event) => changeField(fieldItem, event)} />
                                        <ClearIcon className={classes.changeFieldIcon} onClick={closeField}/>
                                    </InputAdornment>
                                }}
                            />
                            {!props.left && !props.table && (<FormLabel className={classes.textFieldLabel} style={style} component="span">{fieldItem.fieldLabel}: </FormLabel>)}
                        </div>
                        </ClickAwayListener>
                    )
                }
            }
            else
            {//hidden
                return (
                    <div className={props.styles}>
                        <Input
                            required={fieldItem.required}
                            type={fieldItem.type}
                            className={classes.inputHidden}
                            name={fieldItem.fieldName}
                            label={fieldItem.fieldLabel}
                            fullWidth
                            value={fieldItem.fieldValue}
                            error={errorObj.error}
                            // helperText={errorObj.helperText}
                        />
                    </div>
                )
            }
        }
        else if(fieldItem.fieldType === 'textarea') {
            return (
                <ClickAwayListener onClickAway={closeField}>
                <div className={props.styles}>
                    {props.left && !props.table && (<FormLabel className={classes.textareaFieldLabel} style={style} component="span">{fieldItem.fieldLabel}: </FormLabel>)}
                    <TextField
                        style={{float: props.left ? "left" : "right",width: "100%"}}
                        required={fieldItem.required}
                        type={fieldItem.type}
                        name={fieldItem.fieldName}
                        className={classes.textField}
                        id={fieldId}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        tabIndex={props.item} 
                        defaultValue={fieldItem.fieldTextValue}
                        onChange={(event) => checkErrorsFunction(fieldItem, event)}
                        error={errorObj.error}
                        // helperText={errorObj.helperText}
                        InputProps={{
                            endAdornment: 
                                <InputAdornment position="end">
                                    <CheckIcon className={classes.changeFieldIcon} onClick={(event) => changeField(fieldItem, event)} />
                                    <ClearIcon className={classes.changeFieldIcon} onClick={closeField}/>
                                </InputAdornment>
                        }}
                    />
                    {!props.left && !props.table && (<FormLabel className={classes.textFieldLabel} style={style} component="legend">{fieldItem.fieldLabel}: </FormLabel>)}
                </div>
                </ClickAwayListener>
            )
        }
        else if (fieldItem.fieldType === 'select') {
            return (
                // <ClickAwayListener onClickAway={closeField}>
                    <div className={props.styles}>
                        <FormControl className={classes.formControl}>
                        {props.left && !props.table && (<InputLabel id={labelId} required={fieldItem.required}>{fieldItem.fieldLabel}</InputLabel>)}
                            <Select
                                labelId={labelId}
                                id={fieldId}
                                name={fieldItem.fieldName}
                                fullWidth
                                autoFocus
                                tabIndex={props.item}
                                MenuProps={{ classes: { paper: classes.selectHeight } }}
                                value={fieldItem.fieldValue}
                                onChange={(event) => checkErrorsFunctionSelect(fieldItem, event)}
                                onBlur={(event) => toggleCloseFieldSelect(fieldItem, event)}
                            >
                                {
                                    typeof fieldItem.noNone === "undefined" && (
                                        <MenuItem value="" id={`${fieldItem.fieldName}_null`}>
                                            <em>None</em>
                                        </MenuItem>
                                    )
                                }

                                {fieldItem.fieldOptions.map(val => val.label !== "" && (
                                    <MenuItem key={val.val} id={`${fieldItem.fieldName}_${fieldItem.fieldValue}`} value={val.val}>
                                        {val.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
                        </FormControl>
                        {!props.left && !props.table && (<InputLabel id={labelId} required={fieldItem.required}>{fieldItem.fieldLabel}</InputLabel>)}
                    </div>
                // </ClickAwayListener>
            )
        }
        else if(fieldItem.fieldType === 'date' && fieldItem.type !== "hidden") {
            return (
                <MuiPickersUtilsProvider utils={DateFnsUtils} className={`${classes.formControl} ${props.styles}`}>
                    <ClickAwayListener onClickAway={closeField} >
                        <Grid container justify="flex-start">
                            {!props.left && !props.table && (<FormLabel className={classes.textFieldLabel} style={style} component="span">{fieldItem.fieldLabel}: </FormLabel>)}
                                <KeyboardDatePicker
                                    style={{float: props.left ? "left" : "right",width: props.width ? props.width : ""}}
                                    disableToolbar
                                    autoOk
                                    autoFocus
                                    variant="inline"
                                    format={user.dateFormat}
                                    required={fieldItem.required}
                                    name={fieldItem.fieldName}
                                    className={classes.textField}
                                    id={fieldId}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    onKeyDown={(event) => toggleCloseField(fieldItem, event)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    error={errorObj.error}
                                    // helperText={errorObj.helperText}
                                />
                            {props.left && !props.table && (<FormLabel className={classes.textFieldLabel} style={style} component="span">{fieldItem.fieldLabel}: </FormLabel>)}
                        </Grid>
                    </ClickAwayListener>
                </MuiPickersUtilsProvider>
            )
        }
    }

    return !openField  && !props.addRow ? renderField(fieldItem) : renderFormInvoice(fieldItem);
}