import React, { useContext, useState } from 'react';
import { 
    makeStyles, 
 } from '@material-ui/core';
import { UserContext } from '../../../App';
import FormInvoice from '../../common/FormInvoice';
import helper from '../../common/Helper';

const useStyles = makeStyles((theme) => ({
	root: {
        display: 'inline-flex',
        flexDirection: "column",
        position: "relative",
        width: "100%",
        top: theme.spacing(2)
	},
	itemName: {
        position: "absolute",
        right: 0,
        top: -32,
	},
	footer: {
        display: `inline-flex`,
	}
}));

export default function InvoiceFooter(props) {

	const classes = useStyles();
	// Get current user preferences (language)
	const user = useContext(UserContext);
    const invoice = props.invoice;

    let footerText = invoice.footer && invoice.footer[user.lang] !== "" ? invoice.footer[user.lang] : props.output ? props.defaultOutFooter || "" : "";
    
    const [fieldListFooter, setFieldListFooter] = useState([
        {
            required: false,
            type: "text",
            fieldType: "textarea",
            fieldName: "footer",
            fieldLabel: user.translate("footer"),
            fieldValue: footerText,
            fieldTextValue: footerText,
        },
        {
            required: true,
            type: "hidden",
            fieldType: "text",
            fieldName: "footerLangId",
            fieldLabel: "footerLangId",
            fieldValue: invoice.footer ? invoice.footer.id : "",
        },
    ]);

	const setItems = (event, fieldName, fieldValue) => {

        let items = {}, field = {};
        let langId = fieldListFooter.find(c => c.fieldName === `${fieldName}LangId`);

        field = {
            fieldType: `$${fieldName}: String!`,
            field: fieldName
        };

        if(typeof langId !== "undefined")
        {
            items = {
                id: langId.fieldValue,
                [fieldName]: fieldValue,
            }
            props.onEditInvoice(items, field, true);
        }
        else
        {
            items = {
                id: invoice.id,
                [fieldName]: fieldValue,
            }
            props.onEditInvoice(items, field, false);
        }

        user.consoleLog(items);
    }

	return (
		<div className={classes.root}>

            {
                fieldListFooter.map((item, index) => (
                    <FormInvoice 
                        key={"invoice_billing_"+item.fieldValue+index} 
                        fieldList={fieldListFooter} 
                        item={index} 
                        tabIndex={36}
                        setFieldList={setFieldListFooter} 
                        setItems={setItems}
                        left={true}
                        footer={true}
                        noprint="no_print"
                        styles={classes.footer}
                        />
                ))
            }

		</div>
	);

}
