import React, { useContext, useState } from 'react';
import { 
    makeStyles, 
    Button,
    Slide,
    alpha
 } from '@material-ui/core';
import { UserContext } from '../../../App';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import KomitentListDialog from '../../common/KomitentListDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InvoiceApplyBillFrom(props) {
	const classes = useStyles();
	// Get current user preferences (language)
	const user = useContext(UserContext);
    const invoice = props.invoice;

    const [openApply, setOpenApply] = useState(false);

    const onOpenApply = () => {
        setOpenApply(true);
    }
    const onToggleClose = () => {
        setOpenApply(false);
    }

    const handleSelectAccept= (item) => {

        if(item)
        {
            let items = {
                id: invoice.id,
                payeeCompanyId: item.commissionerId,
                billingFrom: item.name,
                billingFromLangId: invoice.billingFrom.id,
                billingRegNumber: item.regNumber,
                billingVAT: item.vat,
                billingBankName: item.bankName,
                billingBankNameLangId: invoice.billingBankName.id,
                billingBankAddress: item.bankAddress,
                billingBankAddressLangId: invoice.billingBankAddress.id,
                billingBankAccount: item.bankAccount,
                billingSwift: item.swift,
                billingAddress: item.address ? item.address+', '+item.city+', '+item.country : "",
                billingAddressLangId: invoice.billingAddress.id,
                billingContactPhone: item.contactPhone,
                billingContactEmail: item.contactEmail,
                billingContactPerson: item.contactPerson,
                billingContactPersonLangId: invoice.billingContactPerson.id,
            }

            let fieldList = [
                {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "id",
                    fieldLabel: "id",
                    fieldValue: invoice.id,
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingFrom",
                    fieldLabel: user.translate("from"),
                    fieldValue: item.name,
                },
                {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "billingFromLangId",
                    fieldLabel: "billingFromLangId",
                    fieldValue: invoice.billingFrom ? invoice.billingFrom.id : "",
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingRegNumber",
                    fieldLabel: user.translate("billing_reg_no"),
                    fieldValue: item.regNumber,
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingBankName",
                    fieldLabel: user.translate("bank_name"),
                    fieldValue: item.bankName,
                },
                {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "billingBankNameLangId",
                    fieldLabel: "billingBankNameLangId",
                    fieldValue: invoice.billingBankName ? invoice.billingBankName.id : "",
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingBankAddress",
                    fieldLabel: user.translate("bank_address"),
                    fieldValue: item.bankAddress,
                },
                {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "billingBankAddressLangId",
                    fieldLabel: "billingBankAddressLangId",
                    fieldValue: invoice.billingBankAddress ? invoice.billingBankAddress.id : "",
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingBankAccount",
                    fieldLabel: user.translate("bank_account"),
                    fieldValue: item.bankAccount,
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingSwift",
                    fieldLabel: user.translate("swift"),
                    fieldValue: item.swift,
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingAddress",
                    fieldLabel: user.translate("address"),
                    fieldValue: items.billingAddress,
                },
                {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "billingAddressLangId",
                    fieldLabel: "billingAddressLangId",
                    fieldValue: invoice.billingAddress ? invoice.billingAddress.id : "",
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingContactPhone",
                    fieldLabel: user.translate("contact_phone"),
                    fieldValue: item.contactPhone,
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingContactEmail",
                    fieldLabel: user.translate("contact_email"),
                    fieldValue: item.contactEmail,
                },
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "billingContactPerson",
                    fieldLabel: user.translate("contact_person"),
                    fieldValue: item.contactPerson,
                },
                {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "billingContactPersonLangId",
                    fieldLabel: "billingContactPersonLangId",
                    fieldValue: invoice.billingContactPerson ? invoice.billingContactPerson.id : "",
                },
            ]

            props.setFieldListBilling([...fieldList]);

            props.onEditInvoiceBillFrom(items)
        }

        onToggleClose();
    }

	return (
		<div className={`${classes.root} no_print`} >
            {!openApply &&(
                <Button  
                    color="primary" 
                    component="span"
                    onClick= {onOpenApply}
                    startIcon={<SubtitlesIcon />}
                >
                    {user.translate("komitents")}
                </Button>
            )}

            {openApply && (
                <KomitentListDialog 
                    setOpenSelectBox={setOpenApply} 
                    openSelectBox={openApply}
                    action={handleSelectAccept}
                />
            )}

		</div>
	);

}
