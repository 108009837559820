import { green, red } from '@material-ui/core/colors';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';


export default function CustomTheme(props) {
    const customTheme = createTheme({
        palette: {
            primary: props.primary || {
                main: green[500],
                contrastText: '#fff',
                dark: green[900]
            },
            secondary: props.secondary || {
                main: red[500],
                contrastText: '#fff',
            },
        },
    });

    return <ThemeProvider theme={customTheme} children={props.children} />

}