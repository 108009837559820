import DateFnsUtils from "@date-io/date-fns";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import NumberFormat from "react-number-format";
import {UserContext} from "../../App";
import {callOrGet} from "./form/utils";
import helper from "./Helper";

const useStyles = makeStyles((theme) => ({
    roots: {
        display: "inline-flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
    },
    root: {
        padding: 0,
        paddingRight: 3,
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3",
        },
    },
}));

function NumberFormatCustom(props) {
    const user = useContext(UserContext);

    const {inputRef, onChange, decimalScale, ...other} = props;

    return (
        <NumberFormat
            {...other}
            style={{fontSize: 12}}
            getInputRef={inputRef}
            onKeyDown={(event) => {
                switch (event.key) {
                    case "ArrowDown":
                        onChange({
                            target: {
                                name: props.name,
                                value: user.parseStringNumber(event.target.value || "0") - 1,
                            },
                        });
                        break;
                    case "ArrowUp":
                        onChange({
                            target: {
                                name: props.name,
                                value: user.parseStringNumber(event.target.value || "0") + 1,
                            },
                        });
                        break;
                    default:
                        break;
                }
            }}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={user.restLang === "mk" ? "." : ","}
            decimalSeparator={user.restLang === "mk" ? "," : "."}
            decimalScale={decimalScale}
            isNumericString
            // prefix={props.name === 'price' || props.name === 'amount' ? user.icu.getCurrencySymbol() : undefined}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    decimalScale: PropTypes.number.isRequired,
};

const preventPropagation = (event, isOpen) => {
    switch (event.key) {
        case "Enter":
            if (typeof isOpen !== "undefined") {
                if (isOpen) event.stopPropagation();
            } else {
                event.stopPropagation();
            }
            break;
        default:
            break;
    }
};

const getDefault = (props, selectDefault) => {
    if (selectDefault instanceof Function) return selectDefault(props.rowData);
    return selectDefault;
};

const tableComponents = {
    FilterOptions: function (options, params, fieldId, rowData) {
        if (typeof rowData !== "undefined") {
            return options.filter((option) => {
                return (
                    option.name &&
                    option.name
                        .cirilicToLatin()
                        .indexOf(params.inputValue.cirilicToLatin()) > -1 &&
                    rowData[fieldId] === option.countryId
                );
            });
        } else {
            return options.filter(
                (option) =>
                    option.name &&
                    option.name
                        .cirilicToLatin()
                        .indexOf(params.inputValue.cirilicToLatin()) > -1
            );
        }
    },
    AutoCompleteAdd: function (
        props,
        options,
        fieldId,
        fieldName,
        firstVal = false
    ) {
        if (firstVal) {
            if (typeof props.rowData[fieldId] === "undefined") {
                props.rowData[fieldId] = firstVal[0].id;
                props.rowData[fieldName] = firstVal[0].name;
            }
        }

        const [isOpen, setIsOpen] = useState(false);

        return (
            <Autocomplete
                onOpen={() => {
                    setIsOpen(true);
                }}
                onClose={() => {
                    setIsOpen(false);
                }}
                options={options}
                autoHighlight
                selectOnFocus
                handleHomeEndKeys
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                }}
                value={{
                    id: props.rowData[fieldId],
                    name: props.rowData[fieldName],
                }}
                filterOptions={(options, params) => {
                    const filtered =
                        fieldName === "city"
                            ? tableComponents.FilterOptions(
                                options,
                                params,
                                "countryId",
                                props.rowData
                            )
                            : tableComponents.FilterOptions(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                onChange={(event, value) => {
                    if (value !== null) {
                        if (typeof value.inputValue !== "undefined") {
                            props.rowData[fieldId] = helper.uid();
                            props.rowData[fieldName] = value.inputValue;
                            if (fieldName === "country") {
                                props.rowData.city = "";
                                props.rowData.cityId = "";
                            }
                            props.onChange(value.inputValue);
                        } else {
                            props.rowData[fieldId] = value.id;
                            props.rowData[fieldName] = value.name;
                            if (fieldName === "country") {
                                props.rowData.city = "";
                                props.rowData.cityId = "";
                            }
                            props.onChange(value.name);
                        }
                    } else props.onChange("");
                }}
                onKeyDown={(event) => preventPropagation(event, isOpen)}
                renderOption={(option) => option.name}
                renderInput={(params) => {
                    params.inputProps.autoComplete = "off";
                    params.inputProps.style = {fontSize: 12};
                    return <TextField {...params} />;
                }}
            />
        );
    },
    AutoComplete: function (
        props,
        options,
        fieldId,
        fieldName,
        loading = false,
        selectDefault
    ) {
        const [isOpen, setIsOpen] = useState(false);

        let value = props.rowData[fieldId];
        let selected =
            value !== undefined
                ? {
                    id: value,
                    name: props.rowData[fieldName],
                }
                : getDefault(props, selectDefault);
        if (selected !== undefined) props.rowData[`_${fieldId}`] = selected.id;

        return (
            <Autocomplete
                onOpen={() => {
                    setIsOpen(true);
                }}
                onClose={() => {
                    setIsOpen(false);
                }}
                autoHighlight
                selectOnFocus
                handleHomeEndKeys
                options={options}
                filterOptions={tableComponents.FilterOptions}
                value={selected}
                onChange={(event, value) => {
                    if (value !== null) {
                        props.rowData[fieldId] = value.id;
                        props.rowData[fieldName] = value.name;
                        props.onChange(value.name);
                    } else {
                        props.rowData[fieldId] = undefined;
                        props.onChange("");
                    }
                }}
                onKeyDown={(event) => preventPropagation(event, isOpen)}
                getOptionSelected={(option, value) =>
                    value && option.name.indexOf(value.name) > -1
                }
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => {
                    params.inputProps.autoComplete = "off";
                    params.inputProps.style = {fontSize: 12};
                    return (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20}/>
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    );
                }}
            />
        );
    },
    Select: function (props, options, fieldName, selectDefault) {
        let value = props.rowData[fieldName];
        let selected =
            value != undefined ? value : getDefault(props, selectDefault);
        if (selected != undefined) props.rowData[`_${fieldName}`] = selected;

        return (
            <Select
                name={fieldName}
                fullWidth
                value={selected}
                onKeyDown={preventPropagation}
                style={{fontSize: 12}}
                onChange={(event) => {
                    if (event.target.value !== "") {
                        props.rowData[fieldName] = event.target.value;
                        props.onChange(event.target.value);
                    } else props.onChange("");
                }}
            >
                {options.map(
                    (option) =>
                        option.label !== "" && (
                            <MenuItem
                                key={option.val}
                                value={option.val}
                                onKeyDown={preventPropagation}
                            >
                                {option.label}
                            </MenuItem>
                        )
                )}
            </Select>
        );
    },
    BooleanRender: function (rowData, fieldName) {
        let fieldValue =
            rowData[fieldName] === 1 || rowData[fieldName] === "1" ? true : false;
        if (fieldValue) return <CheckIcon/>;
        else return <ClearIcon/>;
    },
    StyledCheckbox: function (props) {
        const classes = useStyles();

        return (
            <Checkbox
                className={classes.root}
                disableRipple
                color="default"
                checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)}/>
                }
                icon={<span className={classes.icon}/>}
                inputProps={{"aria-label": "decorative checkbox"}}
                {...props}
            />
        );
    },
    BooleanEditComponent: function (props, fieldName, fieldTitle, classes) {
        const user = useContext(UserContext);

        let fieldValue =
            props.rowData[fieldName] === 1 || props.rowData[fieldName] === "1"
                ? true
                : false;

        return (
            <FormControlLabel
                control={
                    <tableComponents.StyledCheckbox
                        defaultChecked={!!fieldValue || false}
                    />
                }
                onChange={(e) => {
                    props.onChange(e.target.checked ? 1 : 0);
                }}
                label={user.translate(fieldTitle)}
                className={classes.checkedLabel}
            />
        );
    },
    DateEditComponent: function (props, fieldName, firstVal) {
        const user = useContext(UserContext);

        var srcDate = new Date(props.rowData[fieldName]);
        if (srcDate == "Invalid Date") srcDate = new Date(firstVal);
        if (srcDate == "Invalid Date") srcDate = new Date();
        let srcDateValue = srcDate.toISODate();
        if (props.rowData[fieldName] != srcDateValue) {
            props.rowData[fieldName] = srcDateValue;
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="flex-start">
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format={user.dateFormat}
                        value={srcDate}
                        onChange={(date) => {
                            if (!date || date == "Invalid Date") return;
                            let dateValue = date.toISODate();
                            props.rowData[fieldName] = dateValue;
                            props.onChange(dateValue);
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        );
    },
    DateMilisEditComponent: function (props, fieldName, firstVal) {
        const user = useContext(UserContext);

        var srcDate = new Date(parseInt(props.rowData[fieldName]));
        if (srcDate == "Invalid Date") srcDate = new Date(firstVal);
        if (srcDate == "Invalid Date") srcDate = new Date();
        if (props.rowData[fieldName] != srcDate.getTime().toString()) {
            props.rowData[fieldName] = srcDate.getTime().toString();
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid
                    container
                    justify="flex-start"
                    style={{minWidth: 120, ["> input"]: {fontSize: 12}}}
                >
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format={user.dateFormat}
                        value={srcDate}
                        onChange={(date) => {
                            if (!date || date == "Invalid Date") return;
                            let dateValue = date.getTime().toString();
                            props.rowData[fieldName] = dateValue;
                            props.onChange(dateValue);
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        );
    },
    DateTimeEditComponent: function (props, fieldName, firstVal) {
        const user = useContext(UserContext);

        if (firstVal && typeof props.rowData[fieldName] === "undefined") {
            props.rowData[fieldName] = firstVal;
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="flex-start">
                    <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format={user.dateFormat}
                        value={props.rowData[fieldName]}
                        onChange={(date) => {
                            props.rowData[fieldName] = date;
                            props.onChange(date);
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        );
    },
    DefaultEditComponent: function (
        props,
        fieldName,
        type = "text",
        onlyInteger = false
    ) {
        return (
            <TextField
                id={fieldName}
                fullWidth
                type={type}
                onChange={(e) =>
                    props.onChange(
                        onlyInteger
                            ? parseInt(e.target.value)
                            : type === "text"
                                ? e.target.value
                                : parseFloat(e.target.value)
                    )
                }
                value={props.rowData[fieldName]}
            />
        );
    },
    NumericRender: function (rowData, fieldName) {
        const user = useContext(UserContext);

        return user.formatNumber(rowData[fieldName]);
    },
    NumericEditComponent: function (
        props,
        fieldName,
        onlyInteger = false,
        decimalScale = 2,
        defaultVal,
        convert
    ) {
        const item = props.rowData;
        let value = item[fieldName] ?? callOrGet(defaultVal, item);
        if (convert) value = convert(item, value);
        return (
            <TextField
                id={fieldName}
                fullWidth
                name={fieldName}
                value={value}
                onChange={(e) => {
                    let newValue = onlyInteger
                        ? parseInt(e.target.value)
                        : e.target.value;
                    if (convert) newValue = convert(item, newValue, true);
                    return props.onChange(newValue);
                }}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    autoComplete: "off",
                }}
                inputProps={{decimalScale: decimalScale}}
            />
        );
    },
    NumericUomEditComponent: function (
        props,
        fieldName,
        decimalScale = 2,
        invertUom = false,
        defaultUnits
    ) {
        let unitQuantities =
            props.rowData.unitQuantities ||
            (defaultUnits && defaultUnits(props.rowData));
        const convertUom = (value, fromUnit, toUnit = 0, roundTo = 3) => {
            if (unitQuantities == undefined) return undefined;
            if (unitQuantities[fromUnit] == undefined) return undefined;
            if (unitQuantities[toUnit] == undefined) return undefined;
            return (
                (value * unitQuantities[fromUnit]) /
                unitQuantities[toUnit]
            ).round(roundTo);
        };
        let uomOpts =
            props.rowData._uomOpts != undefined
                ? props.rowData._uomOpts
                : props.rowData.uomOpts || 0;
        let fromUomOpts = invertUom ? uomOpts : 0;
        let toUomOpts = invertUom ? 0 : uomOpts;
        let oldValue = props.rowData[fieldName];
        let defaultValue = convertUom(oldValue, fromUomOpts, toUomOpts);

        return (
            <TextField
                id={fieldName}
                key={`input_${fieldName}_${uomOpts}`}
                fullWidth
                name={fieldName}
                defaultValue={defaultValue}
                onChange={(e) => {
                    let value = parseFloat(e.target.value);
                    let newValue = convertUom(value, toUomOpts, fromUomOpts);
                    props.rowData[fieldName] = newValue;

                    if (oldValue != newValue) {
                        props.onChange(newValue);
                    }
                }}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    autoComplete: "off",
                }}
                inputProps={{decimalScale: decimalScale}}
            />
        );
    },
};

export default tableComponents;
