import React, { useContext, useState } from 'react';
import { UserContext } from "../App";
import Add from '../restaurant/common/Add';

// React Function Component
export default function RequestEmail(props) {

	const user = useContext(UserContext);

    const me = user.me;
    
	const EDIT_ITEM = `
    mutation ($email: String!) {
        auth_changeEmail(email: $email) {
            message
            ok
        }
    }`;

	const fieldList = [
		{
			required: true,
			type: "email",
			fieldType: "text",
			fieldName: "email",
			fieldLabel: user.translate("email"),
			fieldValue: me.profile.email
		},
	];

	const onSuccess = (data_requestEmail) => {
        //needs to be empty
	}

	return (

		<Add
			fieldList={fieldList}
			openManipulateBox={props.setOpenRequestEmail}
			actionType={user.translate("edit")}
			name={user.translate("email")}
			manipulateItem={EDIT_ITEM}
            onSuccess={onSuccess}
            returnParams={true}
            returnSuccess={'auth_changeEmail'}
		/>
	)

}
