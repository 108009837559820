import { gql, useMutation, useQuery } from "@apollo/client"
import { CircularProgress, makeStyles, Tooltip } from "@material-ui/core"
import React, { useContext, useState } from "react"
import { RestaurantContext, UserContext } from "../../App"
import DeletedItemsDialog from "../common/DeletedItemsDialog"
import helper from "../common/Helper"
import SnackBarDelete from "../common/SnackBarDelete"
import SnackBarWarning from "../common/SnackBarWarning"
import Table from "../common/Table"
import tableComponents from "../common/TableComponents"
import useWindowSize from "../common/useWindowSize"
import StockTypeDetail from "./StockTypeDetail"

const useStyles = makeStyles((theme) => ({
  roots: {
    "& .MuiTableCell-root .MuiIconButton-root": {
      padding: theme.spacing(1),
    },
    "& .MTableToolbar-spacer-31": {
      [theme.breakpoints.only("xs")]: {
        flex: 0,
      },
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checkedLabel: {
    marginRight: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: 12,
    },
  },
}))

// React Function Component
export default function StockList() {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)
  const size = useWindowSize()

  const [page, setPage] = useState(0)

  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)
  const [deleteState, setDeleteState] = useState({
    variables: {},
    name: "",
    state: {},
  })

  const multiLang = user.me.profile && user.me.profile.isAdmin && !size.isCompact

  const GQL_SAVE_ITEM = gql`
        mutation ($restaurantId: String!, $id: String!, ${
          multiLang ? "$nameMk: String!, $nameEn: String!" : "$name: String!"
        }, $taxTypeId: String!, $uom: Long!, $piece: Float!, $package: Float!, $allergen: Long!, $ingredient: Long!) {
            createStockType(input: {
                id: $id
                ${multiLang ? user.gqlCreateNameTr("$nameMk", "$nameEn") : user.gqlCreateName("$name")}
                taxTypeId: $taxTypeId
                uom: $uom
                piece: $piece
                package: $package
                allergen: $allergen
                ingredient: $ingredient
                restaurantId: $restaurantId
            }){
                id
            }
    }`
  const [saveItem, { loading: loadingSave }] = useMutation(GQL_SAVE_ITEM)

  const DELETE_ITEM = gql`
    mutation ($id: String!) {
      deleteStockType(id: $id)
    }
  `
  const [deleteItem, { loading: loadingDelete }] = useMutation(DELETE_ITEM)

  const UNDELETE_ITEM = `
        mutation ($id: String!) {
            undeleteStockType(id: $id)
    }`

  // GraphQL API request definition (local variables: restaurantId)
  const GET_STOCK_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
        getStockTypesByRestaurantId(restaurantId: $restaurantId) {
            id
            name {
                ${user.gqlFetchName()}
            }
            taxTypeId
            uom
            piece
            package
            allergen
            ingredient
            stockTypeProducts {
                product {
                    name
                }
            }
            receivingStockItemsCount
        }
        getTaxTypesByContextRestaurantId(restaurantId: $restaurantId) {
            id
            name
            rate
            code
        }
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(GET_STOCK_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 7000,
    errorPolicy: "ignore",
    // skip: loadingSave || loadingDelete
  })

  // if (loading) return <CircularProgress />
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error)
    return <p>Error: {error}</p>
  }

  let taxTypes = Object.assign([], data.getTaxTypesByContextRestaurantId)
    .sort((a, b) => b.rate - a.rate)
    .map((tax) => ({
      val: tax.id,
      label: "" + tax.rate * 100,
    }))

  let firstTaxType = taxTypes.slice(0, 1)

  let lookupUom = size.isMobile
    ? [
        { val: 0, label: "L" },
        { val: 1, label: "Kg" },
        { val: 2, label: "p" },
      ]
    : [
        { val: 0, label: user.translate("liter") },
        { val: 1, label: user.translate("kilogram") },
        { val: 2, label: user.translate("piece") },
      ]

  let fieldList = {
    options: {
      detailPanelColumnAlignment: "right",
      detailPanelType: "single",
    },
  }
  fieldList["columns"] = [
    // { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'Id', field: 'id', hidden: true, },
    // { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'langId', field: 'langId', hidden: true, },
    // { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'taxTypeId', field: 'taxTypeId', hidden: true, },
    // { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'uom', field: 'uom', hidden: true, },
    // { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'stockTypeProductsString', field: 'stockTypeProductsString', hidden: true, },
    {
      title: "",
      field: "count",
      editable: "never",
      cellStyle: { width: "1%", padding: `0 5px 0 0`, fontSize: 12, whiteSpace: "pre-line" },
    },
    {
      title: user.translate("title"),
      field: "name",
      cellStyle: { width: "30%", padding: 0, fontSize: 14, whiteSpace: "pre-line" },
      customSort: (a, b) =>
        a.name.cirilicLatinCompare(user.lang).localeCompare(b.name.cirilicLatinCompare(user.lang), user.lang),
    },
    // { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'extra_empty', field: 'extra_empty', hidden: true, export: false },
    {
      title: user.translate("tax") + " %",
      field: "taxType",
      editComponent: (props) => tableComponents.Select(props, taxTypes, "taxTypeId"),
    },
    {
      title: user.translate("uom"),
      field: "uomLabel",
      editComponent: (props) => tableComponents.Select(props, lookupUom, "uom"),
    },
    {
      title: user.translate("piece").capitalize(),
      field: "piece",
      mobile: false,
      render: (rowData) => user.formatQuantity(rowData.piece),
      editComponent: (props) => tableComponents.NumericEditComponent(props, "piece", false, 3),
    },
    {
      title: user.translate("pcg"),
      field: "package",
      render: (rowData) => user.formatQuantity(rowData.package),
      mobile: false,
      editComponent: (props) => tableComponents.NumericEditComponent(props, "package", false, 3),
    },
    {
      title: user.translate("products_count"),
      field: "stockTypeProductsCount",
      editable: "never",
      mobile: false,
      cellStyle: { width: "5%", padding: 0, fontSize: 12, whiteSpace: "pre-line" },
      render: (rowData) => {
        if (rowData.stockTypeProductsString !== "") {
          let title = rowData.stockTypeProductsString.split(",")
          title = title.map((x, i) =>
            x !== "" ? (
              <p key={helper.hash(x + i)}>
                {i + 1}. {x}
              </p>
            ) : (
              ""
            )
          )
          return (
            <Tooltip title={title} arrow style={{ whiteSpace: "pre-line" }}>
              <span>{rowData.stockTypeProductsCount}</span>
            </Tooltip>
          )
        } else return rowData.stockTypeProductsCount
      },
    },
    {
      title: user.translate("allergen"),
      field: "allergen",
      hidden: size.isCompact,
      render: (rowData) => tableComponents.BooleanRender(rowData, "allergen"),
      editComponent: (props) => tableComponents.BooleanEditComponent(props, "allergen", "allergen", classes),
    },
    {
      title: user.translate("ingredient"),
      field: "ingredient",
      hidden: size.isCompact,
      render: (rowData) => tableComponents.BooleanRender(rowData, "ingredient"),
      editComponent: (props) => tableComponents.BooleanEditComponent(props, "ingredient", "ingredient", classes),
      customFilterAndSearch: (filterValue, row) => {
        return (
          (row.name && row.name.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
          (row.nameMk && row.nameMk.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
          (row.nameEn && row.nameEn.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
          (row.taxType && row.taxType.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
          (row.uomLabel && row.uomLabel.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1)
        )
      },
    },
  ]

  if (multiLang) {
    fieldList["columns"].splice(1, 1, {
      title: user.translate("titleMk"),
      field: "nameMk",
      cellStyle: { width: "30%", padding: "0 5px 0 0", fontSize: 14, whiteSpace: "pre-line" },
      customSort: (a, b) => a.name.cirilicLatinCompare("mk").localeCompare(b.name.cirilicLatinCompare("mk"), "mk"),
    })
    fieldList["columns"].splice(2, 0, {
      title: user.translate("titleEn"),
      field: "nameEn",
      cellStyle: { width: "30%", padding: "0 5px 0 0", fontSize: 14, whiteSpace: "pre-line" },
      customSort: (a, b) => a.name.cirilicLatinCompare("en").localeCompare(b.name.cirilicLatinCompare("en"), "en"),
    })
  }

  let timeSlot = Math.pow(2, 62)
  fieldList["data"] = Object.assign([], data.getStockTypesByRestaurantId)
    .sort((a, b) => (a.id % timeSlot) - (b.id % timeSlot))
    .map((stock, index) => ({
      ...stock,
      count: index + 1 + ".",
      langId: stock.name.id,
      name: stock.name[user.lang],
      nameMk: stock.name["mk"],
      nameEn: stock.name["en"],
      stockTypeProductsCount: stock.stockTypeProducts ? stock.stockTypeProducts.length : 0,
      // stockTypeProductsString: stock.stockTypeProducts && stock.stockTypeProducts.length > 0 ? <p>{stock.stockTypeProducts.map(x => x.product.name).join("</p><p>")}</p> : 0,
      stockTypeProductsString:
        stock.stockTypeProducts && stock.stockTypeProducts.length > 0
          ? stock.stockTypeProducts.map((x) => (x.product ? x.product.name : "")).join(",")
          : "",
      allergen: stock.allergen ? 1 : 0,
      ingredient: stock.ingredient ? 1 : 0,
      taxType: taxTypes.find((c) => c.val === stock.taxTypeId).label,
      uomLabel: lookupUom.find((c) => c.val === stock.uom).label,
    }))

  const onSaveItem = (itemData) => {
    return saveItem({
      variables: {
        id: itemData.id || helper.uid(),
        langId: itemData.langId || "",
        name: itemData.name ? itemData.name : "",
        nameMk: itemData.nameMk ? itemData.nameMk : "",
        nameEn: itemData.nameEn ? itemData.nameEn : "",
        taxTypeId: itemData.taxTypeId
          ? itemData.taxTypeId
          : typeof firstTaxType[0] != "undefined"
          ? firstTaxType[0].val
          : "1",
        uom: typeof itemData.uom == "undefined" ? 0 : itemData.uom,
        piece: itemData.piece || 1,
        package: itemData.package || 1,
        allergen: itemData.allergen ? 1 : 0,
        ingredient: itemData.ingredient ? 1 : 0,
        restaurantId: restaurant.id,
      },
    })
      .then((e) => {
        refetch()
        if (itemData.id == undefined) {
          setPage(Math.round((data.getStockTypesByRestaurantId.length + 1) / 50))
        }
      })
      .catch((e) => {
        setOpenSnackBarWarning({
          alertMessage: e.networkError.result.errors.map((x) => x.message).join(),
          autoHideDuration: 3000,
          linkTo: ``,
          linkText: "",
        })
        throw e
      })
  }

  const onDeleteItem = (oldData, state) => {
    let itemData = { id: oldData.id }

    console.log(oldData)
    if (oldData.stockTypeProductsCount > 0) {
      setOpenSnackBarWarning({
        alertMessage: `${user.translate("cant_delete_stock_product")} `,
        autoHideDuration: 3000,
        linkTo: ``,
        linkText: "",
      })
    } else if (oldData.receivingStockItemsCount > 0) {
      setOpenSnackBarWarning({
        alertMessage: `${user.translate("cant_delete_stock_receiving")} `,
        autoHideDuration: 3000,
        linkTo: ``,
        linkText: "",
      })
    } else {
      return deleteItem({ variables: itemData })
        .then(() => {
          refetch()
          setDeleteState({
            variables: itemData,
            name: oldData["name"],
            state: state,
          })
          setOpenSnackBar(true)
        })
        .catch((e) => {
          setOpenSnackBarWarning({
            alertMessage: e.networkError.result.errors.map((x) => x.message).join(),
            autoHideDuration: 3000,
            linkTo: ``,
            linkText: "",
          })
          throw e
        })
    }
  }

  const uomTitles = [user.translate("liter"), user.translate("kilogram"), user.translate("piece")]

  const FIELDS = [
    { name: "id", gql: "String!", id: true, hidden: true },
    {
      name: "name",
      gql: "Translation",
      width: 3,
      subSelection: { [user.lang]: true },
      render: (item) => item.name[user.lang],
    },
    { name: "uom", gql: "String", render: (item) => uomTitles[item.uom] },
    // { name: "return", width: 0.01, render: (item) => <IconButton><RestoreFromTrashIcon /></IconButton> },
  ]

  return (
    <div className={classes.roots}>
      <Table
        key={helper.hash(user.lang + page)}
        tableName={user.translate("stock_items")}
        exportFileName={user.translate("stockitems")}
        fieldList={fieldList}
        pageSize="50"
        pageSizeOptions={[50, 100, 200]}
        onAddItem={onSaveItem}
        onEditItem={onSaveItem}
        onDeleteItem={onDeleteItem}
        page={page}
        detailPanel={(rowData) => <StockTypeDetail stockTypes={data.getStockTypesByRestaurantId} item={rowData} />}
        onRowClick={(rowData, togglePanel) => togglePanel()}
      />

      <DeletedItemsDialog title="stock_items" entityName="StockType" fields={FIELDS} onChange={refetch} />

      {openSnackBarWarning && (
        <SnackBarWarning
          message={openSnackBarWarning.alertMessage}
          linkTo={openSnackBarWarning.linkTo}
          linkText={openSnackBarWarning.linkText}
          autoHideDuration={openSnackBarWarning.autoHideDuration || 3000}
          openSnackBar={true}
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}

      {openSnackBar && (
        <SnackBarDelete
          message={`${deleteState.name} 
                    ${user.translate("deleted")}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}
    </div>
  )
}
