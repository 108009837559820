import React, { useContext, useState } from "react";
import PrimarySearchAppBarDrawer from "../app/AppNavigation";
import RestaurantList from "./RestaurantList";
import { UserContext } from "../App";
import {
    makeStyles,
    CircularProgress,
	Link,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({ 
    resend_mail: {
		cursor: "pointer"
	}
}));

export default function Home(props) {
    const classes = useStyles();

    const user = useContext(UserContext);

    const verifiedMail = user.me.profile.email !== "" && user.me.profile.isEmailVerified === 1;
    const [verifyEmail, setVerifyEmail] = useState(false);

    const onResendMail = () => {
        setVerifyEmail(true);
    }

    let title = user.translate("welcome");
    if(user.me.profile && user.me.profile.name)
    {
        title += ", " + user.me.profile.name;
    }

    return (
        <PrimarySearchAppBarDrawer 
            title={title} 
            onSetLanguage={props.onSetLanguage} 
            refetch={props.refetch} 
            verifyEmail={verifyEmail} 
            setVerifyEmail={setVerifyEmail}
        >
            {verifiedMail && (
                <>
                    <h4>{user.translate("your_restaurants")}</h4>
                    <RestaurantList {...props} />
                </>
            )}
            {!verifiedMail && (
                <h4>{user.translate("verify_email")} <Link className={classes.resend_mail} color="primary" onClick={onResendMail}>{user.translate("here")}</Link></h4>
            )}
        </PrimarySearchAppBarDrawer>
    )
}