const configs = {
  local: {
    apiUrl: "http://192.168.100.112:9080",
    apiImagesUrl: "http://192.168.100.112:9080/image",
    fbAppId: "1436570349884735",
    scanQrUrl: "http://192.168.100.112:9080/qr",
    isTest: true,
  },
  dev: {
    apiUrl: "https://dev-api.eBar.mk",
    apiImagesUrl: "https://dev-images.eBar.mk",
    fbAppId: "1436570349884735",
    scanQrUrl: "https://dev-q.eBar.mk",
    isTest: true,
  },
  test: {
    apiUrl: "https://test-api.eBar.mk",
    apiImagesUrl: "https://test-images.eBar.mk",
    scanQrUrl: "https://test-q.eBar.mk",
    fbAppId: "1436570349884735",
    isTest: true,
  },
  production: {
    apiUrl: "https://api.e-bar.mk",
    apiImagesUrl: "https://images.e-bar.mk",
    scanQrUrl: "https://q.e-bar.mk",
    fbAppId: "1436570349884735",
    isTest: false,
  },
};

const config = configs[process.env.REACT_APP_STAGE];

export default config;
