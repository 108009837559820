import {gql, useMutation, useQuery} from "@apollo/client"
import {CircularProgress} from "@material-ui/core"
import moment from "moment-timezone"
import React, {createContext, useContext, useState} from "react"
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation, useParams} from "react-router-dom"
import "./App.css"
import Home from "./home/Home"
import icuGb from "./icuGb"
import icuMk from "./icuMk"
import Main from "./Main"
import QrGenerator from "./qr/QrGenerator"
import * as translation_en from "./restaurant/common/language/en"
import * as translation_mk from "./restaurant/common/language/mk"
import ChangeEmail from "./signin/ChangeEmail"
import ForgotPassword from "./signin/ForgotPassword"
import Register from "./signin/Register"
import ResetPassword from "./signin/ResetPassword"
import SignIn from "./signin/SignIn"
import {} from "./Roboto-Bold-normal"
import {} from "./Roboto.ttf-normal"

export const RestaurantContext = createContext({})
export const UserContext = createContext({
    lang: "mk",
})

window.columnParents = window.columnParents || new Object([])
window.columnNumbers = window.columnNumbers || new Object([])

export default function App() {
    const [language, setLanguage] = useState("mk")
    const [refetchIt, setRefetchIt] = useState(false)
    const [restLang, setRestLang] = useState("mk")

    const EDIT_USER = gql`
    mutation ($id: String!, $lang: String!) {
      editUser(input: { id: $id, lang: $lang }) {
        id
      }
    }
  `
    const [editUser, {loading: loading_edit}] = useMutation(EDIT_USER)

    const ME = gql`
    {
        me {
            _id
            id
            lang
            profile {
                _id
                userId
                name
                email
                picture
                fbAccessToken
                fbId
                birthday
                isAdmin
                phone
                isEmailVerified
            }
        }
        extras_userRestaurants{
            restaurantId
            role
            employeeId
            restaurant {
                _id
                id
                name { ${language} }
                imageLogo
                imageLogoBg
                address
                lang
                phone
                legalInfo
                restaurantBillings {
                    id
                    bankName
                    bankAccount
                }
            }
        }
    }`

    const {data, loading, refetch} = useQuery(ME, {
        fetchPolicy: "network_only",
        errorPolicy: "ignore",
    })

    if (loading) {
        return (
            <div className="App AppLoading">
                <CircularProgress/>
            </div>
        )
    }

    let me = data && data.me && data.me.profile ? data.me : false
    let role =
        data && data.me && data.extras_userRestaurants && data.extras_userRestaurants.length > 0
            ? data.extras_userRestaurants[0].role
            : false
    if (
        restLang &&
        data &&
        data.me &&
        data.extras_userRestaurants &&
        data.extras_userRestaurants.length > 0 &&
        data.extras_userRestaurants[0].restaurant.lang.toLowerCase() !== restLang
    ) {
        setRestLang(data.extras_userRestaurants[0].restaurant.lang.toLowerCase())
    }

    if (me && language !== me.lang.toLowerCase()) {
        setLanguage(me.lang.toLowerCase())
    }

    const onSetLanguage = (lang) => {
        setLanguage(lang)

        let items = {
            id: me.id,
            lang: lang.toLowerCase(),
        }

        editUser({variables: items})
        setRefetchIt(true)
    }

    const onLogin = () => {
        setRefetchIt(true)
    }

    if (!loading && !loading_edit && refetchIt) {
        setRefetchIt(false)
        refetch()
    }

    const translation = language === "mk" ? translation_mk.default : translation_en.default
    document.documentElement.setAttribute("lang", language) //set html tag attribute lang to language

    let icu = restLang === "mk" ? icuMk : icuGb

    let gqlCreateName = (nameVar, id = false, type = false) => {
        return (
            `create${type || "Name"}: {` +
            (id ? `id: ${id}, ` : "") +
            Array.from(new Set(["en", "mk", language]))
                .map((lang) => `${lang}: ${nameVar}`)
                .join(", ") +
            `}`
        )
    }
    let gqlCreateNameTr = (mkVar, enVar, type = false) => {
        return (
            `create${type || "Name"}: {` +
            Array.from(new Set(["en", "mk"]))
                .map((lang) => `${lang}: ${lang === "mk" ? mkVar : enVar}`)
                .join(", ") +
            `}`
        )
    }
    let gqlEditName = (nameVar, id, type = false) => {
        return (
            `edit${type || "Name"}: {id: ${id}, ` +
            Array.from(new Set([language]))
                .map((lang) => `${lang}: ${nameVar}`)
                .join(", ") +
            `}`
        )
    }
    let gqlEditNameTr = (mkVar, enVar, id, type = false) => {
        return (
            `edit${type || "Name"}: {id: ${id}, ` +
            Array.from(new Set(["en", "mk"]))
                .map((lang) => `${lang}: ${lang === "mk" ? mkVar : enVar}`)
                .join(", ") +
            `}`
        )
    }
    let gqlFetchName = () =>
        Array.from(new Set(["id", language, "en", "mk"]))
            .map((lang) => `${lang}`)
            .join(", ")

    let dateTimeFormatDisplay = "DD.MM.yyyy HH:mm"
    let dateFormatDisplay = "DD.MM.yyyy"
    let dateTimeFormat = "dd.MM.yyyy HH:mm"
    let dateFormat = "dd.MM.yyyy"

    let formatDate = (date, datetime = false, format = false) => {
        if (format) return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format)
        else if (datetime)
            return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(dateTimeFormatDisplay)
        else return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(dateFormatDisplay)
    }

    let formatNumber = (number, percent = false, currency = false, fractions = 2) => {
        let options = {
            style: currency ? "currency" : percent ? "percent" : "decimal",
            maximumFractionDigits: fractions,
        }
        if (currency) options.currency = currency
        if (number === undefined || number === null) return ""
        return percent
            ? new Intl.NumberFormat(restLang, options).format(number)
            : icu.getDecimalFormat(fractions).format(number)
    }
    let formatQuantity = (number, fractions = 3, zero) => {
        if (number === 0 && zero !== undefined) return zero
        if (number === null || number === undefined) return ""
        return icu.getDecimalFormat(fractions).format(number, true)
    }
    let formatQuantitySign = (number, fractions = 3, zero) => {
        if (number == 0) return zero != undefined ? zero : "0"
        let sign = number > 0 ? "+" : "-"
        return sign + icu.getDecimalFormat(fractions).format(Math.abs(number), true)
    }
    let formatQuantityReport = (number, fractions = 3) => {
        return icu.getDecimalFormat(fractions).format(number, true, true)
    }

    let parseStringNumber = (string) => {
        let parsed = restLang === "mk" ? string.replaceAll(".", "").replace(",", ".") : string.replaceAll(",", "")

        return parseFloat(parsed)
    }

    let consoleLog = (param, row = false) => {
        // console.trace(2);
        // console.log((new Error().stack).split('\n')[1])
        console.log(new Error().stack.split("\n")[2])
        if ((me && me.profile.isAdmin) || !me) {
            if (row) console.log(param, "row: " + row)
            else console.log(param)
        }
    }

    // console.log(formatQuantityReport(11234.34));

    const translate = (key, ...params) => {
        const value = translation[key] || key
        return params.reduce((result, param) => result.replace("%s", param), value)
    }

    return (
        <UserContext.Provider
            value={{
                me: me ? me : undefined,
                role: role,
                lang: language,
                restLang: restLang,
                consoleLog: consoleLog,
                setRestLang: setRestLang,
                gqlFetchName: gqlFetchName,
                gqlCreateName: gqlCreateName,
                gqlCreateNameTr: gqlCreateNameTr,
                gqlEditName: gqlEditName,
                gqlEditNameTr: gqlEditNameTr,
                translate: translate,
                formatDate: formatDate,
                dateFormat: dateFormat,
                dateTimeFormat: dateTimeFormat,
                formatNumber: formatNumber,
                formatQuantity: formatQuantity,
                formatQuantitySign: formatQuantitySign,
                formatQuantityReport: formatQuantityReport,
                parseStringNumber: parseStringNumber,
                icu: icu,
            }}
        >
            <div className="App">
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to={{pathname: me ? "/home" : "/login"}}/>
                        </Route>
                        <Route exact path="/home">
                            {me ? (
                                <Home restaurants={data.extras_userRestaurants} onSetLanguage={onSetLanguage} refetch={onLogin}/>
                            ) : (
                                <Redirect to={{pathname: "/login"}}/>
                            )}
                        </Route>
                        <Route path="/restaurant/:restaurantId/:drawerMenu">
                            {me && role > 1 ? (
                                <Restaurant
                                    restaurants={me && data.extras_userRestaurants}
                                    restLang={restLang}
                                    setRestLang={setRestLang}
                                    onSetLanguage={onSetLanguage}
                                    refetch={onLogin}
                                />
                            ) : (
                                <Redirect to={{pathname: "/login"}}/>
                            )}
                        </Route>
                        <Route path="/restaurant/:restaurantId">
                            {me && role > 1 ? (
                                <Restaurant
                                    restaurants={me && data.extras_userRestaurants}
                                    restLang={restLang}
                                    setRestLang={setRestLang}
                                    onSetLanguage={onSetLanguage}
                                    refetch={onLogin}
                                />
                            ) : (
                                <Redirect to={{pathname: "/login"}}/>
                            )}
                        </Route>
                        <Route path="/login">{me ? <Redirect to={{pathname: "/home"}}/> : <SignIn onLogin={onLogin}/>}</Route>
                        <Route path="/register">
                            {me ? <Redirect to={{pathname: "/home"}}/> : <Register onLogin={onLogin}/>}
                        </Route>
                        <Route path="/forgot-password">
                            {me ? <Redirect to={{pathname: "/home"}}/> : <ForgotPassword onLogin={onLogin}/>}
                        </Route>
                        <Route path="/reset-pass/:code">
                            <ResetPassword refetch={onLogin}/>
                        </Route>
                        <Route path="/change-email/:code">
                            <ChangeEmail refetch={onLogin}/>
                        </Route>
                        <Route path="/qr-generator">{me?.profile?.isAdmin ? <QrGenerator/> : <div>Errorr</div>}</Route>
                        <Route>
                            <p>Page Not Found</p>
                        </Route>
                    </Switch>
                </Router>
            </div>
        </UserContext.Provider>
    )
}

const Restaurant = (props) => {
    const user = useContext(UserContext)

    var {restaurantId} = useParams()

    let location = useLocation()

    if (
        !props.restLang &&
        location.pathname.indexOf("invoice_output/") === -1 &&
        location.pathname.indexOf("invoice_input/") === -1
    ) {
        props.setRestLang("mk")
    }

    if (!props.restaurants) {
        return <Redirect to={{pathname: "/login", state: {from: location}}}/>
    }

    const item = props.restaurants.find((it) => it.restaurant._id === restaurantId)

    let currencyOptions = [
        {val: "MKD", label: user.translate("mkd")},
        {val: "EUR", label: user.translate("eur")},
        {val: "USD", label: user.translate("usd")},
    ]

    if (item) {
        const value = {...item.restaurant, currencies: currencyOptions}
        return (
            <RestaurantContext.Provider value={value}>
                <Main onSetLanguage={props.onSetLanguage} refetch={props.refetch}/>
            </RestaurantContext.Provider>
        )
    }

    return <Redirect to={{pathname: "/restaurant"}}/>
}
