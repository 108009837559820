import { gql, useMutation, useQuery } from '@apollo/client';
import {
    CircularProgress,
    IconButton,
    makeStyles,
    Tooltip
} from '@material-ui/core';
import { amber, blue, green, red } from '@material-ui/core/colors';
import DescriptionIcon from '@material-ui/icons/Description';
import Print from '@material-ui/icons/Print';
import React, { useContext, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { RestaurantContext, UserContext } from "../../App";
import useDateRangeFilter from '../common/DateRangeHeader';
import MobileDialog from '../common/MobileDialog';
import SnackBarWarning from '../common/SnackBarWarning';
import Table from '../common/Table';
import tableComponents from '../common/TableComponents';
import useWindowSize from '../common/useWindowSize';
import StockSupplyAction from './StockSupplyAction';
import SupplingListItem from './SupplingListItem';
import { useSupply } from './useSupply';

const useStyles = makeStyles((theme) => ({
    roots: {
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: theme.spacing(1)
        }
    },
    root: {

    },
    checkedLabel: {
        marginRight: 0,
        "& .MuiFormControlLabel-label": {
            fontSize: 12
        }
    },
}));

const GQL_GET_KOMITENTS_AND_EMPLOYEES = function (user) {
    return gql`
        query ($restaurantId: String!) {
            getCommissionersByRestaurantId(restaurantId: $restaurantId) {
                commissioner {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
            }
            getRestaurantEmployeesByContextRestaurantId(restaurantId: $restaurantId) {
                employee {
                    id
                    profile {
                        _id
                        name
                    }
                }
                role
                lang
            }
        }
    `;
};

const GQL_SAVE_ITEM = gql`
    mutation($id: String!, $restaurantId: String!, $supplierId: String!, $employeeId: String!, $supplyingNumber: String!, $date: String!, $comment: String!, $state: String, $updated: Long!, $receivingId: Long!) {
        createStockSupply(
            input: {
                id: $id
                restaurantId: $restaurantId
                supplierId: $supplierId
                employeeId: $employeeId
                supplyingNumber: $supplyingNumber
                date: $date
                comment: $comment
                state: $state
                updated: $updated
                receivingId: $receivingId
            }
        ) { id }
    }
`;

const DELETE_ITEM = gql`
    mutation ($id: String!) {
        deleteStockType(id: $id)
    }
`;


// const stateColors = {
//     OPEN: '#FFF',
//     CLOSED: '#fbfdf4',
//     SENT: '#f4fdf8',
//     RECEIVED: '#f2fdfd',
//     CANCELED: '#f4f4f4',

// }

const intensity = 50;
const stateColors = {
    OPEN: amber[intensity],
    CLOSED: blue[intensity],
    SENT: red[intensity],
    RECEIVED: green[intensity],
    CANCELED: red[100],

}


// React Function Component
export default function SupplingList() {

    const classes = useStyles();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);
    const size = useWindowSize();

    const history = useHistory();
    const location = useLocation();

    const { stockSupplyId: selectedStockSupplyId } = useParams()

    const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false);


    const dateRangeFilter = useDateRangeFilter('this_month');

    const [saveItem, { loading: loadingSave }] = useMutation(GQL_SAVE_ITEM);
    const [deleteItem, { loading: loadingDelete }] = useMutation(DELETE_ITEM);


    const GET_SUPPLINGS_BY_RESTAURANT = gql`
        query($restaurantId: String!) {
            getStockSupplysByContextRestaurantId(restaurantId: $restaurantId, filter: {
                by: "created"
                gte: ${dateRangeFilter.startDate.getTime() || 0}
                lte: ${dateRangeFilter.endDate.getTime() || (new Date()).getTime()}
            }) {
                id
                supplierId
                employeeId
                supplyingNumber
                state
                date
                emailSent
                receivingId
                comment
                created
                updated
            }
        }
    `;

    const { data: dataSupplies, loading: loadingSupplies, error: errorSupplies, refetch: refetchSupplies } = useQuery(GET_SUPPLINGS_BY_RESTAURANT, {
        variables: { restaurantId: restaurant.id },
        pollInterval: 5000,
        errorPolicy: "ignore",
    });

    const { data: dataKomitents, loading: loadingKomitents, error: errorKomitents, refetch: refetchKomitents } = useQuery(GQL_GET_KOMITENTS_AND_EMPLOYEES(user), {
        variables: { restaurantId: restaurant.id },
        pollInterval: 30000,
        errorPolicy: "ignore",
        skip: loadingSupplies
    });

    let stockSupplyOrders = dataSupplies?.getStockSupplysByContextRestaurantId || [];

    const manageSupply = useSupply({
        orders: stockSupplyOrders,
        refetch: refetchSupplies
    });

    if (!dataSupplies) return <div className="App AppLoading"><CircularProgress /></div>;

    const komitentsOptions = dataKomitents?.getCommissionersByRestaurantId?.map(item => ({
        id: item.commissioner.id,
        name: item.commissioner.name[user.lang]
    })) || [];
    const komitents = komitentsOptions.toMapBy(item => item.id);

    const employeeOptions = dataKomitents?.getRestaurantEmployeesByContextRestaurantId?.map(item => ({
        id: item.employee.id,
        name: item.employee.profile.name
    })) || [];
    const employees = employeeOptions.toMapBy(item => item.id);


    let fieldList = {
        options: {
            detailPanelColumnAlignment: 'right',
            detailPanelType: 'single'
        },
        columns: [
            { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'Id', field: 'id', hidden: true, },
            {
                title: user.translate("supplier"), field: 'supplier',
                cellStyle: { width: "30%", padding: 0, fontSize: 14, whiteSpace: 'pre-line' },
                editComponent: (props) => tableComponents.AutoComplete(props, komitentsOptions, "supplierId", "supplier")
            },
            {
                title: user.translate("date"), field: 'date',
                render: (rowData) => new Date(rowData.date).toDateString(),
                editComponent: (props) => tableComponents.DateEditComponent(props, "date", new Date())
            },
            {
                title: user.translate("supplyingNumber"), field: 'supplyingNumber', editable: 'never',
                render: (rowData) => rowData.supplyingNumber,
            },
            {
                title: user.translate("employee"), field: 'employee', mobile: false, editable: 'never'
            },
            {
                title: user.translate("status"), field: 'state', mobile: false,
                editable: 'never', export: false, disableClick: true,
                render: (rowData) => <StockSupplyAction item={rowData} manageSupply={manageSupply} noText />,
            },
            {
                title: user.translate("stockRecv"), field: 'receivingId', mobile: false,
                editable: 'never', export: false, disableClick: true,
                render: (rowData) => rowData.receivingId ? (
                    <Tooltip title={`${user.translate("open_receiving")}`} placement="top">
                        <IconButton
                            color="primary"
                            to={`/restaurant/${restaurant._id}/stock_receiving/${rowData.receivingId}/`}
                            component={Link}
                        ><DescriptionIcon /></IconButton>
                    </Tooltip>
                ) : null,
            },
        ],
        data: stockSupplyOrders.orderByDesc(a => a.created).map((item, index) => ({
            ...item,
            rowBackgroundColor: stateColors[item.state],
            supplier: komitents[item.supplierId]?.name || '...',
            employee: employees[item.employeeId]?.name || '...'
        }))
    };

    // const onDeleteItem = (oldData, state) => {
    //     let itemData = { id: oldData.id };

    //     if (oldData.stockTypeProductsCount === 0) {
    //         return deleteItem({ variables: itemData }).then(() => {
    //             refetchSupplies();
    //         }).catch(e => {
    //             setOpenSnackBarWarning({
    //                 alertMessage: e.networkError.result.errors.map(x => x.message).join(),
    //                 autoHideDuration: 3000,
    //                 linkTo: ``,
    //                 linkText: ""
    //             });
    //             throw e;
    //         });
    //     } else {
    //         setOpenSnackBarWarning({
    //             alertMessage: `${user.translate("cant_delete_stock")} `,
    //             autoHideDuration: 3000,
    //             linkTo: ``,
    //             linkText: ""
    //         });
    //     }
    // };

    const selectedStockSupply = fieldList.data.find(item => item.id == selectedStockSupplyId);
    
    const closeItem = () => {
        if (location.state?.from == 'supplingList') {
            history.goBack();
        } else {
            history.push("../supplies");
        }
    }

    return (
        <div className={classes.roots}>

            <Table
                tableName={user.translate("supplies")}
                exportFileName={user.translate("stockitems")}
                fieldList={fieldList}
                pageSize="50"
                pageSizeOptions={[50, 100, 200]}
                onAddItem={manageSupply.saveSupply}
                className={classes.root}
                onEditItem={manageSupply.saveSupply}
                onDeleteItem={manageSupply.deleteSupply}
                onRowClick={(rowData) => history.push(`supplies/${rowData.id}`, { ...location.state, from: 'supplingList' })}
                search={!size.isMobile}
                header={dateRangeFilter.renderHeader}
            />

            {openSnackBarWarning && (
                <SnackBarWarning
                    message={openSnackBarWarning.alertMessage}
                    linkTo={openSnackBarWarning.linkTo}
                    linkText={openSnackBarWarning.linkText}
                    autoHideDuration={openSnackBarWarning.autoHideDuration || 3000}
                    openSnackBar={true}
                    setOpenSnackBar={setOpenSnackBarWarning}
                />
            )}

            <MobileDialog open={Boolean(selectedStockSupply)} onClose={closeItem} title={selectedStockSupply?.supplier || ''} actionIcon={<Print />}>
                {selectedStockSupply && <SupplingListItem item={selectedStockSupply} refetch={refetchSupplies} />}
            </MobileDialog>

        </div>
    )

}


