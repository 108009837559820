import { gql, useMutation, useQuery } from "@apollo/client"
import { CircularProgress, makeStyles } from "@material-ui/core"
import React, { useContext, useMemo, useState } from "react"
import { RestaurantContext, UserContext } from "../../App"
import helper from "../common/Helper"
import SnackBarWarning from "../common/SnackBarWarning"
import Table from "../common/Table"
import tableComponents from "../common/TableComponents"

const GQL_GET_SUPLY_OPTIONS = gql`
  query ($stockTypeId: String!) {
    getStockTypeSupplyOptionsByStockTypeId(stockTypeId: $stockTypeId) {
      id
      period
      supplierId
      quantity
      minStock
      uomOpts
      priority
      stockTypeSupplyLinkTargetOptions {
        type
        triggerOption {
          stockTypeId
        }
      }
    }
  }
`

const GQL_GET_KOMITENTS = function (user) {
  return gql`
        query ($restaurantId: String!) {
            getCommissionersByRestaurantId(restaurantId: $restaurantId) {
                commissioner {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
            }
        }
    `
}

const GQL_SAVE_ITEM = gql`
  mutation (
    $id: String!
    $stockTypeId: String!
    $supplierId: String!
    $uomOpts: Long!
    $minStock: Float!
    $quantity: Float!
    $priority: Long!
    $period: Long!
  ) {
    createStockTypeSupplyOption(
      input: {
        id: $id
        stockTypeId: $stockTypeId
        minStock: $minStock
        uomOpts: $uomOpts
        quantity: $quantity
        priority: $priority
        supplierId: $supplierId
        period: $period
      }
    ) {
      id
    }
  }
`

const DELETE_ITEM = gql`
  mutation ($id: String!) {
    deleteStockTypeSupplyOption(id: $id)
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#EEF",
    paddingBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
}))

export default function StockTypeDetail(props) {
  const classes = useStyles()

  const restaurant = useContext(RestaurantContext)
  const user = useContext(UserContext)

  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)

  const [saveItem, { loading: loadingSave }] = useMutation(GQL_SAVE_ITEM)
  const [deleteItem, { loading: loadingDelete }] = useMutation(DELETE_ITEM)

  const {
    data: dataSupplyOptions,
    loading: loadingSupplyOptions,
    error: errorSupplyOptions,
    refetch: refetchSupplyOptions,
  } = useQuery(GQL_GET_SUPLY_OPTIONS, {
    variables: { stockTypeId: props.item.id },
    pollInterval: 3000,
    errorPolicy: "ignore",
  })

  const {
    data: dataKomitents,
    loading: loadingKomitents,
    error: errorKomitents,
    refetch: refetchKomitents,
  } = useQuery(GQL_GET_KOMITENTS(user), {
    variables: { restaurantId: restaurant.id },
    pollInterval: 30000,
    errorPolicy: "ignore",
    skip: loadingSupplyOptions,
  })

  const uomUoptions = useMemo(() => {
    let stockType = props.item

    const uomUnit = ["L", "Kg", "p"]

    const uomTitles = [user.translate("liter"), user.translate("kilogram"), user.translate("piece")]

    if (stockType == undefined) return []
    let uom = stockType.uom

    const pieceQuantity = `${stockType.piece} ${uomUnit[uom]}`
    const uomOptionPiece = [
      { label: `${user.translate("dose")} (${pieceQuantity})`, val: 1 },
      { label: `${user.translate("dose")} (${pieceQuantity})`, val: 1 },
      { label: `${user.translate("dose")} (${pieceQuantity})`, val: 1 },
    ]

    const packageQuantity = `${stockType.package}${uomUnit[uom]}`
    const uomOptionPackage = [
      { label: `${user.translate("bottle")} ${packageQuantity}`, val: 2 },
      { label: `${user.translate("bag")} ${packageQuantity}`, val: 2 },
      { label: `${user.translate("box")} ${packageQuantity}`, val: 2 },
    ]

    const basicUomUoption = { label: uomTitles[uom].capitalize(), val: 0 }

    return [basicUomUoption, uomOptionPiece[uom], uomOptionPackage[uom]]
  }, [props.item])

  if (!dataSupplyOptions)
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )

  const komitentsOptions =
    dataKomitents?.getCommissionersByRestaurantId?.map((item) => ({
      id: item.commissioner.id,
      name: item.commissioner.name[user.lang],
    })) || []
  const komitents = komitentsOptions.toMapBy((item) => item.id)

  const onSaveItem = (itemData) => {
    console.log(itemData)
    return saveItem({
      variables: {
        id: itemData.id || helper.uid(),
        stockTypeId: props.item.id,
        minStock: itemData.minStock,
        quantity: itemData.quantity,
        uomOpts: itemData.uomOpts != undefined ? itemData.uomOpts : itemData._uomOpts || 0,
        priority: itemData.priority,
        period: itemData.period,
        supplierId: itemData.supplierId,
      },
    })
      .then((e) => {
        refetchSupplyOptions()
      })
      .catch((e) => {
        setOpenSnackBarWarning({
          alertMessage: e.networkError.result.errors.map((x) => x.message).join(),
          autoHideDuration: 3000,
          linkTo: ``,
          linkText: "",
        })
        throw e
      })
  }

  const onDeleteItem = (itemData) => {
    return deleteItem({ variables: itemData }).then(() => {
      refetchSupplyOptions()
    })
  }

  let fieldList = {
    columns: [
      { width: "1%", cellStyle: { whiteSpace: "nowrap" }, title: "Id", field: "id", hidden: true },
      {
        title: user.translate("supplier"),
        field: "supplier",
        cellStyle: { width: "30%", padding: 0, fontSize: 14, whiteSpace: "pre-line" },
        editComponent: (props) => tableComponents.AutoComplete(props, komitentsOptions, "supplierId", "supplier"),
      },
      {
        title: user.translate("minStock"),
        field: "minStock",
        render: (rowData) => user.formatQuantity(rowData.minStock),
        editComponent: (props) => tableComponents.NumericEditComponent(props, "minStock", false, 3),
      },
      {
        title: user.translate("uomOpts"),
        field: "uomOptsLabel",
        editComponent: (props) => tableComponents.Select(props, uomUoptions, "uomOpts", () => 0),
        render: (rowData) => uomUoptions[rowData.uomOpts || 0].label,
      },
      {
        title: user.translate("quantity"),
        field: "quantity",
        render: (rowData) => user.formatQuantity(rowData.quantity),
        editComponent: (props) => tableComponents.NumericEditComponent(props, "quantity", false, 3),
      },
      {
        title: user.translate("period"),
        field: "period",
        render: (rowData) => user.formatQuantity(rowData.period),
        editComponent: (props) => tableComponents.NumericEditComponent(props, "period", false, 0),
      },
      {
        title: user.translate("priority"),
        field: "priority",
        render: (rowData) => user.formatQuantity(rowData.priority),
        editComponent: (props) => tableComponents.NumericEditComponent(props, "priority", false, 0),
      },
    ],
    data: dataSupplyOptions.getStockTypeSupplyOptionsByStockTypeId.map((item) => ({
      ...item,
      supplier: komitents[item.supplierId]?.name || "...",
    })),
  }

  return (
    <div className={classes.root}>
      <Table
        tableName={user.translate("supplies")}
        exportFileName={user.translate("stockitems")}
        fieldList={fieldList}
        pageSize="50"
        paginationPosition={false}
        onAddItem={onSaveItem}
        onEditItem={onSaveItem}
        onDeleteItem={onDeleteItem}
        backgroundColor="#f3feff"
        headerBackgroundColor="#d1e8ea"
        itemBackgroundColor="#f3f8f9"
        search={false}
      />

      {openSnackBarWarning && (
        <SnackBarWarning
          message={openSnackBarWarning.alertMessage}
          linkTo={openSnackBarWarning.linkTo}
          linkText={openSnackBarWarning.linkText}
          autoHideDuration={openSnackBarWarning.autoHideDuration || 3000}
          openSnackBar={true}
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}
    </div>
  )
}
