import { gql, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from "../../App";
import helper from '../common/Helper';
import ProgressDialog from '../common/ProgressDialog';

const GQL_SAVE_RECEIVING_STOCK = gql`
    mutation ($id: String!, $companyId: String!, $senderId: String!, $employeeId: String!, $sendingNumber: String!, $invoiceNumber: String!, $created: Long!, $updated: Long!) {
        createReceivingStock(input: {
            id: $id
            companyId: $companyId
            senderId: $senderId
            employeeId: $employeeId
            sendingNumber: $sendingNumber
            invoiceNumber: $invoiceNumber
            created: $created
            updated: $updated
        }) {
            id
        }
}`;

const GQL_DELETE_RECEIVING = gql`
    mutation ($id: String!) {
        deleteReceivingStock(id: $id)
    }
`;

const GQL_SAVE_RECEIVING_INVOICE_ITEM = (user) => gql`
    mutation (
        $id: String!, 
        $invoiceItemId: String!, 
        $invoiceId: String!, 
        $invoiceAmount: Float!, 
        $receivingStockId: String!, 
        $warehouseId: String!, 
        $stockTypeId: String!, 
        $article: String!, 
        $description: String!, 
        $quantity: Float!, 
        $uomOpts: Long!,
        $invoiceQuantity: Float!,
        $price: Float!, 
        $tax: Float!, 
        $discount: Float!, 
        $created: Long!, 
        $updated: Long!
        ) {
        createReceivingStockItem(input: {
            id: $id
            receivingStockId: $receivingStockId
            warehouseId: $warehouseId
            stockType: $stockTypeId
            quantity: $quantity
            uomOpts: $uomOpts
            price: $price
            tax: $tax
            discount: $discount
            created: $created
            updated: $updated
        }) {
            id
        }
        createInvoiceItem(input: {
            id: $invoiceItemId
            ${user.gqlCreateName("$article", false, "Article")}
            ${user.gqlCreateName("$description", false, "Description")}
            quantity: $invoiceQuantity
            price: $price
            tax: $tax
            discount: $discount
            invoiceId: $invoiceId
        }){
            id
        }
        createInvoiceItemReceivingItem(input: {
            invoiceItemId: $invoiceItemId,
            receivingItemId: $id
        }) {
            invoiceItemId
        }
        editInvoice(input: {
            id: $invoiceId
            amount: $invoiceAmount
        }) {
            id
        }
    }
`;

const GQL_SAVE_RECEIVING_ITEM = gql`
    mutation ($id: String!, $receivingStockId: String!, $warehouseId: String!, $stockTypeId: String!, $quantity: Float!, $uomOpts: Long!, $price: Float!, $tax: Float!, $discount: Float!, $created: Long!, $updated: Long!) {
        createReceivingStockItem(input: {
            id: $id
            receivingStockId: $receivingStockId
            warehouseId: $warehouseId
            stockType: $stockTypeId
            quantity: $quantity
            uomOpts: $uomOpts
            price: $price
            tax: $tax
            discount: $discount
            created: $created
            updated: $updated
        }) {
            id
        }
}`;


const GQL_DELETE_RECEIVING_ITEM = gql`
    mutation ($id: String!) {
        deleteReceivingStockItem(id: $id)
    }
`;

const GQL_DELETE_RECEIVING_INVOICE_ITEM = gql`
    mutation ($id: String!, $invoiceItemId: String!) {
        deleteReceivingStockItem(id: $id)
        deleteInvoiceItemReceivingItem(receivingItemId: $id)
        deleteInvoiceItem(id: $invoiceItemId)
    }
`

export default function useStockReceiving(props) {
    const user = useContext(UserContext);

    const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false);

    const [saveStockReceiving, { loading: loadingSave }] = useMutation(GQL_SAVE_RECEIVING_STOCK);
    const [deleteStockReceiving, { loading: loadingDelete }] = useMutation(GQL_DELETE_RECEIVING);

    const [saveStockReceivingItem, { loading: loadingSaveItem }] = useMutation(GQL_SAVE_RECEIVING_ITEM);
    const [saveStockReceivingInvoiceItem, { loading: loadingSaveInvoiceItem }] = useMutation(GQL_SAVE_RECEIVING_INVOICE_ITEM(user));
    const [deleteStockReceivingItem, { loading: loadingDeleteItem }] = useMutation(GQL_DELETE_RECEIVING_ITEM);
    const [deleteStockReceivingInvoiceItem, { loading: loadingDeleteInvoiceItem }] = useMutation(GQL_DELETE_RECEIVING_INVOICE_ITEM);


    const refetch = props?.refetch || (() => new Promise(resolve => console.log("props.refetch not implemented in `useStockReceiving`") || resolve()));

    const onSaveStockReceiving = (data, autoRefetch = true) => saveStockReceiving({ variables: { ...data, id: data.id || helper.uid() } }).then(({ data }) => {
        if (autoRefetch) {
            return refetch().then(() => data);
        }
        return data;
    }).catch(e => {
        setOpenSnackBarWarning({
            alertMessage: e.networkError.result.errors.map(x => x.message).join(),
            autoHideDuration: 3000,
            linkTo: ``,
            linkText: ""
        });
    });

    const onSaveStockReceivingItem = (itemData) => saveStockReceivingItem({
        variables: {
            ...itemData,
            id: itemData.id || helper.uid(),
            updated: new Date().getTime()
        }
    });

    const onSaveStockReceivingInvoiceItem = (itemData) => saveStockReceivingInvoiceItem({
        variables: {
            ...itemData,
            id: itemData.id || helper.uid(),
            updated: new Date().getTime()
        }
    });

    const onDeleteStockReceiving = (itemId, autoRefetch = true) => deleteStockReceiving({ variables: { id: itemId } }).then(({ data }) => {
        if (autoRefetch) {
            refetch().then(() => data);
        }
        return data;
    }).catch(e => {
        setOpenSnackBarWarning({
            alertMessage: e.networkError.result.errors.map(x => x.message).join(),
            autoHideDuration: 3000,
            linkTo: ``,
            linkText: ""
        });
        throw e;
    });

    const onDeleteStockReceivingItem = (itemId, autoRefetch = true) => deleteStockReceivingItem({ variables: { id: itemId } }).then(({ data }) => {
        if (autoRefetch) {
            refetch().then(() => data);
        }
        return data;
    });

    const onDeleteStockReceivingInvoiceItem = (itemId, autoRefetch = true) => deleteStockReceivingInvoiceItem({ variables: { id: itemId } }).then(({ data }) => {
        if (autoRefetch) {
            refetch().then(() => data);
        }
        return data;
    });

    return {
        isLoading: loadingSave || loadingSaveItem || loadingSaveInvoiceItem || loadingDelete || loadingDeleteItem || loadingDeleteInvoiceItem,
        warning: openSnackBarWarning,
        saveStockReceiving: onSaveStockReceiving,
        saveStockReceivingItem: onSaveStockReceivingItem,
        saveStockReceivingInvoiceItem: onSaveStockReceivingInvoiceItem.apply,
        deleteStockReceiving: onDeleteStockReceiving,
        deleteStockReceivingItem: onDeleteStockReceivingItem,
        deleteStockReceivingInvoiceItem: onDeleteStockReceivingInvoiceItem
    };
}

export function StockReceivingComponent(props) {
    const stockReceiving = useStockReceiving(props);

    useEffect(() => {
        stockReceiving[props.action](props.params).then((data) => {
            props.onComplete(data);
        }).catch((err) => {
            props.onError(err);
        });
    }, [props.action, JSON.stringify(props.params)]);

    return null;
}

export function useStockReceivingDialog(props) {
    var [actionIndex, setActionIndex] = useState();
    const actionRef = useRef();

    const update = (value) => {
        let refresh = (actionRef.current?.refresh || 0) + 1;
        actionRef.current = value && { ...value, refresh }
        setActionIndex(refresh);
    }

    const updateAction = (value) => {
        update({ action: value });
    }

    const doAction = (actionName) => (data) => new Promise((resolve, reject) => {
        let action = actionRef.current?.action;
        update({
            resolve, reject, action: {
                name: actionName,
                params: data,
                progress: action?.progress != undefined ? action.progress : -1,
                keepProgress: action?.keepProgress,
            }
        });
    });

    const onComplete = (result) => {
        let action = actionRef.current?.action;
        if (actionRef.current?.resolve) {
            actionRef.current.resolve(result);
            actionRef.current = undefined;
        }
        updateAction({ progress: action?.keepProgress ? action?.progress : undefined, keepProgress: action?.keepProgress });
    }

    const onError = (err) => {
        let action = actionRef.current?.action;
        if (actionRef.current?.reject) {
            actionRef.current.reject(err);
            actionRef.current = undefined;
        }
        updateAction({ progress: action?.keepProgress ? action?.progress : undefined, keepProgress: action?.keepProgress });
    }

    const onShow = (progress) => {
        let action = actionRef.current?.action;
        updateAction({ ...action, progress: progress, keepProgress: true });
    }

    return {
        saveStockReceiving: doAction("saveStockReceiving"),
        saveStockReceivingItem: doAction("saveStockReceivingItem"),
        saveStockReceivingInvoiceItem: doAction("saveStockReceivingInvoiceItem"),
        deleteStockReceiving: doAction("deleteStockReceiving"),
        deleteStockReceivingItem: doAction("deleteStockReceivingItem"),
        deleteStockReceivingInvoiceItem: doAction("deleteStockReceivingInvoiceItem"),
        render: () => {
            let action = actionRef.current?.action;
            if (action == undefined) return null;
            return <>
                {action?.name != undefined && <StockReceivingComponent action={action.name} params={action.params} {...props} onComplete={onComplete} onError={onError} />}
                {action?.progress != undefined && <ProgressDialog show={true} progress={action.progress} />}
            </>
        },
        show: onShow,
        done: () => {
            onShow(100);
            setTimeout(() => { updateAction() }, 100);
        }
    };
}