import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { UserContext } from "../../App";
import { useEntityRelationQuery } from '../common/useEntityQuery';
import PaymentDistribution, { invoiceGqlSelection } from './PaymentDistribution';
var hash = require('object-hash');

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f5f5f5',
        paddingBottom: theme.spacing(4)
    }
}));


export function PaymentDetails(props) {

    const item = props.item;

    const classes = useStyles();

    const user = useContext(UserContext);

    const FIELDS = [
        { name: "paymentId", gql: "String!", hidden: true, value: item?.id },
        { name: "invoiceId", gql: "String!", hidden: true },
        { name: "amount", gql: "Float!" },
        { name: "currency", gql: "String!", hidden: true, value: item.currency },
        { name: "date", gql: "String!", edit: "DateMilis", hidden: true, value: item.date },
        { name: "created", gql: "Long!", default: new Date().getTime(), hidden: true },
        { name: "updated", gql: "Long!", default: new Date().getTime(), hidden: true },
        { name: "seen", gql: "Long", hidden: true },
        { name: "invoice", gql: "Invoice", hidden: true, readOnly: true, subSelection: invoiceGqlSelection(user) },
    ];

    const invoicePaymentsQuery = useEntityRelationQuery("PaymentInvoice", FIELDS, "paymentId", props.item.id, {
        keys: ["paymentId", "invoiceId"]
    });

    const invoicePayments = invoicePaymentsQuery.items || [];

    const details = [
        { name: 'purpose', value: item.purpose },
        { name: 'date', value: user.formatDate(parseInt(item.date)) },
    ];

    return <PaymentDistribution
        isExpense={item.direction == "DEBIT"}
        item={item}
        className={classes.root}
        invoicePaymentsQuery={invoicePaymentsQuery}
        invoicePayments={invoicePayments}

    />
}