import { gql, useMutation, useQuery } from "@apollo/client";
import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { RestaurantContext, UserContext } from "../../App";
import helper from "../common/Helper";
import { InvoicePayments } from "./InvoicePayments";
import InvoiceBilling from "./item/InvoiceBilling";
import InvoiceBillTo from "./item/InvoiceBillTo";
import InvoiceComment from "./item/InvoiceComment";
import InvoiceFooter from "./item/InvoiceFooter";
import InvoiceItemTable from "./item/InvoiceItemTable";
import InvoiceStatus from "./item/InvoiceStatus";
import InvoiceTitle from "./item/InvoiceTitle";

const useStyles = makeStyles((theme) => ({
  rootBtns: {
    display: "block",
    textAlign: `left`,
    padding: theme.spacing(3),
    margin: `0 auto`,
    backgroundColor: "white",
    fontSize: 14,
  },
  root: {
    display: "inline-block",
    textAlign: `left`,
    padding: 0,
    maxWidth: 1024,
    minWidth: 768,
    margin: `${theme.spacing(4)}px auto ${theme.spacing(8)}px`,
    backgroundColor: "white",
    fontSize: 12,
    paddingBottom: theme.spacing(3),
    "& .MuiFormLabel-root": {
      fontSize: 12,
    },
  },
  payments: {
    paddingBottom: theme.spacing(3),
  },
  rootPadding: {
    padding: theme.spacing(1),
  },
  itemName: {
    margin: theme.spacing(1),
    flexGrow: 1,
    fontSize: 14,
  },
  logo: {
    float: "right",
    right: theme.spacing(4),
    position: "relative",
    top: -12,
  },
  total: {
    width: "30%",
    display: "inline-flex",
    position: "relative",
    justifyContent: "flex-end",
    top: 16,
    fontSize: 15,
    fontWeight: "700",
  },
  totalZero: {
    fontSize: 12,
    fontWeight: "normal",
  },
}));

export default function InvoiceItem() {
  const classes = useStyles();

  const typeOutput = true;

  // Get current user preferences (language)
  const user = useContext(UserContext);
  const restaurant = useContext(RestaurantContext);

  const { restaurantId, invoiceId } = useParams();

  let invoiceReceipts = [],
    invoice = {};
  let field = {
    fieldType: `$billTo: String!`,
    field: `billTo`,
  };
  let devizna = false;

  const [editIt, setEditIt] = useState(false);
  const [items, setItems] = useState(false);
  const [refetchIt, setRefetchIt] = useState(false);
  const [total, setTotal] = useState(false);

  const ADD_INVOICE_ITEM = gql`
        mutation ($id: String!, $invoiceId: String!, $article: String!, $description: String!, $quantity: Float!, $price: Float!, $tax: Float!, $discount: Float! ) {
            createInvoiceItem(input: {
                id: $id
                ${user.gqlCreateName("$article", false, "Article")}
                ${user.gqlCreateName("$description", false, "Description")}
                quantity: $quantity
                price: $price
                tax: $tax
                discount: $discount
                invoiceId: $invoiceId
            }){
                id
            }
        }`;
  const [addInvoiceItem, { loading: loadingInvoiceItem }] =
    useMutation(ADD_INVOICE_ITEM); //, {update: updateCache}

  const ADD_INVOICE_ITEM_RECEIPT = gql`
    mutation ($invoiceId: String!, $receiptId: String!) {
      extras_addReceiptToInvoice(invoiceId: $invoiceId, receiptId: $receiptId)
    }
  `;
  const [addInvoiceItemReceipt, { loading: loadingInvoiceItemReceipt }] =
    useMutation(ADD_INVOICE_ITEM_RECEIPT); //, {update: updateCache}

  const [editInvoiceQuery, setEditInvoiceQuery] = useState(`
        mutation ( $id: String!, ${field.fieldType} ) { 
            editInvoice(input: { 
                id: $id, 
                ${field.field}: $${field.field}
            })
            { 
                id 
            } 
        }`);
  let [editInvoice, { loading: loadingEditInvoice }] = useMutation(
    gql`
      ${editInvoiceQuery}
    `
  ); //, {update: updateCache}

  const EDIT_INVOICE_BILL_TO = gql(`
        mutation ( 
            $id: String!, 
            $payerCompanyId: String!,
            $billTo: String!, 
            $billToLangId: String!, 
            $billToAddress: String, 
            $billToAddressLangId: String!, 
            $billToContactPerson: String, 
            $billToContactPersonLangId: String!, 
            $billToRegNumber: String, 
            $billToVat: String, 
            $billToContactPhone: String, 
            $billToContactEmail: String 
            ) { 
                editInvoice(input: { 
                    id: $id, 
                    payerCompanyId: $payerCompanyId,
                    billToRegNumber: $billToRegNumber,
                    billToVAT: $billToVat,
                    billToContactPhone: $billToContactPhone,
                    billToContactEmail: $billToContactEmail
                })
                { 
                    id 
                } 
                editBillTo: editInvoiceTranslation(input: { 
                    id: $billToLangId, 
                    ${user.lang}: $billTo 
                }){ 
                    id 
                }
                editBillToAddress: editInvoiceTranslation(input: { 
                    id: $billToAddressLangId, 
                    ${user.lang}: $billToAddress
                }){ 
                    id 
                }
                editBillToContactPerson: editInvoiceTranslation(input: { 
                    id: $billToContactPersonLangId, 
                    ${user.lang}: $billToContactPerson
                }){ 
                    id 
                }
        }
        `);
  const [editInvoiceBillTo, { loading: loadingEditInvoiceBillTo }] =
    useMutation(EDIT_INVOICE_BILL_TO); //, {update: updateCache}

  const [editInvoiceItemQuery, setEditInvoiceItemQuery] = useState(`
        mutation ( $id: String!, ${field.fieldType} ) { 
            editInvoiceItem(input: { 
                id: $id, 
                ${field.field}: $${field.field}
            })
            { 
                id 
            } 
        }`);
  let [editInvoiceItem, { loading: loadingEditInvoiceItem }] = useMutation(
    gql`
      ${editInvoiceItemQuery}
    `
  ); //, {update: updateCache}

  const EDIT_INVOICE_DISCOUNT = gql`
    mutation ($invoiceId: String!, $discount: Float!) {
      extras_setInvoiceDiscount(invoiceId: $invoiceId, discount: $discount)
    }
  `;
  let [editInvoiceDiscount, { loading: loadingEditInvoiceDiscount }] =
    useMutation(EDIT_INVOICE_DISCOUNT); //, {update: updateCache}

  const [editInvoiceTranslationQuery, setEditInvoiceTranslationQuery] =
    useState(
      `mutation ( $id: String!, ${field.fieldType} ) { 
            editInvoiceTranslation(input: { 
                id: $id, 
                ${user.lang}: $${field.field} 
            }){ 
                id 
            } 
        }`
    );
  let [editInvoiceTranslation, { loading: loadingEditInvoiceItemTranslation }] =
    useMutation(
      gql`
        ${editInvoiceTranslationQuery}
      `
    ); //, {update: updateCache}

  // GraphQL API request definition (local variables: restaurantId)
  const GET_INVOICE_BY_RESTAURANT = gql`
    query ($id: String!, $restaurantId: String!, $lang: String!){
        getRestaurantConfigTxtsByContextRestaurantId(
            restaurantId: $restaurantId
            filter: {
            by: "config"
            eqStr: "INVOICE_FOOTER"
            and: { by: "lang", like: $lang }
            }
        ) {
            value
        }
          
        getInvoiceById(id: $id) {
            id
            payerCompanyId
            payeeCompanyId
            refInvoiceId
            refInvoiceInvoices {
                id
            }
            type
            isCash
            status
            created
            updated
            invoiceNumber
            issuingDate
            dueToDate
            amount
            discount
            currency
            comment { ${user.gqlFetchName()} }
            footer { ${user.gqlFetchName()} }
            billTo { ${user.gqlFetchName()} }
            billToRegNumber
            billToVAT
            billToAddress { ${user.gqlFetchName()} }
            billToContactPhone
            billToContactEmail
            billToContactPerson { ${user.gqlFetchName()} }
            billingFrom { ${user.gqlFetchName()} }
            billingVAT
            billingRegNumber
            billingBankName { ${user.gqlFetchName()} }
            billingBankAddress { ${user.gqlFetchName()} }
            billingBankAccount
            billingSwift
            billingAddress { ${user.gqlFetchName()} }
            billingContactPhone
            billingContactEmail
            billingContactPerson { ${user.gqlFetchName()} }
            payerCompany {
                imageLogo
                imageLogoBg
                name { ${user.gqlFetchName()} }
            }
            payeeCompany {
                imageLogo
                imageLogoBg
                name { ${user.gqlFetchName()} }
            }
            invoiceItemsCount
            invoiceItems {
                _id
                id
                article {  ${user.gqlFetchName()} }
                description { ${user.gqlFetchName()} }
                quantity
                price
                tax
                discount
                invoiceItemReceipts {
                    receipt {
                        id
                        receiptNum
                    }
                }
                invoiceItemProducts {
                    invoiceItemId
                  	productId
                }
                invoiceItemReceivingItems {
                    receivingItemId
                    invoiceItemId
                }
            }
            invoicePaymentsCount
            invoicePayments {
                amount
                currency
                date
            }
        }
        getTaxTypesByContextRestaurantId(restaurantId: $restaurantId) {
            id
            name
            rate
        }
    }`;

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(
    GET_INVOICE_BY_RESTAURANT,
    {
      variables: {
        id: invoiceId,
        restaurantId: restaurant.id,
        lang: user.lang,
      },
      pollInterval: 3000,
    }
  );

  // if (loading) return <CircularProgress />
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    );
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error);
    return <p>Error</p>;
  }

  let invoiceReceivingItems = [],
    taxs,
    taxTypes = [],
    tempTaxTypes = [],
    firstTaxType;
  let currencyValue = user.translate("den");

  if (data) {
    invoice = data.getInvoiceById;

    if (invoice) {
      devizna = invoice.currency !== "MKD" ? true : false;
      if (invoice.currency === "USD" && user.restLang === "mk")
        user.setRestLang(false);
      if (invoice.currency !== "USD" && user.restLang === false)
        user.setRestLang("mk");

      invoice.invoiceItems.forEach((invoiceItem) => {
        invoiceItem.invoiceItemReceipts.forEach((receipt) => {
          invoiceReceipts.push(receipt);
        });
        invoiceItem.invoiceItemReceivingItems.forEach((receiving) => {
          invoiceReceivingItems.push(receiving);
        });
      });

      taxs = data.getTaxTypesByContextRestaurantId;
      taxs.forEach((tax) => {
        if (typeof tempTaxTypes[tax.rate * 100] === "undefined") {
          taxTypes.push({
            val: tax.rate * 100,
            label: tax.rate * 100,
          });
        }
        tempTaxTypes[tax.rate * 100] = tax.rate * 100;
      });
      taxTypes.sort((a, b) => b.val - a.val);

      firstTaxType = taxTypes.slice(0, 1)[0];

      if (invoice.currency === "EUR") {
        currencyValue = user.translate("euro");
      } else if (invoice.currency === "USD")
        currencyValue = user.translate("usdo");
    } else return <div></div>;
  }

  let footerConfigText =
    data.getRestaurantConfigTxtsByContextRestaurantId.length > 0
      ? data.getRestaurantConfigTxtsByContextRestaurantId[0].value
      : "";

  const onEditInvoice = (items, field, langId) => {
    if (langId) {
      setEditInvoiceTranslationQuery(
        `mutation ( $id: String!, ${field.fieldType} ) { editInvoiceTranslation(input: { id: $id, ${user.lang}: $${field.field}, }){ id } }`
      );
      setItems({ ...items });
      setEditIt("eit");
    } else {
      setEditInvoiceQuery(
        `mutation ( $id: String!, ${field.fieldType} ) { editInvoice(input: { id: $id ${field.field}: $${field.field} }){ id } }`
      );
      setItems({ ...items });
      setEditIt("ei");
    }
  };

  const onAddInvoiceItem = (items) => {
    addInvoiceItem({ variables: items });
    setRefetchIt(true);
  };

  const onAddInvoiceItemReceipt = (items) => {
    addInvoiceItemReceipt({ variables: items });
    setRefetchIt(true);
  };

  const onEditInvoiceItem = (items, field, langId) => {
    if (langId) {
      setEditInvoiceTranslationQuery(
        `mutation ( $id: String!, ${field.fieldType} ) { editInvoiceTranslation(input: { id: $id, ${user.lang}: $${field.field}, }){ id } }`
      );
      setItems({ ...items });
      setEditIt("eit");
    } else {
      setEditInvoiceItemQuery(
        `mutation ( $id: String!, ${field.fieldType} ) { editInvoiceItem(input: { id: $id ${field.field}: $${field.field} }){ id } }`
      );
      setItems({ ...items });
      setEditIt("eii");
    }
  };

  const onEditInvoiceDiscount = (items) => {
    editInvoiceDiscount({ variables: items });
    setRefetchIt(true);
  };

  const onEditInvoiceBillTo = (items) => {
    editInvoiceBillTo({ variables: items });
    setRefetchIt(true);
  };

  if (editIt) {
    if (editIt === "eit") {
      editInvoiceTranslation({ variables: items });
    } else if (editIt === "ei") {
      editInvoice({ variables: items });
    } else if (editIt === "eii") {
      editInvoiceItem({ variables: items });
    }
    setRefetchIt(true);
    setEditIt(false);
  }

  if (
    !loadingInvoiceItem &&
    !loadingInvoiceItemReceipt &&
    !loadingEditInvoice &&
    !loadingEditInvoiceBillTo &&
    !loadingEditInvoiceItem &&
    !loadingEditInvoiceItemTranslation &&
    !loadingEditInvoiceDiscount &&
    refetchIt
  ) {
    refetch();
    user.consoleLog("Refetch Done!");
    setRefetchIt(false);
  }

  const onPrint = () => {
    helper.printdiv("printDiv", "no_print");
  };

  return (
    <>
      <div className={`${classes.rootBtns} no_print`}>
        <InvoiceStatus
          key={"invoice_status_"}
          invoice={invoice}
          onEditInvoice={onEditInvoice}
          onSuccess={refetch}
          onPrint={onPrint}
          typeOutput={typeOutput}
        />
      </div>

      <div className={`${classes.root}`} id="printDiv">
        <div className={`${classes.rootPadding}`}>
          <div className={classes.logo}>
            <img src={invoice.payeeCompany.imageLogo} width="100" />
          </div>
          <div style={{ float: "left" }}>
            <InvoiceBilling
              key={"invoice_billing_" + field.field}
              invoice={invoice}
              onEditInvoice={onEditInvoice}
              typeOutput={typeOutput}
              devizna={devizna}
            />
          </div>
          <div
            style={{
              float: "left",
              clear: "both",
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                display: "inline-flex",
                width: "45%",
              }}
            >
              <InvoiceTitle
                key={"invoice_title_" + field.field}
                invoice={invoice}
                onEditInvoice={onEditInvoice}
              />
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "inline-flex",
                width: "10%",
              }}
            >
              <div className={invoice.status !== 3 ? "no_print" : ""}>
                {invoice.status === 0
                  ? user.translate("createda")
                  : invoice.status === 1
                  ? invoice.dueToDate < new Date().getTime()
                    ? user.translate("expired")
                    : user.translate("sent")
                  : invoice.status === 2
                  ? user.translate("paid")
                  : user.translate("storna")}
              </div>
              &nbsp;
            </div>
            <div
              style={{
                justifyContent: "flex-end",
                display: "inline-flex",
                width: "45%",
              }}
            >
              <InvoiceBillTo
                key={"invoice_billTo_" + field.field}
                invoice={invoice}
                onEditInvoice={onEditInvoice}
                onEditInvoiceBillTo={onEditInvoiceBillTo}
                typeOutput={typeOutput}
              />
            </div>
          </div>

          <hr
            style={{
              width: "100%",
              clear: "both",
              marginTop: 20,
              display: "inline-block",
            }}
          />

          <InvoiceItemTable
            key={"invoice_items_" + field.field}
            invoice={invoice}
            devizna={devizna}
            invoiceReceipts={invoiceReceipts}
            invoiceReceivingItems={invoiceReceivingItems}
            taxTypes={taxTypes}
            firstTaxType={firstTaxType}
            onAddInvoiceItem={onAddInvoiceItem}
            onAddInvoiceItemReceipt={onAddInvoiceItemReceipt}
            onEditInvoice={onEditInvoice}
            onEditInvoiceItem={onEditInvoiceItem}
            onEditInvoiceDiscount={onEditInvoiceDiscount}
            currencyValue={currencyValue}
            total={total}
            setTotal={setTotal}
            onSuccess={refetch}
          />

          {/* <hr style={{width: "100%",clear: "both",marginTop: 50,display: "inline-block",}}/> */}

          <div
            style={{
              float: "left",
              clear: "both",
              marginTop: 30,
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "100%" }}>
              <InvoiceComment
                key={"invoice_comment_" + field.field}
                invoice={invoice}
                onEditInvoice={onEditInvoice}
              />
              {total !== false && (
                <div
                  className={`${classes.total} ${
                    total == 0 ? classes.totalZero : ""
                  }`}
                >
                  {user.translate("to_pay")}: {total} {currencyValue}
                </div>
              )}
            </div>
            <div style={{ width: "100%", marginTop: 20 }}>&nbsp;</div>
            <div style={{ width: "100%" }}>
              <InvoiceFooter
                key={"invoice_title_" + field.field}
                invoice={invoice}
                onEditInvoice={onEditInvoice}
                defaultOutFooter={""}
                output={true}
              />
            </div>
            <div
              style={{
                width: "100%",
                clear: "both",
                marginTop: 60,
                display: "inline-block",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  width: "33%",
                  float: "left",
                  position: "relative",
                  fontSize: 10,
                }}
              >
                {user.translate("received_byl")}{" "}
                <hr
                  style={{
                    width: "90%",
                    marginTop: 20,
                    marginLeft: 16,
                    marginRight: 16,
                  }}
                />
              </div>
              <div
                style={{
                  width: "33%",
                  float: "left",
                  position: "relative",
                  fontSize: 10,
                }}
              >
                {user.translate("issued_byl")}{" "}
                <hr
                  style={{
                    width: "90%",
                    marginTop: 20,
                    marginLeft: 16,
                    marginRight: 16,
                  }}
                />
              </div>
              <div
                style={{
                  width: "33%",
                  float: "left",
                  position: "relative",
                  fontSize: 10,
                }}
              >
                {user.translate("authorized_person")}{" "}
                <hr
                  style={{
                    width: "90%",
                    marginTop: 20,
                    marginLeft: 16,
                    marginRight: 16,
                  }}
                />{" "}
                <p>{user.me.profile.name}</p>
              </div>
            </div>
            {/* <hr style={{width: "100%",clear: "both",marginTop: 40,display: "inline-block",}}/> */}
          </div>
        </div>
      </div>

      <div className={classes.payments}>
        <InvoicePayments
          invoiceId={invoiceId}
          invoiceAmount={total}
          invoiceNumber={invoice.invoiceNumber}
        />
      </div>
    </>
  );
}
