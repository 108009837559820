import React, { useState, useContext } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
} from '@material-ui/core';
import { UserContext } from '../App';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function RequestPassword(props) {

	const user = useContext(UserContext);

	const [requestIt, setRequestIt] = useState(false);

	const REQUEST = gql`
    mutation {
        auth_requestResetPassword {
            message
            ok
        }
    }`;

	const [requestPassword, { loading: loading_requestPass, data: data_requestPass }] = useMutation(REQUEST);

	const handleClose = () => {
        props.refetch();
        props.setOpenPassword(false);
	};

	const handleRequestAccept = () => {

		requestPassword();
		setRequestIt(true);
    };

    let message = "";
    
    if(requestIt && data_requestPass)
    {
        message = data_requestPass.auth_requestResetPassword.message;
    }

	return (
		<>
			<Dialog
				open={!requestIt}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{props.message} {props.name}</DialogTitle>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{user.translate("cancel")}
					</Button>
					<Button onClick={handleRequestAccept} color="primary" autoFocus>
						{user.translate("accept")}
					</Button>
				</DialogActions>
			</Dialog>

            <Dialog
				open={requestIt && data_requestPass}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{message}</DialogTitle>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{user.translate("close")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
