import { makeStyles } from '@material-ui/core';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';
import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    ripple: {
        left: "unset",
        top: "unset",
        bottom: "unset",
        right: "unset",
        maxWidth: "calc(100% - 45px)",
        width: 540,
        height: 45
    }
}));


export default function RippleTouch(props) {
    const classes = useStyles();

    const rippleRef = useRef(null);

    const onRippleStart = (e) => {
        rippleRef.current.start(e);
    };
    const onRippleStop = (e) => {
        rippleRef.current.stop(e);
    };

    return <>
        <TouchRipple ref={rippleRef} center={false} className={classes.ripple} />
        <div {...props}
            onMouseDown={onRippleStart}
            onMouseUp={onRippleStop}>

            {props.children}
        </div>
    </>
}