import { useEffect, useRef, useState } from 'react';

export default function useWindowSize(onlyBreakpoints = true) {

    const windowSizeRef = useRef((() => {
        let width = window.innerWidth;
        let height = window.innerHeight;
        return {
            width, height,
            isMobile: width ? width < 600 : false,
            isCompact: width ? width <= 1024 : false
        }
    })());

    const [windowSize, setWindowSize] = useState(windowSizeRef.current);

    useEffect(() => {
        function handleResize() {
            let width = window.innerWidth;
            let height = window.innerHeight;
            let newSize = {
                width, height,
                isMobile: width ? width < 600 : false,
                isCompact: width ? width <= 1024 : false
            }

            let currentSize = windowSizeRef.current;
            if (onlyBreakpoints) {
                if (currentSize.isMobile == newSize.isMobile && currentSize.isCompact == newSize.isCompact) {
                    return;
                }
            } else {
                if (currentSize.width == newSize.width && currentSize.height == newSize.height) {
                    return;
                }
            }

            windowSizeRef.current = newSize;
            setWindowSize(newSize);
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}