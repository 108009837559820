import React, {useState, useContext} from 'react';
import {
    alpha,
    makeStyles,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Paper,
    InputBase,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid,
    Slide
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery';
import { UserContext } from "../../App";

library.add(fas);
library.add(far);

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    gridItem: {
        maxWidth: 60,
        minWidth: 60,
        flexGrow: 1
    },
    itemName: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        '&:hover' : {
            backgroundColor: alpha(theme.palette.common.black, 0.05),
            cursor: `pointer`
        }
    },
    search: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 600,
        height: 40,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    }
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function IconDialog(props) {

    const classes = useStyles();
    const user = useContext(UserContext);

    const [selectIt, setSelectIt] = useState(false);

    // Add Dialog Close
    const handleClose = (icon) => {
        setSelectIt(true);
        props.setOpenSelectBox(false);
        if(typeof icon != "undefined")
        props.action(icon);
    };

    const handleSelectAccept= (event, icon) => {

        if(props.setSelectedItem)
            props.setSelectedItem(icon)

        handleClose(icon);
    }

    let fas = library.definitions.fas, icons=[];

    for(var _icon in fas) {
        icons.push(_icon)
    }

    function search(event) {
        if(event.target.value.length > 0){
            $(".MuiDialog-container .MuiGrid-container > div").hide();
            for(var _icon in icons) {
                if(icons[_icon].indexOf(event.target.value.cirilicToLatin()) > -1) {
                    $(".MuiDialog-container .MuiGrid-container > div."+icons[_icon]).show();
                }
            }
        }else {
            $(".MuiDialog-container .MuiGrid-container > div").show();
        }
    }

    return (
        <Dialog
            open={!selectIt}
            fullScreen
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="form-dialog-title"
            aria-describedby="form-dialog-description"
            fullWidth
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>

                        <Paper component="form" className={classes.search}>
                            <IconButton className={classes.iconButton} aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                            <InputBase
                                className={classes.input}
                                placeholder={user.translate("search_icon")}
                                inputProps={{ 'aria-label': 'search icon' }}
                                onChange={search}
                            />
                            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                </Toolbar>
             </AppBar>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid xs={12} container
                        spacing={2}
                        direction="row"
                        justify="center"
                        alignItems="center">
                        {
                            icons.map(_icon => (
                                <Grid item xs={4} key={_icon} className={`${classes.gridItem} ${_icon}`} onClick={(e) => handleSelectAccept(e, _icon)}>
                                    <Paper elevation={2} className={classes.itemName}>
                                        <FontAwesomeIcon icon={_icon}/> 
                                    </Paper>
                                </Grid>
                            ))
                        }
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {user.translate("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );

}