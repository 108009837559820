import { makeStyles, TextField } from '@material-ui/core';
import React, { useContext } from 'react';
import { UserContext } from '../../../App';
import { callOrGet, ifNull, parseDecimal, parseInteger } from './utils';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-grid',
        margin: theme.spacing(1),
        minWidth: '20ch',
        flexGrow: 1,
        '& > .MuiTextField-root': {
            width: '-webkit-fill-available'
        },
    },
}));

export default function EditText(props) {
    const classes = useStyles();
    const user = useContext(UserContext);

    const value = ifNull(props.value, callOrGet(props.default, props.item));

    return <TextField
        className={classes.root}
        fullWidth={props.fullWidth}
        style={{ fontSize: props.size == "small" ? 12 : 14 }}
        type={props.type || "text"}
        label={props.label || user.translate(props.name)}
        variant="outlined"
        size={props.size}
        error={props.error}
        disabled={props.disabled}
        onSubmit={props.onSubmit}
        inputProps={{ style: { fontSize: props.size == "small" ? 14 : 16 } }}
        onChange={e => {
            const valueStr = e.target.value;
            if (valueStr.length == 0) return props.onChange(valueStr);
            if (props.intNumber) return props.onChange(parseInteger(valueStr));
            if (props.decimalNumber) return props.onChange(parseDecimal(valueStr));
            return props.onChange(valueStr);

        }}
        value={value}
    />

}
EditText.gql = "String";