import { Divider, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { RestaurantContext, UserContext } from "../../App";
import useConfirmDialog from '../common/ConfirmDialog';
import EditText from '../common/form/EditText';
import EditTextDate from '../common/form/EditTextDate';
import EditTextInt from '../common/form/EditTextInt';
import ItemForm, { Field } from '../common/form/ItemForm';
import KomitentAutoComplete from '../common/form/KomitentAutoComplete';
import SelectCurrency from '../common/form/SelectCurrency';
import helper from '../common/Helper';
import { useEntityRelationQuery } from '../common/useEntityQuery';
import useWindowSize from '../common/useWindowSize';
import PaymentDistribution, { invoiceGqlSelection, PaymentDistributionHeader } from './PaymentDistribution';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#EEE',
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        // padding: theme.spacing(1),
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: theme.spacing(1)
        },
        paddingBottom: theme.spacing(0.5)
    },
    distributions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function Compensation(props) {

    const item = props.item;

    const classes = useStyles();

    const user = useContext(UserContext);
    const restaurant = useContext(RestaurantContext);

    // if (props.setOnAction) {
    //     props.setOnAction(() => {
    //         helper.printdiv("print_content", "no_print", true);
    //     });
    // }

    const size = useWindowSize(false);
    const confirmDialog = useConfirmDialog();

    const [itemId, setItemId] = useState(props.itemId || item?.id);

    const details = [
        { name: 'bank', value: item?.bank },
        { name: 'bankAccount', value: item?.bankAccount },
        { name: 'date', value: user.formatDate(parseInt(item?.date)) },
    ];

    const INVOICE_FIELDS = [
        { name: "invoiceId", gql: "String!", hidden: true },
        { name: "compensationId", gql: "String!", hidden: true },
        { name: "currency", gql: "String!", width: 0.5, edit: "Select", default: item?.currency },
        { name: "amount", gql: "Float!", width: 1 },
        { name: "date", gql: "String!", edit: "DateMilis" },
        { name: "note", gql: "String!", default: "" },
        { name: "created", gql: "Long!", default: new Date().getTime(), hidden: true },
        { name: "updated", gql: "Long!", default: new Date().getTime(), hidden: true },
        { name: "seen", gql: "Long", hidden: true },
        {
            name: "invoice", gql: "Invoice", subSelection: invoiceGqlSelection(user), hidden: true, readOnly: true
        },
    ];

    const invoicePaymentsQuery = useEntityRelationQuery("CompensationInvoice", INVOICE_FIELDS, "compensationId", itemId, {
        keys: ["compensationId", "invoiceId"]
    });

    const invoicePayments = invoicePaymentsQuery.items || [];


    const renderContent = (item, isItemCreated) => {
        if (!isItemCreated) return null;

        return <div className={classes.distributions}>
            <PaymentDistributionHeader />
            <PaymentDistribution
                item={{ ...item, payeeId: item.firstRestaurantId, payerId: item.secondRestaurantId }}
                invoicePaymentsQuery={invoicePaymentsQuery}
                invoicePayments={invoicePayments.filter(item => item.invoice.payeeCompanyId == restaurant.id)}
            />

            <PaymentDistribution
                isExpense={true}
                item={{ ...item, payerId: item.firstRestaurantId, payeeId: item.secondRestaurantId }}
                invoicePaymentsQuery={invoicePaymentsQuery}
                invoicePayments={invoicePayments.filter(item => item.invoice.payerCompanyId == restaurant.id)}
            />
        </div>
    }

    return <div className={classes.root} id="print_content">
        <ItemForm
            entityName="Compensation"
            entityRelFieldName="id"
            entityRelFieldValue={itemId}
            refresh={props.refresh}
            onSave={(item) => {
                if (!props.itemId) {
                    setItemId(item.id);
                    !props.itemId && props.onCreate && props.onCreate(item);
                }
            }}
            onDelete={props.onDelete}
            item={props.item}
            content={renderContent}
            dense={Boolean(itemId)}
        >

            <KomitentAutoComplete
                name="firstRestaurantId"
                default={restaurant.id}
                label={"komitent"}
                hidden={(value) => value == restaurant.id}
                exclude={(value, item) => item.secondRestaurantId == restaurant.id ? [restaurant.id] : []}
                fullWidth
            />

            <KomitentAutoComplete
                name="secondRestaurantId"
                label={"komitent"}
                hidden={(value) => value == restaurant.id}
                exclude={(value, item) => item.secondRestaurantId != restaurant.id ? [restaurant.id] : []}
                fullWidth
            />
            <EditTextInt
                name="year"
                default={new Date().getFullYear()}
                minValue={2020}
            />
            <EditText
                name="document"
                default="1"
            />
            <EditTextDate
                name="date"
                default={new Date().getTime()}
                fullWidth={size.width <= 655}
            />
            <SelectCurrency
                name="currency"
                default="MKD"
                showLabel={true}
            />

            <EditTextInt
                name="amount"
                gql="Float"
                value={item?.amount || 0}
                minValue={1}
            />

            <Field
                name="updated"
                gql="Long"
                value={new Date().getTime()}
            />
        </ItemForm>

        {confirmDialog.render()}
    </div>
}
