import React, { useContext, useState } from 'react';
import { UserContext } from "../../App";
import Report from '../common/Report';

// React Function Component
export default function EtuReport(props) {

    const user = useContext(UserContext);
    const [page, setPage] = useState(0);

    let startDate = new Date(), endDate = new Date();
    startDate.setMonth(startDate.getMonth(), 1); //the first of this month
    // endDate.setMonth(endDate.getMonth(), 0); //- the last day of last month
    startDate.setHours("00"); 
    startDate.setMinutes("00"); 
    startDate.setSeconds("00");
    endDate.setHours("23"); 
    endDate.setMinutes("59"); 
    endDate.setSeconds("59"); 

    let params = {
        query: {
            name: "report_tradeRecords",
            dateInit: "$dateFrom: Long!, $dateTo: Long!",
            date: "dateFrom: $dateFrom, dateTo: $dateTo",
            dateVars: {
                dateFrom: startDate.getTime(),
                dateTo: endDate.getTime(),
            },
            results: `
                id
                bookDate
                documentNum
                documentDate
                title
                serviceTitle
                amount
                taxAmount
                totalAmount
            `,
            columnsPush: ["id"]
        },
        title: user.translate("etu_report"),
        titleName: user.translate("etureport"),
        key: "etu_report_",
        page: page,
        setPage: setPage,
        datetime: true
    }

    return (
        <Report {...params} />
    )

}
