import React, { useContext, useState } from 'react';
import { UserContext } from "../../App";
import Report from '../common/Report';

// React Function Component
export default function ReceivingReport(props) {

    const user = useContext(UserContext);

    const [page, setPage] = useState(0);
    const [print, setPrint] = useState(false);

    let startDate = new Date(), endDate = new Date();
    startDate.setMonth(startDate.getMonth(), 1); //the first of this month
    // endDate.setMonth(endDate.getMonth(), 0); //- the last day of last month
    startDate.setHours("00"); 
    startDate.setMinutes("00"); 
    startDate.setSeconds("00");
    endDate.setHours("23"); 
    endDate.setMinutes("59"); 
    endDate.setSeconds("59"); 

    //report_stockReceiving(restaurantId: $restaurantId, dateFrom: $dateFrom, dateTo: $dateTo, lang: $lang

    let params = {
        query: {
            name: "report_stockReceiving",
            dateInit: "$dateFrom: Long!, $dateTo: Long!",
            date: "dateFrom: $dateFrom, dateTo: $dateTo",
            dateVars: {
                dateFrom: startDate.getTime(),
                dateTo: endDate.getTime(),
            },
            results: `
                id
                stockTypeId
                stockType
                uom
                quantity
                itemPrice
                totalPrice
                totalTax
                unitTax
                tax
                taxRate
                itemSellPrice
                totalSellPrice
                totalSellTax
                totalPriceWithTax
                totalSellPriceWithTax
                totalFee
                totalFeeWithTax
                totalTaxDiff
                receivingCreated
                itemCreated
                sendingNumber
                warehouseId
                warehouse
                senderId
                sender
                invoiceNumber
            `,
            columnsPush: ["id","stockTypeId","senderId","warehouseId"]
        },
        title: user.translate("receiving_report"),
        titleName: user.translate("receivingreport"),
        page: page,
        setPage: setPage,
        dataLimit: 200,
        print: print,
        setPrint: setPrint,
        displayPrint: true,
    }

    if(print) {
        setPrint(false);
        window.location.reload();
    }

    return (
        <div id="printDiv">
            <Report {...params} />
        </div>
    )

}
