import { useRef } from 'react';

const defaultTransition = 'opacity 0.2s ease-in, box-shadow 0.2s ease-in, transform 0.2s ease-in';

const isScrollable = function (ele) {
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight || ele.scrollWidth > ele.clientWidth;

    const overflowYStyle = window.getComputedStyle(ele).overflowY;
    const isOverflowScroll = overflowYStyle.indexOf('scroll') !== -1;
    const isOverflowAuto = overflowYStyle.indexOf('auto') !== -1;

    return hasScrollableContent && (isOverflowScroll || isOverflowAuto);
};

const getScrollableParent = function (ele) {
    return (!ele || ele === document.body)
        ? window
        : (isScrollable(ele) ? ele : getScrollableParent(ele.parentNode));
};

export default function useDragableItem(direction, callback) {

    const elRef = useRef();

    let startDragTimer = undefined;
    var touchRelPos = undefined;
    var currentNextEl = undefined;
    var currentPrevEl = undefined;
    var dropIndex = undefined;
    var touchStartPos = undefined;

    const disgardStartingDrag = () => {
        if (startDragTimer) {
            clearTimeout(startDragTimer);
            startDragTimer = undefined;
            return
        }
    }

    const updateItemPosition = (x, y, isStart) => {
        const el = elRef.current;

        const newX = x - touchRelPos.x;
        const newY = y - touchRelPos.y;

        el.style.left = `${newX}px`;
        el.style.top = `${newY}px`;

        const childs = Array.from(el.parentElement.children).filter(item => item != el);
        const nextElIndex = direction == "horizontal"
            ? childs.findIndex(item => item.getBoundingClientRect().x > (newX - 5))
            : childs.findIndex(item => item.getBoundingClientRect().y > (newY - 5));
        let nextEl = undefined;

        if (nextElIndex > -1) {
            nextEl = childs[nextElIndex];
            dropIndex = nextElIndex;
        }

        if (nextEl != currentNextEl) {
            if (currentNextEl) {
                if (direction == "horizontal") {
                    currentNextEl.style.marginLeft = "unset";
                } else if (direction == "vertical") {
                    currentNextEl.style.marginTop = "unset";
                } else {
                    console.error("Unknown direction", direction);
                }
            }
            if (nextEl) {
                if (isStart) {
                    nextEl.style.transition = defaultTransition;
                } else {
                    nextEl.style.transition = `${defaultTransition}, margin-top 0.3s ease, margin-left 0.3s ease`;
                }
                if (direction == "horizontal") {
                    nextEl.style.marginLeft = el.clientWidth + "px";
                } else if (direction == "vertical") {
                    nextEl.style.marginTop = el.clientHeight + "px";
                } else {
                    console.error("Unknown direction", direction);
                }
            }
            currentNextEl = nextEl;
        }

        const prevEl = nextEl ? null : childs[childs.length - 1];

        if (prevEl) {
            dropIndex = childs.length;
        }

        if (prevEl != currentPrevEl) {
            if (currentPrevEl) {
                if (direction == "horizontal") {
                    currentPrevEl.style.marginRight = "unset";
                } else if (direction == "vertical") {
                    currentPrevEl.style.marginBottom = "unset";
                } else {
                    console.error("Unknown direction", direction);
                }
            }
            if (prevEl) {
                if (isStart) {
                    prevEl.style.transition = defaultTransition;
                } else {
                    prevEl.style.transition = `${defaultTransition}, margin-top 0.3s ease, margin-left 0.3s ease`;
                }
                if (direction == "horizontal") {
                    prevEl.style.marginRight = el.clientWidth + "px";
                } else if (direction == "vertical") {
                    prevEl.style.marginBottom = el.clientHeight + "px";
                } else {
                    console.error("Unknown direction", direction);
                }
            }
            currentPrevEl = prevEl;
        }
    }

    const onTouchStart = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const el = elRef.current;
        const target = e.target;

        target.setPointerCapture(e.pointerId);

        const pos = el.getBoundingClientRect();
        const x = e.clientX;
        const y = e.clientY;

        touchStartPos = { x: x, y: y };

        startDragTimer = setTimeout(() => {
            startDragTimer = undefined;

            touchRelPos = {
                x: x - pos.left,
                y: y - pos.top
            }

            el.parentElement.style.height = el.parentElement.clientHeight + "px";

            target.style.cursor = "grabbing";
            el.style.width = el.clientWidth + "px";
            el.style.position = "fixed";
            el.style.zIndex = 99;
            el.style['box-shadow'] = "0px 0px 10px #00000070";
            el.style.opacity = 0.9;
            el.style.transform = "scale(1.01)";

            updateItemPosition(x, y, true);
        }, 150);


        return true;
    }

    const onTouchMove = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (touchRelPos == undefined) {
            if (touchStartPos) {
                const dx = touchStartPos.x - e.clientX;
                const dy = touchStartPos.y - e.clientY;
                if (Math.sqrt(dx * dx + dy * dy) > 10) {
                    if (startDragTimer) {
                        clearTimeout(startDragTimer);
                    }
                }
            }
            return;
        }

        updateItemPosition(e.clientX, e.clientY);

        const container = getScrollableParent(e.target);

        if (direction == "horizontal") {
            const rightEdgeDistance = Math.max(window.innerWidth - e.clientX, 1) / 30;
            if (rightEdgeDistance < 1) {
                container.scrollBy((1 - rightEdgeDistance) * 40, 0);
            }

            const leftEdgeDistance = Math.max(e.clientX, 1) / 30;
            if (leftEdgeDistance < 1) {
                container.scrollBy((1 - leftEdgeDistance) * -40, 0);
            }
        } else if (direction == "vertical") {
            const bottomEdgeDistance = Math.max(window.innerHeight - e.clientY, 1) / 30;
            if (bottomEdgeDistance < 1) {
                container.scrollBy(0, (1 - bottomEdgeDistance) * 40);
            }

            const topEdgeDistance = Math.max(e.clientY, 1) / 30;
            if (topEdgeDistance < 1) {
                container.scrollBy(0, (1 - topEdgeDistance) * -40);
            }
        } else {
            console.error("Unknown direction", direction);
        }

        return true;
    }

    const resetDragAndDrop = () => {
        const el = elRef.current;
        if (el == undefined) return;

        el.style.position = "unset";
        el.style.left = "unset";
        el.style.top = "unset";
        el.style['box-shadow'] = "unset";
        el.style.transform = "scale(1)";
        el.style.zIndex = "unset";


        el.style.backgroundColor = "#dde4ef";
        requestAnimationFrame(() => {
            el.style.transition = `all 0.5s ease-out`;
            el.style.backgroundColor = "#FFF";
        })

        setTimeout(() => {
            el.style.transition = defaultTransition;
        }, 300);

        if (currentPrevEl) {
            if (direction == "horizontal") {
                currentPrevEl.style.marginRight = "unset";
            } else if (direction == "vertical") {
                currentPrevEl.style.marginBottom = "unset";
            } else {
                console.error("Unknown direction", direction);
            }
            currentPrevEl.style.transition = defaultTransition;
        }
        if (currentNextEl) {
            if (direction == "horizontal") {
                currentNextEl.style.marginLeft = "unset";
            } else if (direction == "vertical") {
                currentNextEl.style.marginTop = "unset";
            } else {
                console.error("Unknown direction", direction);
            }
            currentNextEl.style.transition = defaultTransition;
        }

        currentNextEl = undefined;
        currentPrevEl = undefined;
    }

    const onTouchEnd = (e) => {
        e.preventDefault();
        e.stopPropagation();

        e.target.releasePointerCapture(e.pointerId);

        if (disgardStartingDrag()) {
            resetDragAndDrop();
            return;
        }

        if (touchRelPos == undefined) return

        touchRelPos = undefined;

        const el = elRef.current;
        e.target.style.cursor = "grab";

        el.style.opacity = 1;
        el.style.transform = "scale(0.96)";

        el.style.transition = `${defaultTransition}, top 0.3s ease-out, left 0.3s ease-out`;

        const parentPos = el.parentElement.getBoundingClientRect();
        if (direction == "horizontal") {
            el.style.left = (parentPos.left + dropIndex * el.clientWidth) + "px";
            el.style.top = parentPos.top + "px";
        } else if (direction == "vertical") {
            el.style.top = (parentPos.top + dropIndex * el.clientHeight) + "px";
            el.style.left = parentPos.left + "px";
        } else {
            console.error("Unknown direction", direction);
        }

        const result = callback && callback(dropIndex);

        if (result instanceof Promise) {
            result.finally(resetDragAndDrop);
        } else {
            resetDragAndDrop();
        }
    }


    return {
        ref: elRef,
        Handle: (props) => {
            return <div {...props} onPointerDown={onTouchStart} onPointerMove={onTouchMove} onPointerOut={onTouchEnd} onPointerUp={onTouchEnd} onPointerCancel={onTouchEnd}>
                {props.children}
            </div>
        }
    }

}