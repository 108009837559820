import { gql, useMutation, useQuery } from "@apollo/client";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import NormativesIcon from "@material-ui/icons/FormatListNumbered";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RestaurantContext, UserContext } from "../../App";
import DeletedItemsDialog from "../common/DeletedItemsDialog";
import helper from "../common/Helper";
import ProductAdjustments from "../common/ProductAdjustments";
import SnackBarDelete from "../common/SnackBarDelete";
import SnackBarWarning from "../common/SnackBarWarning";
import Table from "../common/Table";
import tableComponents from "../common/TableComponents";
import TableBottomLink from "../common/TabletBottomLink";
import ProductItemList from "./ProductItemList";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-root .MuiIconButton-root.MuiIconButton-colorInherit, & .MuiTableCell-root .MuiIconButton-root.MuiIconButton-colorPrimary":
      {
        padding: 4,
      },
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  checkedLabel: {
    marginRight: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: 12,
    },
  },
}));

// React Function Component
export default function ProductList(props) {
  const classes = useStyles();

  let path = new URLSearchParams(useLocation().search);
  let _rowData = {};
  path.forEach(function (value, key) {
    if (key === "id") _rowData.id = value;
    if (key === "name") _rowData.name = value;
  });

  let history = useHistory();

  const restaurant = useContext(RestaurantContext);
  const user = useContext(UserContext);

  const [page, setPage] = useState(0);

  const [rowData, setRowData] = useState(_rowData.id ? _rowData : false);
  if (rowData && !_rowData.id) {
    if (!props.handleSelectAccept) setRowData(false); //take this out for handleSelectAccept
  }

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openAdjust, setOpenAdjust] = useState(false);
  const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false);

  const [deleteState, setDeleteState] = useState({
    variables: {},
    name: "",
  });
  const [adjustState, setAdjustState] = useState({
    variables: {},
    name: "",
  });

  const createEditIdem = (createdName) => gql`
    mutation (
      $id: String!
      $restaurantId: String!
      $productionCategoryId: String!
      $taxTypeId: String
      $name: String!
      ${
        createdName
          ? `$menuName: String!
      $menuNameId: String!`
          : ""
      }
      $code: Long!
      $price: Float!
      $image: String!
      $prepTime: Long!
      $discountable: Long!
      $isAddition: Long!
      $isDomestic: Long!
      $active: Long!
    ) {
      createProduct(
        input: {
          id: $id
          taxTypeId: $taxTypeId
          name: $name
          ${
            createdName
              ? createdName("$menuName", "$menuNameId", "MenuName")
              : ""
          }
          code: $code
          price: $price
          image: $image
          prepTime: $prepTime
          discountable: $discountable
          isAddition: $isAddition
          isDomestic: $isDomestic
          active: $active
          productionCategoryId: $productionCategoryId
          restaurantId: $restaurantId
          invoiceTitleId: "null"
        }
      ) {
        id
      }
    }
  `;
  const [editItem, { loading: loading_edit }] = useMutation(
    createEditIdem(user.gqlEditName)
  );
  const [createItem, { loading: loading_create }] = useMutation(
    createEditIdem(undefined)
  );
  const [createItemMenuName, { loading: loading_createWMenuName }] =
    useMutation(createEditIdem(user.gqlCreateName));

  const EDIT_ITEM_INVOICE_TITLE = gql`
    mutation ($id: String!, $restaurantId: String!, $productionCategoryId: String!, $taxTypeId: String, $invoiceTitleId: String!, $invoiceTitle: String!, $name: String!, $menuName: String!, $menuNameId: String, $code: Long!, $price: Float!, $image: String!, $prepTime: Long!, $discountable: Long!, $isAddition: Long!, $isDomestic: Long!, $active: Long! ) {
        createProduct(input:{
            id: $id
            taxTypeId: $taxTypeId
            name: $name
            ${user.gqlCreateName("$menuName", "$menuNameId", "MenuName")}
            code: $code
            price: $price
            image: $image
            prepTime: $prepTime
            discountable: $discountable
            isAddition: $isAddition
            isDomestic: $isDomestic
            active: $active
            productionCategoryId: $productionCategoryId
            restaurantId: $restaurantId
            createInvoiceTitle: {
                id: $invoiceTitleId,
                restaurantId: $restaurantId,
                ${user.gqlCreateName("$invoiceTitle", false, "Title")}
            }
        }){
          id
        }
    }`;
  const [editItemInvoiceTitle, { loading: loading_edit_invoice_title }] =
    useMutation(EDIT_ITEM_INVOICE_TITLE);
  // @TODO: fali ProductionCategory novo e napravi go pokasno

  const UPDATE_MENU_ITEMS_PRICE = gql`
    mutation ($id: String!) {
      extras_updateMenuItemsPriceByProductId(productId: $id)
    }
  `;
  const [updateMenuItemsPrice, { loading: loadingMenuItemsPrice }] =
    useMutation(UPDATE_MENU_ITEMS_PRICE);

  const DELETE_ITEM = gql`
    mutation ($id: String!) {
      deleteProduct(id: $id)
    }
  `;
  const [deleteItem, { loading: loading_delete }] = useMutation(DELETE_ITEM);

  const UNDELETE_ITEM = `
        mutation ($id: String!) {
            undeleteProduct(id: $id)
    }`;

  // GraphQL API request definition (local variables: restaurantId)
  const GET_PRODUCT_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
        getProductsByContextRestaurantId(restaurantId: $restaurantId) {
            id
            taxTypeId
            name
            menuNameId
            menuName {
                ${user.gqlFetchName()}
            }
            code
            price
            image
            prepTime
            discountable
            isAddition
            isDomestic
            productionCategoryId
            active
            menuItemGroupItemsCount
            invoiceTitle {
                id
                title {
                    ${user.gqlFetchName()}
                }
            }
        }
        getTaxTypesByContextRestaurantId(restaurantId: $restaurantId) {
            id
            name
            code
            rate
        }
        getProductionCategorysByContextRestaurantId(restaurantId: $restaurantId) {
            id
            name {
                ${user.gqlFetchName()}
            }
                
        }
        getInvoiceTitlesByContextRestaurantId(restaurantId: $restaurantId) {
            _id
            id
            title { 
                ${user.gqlFetchName()}
            }
        }
    }`;

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(
    GET_PRODUCT_BY_RESTAURANT,
    {
      variables: { restaurantId: restaurant.id },
      pollInterval: 3000,
    }
  );

  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    );
  }

  // In case there is an error, just show it for now
  // if (!data) { user.consoleLog(error); return <p>Error</p> }

  let fieldList = {},
    _taxs = {},
    taxTypes = [{ val: "0", label: user.translate("auto") }],
    _taxTypes = [],
    prCats = {},
    inTitles = {},
    invoiceTitles = [],
    tempInvoiceTitles = [],
    productionCategorys = [],
    firstProductionCategory = {},
    alertMessage = `${user.translate("cant_delete_product")} `,
    autoHideDuration = 3000,
    linkTo = ``,
    linkText = "";

  if (data) {
    _taxs = data.getTaxTypesByContextRestaurantId;
    inTitles = data.getInvoiceTitlesByContextRestaurantId;
    prCats = data.getProductionCategorysByContextRestaurantId;
  }

  if (_taxs.length > 0) {
    let taxs = Object.assign([], _taxs);
    taxs.sort((a, b) => b.rate - a.rate);

    _taxTypes = taxs.map((_tax) => {
      return {
        val: _tax.id,
        label: "" + _tax.rate * 100,
      };
    });

    taxTypes = [...taxTypes, ..._taxTypes];
  }

  invoiceTitles = [
    {
      name: user.translate("auto"),
      id: "0",
    },
  ];

  tempInvoiceTitles = inTitles.map((_title) => {
    return {
      id: _title.id,
      name: _title.title[user.lang],
    };
  });

  invoiceTitles = [...invoiceTitles, ...tempInvoiceTitles];

  let firstInvoiceTitle = invoiceTitles.slice(0, 1);

  if (data && Object.keys(prCats).length > 0) {
    productionCategorys = prCats.map((productCategory) => {
      return {
        val: productCategory.id,
        label: productCategory.name[user.lang],
      };
    });

    firstProductionCategory = productionCategorys[0];
  } else if (!rowData && !loading) {
    alertMessage = `${user.translate("please_create")} `;
    autoHideDuration = undefined;
    linkTo = `/restaurant/${restaurant._id}/production_category/`;
    linkText = user.translate("production_category");
    if (!openSnackBarWarning) setOpenSnackBarWarning(true);
  }

  const onOpenAdjust = (_rowData) => {
    let items = {};
    items["productId"] = _rowData.id;

    setAdjustState({
      variables: items,
      name: _rowData.name,
    });

    setOpenAdjust(true);
  };

  fieldList["columns"] = [
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "Id",
      field: "id",
      hidden: true,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "Image",
      field: "image",
      hidden: true,
    }, //, render: rowData => <img src={rowData.image} style={{width: 50}}/>
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "taxTypeId",
      field: "taxTypeId",
      hidden: true,
    }, //, render: rowData => <img src={rowData.image} style={{width: 50}}/>
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "productionCategoryId",
      field: "productionCategoryId",
      hidden: true,
    }, //, render: rowData => <img src={rowData.image} style={{width: 50}}/>
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "invoiceTitleId",
      field: "invoiceTitleId",
      hidden: true,
    }, //, render: rowData => <img src={rowData.image} style={{width: 50}}/>
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "menuItemGroupItemsCount",
      field: "menuItemGroupItemsCount",
      hidden: true,
    }, //, render: rowData => <img src={rowData.image} style={{width: 50}}/>
    {
      title: "",
      field: "count",
      editable: "never",
      cellStyle: {
        width: "1%",
        padding: `0 5px 0 0`,
        fontSize: 12,
        whiteSpace: "pre-line",
      },
    },
    {
      title: user.translate("title"),
      field: "name",
      cellStyle: {
        width: "30%",
        padding: 0,
        fontSize: 14,
        whiteSpace: "pre-line",
      },
      customSort: (a, b) =>
        a.name
          .cirilicLatinCompare(user.lang)
          .localeCompare(b.name.cirilicLatinCompare(user.lang), user.lang),
    },
    {
      title: user.translate("menu_name"),
      field: "menuName",
      cellStyle: {
        width: "30%",
        padding: 0,
        fontSize: 14,
        whiteSpace: "pre-line",
      },
      customSort: (a, b) =>
        a.name
          .cirilicLatinCompare(user.lang)
          .localeCompare(b.name.cirilicLatinCompare(user.lang), user.lang),
    },
    {
      title: user.translate("category"),
      field: "productionCategory",
      editComponent: (props) =>
        tableComponents.Select(
          props,
          productionCategorys,
          "productionCategoryId"
        ),
    },
    {
      title: user.translate("invoice_title"),
      field: "invoiceTitle",
      cellStyle: {
        width: "30%",
        padding: "0 5px 0 0",
        fontSize: 12,
        whiteSpace: "nowrap",
      },
      editComponent: (props) =>
        tableComponents.AutoCompleteAdd(
          props,
          invoiceTitles,
          "invoiceTitleId",
          "invoiceTitle"
        ),
    },
    {
      title: user.translate("tax") + " %",
      field: "taxType",
      editComponent: (props) =>
        tableComponents.Select(props, taxTypes, "taxTypeId"),
    },
    // {
    //   title: user.translate("code"),
    //   field: "code",
    //   cellStyle: { width: "5%", padding: "0 5px 0 0", fontSize: 12, whiteSpace: "nowrap" },
    // },
    {
      title: user.translate("price"),
      field: "price",
      cellStyle: {
        width: "5%",
        padding: "0 5px 0 0",
        fontSize: 12,
        whiteSpace: "nowrap",
      },
      render: (rowData) => user.formatNumber(rowData.price),
      editComponent: (props) =>
        tableComponents.NumericEditComponent(props, "price"),
    },
    {
      title: `${user.translate("prep_time")}`,
      field: "prepTime",
      render: (rowData) => user.formatNumber(rowData.prepTime),
      editComponent: (props) =>
        tableComponents.NumericEditComponent(props, "prepTime"),
    },
    {
      title: user.translate("discount"),
      field: "discountable",
      render: (rowData) =>
        tableComponents.BooleanRender(rowData, "discountable"),
      editComponent: (props) =>
        tableComponents.BooleanEditComponent(
          props,
          "discountable",
          "discount",
          classes
        ),
    },
    {
      title: user.translate("addition"),
      field: "isAddition",
      render: (rowData) => tableComponents.BooleanRender(rowData, "isAddition"),
      editComponent: (props) =>
        tableComponents.BooleanEditComponent(
          props,
          "isAddition",
          "addition",
          classes
        ),
    },
    {
      title: user.translate("domestic"),
      field: "isDomestic",
      render: (rowData) => tableComponents.BooleanRender(rowData, "isDomestic"),
      editComponent: (props) =>
        tableComponents.BooleanEditComponent(
          props,
          "isDomestic",
          "domestic",
          classes
        ),
    },
    {
      title: user.translate("active"),
      field: "active",
      render: (rowData) => tableComponents.BooleanRender(rowData, "active"),
      editComponent: (props) =>
        tableComponents.BooleanEditComponent(
          props,
          "active",
          "active",
          classes
        ),
    },
    user.me.profile.isAdmin
      ? {
          title: user.translate("adjust"),
          field: "adjustItem",
          render: (rowData) => {
            return (
              <Tooltip title={`${user.translate("adjust")}`} placement="top">
                <IconButton
                  color="primary"
                  onClick={(e) => onOpenAdjust(rowData)}
                >
                  <ControlCameraIcon />
                </IconButton>
              </Tooltip>
            );
          },
          editable: "never",
          export: false,
          disableClick: true,
        }
      : {},
    {
      title: "",
      field: "items",
      editable: "never",
      render: (rowData) => (
        <Tooltip title={`${user.translate("ingredients")}`} placement="top">
          <IconButton color="primary" onClick={(event) => onRowClick(rowData)}>
            <ArrowForwardIcon />
          </IconButton>
        </Tooltip>
      ),
      sorting: false,
      export: false,
      disableClick: true,
      customFilterAndSearch: (filterValue, row) => {
        return (
          (row.name &&
            row.name.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) >
              -1) ||
          (row.productionCategory &&
            row.productionCategory
              .cirilicToLatin()
              .indexOf(filterValue.cirilicToLatin()) > -1) ||
          (row.taxType &&
            row.taxType.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) >
              -1) ||
          (row.price &&
            user.formatNumber(row.price).indexOf(filterValue) > -1) ||
          (row.prepTime &&
            user.formatNumber(row.prepTime).indexOf(filterValue) > -1)
        );
      },
    },
  ];

  fieldList["data"] =
    data &&
    data.getProductsByContextRestaurantId.map((result, index) => ({
      ...result,
      count: index + 1 + ".",
      menuName: result.menuName ? result.menuName[user.lang] : "",
      productionCategoryId: result.productionCategoryId,
      productionCategory: result.productionCategoryId
        ? productionCategorys.find((c) => c.val === result.productionCategoryId)
            ?.label
        : "n/a",
      taxTypeId: result.taxTypeId ? result.taxTypeId : "0",
      taxType: result.taxTypeId
        ? taxTypes.find((c) => c.val === result.taxTypeId)?.label
        : taxTypes.find((c) => c.val === "0")?.label ?? "n/a",
      invoiceTitleId: result.invoiceTitle ? result.invoiceTitle.id : "0",
      invoiceTitle: result.invoiceTitle
        ? result.invoiceTitle.title[user.lang]
        : firstInvoiceTitle[0].name,
      discountable: result.discountable ? 1 : 0,
      isAddition: result.isAddition ? 1 : 0,
      isDomestic: result.isDomestic ? 1 : 0,
    }));

  // console.log(fieldList['data'])

  const setItemss = (dataObj, action) => {
    let items = {
      ...dataObj,
      id: dataObj.id ? dataObj.id : helper.uid(),
      productionCategoryId:
        typeof dataObj.productionCategoryId != "undefined"
          ? dataObj.productionCategoryId
          : firstProductionCategory.val,
      name: dataObj.name,
      taxTypeId: dataObj.taxTypeId !== "0" ? dataObj.taxTypeId : "null",
      invoiceTitleId:
        dataObj.invoiceTitleId !== "0" ? dataObj.invoiceTitleId : undefined,
      invoiceTitle:
        dataObj.invoiceTitleId !== "0" ? dataObj.invoiceTitle : undefined,
      code:
        typeof dataObj.code != "undefined" && dataObj.code != ""
          ? dataObj.code
          : 0,
      price:
        typeof dataObj.price != "undefined" && dataObj.price != ""
          ? dataObj.price
          : 0,
      image: typeof dataObj.image != "undefined" ? dataObj.image : "",
      prepTime:
        typeof dataObj.prepTime != "undefined" && dataObj.prepTime != ""
          ? parseInt(dataObj.prepTime)
          : 0,
      discountable: dataObj.discountable ? 1 : 0,
      isAddition: dataObj.isAddition ? 1 : 0,
      isDomestic: dataObj.isDomestic ? 1 : 0,
      active: dataObj.active ? 1 : 0,
      restaurantId: restaurant.id,
    };

    return items;
  };

  const onAddItem = (newData, state) => {
    let items = setItemss(newData);
    setPage(
      Math.round((data.getProductsByContextRestaurantId.length + 1) / 50)
    );

    if (items.invoiceTitleId) return editItemInvoiceTitle({ variables: items });
    else {
      items.menuNameId = helper.uid();
      const create = items.menuName ? createItemMenuName : createItem;
      return create({ variables: items })
        .then((e) => {
          updateMenuItemsPrice({
            variables: {
              id: e.data.createProduct.id || newData.id,
            },
          });
        })
        .then(() => refetch());
    }

    // return items;
  };

  const onEditItem = (newData, oldData) => {
    let items = setItemss(newData);
    if (items.invoiceTitleId) {
      return editItemInvoiceTitle({ variables: items }).then(() => refetch());
    }

    items.menuNameId =
      newData.menuNameId !== null ? newData.menuNameId : helper.uid();
    const mutation = oldData.menuNameId
      ? editItem
      : newData.menuName
      ? createItemMenuName
      : createItem;
    return mutation({ variables: items })
      .then(() => {
        updateMenuItemsPrice({
          variables: {
            id: newData.id,
          },
        });
      })
      .then(() => refetch());

    // return items;
  };

  const onDeleteItem = (oldData, state, setState) => {
    let items = {};
    items["id"] = oldData.id;

    user.consoleLog(items);
    // if (oldData.menuItemGroupItemsCount === 0) {
    return deleteItem({ variables: items })
      .then(() => refetch())
      .then(() => {
        setDeleteState({
          variables: items,
          name: oldData.name,
          state: state,
        });
        setOpenSnackBar(true);
      });
    // } else {
    //   setOpenSnackBarWarning(true)
    // }
  };

  const onRowClick = (_rowData) => {
    if (!props.handleSelectAccept)
      history.push(
        `/restaurant/${restaurant._id}/product/?id=${
          _rowData.id
        }&name=${_rowData.name.replaceAll("/", "_")}`
      );
    setRowData(_rowData);
  };

  const FIELDS = [
    { name: "id", gql: "String!", id: true, hidden: true },
    { name: "name", gql: "String", width: 3 },
    {
      name: "productionCategory",
      title: "category",
      gql: "ProductionCategory",
      subSelection: { name: { [user.lang]: true } },
      render: (item) => item.productionCategory.name[user.lang],
    },
  ];

  return (
    <div className={classes.root}>
      <Table
        key={helper.hash(user.lang + page)}
        tableName={user.translate("products")}
        exportFileName={user.translate("products")}
        columnCount={11}
        fieldList={fieldList}
        pageSize="50"
        pageSizeOptions={[50, 100, 200]}
        onAddItem={onAddItem}
        onEditItem={onEditItem}
        onDeleteItem={onDeleteItem}
        page={page}
        onRowClick={onRowClick}
        hidden={rowData}
      />

      <DeletedItemsDialog
        title="products"
        entityName="Product"
        fields={FIELDS}
        onChange={refetch}
      />

      <TableBottomLink
        title={user.translate("normatives")}
        path="normatives"
        icon={<NormativesIcon />}
      />

      {!rowData && openSnackBar && (
        <SnackBarDelete
          message={`${deleteState.name} ${user.translate("deleted")}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}

      {openSnackBarWarning && (
        <SnackBarWarning
          message={alertMessage}
          linkTo={linkTo}
          linkText={linkText}
          autoHideDuration={autoHideDuration}
          openSnackBar={openSnackBarWarning}
          setOpenSnackBar={setOpenSnackBarWarning}
        />
      )}

      <ProductItemList
        key={helper.hash(JSON.stringify(rowData))}
        handleSelectAccept={props.action}
        rowData={rowData}
        setRowData={setRowData}
      />

      {openAdjust && (
        <ProductAdjustments
          name={adjustState.name}
          variables={adjustState.variables}
          openAdjust={openAdjust}
          setOpenAdjust={setOpenAdjust}
        />
      )}
    </div>
  );
}
