import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  deleted: {
    width: 140,
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      bottom: 44,
      left: theme.spacing(1)
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function TableBottomLink(props) {
  const classes = useStyles();

  const history = useHistory();

  const onClick = () => {
    history.push(props.path);
  };

  return (
    <Button startIcon={props.icon} className={classes.deleted} onClick={onClick}>
      {props.title}
    </Button>
  );
}
