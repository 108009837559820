import React, { useState, useContext, useEffect } from 'react';
import {
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    Button
} from "@material-ui/core";
import { useMutation, gql } from '@apollo/client';
import { UserContext } from "../../App";
import helper from './Helper';

export default function Delete(props) {
    const user = useContext(UserContext);

    const DELETE_ITEM = gql`${props.deleteItem}`;

    const [deleteItem, { loading }] = useMutation(DELETE_ITEM);

    // Delete Dialog
    const handleClose = () => {
        props.setOpenDeleteBox(false);
    };

    if (loading) {
        return (<div className="App AppLoading"><CircularProgress /></div>);
    }

    const handleDeleteAccept = () => {
        if(props.setOpenSnackBar) props.setOpenSnackBar(true);
        deleteItem({
            variables: props.variables
        }).then(e => {
            props.onSuccess();
            props.setOpenDeleteBox(false);
        });
        if (props.setUpdatedAmount) props.setUpdatedAmount(false);
        user.consoleLog(props.variables);
    }

    return (
        <>
            <Dialog
                open={!loading}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{user.translate("delete_accept")} {props.name}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {user.translate("cancel")}
                    </Button>
                    <Button onClick={handleDeleteAccept} color="primary" autoFocus>
                        {user.translate("yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}