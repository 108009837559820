import React, { useContext, useState } from 'react';
import { UserContext } from "../App";
import { useParams, useHistory } from "react-router-dom";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
} from '@material-ui/core';
import Add from '../restaurant/common/Add';

// React Function Component
export default function ResetPassword(props) {

	const { code } = useParams();

	const user = useContext(UserContext);

	const [cancel, setCancel] = useState(true);
	const [success, setSuccess] = useState("");

	let history = useHistory();

	const EDIT_ITEM = `
    mutation($code: String!, $password: String!) {
        auth_resetPassword(codeWithVerifier: $code, password: $password) {
            message
            ok
        }
    }`;

	const fieldList = [
		{
			required: true,
			type: "password_show",
			fieldType: "text",
			fieldName: "password",
			fieldLabel: user.translate("password"),
			fieldValue: "",
		},
		{
			required: true,
			type: "hidden",
			fieldType: "text",
			fieldName: "code",
			fieldLabel: "code",
			fieldValue: code,
		},
	];

	const goHome = () => {
        props.refetch();
		history.push('/home');
    }

    const onSuccess = (data_resetPass) => {
        setSuccess(data_resetPass.auth_resetPassword.message);
    }

    if (!cancel && !success) {
		goHome();
	}

	return (
        <>
            {!success && (
                <Add
                    fieldList={fieldList}
                    openManipulateBox={setCancel}
                    actionType={user.translate("edit")}
                    name={user.translate("password")}
                    manipulateItem={EDIT_ITEM}
                    onSuccess={onSuccess}
                    returnParams={true}
                />
            )}

            <Dialog
				open={success}
				onClose={goHome}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{success}</DialogTitle>
				<DialogActions>
					<Button onClick={goHome} color="primary">
						{user.translate("home")}
					</Button>
				</DialogActions>
			</Dialog>
        </>
	)

}
