import { makeStyles, MenuItem, Select } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Print from '@material-ui/icons/Print';
import React, { useContext, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { RestaurantContext, UserContext } from "../../App";
import SnackBarDelete from '../common/action/SnackBarDelete';
import useDateRangeFilter from '../common/DateRangeHeader';
import KomitentAutoComplete from '../common/form/KomitentAutoComplete';
import SelectPaymentDirection from '../common/form/SelectPaymentDirection';
import GqlSimpleTable from '../common/GqlSimpleTable';
import MobileDialog from '../common/MobileDialog';
import Show from '../common/Show';
import useWindowSize from '../common/useWindowSize';
import Compensation from './Compensation';

const useStyles = makeStyles((theme) => ({
    roots: {
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: theme.spacing(1)
        }
    },
    root: {

    },
    checkedLabel: {
        marginRight: 0,
        "& .MuiFormControlLabel-label": {
            fontSize: 12
        }
    },
    distOk: {
        color: '#069a87',
        fontSize: '1.1em',
        verticalAlign: 'middle',
        paddingBottom: 1
    },
    options: {
        display: 'flex',
        flexFlow: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(2)
    },
    tableCell: {
        textAlign: 'left',
        paddingTop: 0
    }
}));

// React Function Component
export default function AnaliticalCard() {

    const classes = useStyles();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const size = useWindowSize();

    const history = useHistory();
    const location = useLocation();

    const { documentId: selectedDocumentId, documentType } = useParams()

    const [deletedItem, setDeletedItem] = useState();
    const [selectedKomitent, setSelectedKomitent] = useState();
    const [selectedDirection, setSelectedDirection] = useState("all");


    const green = "#069a87";
    const red = "#644";

    const renderDist = (item) => {
        if (item.amount == item.distExpense && item.amount == item.distIncome) return <Check className={classes.distOk} />;
        const expense = item.distExpense == item.amount ? <Check className={classes.distOk} /> : <span style={{ color: '#800' }}>{item.distExpense}</span>;
        const income = item.distIncome == item.amount ? <Check className={classes.distOk} /> : <span style={{ color: '#800' }}>{item.distIncome}</span>;

        return <span>{expense} / {income}</span>;
    }

    const typeMaxLen = 16;
    const types = {
        compensation: user.translate("compensation").substring(0, typeMaxLen),
        COMPENSATION: user.translate("compensation").substring(0, typeMaxLen),
        cession: user.translate("cession").substring(0, typeMaxLen),
        CESSION: user.translate("cession").substring(0, typeMaxLen),
        BANK_ACCOUNT: user.translate("bank_statement").substring(0, typeMaxLen),
        INVOICE: user.translate("invoice").substring(0, typeMaxLen),
        NONE: user.translate("not_dist").substring(0, typeMaxLen),
        OTHER: user.translate("other").substring(0, typeMaxLen),
        INITIAL: user.translate("initialo").substring(0, typeMaxLen),
        TURNOVER: user.translate("turnover").substring(0, typeMaxLen),
        BALANCE: user.translate("balance").substring(0, typeMaxLen),
        BALANCE_SUM: user.translate("balance_sum").substring(0, typeMaxLen),
        CANCELED: <span style={{ color: '#F00' }}>{user.translate("storna").substring(0, typeMaxLen)}</span>,
    }

    const renderAmount = (item, value, formated) => {

        const type = item.srcDocTypeVal;

        if (type == "INITIAL" || type == "TURNOVER" || type == "BALANCE" || type == "BALANCE_SUM") {
            return <i><b style={{ whiteSpace: 'nowrap' }}>{formated}</b></i>;
        } else if (item.targetDocType === "NONE" && value > 0) {
            return <span style={{ color: '#08753e', fontWeight: 600, whiteSpace: 'nowrap' }}>{formated}</span>;
        } else if (value == 0) {
            return <span style={{ color: '#6d6d6d', whiteSpace: 'nowrap' }}>{formated}</span>;
        }
        return formated;
    }

    const FIELDS = [
        { name: "sourceId", gql: "String!", id: true, hidden: true },
        { name: "sourceParentId", gql: "String!", id: true, hidden: true },
        { name: "date", gql: "String!" },
        { name: "komitent", gql: "String!", width: 2, nowrap: true, maxWidth: '12vw', fontSize: 10, hidden: selectedKomitent?.id != undefined },
        { name: "srcDocType", gql: "String!", render: (item) => <span style={{ fontWeight: item.srcDocTypeVal.startsWith('BALANCE') ? 700 : 400 }}>{item.srcDocType}</span> },
        { name: "srcParentDoc", gql: "String!", width: 0.7, default: new Date().getFullYear() },
        { name: "srcDoc", gql: "String!", width: 0.7 },
        { name: "targetDocType", gql: "String!" },
        { name: "targetDoc", gql: "String!", width: 0.7 },
        { name: "debit", gql: "Float!", minWidth: 110, render: (item) => renderAmount(item, item.debitVal, item.debit), align: 'right' },
        { name: "credit", gql: "Float!", minWidth: 110, render: (item) => renderAmount(item, item.creditVal, item.credit), align: 'right' },
        { name: "currency", gql: "String!", hidden: true },
        { name: "createdById", gql: "String", default: user.me.id, hidden: true },
        { name: "updatedById", gql: "String", value: user.me.id, hidden: true },
        { name: "created", gql: "Long!", default: new Date().getTime(), hidden: true },
        { name: "updated", gql: "Long!", default: new Date().getTime(), hidden: true }
    ];

    const dateRangeFilter = useDateRangeFilter('last_and_this_month');

    const apiControler = useRef();

    const noTarget = ["BALANCE_SUM", "INITIAL", "TURNOVER", "BALANCE"]
    const itemsAdapter = (items) => items?.map(item => {
        return {
            ...item,
            date: user.formatDate(item.date),
            srcDocType: types[item.srcDocType],
            srcDocTypeVal: item.srcDocType,
            targetDocType: (noTarget.indexOf(item.srcDocType) > -1 || (item.srcDocType == "INVOICE" && item.targetDocType == "NONE")) ? "" : types[item.targetDocType],
            debitVal: item.debit,
            creditVal: item.credit,
            debit: user.formatQuantity(item.debit) + ' ' + item.currency,
            credit: user.formatQuantity(item.credit) + ' ' + item.currency
        }
    });

    const closeItem = () => {
        if (location.state?.from == 'list-document') {
            history.goBack();
        } else {
            history.push(`/restaurant/${restaurant._id}/document`);
        }
    }

    const openCompensation = (id = "new") => {
        const url = `/restaurant/${restaurant._id}/document/compensation/${id}`
        if (id == "new") {
            history.push(url, { ...location.state, from: `list-document` });
        } else {
            history.replace(url, { ...location.state, from: `list-document` });
        }
    }

    const openCession = (id) => {
        const url = `/restaurant/${restaurant._id}/document/cession/${id}`
        if (id == "new") {
            history.push(url, { ...location.state, from: `list-document` });
        } else {
            history.replace(url, { ...location.state, from: `list-document` });
        }
    }

    const onCreateCompensationClick = () => openCompensation("new");
    const onCreateCessionClick = () => openCession("new");

    const onRowClick = (item) => {
        if (item.srcDocTypeVal == "INVOICE") {
            if (item.debit > 0) {
                history.push(`/restaurant/${restaurant._id}/invoice_output/${item.sourceId}`);
            } else {
                history.push(`/restaurant/${restaurant._id}/invoice_input/${item.sourceId}`);
            }
        } else if (item.srcDocTypeVal == "COMPENSATION") {
            history.push(`/restaurant/${restaurant._id}/document/compensation/${item.sourceId}`);
        } else if (item.srcDocTypeVal == "BANK_ACCOUNT") {
            history.push(`/restaurant/${restaurant._id}/bank_statement/${item.sourceParentId}/payment/${item.sourceId}`);
        }
        console.log(item.srcDocTypeVal);
    }

    const onDeleteDocument = (item) => {
        closeItem();
        setDeletedItem({ entityName: documentType.capitalize(), itemId: item.id });
    }

    const showCompensation = documentType?.toUpperCase() == "COMPENSATION";
    const showCession = documentType?.toUpperCase() == "CESSION";

    const isNew = selectedDocumentId?.toLowerCase() == "new";
    const selectedDocument = !isNew && selectedDocumentId;

    const titleDirection = selectedDirection == "CREDIT"
        ? ' / ' + user.translate("outputs")
        : (selectedDirection == "DEBIT" ? ' / ' + user.translate("inputs") : '');

    const titleSufix = <> / {user.formatDate(dateRangeFilter.startDate)} - {user.formatDate(dateRangeFilter.endDate)}{titleDirection}</>

    const columnParents = [];

    if (selectedKomitent?.name) {
        columnParents.push({ title: selectedKomitent?.name, colSpan: 8 });
    }

    return (
        <div className={classes.roots}>
            <div className={classes.options}>
                <KomitentAutoComplete
                    name="komitent"
                    label={"komitent"}
                    value={selectedKomitent?.id}
                    onChange={(id, name) => setSelectedKomitent({ id, name })}
                    onClear={() => setSelectedKomitent()}
                />
                <SelectPaymentDirection
                    name="document_type_io"
                    style={{ width: size.isMobile ? '100%' : 150, marginLeft: 8, marginRight: 8 }}
                    value={selectedDirection}
                    onChange={(value) => setSelectedDirection(value)}
                    options={[
                        { val: "all", name: user.translate('all') },
                        { val: "DEBIT", name: user.translate('inputs') },
                        { val: "CREDIT", name: user.translate('outputs') }
                    ]}
                    showLabel
                    shrink
                />
                <div style={{ width: '100%' }}>{dateRangeFilter.renderHeader()}</div>
            </div>
            <GqlSimpleTable
                apiRef={apiControler}
                customQuery="finance_getAnalyticalCard"
                relFieldName="restaurantId"
                relFieldValue={restaurant.id}
                pathName={(item) => `document/${item.type.toLowerCase()}`}
                fields={FIELDS}
                itemsAdapter={itemsAdapter}
                header={() => titleSufix}
                args={{
                    komitentIds: selectedKomitent?.id ? [selectedKomitent?.id] : [],
                    direction$: selectedDirection == "all" ? null : selectedDirection,
                    restaurantId: "1",
                    dateFrom: dateRangeFilter.startDate.getTime(),
                    dateTo: dateRangeFilter.endDate.getTime()
                }}
                title="analytical_card"
                paging={true}
                exportAllData={true}
                columnParents={columnParents}
                onRowClick={onRowClick}
            />

            <Show if={Boolean(deletedItem)}>
                <SnackBarDelete {...deletedItem} refresh={apiControler.current?.gql?.refresh} onClose={setDeletedItem} />
            </Show>

            <MobileDialog open={Boolean(showCompensation)} onClose={closeItem} title={user.translate(isNew ? "new_compensation" : "compensation")} actionIcon={<Print />}>
                <Show if={showCompensation}>
                    <Compensation
                        itemId={selectedDocument}
                        onCreate={(item) => openCompensation(item.id)}
                        onClose={closeItem}
                        onDelete={onDeleteDocument}
                        refresh={apiControler.current?.gql?.refresh} />
                </Show>
            </MobileDialog>

            <MobileDialog open={Boolean(showCession)} onClose={closeItem} title={user.translate(isNew ? "new_cession" : "cession")} actionIcon={<Print />}>
                <Show if={showCession}>
                    <Compensation
                        itemId={selectedDocument}
                        onCreate={(item) => openCession(item.id)}
                        onClose={closeItem}
                        onDelete={onDeleteDocument}
                        refresh={apiControler.current?.gql?.refresh} />
                </Show>
            </MobileDialog>

        </div>
    )

}