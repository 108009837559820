import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import {
    Snackbar,
} from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SnackBarDeactivate(props) {

    const handleClose = () => {
        props.setOpenDeactivateSnackBar(false);
    };

    return (
        <>
            <Snackbar 
                open={props.openDeactivateSnackBar} 
                autoHideDuration={5000} 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onExited={handleClose}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.deactivated ? "warning" : "success"}>
                    {props.message}
                </Alert>
            </Snackbar>
        </>
    )
}
