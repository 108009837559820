import {gql, NetworkStatus, useMutation, useQuery} from '@apollo/client';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent, CircularProgress,
    IconButton, makeStyles,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core';
import {green, red} from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCloseIcon from '@material-ui/icons/BlockOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ExposureZeroIcon from '@material-ui/icons/ExposureZero';
import PersonIcon from '@material-ui/icons/Person';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ReplayIcon from '@material-ui/icons/Replay';
import SpeedDial from '@material-ui/lab/SpeedDial';
import React, {useContext, useState} from 'react';
import {HideScroll} from "react-hide-on-scroll";
import {useHistory} from "react-router-dom";
import Switch, {Case} from 'react-switch-case';
import {RestaurantContext, UserContext} from "../../App";
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import CustomTheme from '../common/CustomTheme';
import Delete from '../common/Delete';
import InventoryCountingActionDialog from './InventoryCountingActionDialog';
import InventoryCountingDialog from './InventoryCountingDialog';


const useStyles = makeStyles((theme) => ({
    roots: {
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: theme.spacing(1)
        }
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        padding: 0,
        justifyContent: 'space-evenly',
    },
    item: {
        width: '100%',
        maxWidth: 320,
        minWidth: 280,
        margin: theme.spacing(1)
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        '& > *': {
            height: 22,
            display: 'flex',
            alignItems: 'flex-end'
        }
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    iconInactive: {
        marginRight: theme.spacing(1),
        opacity: 0.4
    },
    checkedLabel: {
        marginRight: 0,
        "& .MuiFormControlLabel-label": {
            fontSize: 12
        }
    },
    progress: {
        // paddingLeft: theme.spacing(1),
    },
    stateSection: {
        marginLeft: theme.spacing(2)
    },
    speedDial: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    loading: {
        position: "absolute",
        right: 0,
        top: 0,
        margin: theme.spacing(1)
    }
}));

const DELETE_ITEM = `
mutation ($id: String!) {
    deleteInventoryCounting(id: $id)
}`;

const GQL_INVENTORY_COUNTING_ZERO = gql`
    mutation($id: String!) {
        inventoryCounting_addZeroForNoEntry(inventoryCountingId: $id)
    }
`;

// React Function Component
export default function InventoryCountingList() {

    const classes = useStyles();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const history = useHistory();

    const buttonTheme = {
        primary: {
            main: green[500],
            contrastText: '#fff',
        },
        secondary: {
            main: red[500],
            contrastText: '#fff',
        },
    }

    // const { restaurantId, invoiceId } = useParams();
    const [openInventoryCounting, setOpenInventoryCounting] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [deleteState, setDeleteState] = useState(false);
    const [changeState, setChangeState] = useState(false);

    const [applyZero, {loading: loadingZero}] = useMutation(GQL_INVENTORY_COUNTING_ZERO);

    const applyZeroCounting = (item) => {
        applyZero({
            variables: {
                id: item.id
            }
        }).then(() => refetch());
    }

    const GQL_INVENTORY_COUNTING_LIST = gql`
        query($restaurantId: String!) {
            getInventoryCountingsByContextRestaurantId(restaurantId: $restaurantId) {
                id
                state
                date
                counted
                created
                updated
                warehouse {
                    name {
                        ${user.lang}
                    }
                }
                employee {
                    profile {
                        _id
                        name
                    }
                }
                reviewed
                reviewer {
                    profile {
                        _id
                        name
                    }
                }
                approve
                approver {
                    profile {
                        _id
                        name
                    }
                }
            }
            inventoryCounting_getProgress(restaurantId: $restaurantId) {
                countingId
                stockTypesCount
                stockTypesCounted
            }
        }  
    `

    const {loading, error, data, refetch, networkStatus} = useQuery(GQL_INVENTORY_COUNTING_LIST, {
        pollInterval: 5000,
        variables: {
            restaurantId: restaurant.id
        }
    });

    if (loading && data == undefined) {
        return <CircularProgress/>
    }

    let byState = data?.getInventoryCountingsByContextRestaurantId?.groupBy(x => x.state) || {};
    let progressByCountingId = data?.inventoryCounting_getProgress?.toMapBy(x => x.countingId) || {};

    const onAddClick = () => {
        setOpenInventoryCounting(true);
    }

    const onDeleteClick = (item) => {
        setDeleteState({
            name: item.date,
            variables: {id: item.id}
        })
    }

    const onItemClick = (item) => {
        history.push(`inventory/${item.id}`);
    }


    let states = ["COUNTING", "REVIEWING", "APPROVING", "APPLIED", "REJECTED"].filter(x => byState[x] !== undefined);

    return <div className={classes.root}>
        {Boolean(loading) || networkStatus === NetworkStatus.refetch && <CircularProgress size={24} className={classes.loading}/>}
        {
            states.map(state => (
                <div>
                    <Typography key={`inventory_counting_state_${state}`} align="left" variant="h6" component="h2" className={classes.stateSection}>
                        {user.translate(state)}
                    </Typography>
                    <ul className={classes.list} key={`inventory_counting_list_${state}`}>
                        {
                            byState[state].orderByDesc(item => item.updated).map((item) => {
                                    const progressInfo = progressByCountingId[item.id];
                                    const stockTypesCounted = progressInfo?.stockTypesCounted || 0;
                                    const stockTypesCount = progressInfo?.stockTypesCount || 1;
                                    const progress = (stockTypesCounted * 100 / stockTypesCount).roundDownBy(1);
                                    return (
                                        <Card className={classes.item} key={`inventory_counting_list_${item.id}`}>
                                            <CardActionArea onClick={() => onItemClick(item)}>
                                                <CardContent>
                                                    <div className={classes.title}>
                                                        <Typography align="left" gutterBottom variant="h5" component="h2">
                                                            {new Date(item.date).toLocaleDateString()}
                                                        </Typography>
                                                        <Typography align="left" gutterBottom variant="subtitle2" component="h2">
                                                            {item.warehouse.name[user.lang]}
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.content}>
                                                        <PersonIcon size="small" color="secondary" className={classes.icon}/>
                                                        <Typography variant="body2" color="textSecondary" component="p" style={{flexGrow: 1}}>
                                                            {item.employee.profile.name}
                                                        </Typography>
                                                        <Typography variant="caption" color="textSecondary" component="p">
                                                            {new Date(parseInt(item.created)).toLocaleString()}
                                                        </Typography>

                                                    </div>
                                                    <div className={classes.content}>
                                                        {
                                                            item.reviewed
                                                                ? <CheckCircleIcon size="small" color="primary" className={classes.icon}/>
                                                                : <RadioButtonUncheckedIcon size="small" color="disabled" className={classes.icon}/>
                                                        }
                                                        <Typography variant="body2" color="textSecondary" component="p" style={{flexGrow: 1}}>
                                                            {item.reviewer?.profile?.name || user.translate("not_reviewed")}
                                                        </Typography>
                                                        <Typography variant="caption" color="textSecondary" component="p">
                                                            {
                                                                item.reviewed
                                                                    ? new Date(parseInt(item.reviewed)).toLocaleString()
                                                                    : ""
                                                            }
                                                        </Typography>
                                                    </div>
                                                    <div className={classes.content}>
                                                        {
                                                            item.approve
                                                                ? (
                                                                    state == "REJECTED"
                                                                        ? <CheckCloseIcon size="small" color="primary" className={classes.icon}/>
                                                                        : <CheckCircleIcon size="small" color="primary" className={classes.icon}/>
                                                                )
                                                                : <RadioButtonUncheckedIcon size="small" color="disabled" className={classes.icon}/>
                                                        }
                                                        <Typography variant="body2" color="textSecondary" component="p" style={{flexGrow: 1}}>
                                                            {item.approver?.profile?.name || user.translate("not_approved")}
                                                        </Typography>
                                                        <Typography variant="caption" color="textSecondary" component="p">
                                                            {
                                                                item.approve
                                                                    ? new Date(parseInt(item.approve)).toLocaleString()
                                                                    : ""
                                                            }
                                                        </Typography>
                                                    </div>

                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                &nbsp;&nbsp;
                                                <Tooltip title={`Попишани се ${stockTypesCounted} артикли од вкупно ${stockTypesCount}`} aria-label="Inventory"
                                                         className={classes.progress}>
                                                    <CircularProgressWithLabel value={progress} color="secondary"/>
                                                </Tooltip>
                                                <CustomTheme {...buttonTheme}>
                                                    <Switch condition={state}>
                                                        <Case value="COUNTING">
                                                            <Button onClick={() => setChangeState({...item, newState: "REVIEWING"})} size="small"
                                                                    variant="contained" color="primary" endIcon={<AssignmentTurnedInIcon/>}
                                                                    disabled={!user.me.profile.isAdmin && stockTypesCounted < stockTypesCount}>
                                                                {user.translate("finish")}
                                                            </Button>
                                                            <Button onClick={() => applyZeroCounting(item)} size="small" variant="contained" color="primary"
                                                                    endIcon={<ExposureZeroIcon/>} disabled={stockTypesCounted == stockTypesCount}>
                                                                {loadingZero ? "..." : user.translate("zero")}
                                                            </Button>
                                                        </Case>
                                                        <Case value="REVIEWING">
                                                            <Button onClick={() => setChangeState({...item, newState: "APPROVING"})} size="small"
                                                                    variant="contained" color="primary" endIcon={<DoneIcon/>}>
                                                                {user.translate("approve")}
                                                            </Button>
                                                            <Button onClick={() => setChangeState({...item, newState: "REJECTED"})} size="small" variant="contained"
                                                                    color="secondary" endIcon={<CloseIcon/>}>
                                                                {user.translate("reject")}
                                                            </Button>
                                                        </Case>
                                                        <Case value="APPROVING">
                                                            <Button onClick={() => setChangeState({...item, newState: "APPLIED"})} size="small" variant="contained"
                                                                    color="primary" startIcon={<DoneAllIcon/>}>
                                                                {user.translate("apply")}
                                                            </Button>
                                                            <Button onClick={() => setChangeState({...item, newState: "REJECTED"})} size="small" variant="contained"
                                                                    color="secondary" startIcon={<CloseIcon/>}>
                                                                {user.translate("reject")}
                                                            </Button>
                                                        </Case>
                                                        <Case value="REJECTED">
                                                            <Button onClick={() => setChangeState({...item, newState: "COUNTING"})} size="small" variant="contained"
                                                                    color="secondary" startIcon={<ReplayIcon/>}>
                                                                {user.translate("restart")}
                                                            </Button>
                                                        </Case>
                                                    </Switch>
                                                </CustomTheme>

                                                <IconButton style={{marginLeft: 'auto'}} onClick={() => onDeleteClick(item)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </CardActions>
                                        </Card>
                                    )
                                }
                            )

                        }
                    </ul>
                </div>
            ))
        }

        <HideScroll variant="down">
            <SpeedDial
                ariaLabel="Add Inventory Counting"
                className={classes.speedDial}
                open={false}
                onClick={onAddClick}
                icon={<AddIcon/>}
            />
        </HideScroll>

        {Boolean(deleteState) && (
            <Delete
                name={user.translate("invoice_item") + " " + deleteState.name}
                variables={deleteState.variables}
                setOpenSnackBar={setOpenSnackBar}
                setOpenDeleteBox={setDeleteState}
                deleteItem={DELETE_ITEM}
                onSuccess={refetch}
            />
        )}

        {Boolean(openInventoryCounting) && (
            <InventoryCountingDialog
                item={openInventoryCounting}
                onClose={setOpenInventoryCounting}
                onSuccess={refetch}
            />
        )}

        {Boolean(changeState) && (
            <InventoryCountingActionDialog
                item={changeState}
                onClose={setChangeState}
                onSuccess={refetch}
            />
        )}

        <Snackbar
            key={"item_deleted"}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={() => setOpenSnackBar(false)}
            onExited={() => setOpenSnackBar(false)}
            message={"Пописот е избришан"}
            action={
                <>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={() => setOpenSnackBar(false)}
                    >
                        <CloseIcon/>
                    </IconButton>
                </>
            }
        />

    </div>

}
