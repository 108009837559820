import React, { useContext, useState } from 'react';
import { UserContext } from "../../../App";
import Report from '../../common/Report';

// React Function Component
export default function InventoryOrder(props) {

    const user = useContext(UserContext);
    const [page, setPage] = useState(0);

    const startDate = new Date();
    const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(5, 0, 0, 0);

    let params = {
        query: {
            name: "report_orders",
            dateInit: "$dateFrom: Long!, $dateTo: Long!",
            date: "dateFrom: $dateFrom, dateTo: $dateTo",
            dateVars: {
                dateFrom: startDate.getTime(),
                dateTo: endDate.getTime(),
            },
            results: `
                pos
                prodDep
                warehouse
                orderName
                category
                productName
                quantity
                productCost
                amount
                firstOrderTime
                lastOrderTime
                employee
            `,
            columnsPush: []
        },
        title: user.translate("inventory_order"),
        titleName: user.translate("inventoryorder"),
        // InventoryAdjustments: user.role > 2 ? true : false,
        key: "inventory_order_",
        page: page,
        setPage: setPage,
        datetime: true,
    }

    return (
        <Report {...params} />
    )

}
