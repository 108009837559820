const helper = {
  uid: function () {
    let d = new Date();
    return (
      (d.getTime() * 1000 + d.getMilliseconds()).toString() +
      parseInt(Math.random() * 1000)
        .toString()
        .padStart(3, "0")
    );
  },
  trim: function (str) {
    return str.replace(/\s+/g, "");
  },
  flat: function (arr) {
    return arr.reduce((acc, val) => acc.concat(val), []);
  },
  hash: function (str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      let character = str.charCodeAt(i);
      hash = (hash << 5) - hash + character;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  },
  addAfter: function (obj, index, newItem) {
    return [
      ...Object.entries(obj)
        .slice(0, index)
        .map((entry) => entry[1]),
      newItem,
      ...Object.entries(obj)
        .slice(index)
        .map((entry) => entry[1]),
    ];
  },
  printdiv: function (printpage, noprint, newWindow) {
    Array.from(
      document.querySelectorAll(
        '[class*="MTableToolbar-searchField"],[class*="MTableToolbar-actions"]'
      )
    ).forEach((el) => (el.style.display = "none"));

    if (noprint !== undefined && noprint.length > 0) {
      document.querySelectorAll(`.${noprint}`)?.forEach((el) => {
        if (typeof el.style != "undefined") {
          el.style.display = "none";
        }
      });
    }

    document.body.innerHTML = document.getElementById(printpage).innerHTML;

    window.print();

    if (!("ontouchstart" in window)) {
      setTimeout(() => {
        document.location.reload();
      }, 100);
      return;
    }

    const refreshOnEvent = (event) => {
      document.location.reload();
    };

    setTimeout(() => {
      ["mousemove", "touchmove", "touchstart"].forEach((eventName) =>
        window.addEventListener(eventName, refreshOnEvent)
      );
    }, 500);

    return true;
  },
  groupBy: function (
    OurArray,
    property,
    sortProperty = "id",
    sortType = "numbers"
  ) {
    return OurArray.reduce(function (accumulator, object) {
      // get the value of our object(age in our case) to use for group    the array as the array key
      const key = object[property] !== "" ? object[property] : "NaN";
      // if the current value is similar to the key(age) don't accumulate the transformed array and leave it empty
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      // add the value to the array
      accumulator[key].push(object);
      //sort the array by its key ASC
      accumulator[key].sort(function (a, b) {
        if (sortType === "letters")
          return a[sortProperty].localeCompare(b[sortProperty]);
        else return a[sortProperty] - b[sortProperty];
      });
      // return the transformed array
      return accumulator;
      // Also we also set the initial value of reduce() to an empty object
    }, {});
  },
  findMissingLetter: function (arr, label) {
    // arr.sort((a, b) => a['val'].localeCompare(b['val']));
    let arrLength = arr.length;
    let newVal = String.fromCharCode(
      arr[arrLength - 1]["val"].charCodeAt() + 1
    );

    // we can skip the 1st letter
    for (let i = 1; i < arrLength; i++) {
      // get the char code of the previous letter
      const prev = arr[i - 1]["val"].charCodeAt();
      // get the char code of the current letter
      const current = arr[i]["val"].charCodeAt();

      if (current - prev !== 1) {
        // if the difference between current and previous is not 1
        // get the character after the previous
        newVal = String.fromCharCode(prev + 1);
      }
    }

    arr.push({
      val: newVal,
      label: label + newVal,
    });

    return { arr, newVal }; // if nothing is found
  },
  findMissingNumber: function (arr, start, label = "Pos ", count = 100) {
    let newVal = start;
    for (let i = 0; i <= arr.length; i++) {
      let tempVal = start === 0 ? i : i + 1;
      tempVal = tempVal.toString();

      if (typeof arr[i] === "undefined" || arr[i]["val"] !== tempVal) {
        newVal = start === 0 ? i : i + 1;
        newVal = newVal.toString();
      }
    }

    arr.push({
      val: newVal,
      label: label + newVal,
    });

    return { arr, newVal };
  },
  renderFontIcon: function (icon) {
    icon = icon.replace(/fa_/g, "");

    let solid = "fas"; //far
    if (icon.indexOf("_solid") > -1) solid = "fas";

    icon = icon.replace(/_solid/g, "");
    icon = icon.replace(/_alt/g, "-alt");
    icon = icon.split("_").join("-");

    return [solid, icon];
  },
};

export default helper;
