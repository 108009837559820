import { gql, useMutation } from "@apollo/client"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@material-ui/core"
import React, { useContext } from "react"
import { RestaurantContext, UserContext } from "../../../App"
import Show from "../../common/Show"

export default function StornoInvoiceDialog(props) {
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)

  const [craeteStornaInvoice, { loading, error }] = useMutation(
    gql(`
  mutation ($invoiceId: String!) {
          extras_createStornaInvoice(invoiceId: $invoiceId)
  }`)
  )

  const handleCreateStorna = () => {
    craeteStornaInvoice({
      variables: {
        invoiceId: props.invoiceId,
      },
    })
      .then(() => {
        props.onClose(true)
      })
      .catch((e) => {
        console.log("craeteStornaInvoice", e)
      })
  }

  return (
    <Dialog
      open={Boolean(props.invoiceId)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{user.translate("storn_invoice")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <br />
          <Typography>{user.translate("storn_invoice_message")}</Typography>
          <Show if={Boolean(error)}>
            <br />
            <Typography color="error">{user.translate("storn_invoice_error")}</Typography>
          </Show>
        </DialogContentText>
        <Show if={loading}>
          <br />
          <LinearProgress />
        </Show>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {user.translate("close")}
        </Button>
        <Button onClick={handleCreateStorna} color="primary" disabled={loading} autoFocus>
          {user.translate("storn")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
