import { CircularProgress, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../../App';
import { useEntityContextQuery } from '../../common/useEntityQuery';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-grid',
        margin: theme.spacing(1),
        minWidth: '20ch',
        flexGrow: 1,
        '& > .MuiTextField-root': {
            display: 'grid',
            width: '-webkit-fill-available'
        },
    },
}));

const filterOptions = (options, params) => {
    return options.filter(option =>
        option.name &&
        option.name.cirilicToLatin().indexOf(params.inputValue.cirilicToLatin()) > -1
    );
}

const preventPropagation = (event, isOpen) => {
    if (isOpen == false) return;
    if (event.key == "Enter") {
        event.stopPropagation();
    }
}

function ifnull(...params) {
    return params.find(param => param != undefined || param != null);
}

const callOrGet = (value, ...args) => {
    if (value instanceof Function) return value(...args);
    return value;
}

export default function KomitentAutoComplete(props) {
    const classes = useStyles();

    const user = useContext(UserContext);

    const [isOpen, setIsOpen] = useState(false);

    const komitentsQuery = useEntityContextQuery("Commissioner", [
        { name: "commissioner", gql: "Commissioner", subSelection: { id: true, name: { [user.lang]: true } } }
    ], {
        readOnly: true,
        pollInterval: 30000,
        skip: props.hidden,
        onLoad: props.onLoad
    });

    if (props.hidden) return null;

    const value = ifnull(props.value, callOrGet(props.default, props.item));
    let selected = null;

    const exclude = callOrGet(props.exclude, value, props.item);

    const filteredItems = exclude ? komitentsQuery.items?.filter(item => exclude.indexOf(item.commissioner.id) == -1) : komitentsQuery.items;
    const komitentsOptions = filteredItems?.map(item => {
        const option = { id: item.commissioner.id, name: item.commissioner.name[user.lang] };
        if (option.id == value) {
            selected = option;
        }
        return option;
    }) || [];

    return <Autocomplete
        className={classes.root}
        onOpen={() => { setIsOpen(true); }}
        onClose={() => { setIsOpen(false); }}
        autoHighlight
        selectOnFocus
        fullWidth={props.fullWidth}
        handleHomeEndKeys
        options={komitentsOptions}
        filterOptions={filterOptions}
        value={selected}
        onChange={props.onChange && ((event, value) => props.onChange(value?.id, value?.name))}
        onInputChange={(e, value) => value.length == 0 && props.onClear && props.onClear()}
        onSubmit={props.onSubmit}
        disabled={props.disabled}
        size={props.size}
        onKeyDown={(event) => preventPropagation(event, isOpen)}
        getOptionSelected={(option, value) => value && option.id == value.id}
        getOptionLabel={(option) => option?.name || ''}
        renderInput={(params) => {
            params.inputProps.autoComplete = "off";
            params.inputProps.style = { fontSize: props.size == "small" ? 12 : 14 };
            return <TextField
                {...params}
                label={user.translate(props.label || props.name)}
                error={props.error}
                variant={props.variant || "outlined"}
                size={props.size}
                fullWidth={props.fullWidth}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                        <React.Fragment>
                            {komitentsQuery.isLoading ? <CircularProgress color="inherit" size={props.size == "small" ? 14 : 18} style={{ marginLeft: 4 }} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }} />
        }}
    />

}
KomitentAutoComplete.gql = "String";