import Print from '@material-ui/icons/Print';
import React, { useContext, useRef, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { RestaurantContext, UserContext } from '../../App';
import GqlSimpleTable from '../common/GqlSimpleTable';
import MobileDialog from '../common/MobileDialog';
import Show from '../common/Show';
import useWindowSize from '../common/useWindowSize';
import { BankStatement } from '../payment/BankStatementList';
import Compensation from '../payment/Compensation';


export function InvoicePayments(props) {
    const size = useWindowSize();

    const user = useContext(UserContext);
    const restaurant = useContext(RestaurantContext);
    const history = useHistory();
    const location = useLocation();

    const apiControler = useRef();
    const [selectedDocument, setSelectedDocument] = useState();

    const typeMaxLen = 16;
    const types = {
        compensation: user.translate("compensation").substring(0, typeMaxLen),
        COMPENSATION: user.translate("compensation").substring(0, typeMaxLen),
        cession: user.translate("cession").substring(0, typeMaxLen),
        CESSION: user.translate("cession").substring(0, typeMaxLen),
        bank_account: user.translate("bank_account").substring(0, typeMaxLen),
        BANK_ACCOUNT: user.translate("bank_account").substring(0, typeMaxLen),
        invoice: user.translate("invoice").substring(0, typeMaxLen),
    }

    const renderPayment = (item) => {
        const fontWeight = item.bold ? 'bold' : 'normal';
        const fontSize = item.large ? 16 : undefined;
        return <span style={{ fontWeight, fontSize }}>{item.paid}</span>;
    }

    const PYAMENT_FIELDS = [
        { name: "sourceId", gql: "String!", id: true, hidden: true },
        { name: "sourceParentId", gql: "String!", hidden: true },
        { name: "type", gql: "String!", title: size.isMobile ? "doc" : "document" },
        { name: "year", gql: "Long!", default: new Date().getFullYear(), hidden: true },
        { name: "document", title: size.isMobile ? "doc_num" : "document_number", gql: "String!" },
        { name: "paid", gql: "Float!", render: renderPayment, align: 'right', mobile: false },
    ];

    const itemsAdapter = (items) => items?.map(item => (
        {
            ...item,
            typeVal: item.type,
            type: types[item.type],
            document: item.year ? `${item.document}/${item.year}` : item.document,
            paid: user.formatNumber(item.paid)
        }
    ));

    const onRowClick = (rowData, _, e) => {
        if (e.ctrlKey || e.metaKey) {
            const type = rowData.type?.toLowerCase();
            if (type == "compensation")
                history.push(`/restaurant/${restaurant._id}/document/compensation/${rowData.sourceId}`, { ...location.state, from: `invoice` })
            else if (type == "cession")
                history.push(`/restaurant/${restaurant._id}/document/cession/${rowData.sourceId}`, { ...location.state, from: `invoice` })
            else if (type == "bank_account")
                history.push(`/restaurant/${restaurant._id}/bank_statement/${rowData.sourceParentId}/payment/${rowData.sourceId}`, { ...location.state, from: `invoice` })
        } else {
            setSelectedDocument(rowData);
        }
    }

    const getPreRows = (items) => {
        if (items.length == 0 || !(props.invoiceNumber && props.invoiceAmount)) return [];

        const totalAmount = user.parseStringNumber(props.invoiceAmount);
        return [{ type: "invoice", document: props.invoiceNumber, paid: totalAmount, bold: true }];
    }

    const getTotalRows = (items) => {
        if (items.length <= 1) return [];
        
        const totalPaid = items.slice(1).sumOf(item => item.paid);

        const result = [];

        result.push({ document: user.translate("total_paid"), paid: totalPaid, bold: true });

        if (props.invoiceAmount) {
            const totalAmount = user.parseStringNumber(props.invoiceAmount);
            result.push({ document: user.translate("remained"), paid: totalAmount - totalPaid, large: true });
        }

        return result
    }

    const showCompensation = selectedDocument?.type?.toUpperCase() == "COMPENSATION";
    const showCession = selectedDocument?.type?.toUpperCase() == "CESSION";

    const closeItem = () => {
        setSelectedDocument();
        apiControler.current?.gql?.refresh();
    }

    const onRefresh = () => {
        apiControler.current?.gql?.refresh();
    }

    return <>
        <GqlSimpleTable
            customQuery="finance_getInvoicePayments"
            relFieldName="invoiceId"
            relFieldValue={props.invoiceId}
            pathName={(item) => `document/${item.type.toLowerCase()}`}
            fields={PYAMENT_FIELDS}
            title="payments"
            onRowClick={onRowClick}
            preRows={getPreRows}
            extraRows={getTotalRows}
            maxWidth={640}
            apiRef={apiControler}
            itemsAdapter={itemsAdapter}
        />
        <MobileDialog open={Boolean(showCompensation)} onClose={closeItem} title={user.translate("compensation")} actionIcon={<Print />}>
            <Show if={showCompensation}>
                <Compensation
                    itemId={selectedDocument?.sourceId}
                    onClose={closeItem}
                    refresh={onRefresh} />
            </Show>
        </MobileDialog>

        <MobileDialog open={Boolean(showCession)} onClose={closeItem} title={user.translate("cession")} actionIcon={<Print />}>
            <Show if={showCession}>
                <Compensation
                    itemId={selectedDocument?.sourceId}
                    onClose={closeItem}
                    refresh={onRefresh} />
            </Show>
        </MobileDialog>

        <Show if={selectedDocument?.type?.toUpperCase() == "BANK_ACCOUNT"}>
            <BankStatement itemId={selectedDocument?.sourceParentId} subItemId={selectedDocument?.sourceId} refetch={onRefresh} onClose={closeItem} />
        </Show>

    </>
}
