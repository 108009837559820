import React, {useContext, useState} from "react";
import {UserContext} from "../../App";
import Report from "../common/Report";
import {makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        // '& #table-title': {
        //     maxHeight: 34
        // }
    }
}))
export default function TurnoverReport(props) {
    const classes = useStyles()
    const user = useContext(UserContext);
    const [page, setPage] = useState(0);

    const history = useHistory()

    const startDate = new Date();
    const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(5, 0, 0, 0);

    let params = {
        query: {
            name: "report_revenueExpenditureReport",
            dateInit: "$dateFrom: Long!, $dateTo: Long!",
            date: "dateFrom: $dateFrom, dateTo: $dateTo",
            dateVars: {
                dateFrom: startDate.getTime(),
                dateTo: endDate.getTime(),
            },
            results: `
                id
                timeFrom
                timeTo
                fromTo
                fiscal
                marker
                invoice
                staff
                lost
                meters
                supply
                overhead
                misc
                totalRevenue
                totalExpenses
                totalProfit
            `,
            columnsPush: ["id"],
        },
        title: user.translate("turnover_report"),
        titleName: user.translate("turnoverreport"),
        key: "turnover_report_",
        page: page,
        setPage: setPage,
        datetime: false,
        header: true,
        headerNumbers: true,
        hideHeaderNumbers: true,
        exportOrientation: "landscape",
        handleSelectAccept: (item) => history.push(`turnover_report/${new Date(parseInt((item.timeFrom + item.timeTo) / 2)).toISODate()}`),
        rowStyle: (row) => {
            if (row.isTotal) {
                return {
                    background: "#CCC",
                    fontWeight: 600,
                    height: 24,
                    borderTop: "2px solid #333",
                    borderBottom: "2px solid #333",
                };
            }

            return undefined;
        },
    };

    return <div className={classes.root}><Report {...params} /></div>;
}
