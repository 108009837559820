import React, { useContext, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { RestaurantContext, UserContext } from "../../App";
import { CircularProgress, makeStyles } from "@material-ui/core";
import Table from "../common/Table";
import SnackBarDelete from "../common/SnackBarDelete";
import helper from "../common/Helper";
import tableComponents from "../common/TableComponents";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    "& .MuiTableCell-root .MuiIconButton-root": {
      padding: 3,
    },
  },
  tabs: {
    display: `grid`,
  },
  gridList: {
    flexGrow: 1,
    width: `100%`,
  },
}));

// React Function Component
export default function RestaurantConfigInt(props) {
  const classes = useStyles();

  const restaurant = useContext(RestaurantContext);
  const user = useContext(UserContext);

  const [refetchIt, setRefetchIt] = useState(false);
  const [page, setPage] = useState(0);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [deleteState, setDeleteState] = useState({
    variables: {},
    name: "",
    state: {},
  });

  const EDIT_ITEM = gql`
    mutation (
      $id: String!
      $restaurantId: String!
      $config: String!
      $value: Float!
    ) {
      createRestaurantConfig(
        input: {
          id: $id
          restaurantId: $restaurantId
          config: $config
          value: $value
        }
      ) {
        id
      }
    }
  `;
  const [editItem, { loading: loading_edit }] = useMutation(EDIT_ITEM);

  const DELETE_ITEM = gql`
    mutation ($id: String!) {
      deleteCommissioner(commissionerId: $id)
    }
  `;
  const [deleteItem, { loading: loading_delete }] = useMutation(DELETE_ITEM);
  const UNDELETE_ITEM = `
      mutation ($id: String!) {
        undeleteCommissioner(commissionerId: $id)
    }`;
  // const [unDeleteItem] = useMutation(UNDELETE_ITEM);

  // GraphQL API request definition (local variables: restaurantId)
  const GET_CONFIG_BY_RESTAURANT = gql`
    query ($restaurantId: String!) {
      getRestaurantConfigsByRestaurantId(restaurantId: $restaurantId) {
        id
        value
        config
      }
    }
  `;

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(GET_CONFIG_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 3000,
  });

  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    );
  }

  // In case there is an error, just show it for now
  // if (!data) { user.consoleLog(error); return <p>Error</p> }

  let data_arr = {};
  data_arr = Object.values(data?.getRestaurantConfigsByRestaurantId || []);

  let fieldList = {};

  fieldList["columns"] = [
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "Id",
      field: "id",
      hidden: true,
      export: false,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "config" },
      title: "config",
      field: "id",
      hidden: true,
      export: false,
    },
    {
      title: "",
      field: "count",
      editable: "never",
      cellStyle: {
        width: "1%",
        padding: `0 5px 0 0`,
        fontSize: 12,
        whiteSpace: "pre-line",
      },
    },
    {
      title: user.translate("title"),
      field: "title",
      editable: "never",
      cellStyle: {
        width: "80%",
        padding: `0 5px 0 0`,
        fontSize: 14,
        whiteSpace: "pre-line",
      },
      customFilterAndSearch: (filterValue, row) => {
        return (
          (row.config &&
            row.config.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) >
              -1) ||
          (row.title &&
            row.title.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) >
              -1) ||
          (row.value?.cirilicToLatin &&
            row.value.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) >
              -1)
        );
      },
      customSort: (a, b) =>
        a.title
          .cirilicLatinCompare(user.lang)
          .localeCompare(b.title.cirilicLatinCompare(user.lang), user.lang),
    },
    {
      title: user.translate("value"),
      field: "value",
      align: "left",
      render: (rowData) => {
        return rowData.type === "boolean"
          ? tableComponents.BooleanRender(rowData, "value")
          : rowData.type === "percent"
          ? user.formatNumber(rowData.value, true)
          : rowData.value;
      },
      editComponent: (props) => {
        return props.rowData.type === "boolean"
          ? tableComponents.BooleanEditComponent(
              props,
              "value",
              "value",
              classes
            )
          : props.rowData.type === "integer"
          ? tableComponents.NumericEditComponent(props, "value", true)
          : tableComponents.NumericEditComponent(props, "value", false, 4);
      },
      cellStyle: {
        width: "10%",
        padding: `0 5px 0 0`,
        fontSize: 12,
        whiteSpace: "pre-line",
      },
    },
  ];

  var ConfigType = {
    BOOLEAN: "boolean",
    DECIMAL: "decimal",
    PERCENT: "percent",
    INTEGER: "integer",
  };

  let defaultResults = [
    {
      id: helper.uid(),
      title: user.translate("MAX_DISCOUNT_PERCENT"),
      config: "MAX_DISCOUNT_PERCENT",
      type: ConfigType.PERCENT,
      value: 0.5,
    },
    {
      id: helper.uid(),
      title: user.translate("MAX_DISCOUNT_FIXED"),
      config: "MAX_DISCOUNT_FIXED",
      type: ConfigType.DECIMAL,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("DISCOUNT_EMPLOYEE"),
      config: "DISCOUNT_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("DISCOUNT_MANAGER"),
      config: "DISCOUNT_MANAGER",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CUSTOMER_DISCOUNT_EMPLOYEE"),
      config: "CUSTOMER_DISCOUNT_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CUSTOMER_DISCOUNT_MANAGER"),
      config: "CUSTOMER_DISCOUNT_MANAGER",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("PIN_CODE_ENABLED"),
      config: "PIN_CODE_ENABLED",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("PIN_CODE_MIN_LEN"),
      config: "PIN_CODE_MIN_LEN",
      type: ConfigType.INTEGER,
      value: 6.0,
    },
    {
      id: helper.uid(),
      title: user.translate("QR_CODE_ENABLED"),
      config: "QR_CODE_ENABLED",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("QR_CODE_MIN_LEN"),
      config: "QR_CODE_MIN_LEN",
      type: ConfigType.INTEGER,
      value: 6.0,
    },
    {
      id: helper.uid(),
      title: user.translate("RFID_CODE_ENABLED"),
      config: "RFID_CODE_ENABLED",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_ORDER_EMPLOYEE"),
      config: "CANCEL_ORDER_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_ORDER_SHIFT_MANAGER"),
      config: "CANCEL_ORDER_SHIFT_MANAGER",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_ORDER_MANAGER"),
      config: "CANCEL_ORDER_MANAGER",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_ORDER_OWNER"),
      config: "CANCEL_ORDER_OWNER",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_RESERVATION_EMPLOYEE"),
      config: "CANCEL_RESERVATION_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_RESERVATION_MANAGER"),
      config: "CANCEL_RESERVATION_MANAGER",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_RECEIPT_EMPLOYEE"),
      config: "CANCEL_RECEIPT_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_RECEIPT_SHIFT_MANAGER"),
      config: "CANCEL_RECEIPT_SHIFT_MANAGER",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_RECEIPT_MANAGER"),
      config: "CANCEL_RECEIPT_MANAGER",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CANCEL_RECEIPT_OWNER"),
      config: "CANCEL_RECEIPT_OWNER",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("MANAGE_TABLES_EMPLOYEE"),
      config: "MANAGE_TABLES_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("MANAGE_TABLE_QR_EMPLOYEE"),
      config: "MANAGE_TABLE_QR_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("TICKET_PRINT_POS"),
      config: "TICKET_PRINT_POS",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    // { id: helper.uid(), title: user.translate('TICKET_PRINT_WAREHOUSE'), config: 'TICKET_PRINT_WAREHOUSE', type: ConfigType.BOOLEAN, value: 1.0 },
    {
      id: helper.uid(),
      title: user.translate("ORDER_PRODUCTION_STATE"),
      config: "ORDER_PRODUCTION_STATE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("ORDER_DELIVERY_STATE"),
      config: "ORDER_DELIVERY_STATE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("PRINT_SELF_INVOICE_RECEIPT"),
      config: "PRINT_SELF_INVOICE_RECEIPT",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("RESTRICTED_EMPLOYEE_SETTLEMENT"),
      config: "RESTRICTED_EMPLOYEE_SETTLEMENT",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("MANAGE_RECEIPTS_EMPLOYEE"),
      config: "MANAGE_RECEIPTS_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("TOTAL_ONLY_SETTLEMENT"),
      config: "TOTAL_ONLY_SETTLEMENT",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("EDGE_DEVICE_LOCAL_NETWORK_ONLY"),
      config: "EDGE_DEVICE_LOCAL_NETWORK_ONLY",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("DEFAULT_RECEIPT_INVOICE_TYPE"),
      config: "DEFAULT_RECEIPT_INVOICE_TYPE",
      type: ConfigType.INTEGER,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("DECREASE_ORDER_QUANTITY_BY_HALF"),
      config: "DECREASE_ORDER_QUANTITY_BY_HALF",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("MIN_DECREASE_ORDER_QUANTITY"),
      config: "MIN_DECREASE_ORDER_QUANTITY",
      type: ConfigType.DECIMAL,
      value: 0.5,
    },
    {
      id: helper.uid(),
      title: user.translate("MIN_ORDER_QUANTITY"),
      config: "MIN_ORDER_QUANTITY",
      type: ConfigType.DECIMAL,
      value: 0.1,
    },
    {
      id: helper.uid(),
      title: user.translate("ALLOWED_ORDER_FRACTION"),
      config: "ALLOWED_ORDER_FRACTION",
      type: ConfigType.DECIMAL,
      value: 10.0,
    },
    {
      id: helper.uid(),
      title: user.translate("STAFF_ORDERS"),
      config: "STAFF_ORDERS",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("EMPLOYEE_AUTO_LOGOUT"),
      config: "EMPLOYEE_AUTO_LOGOUT",
      type: ConfigType.INTEGER,
      value: 100.0,
    },
    {
      id: helper.uid(),
      title: user.translate("EMPLOYEE_DAILY_CLOSURE"),
      config: "EMPLOYEE_DAILY_CLOSURE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("RECEIPT_NOTE"),
      config: "RECEIPT_NOTE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("KIOSK_MODE"),
      config: "KIOSK_MODE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("DEP_ALERT_NO_NET_INTERVAL_S"),
      config: "DEP_ALERT_NO_NET_INTERVAL_S",
      type: ConfigType.DECIMAL,
      value: 8.0,
    },
    {
      id: helper.uid(),
      title: user.translate("DEP_ALERT_NEW_ORDER_INTERVAL_S"),
      config: "DEP_ALERT_NEW_ORDER_INTERVAL_S",
      type: ConfigType.DECIMAL,
      value: 13.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CROSS_EMPLOYEE_RECEIPT_ORDERING"),
      config: "CROSS_EMPLOYEE_RECEIPT_ORDERING",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CLOSE_RECEIPT_CHANGE_EMPLOYEE"),
      config: "CLOSE_RECEIPT_CHANGE_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("PRINT_INVOICE_RECEIPT_DUPLICATE"),
      config: "PRINT_INVOICE_RECEIPT_DUPLICATE",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("AGR_PROD_TICKETS_BY_PRINTER"),
      config: "AGR_PROD_TICKETS_BY_PRINTER",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("EMPLOYEE_AUTO_LOGOUT_ON_CLOSE"),
      config: "EMPLOYEE_AUTO_LOGOUT_ON_CLOSE",
      type: ConfigType.DECIMAL,
      value: -1,
    },
    {
      id: helper.uid(),
      title: user.translate("EMPLOYEE_AUTO_LOGOUT_ON_ORDER"),
      config: "EMPLOYEE_AUTO_LOGOUT_ON_ORDER",
      type: ConfigType.DECIMAL,
      value: -1,
    },
    {
      id: helper.uid(),
      title: user.translate("EMPLOYEE_AUTO_LOGOUT_ON_MENU"),
      config: "EMPLOYEE_AUTO_LOGOUT_ON_MENU",
      type: ConfigType.DECIMAL,
      value: -1,
    },

    {
      id: helper.uid(),
      title: user.translate("FISCAL_PRINT_TABLE"),
      config: "FISCAL_PRINT_TABLE",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("FISCAL_PRINT_WAITER"),
      config: "FISCAL_PRINT_WAITER",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("SHOW_MENU_ITEM_INFO"),
      config: "SHOW_MENU_ITEM_INFO",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("TICKET_ORDINAL_NUMBER"),
      config: "TICKET_ORDINAL_NUMBER",
      type: ConfigType.INTEGER,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CHANGE_RECEIPT_EMPLY_BY_EMPLOYEE"),
      config: "CHANGE_RECEIPT_EMPLY_BY_EMPLOYEE",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("CHANGE_ORDER_EMPLOYEE_ON_RECEIPT"),
      config: "CHANGE_ORDER_EMPLOYEE_ON_RECEIPT",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("FISCAL_INTEGRITY_CHECK"),
      config: "FISCAL_INTEGRITY_CHECK",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    },
    {
      id: helper.uid(),
      title: user.translate("RECEIPT_CUSTOMER_COUNT"),
      config: "RECEIPT_CUSTOMER_COUNT",
      type: ConfigType.BOOLEAN,
      value: 0.0,
    },
    {
      id: helper.uid(),
      title: user.translate("IDLE_TIMEOUT"),
      config: "IDLE_TIMEOUT",
      type: ConfigType.INTEGER,
      value: 5.0,
    },
  ];

  if (user.me.profile.isAdmin) {
    defaultResults.unshift({
      id: helper.uid(),
      title: user.translate("ORDERING_UNLOCKED"),
      config: "ORDERING_UNLOCKED",
      type: ConfigType.BOOLEAN,
      value: 1.0,
    });
  }

  let results = data?.getRestaurantConfigsByRestaurantId || [];

  fieldList["data"] = defaultResults.map((defaultResult, index) => {
    let result = results.find((c) => c.config === defaultResult.config);

    return {
      ...defaultResult,
      count: index + 1 + ".",
      id: result ? result.id : defaultResult.id,
      value: result ? result.value : defaultResult.value,
    };
  });

  const setItems = (dataObj, action) => {
    let items = {};

    items = {
      ...dataObj,
      restaurantId: restaurant.id,
    };

    return items;
  };

  const onAddItem = (newData) => {
    let items = setItems(newData, "add");

    setPage(Math.round((data.getCommissionersByRestaurantId.length + 1) / 50));
    editItem({ variables: items });
    setRefetchIt(true);
  };

  const onEditItem = (newData) => {
    let items = setItems(newData, "edit");

    editItem({ variables: items });
    setRefetchIt(true);
  };

  const onDeleteItem = (oldData, state) => {
    let items = {};
    items["id"] = oldData.id;

    deleteItem({ variables: items });

    setDeleteState({
      variables: items,
      name: oldData["config"],
      state: state,
    });

    setOpenSnackBar(true);
    setRefetchIt(true);
  };

  if (!loading && !loading_edit && !loading_delete && refetchIt) {
    refetch();
    setRefetchIt(false);
  }

  return (
    <div className={classes.root}>
      <Table
        key={helper.hash(user.lang + page)}
        tableName={user.translate("restaurant_config")}
        exportFileName={user.translate("restaurantconfig")}
        fieldList={fieldList}
        pageSize="100"
        pageSizeOptions={[50, 100, 250]}
        // onAddItem={onAddItem}
        onEditItem={onEditItem}
        onDeleteItem={onDeleteItem}
        page={page}
      />

      {openSnackBar && (
        <SnackBarDelete
          message={`${deleteState.name} 
                    ${user.translate("deleted")}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}
    </div>
  );
}
