import { gql, useMutation } from '@apollo/client';
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, makeStyles
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { RestaurantContext, UserContext } from "../../App";

const useStyles = makeStyles((theme) => ({
    roots: {
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: theme.spacing(1)
        }
    },
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        padding: 0,
        justifyContent: 'space-evenly',
    },
    item: {
        width: '100%',
        maxWidth: 320,
        minWidth: 280,
        margin: theme.spacing(1)
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        '& > *': {
            height: 22,
            display: 'flex',
            alignItems: 'flex-end'
        }
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    iconInactive: {
        marginRight: theme.spacing(1),
        opacity: 0.4
    },
    checkedLabel: {
        marginRight: 0,
        "& .MuiFormControlLabel-label": {
            fontSize: 12
        }
    },
    progress: {
        // paddingLeft: theme.spacing(1),
    },
    stateSection: {
        marginLeft: theme.spacing(2)
    },
    speedDial: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    loading: {
        position: "absolute",
        left: 0,
        bottom: 0,
        margin: theme.spacing(2)
    },
    dialogContent: {
        minWidth: 330,
        [theme.breakpoints.down('sm')]: {
            minWidth: 220
        },
    }
}));

const GQL_INVENTORY_COUNTING_UPDATE_STATE = gql`
    mutation($id: String!, $state: String!, $withWarehouseId: String!) {
        inventoryCounting_updateState(inventoryCountingId: $id, state: $state, withWarehouseId: $withWarehouseId)
    }
`

// React Function Component
export default function InventoryCountingActionDialog(props) {
    const classes = useStyles();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const history = useHistory();

    const [updateState, { loading: loadingUpdate, called: called }] = useMutation(GQL_INVENTORY_COUNTING_UPDATE_STATE);

    const handleClose = () => {
        props.onClose(false);
    };

    const onSaveClick = () => {
        let vars = { ...props.item, state: props.item.newState, withWarehouseId: props.item.actionOption || 0 };
        // if(props.item.newState == "COUNTING") {

        // }

        updateState({
            variables: vars
        })
        .then(e => props.onSuccess())
        .then(() => {
            props.onClose(false);
            // history.push(`./inventory`);
            // history.go(0);
        });
    }

    const messages = user.translate(`counting_next_state_${props.item.newState.toUpperCase()}`).split("\n");



    return <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{user.translate("edit")} {user.translate("inventory_counting")}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText id="alert-dialog-description">
                {messages[0]}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" color="error" >
                <small>{messages[1]}</small>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {Boolean(loadingUpdate) && <CircularProgress size={24} className={classes.loading} />}
            <Button onClick={handleClose} color="primary" disabled={loadingUpdate || called}>
                {user.translate("cancel")}
            </Button>
            <Button onClick={onSaveClick} color="primary" autoFocus disabled={loadingUpdate || called}>
                {user.translate("save")}
            </Button>
        </DialogActions>
    </Dialog>

}


