import React, {useContext, useState} from 'react';
import {useParams} from "react-router-dom";
import {UserContext} from "../../App";
import {useMutation, gql} from "@apollo/client";
import Report from '../common/Report';
import helper from '../common/Helper';

// React Function Component
export default function PltReport(props) {

    const user = useContext(UserContext);

    const {restaurantId, receivingId} = useParams();

    const [page, setPage] = useState(0);
    const [print, setPrint] = useState(false);
    const [editIt, setEditIt] = useState(false);
    const [printIt, setPrintIt] = useState(false);
    const [refetchIt, setRefetchIt] = useState(false);

    let startDate = new Date(), endDate = new Date();
    startDate.setMonth(startDate.getMonth(), 1); //the first of this month
    // endDate.setMonth(endDate.getMonth(), 0); //- the last day of last month
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23,59, 59);

    const EDIT_RECEIVING_STOCK = gql`
        mutation ($receivingStockId: String!, $lastTimePrinted: String!) {
            editReceivingStock(input: {
                id: $receivingStockId
                lastTimePrinted: $lastTimePrinted
            }) {
                id
            }
    }`;
    const [editReceivingStock, {loading: loading_edit}] = useMutation(EDIT_RECEIVING_STOCK);

    const onPrintPlt = () => {

        let items = {
            receivingStockId: receivingId,
            lastTimePrinted: new Date().getTime()
        }

        editReceivingStock({variables: items});

        setEditIt(true);
    };

    if (!loading_edit && editIt) {
        setEditIt(false);
        setPrintIt(true);
        setRefetchIt(true);
    }

    if (printIt && !editIt && !refetchIt) {
        setPrintIt(false);
        Array.from(document.getElementsByClassName('MuiToolbar-root')).forEach(a => a.style.display = 'none')
        setPrint(helper.printdiv("printDiv", "no_print"));
        Array.from(document.getElementsByClassName('MuiToolbar-root')).forEach(a => a.style.display = 'flex')
    }

    let params = {
        query: {
            name: "report_stockReceivingPLT",
            receivingIdInit: "$receivingId: String!, ",
            receivingId: "receivingId: $receivingId, ",
            dateInit: "$dateFrom: Long!, $dateTo: Long!",
            date: "dateFrom: $dateFrom, dateTo: $dateTo",
            dateVars: {
                dateFrom: "0",//startDate.getTime(),
                dateTo: endDate.getTime(),
            },
            receivingIdVars: {
                receivingId: receivingId
            },
            results: `
                id
                stockTypeId
                stockType
                uom
                quantity
                itemPrice
                totalPrice
                totalTax
                unitTax
                tax
                taxRate
                itemSellPrice
                totalSellPrice
                totalSellTax
                totalPriceWithTax
                totalSellPriceWithTax
                totalFee
                totalFeeWithTax
                totalTaxDiff
                receivingCreated
                itemCreated
                sendingNumber
                warehouseId
                warehouse
                senderId
                sender
                invoiceNumber
                receivingNumber
            `,
            columnsPush: ["id", "stockTypeId", "senderId", "warehouseId"]
        },
        title: user.translate("plt_report"),
        titleName: user.translate("pltreport"),
        page: page,
        setPage: setPage,
        dataLimit: 200,
        print: print,
        isPrint: printIt,
        setPrint: setPrint,
        receivingId: receivingId,
        onPrintPlt: onPrintPlt,
        refetchIt: refetchIt,
        setRefetchIt: setRefetchIt,
        loading_edit: loading_edit,
        key: "plt_report_" + refetchIt,
    }

    if (print) {
        window.location.reload();
    }

    return (
        <div id="printDiv">
            {!loading_edit && (
                <>
                    <Report {...params} noTax />

                    <div style={{width: "100%", clear: "both", marginTop: 60, display: "inline-block", textAlign: "center"}}>
                        <div style={{width: "33%", float: "left", position: "relative"}}>
                            <hr style={{width: "90%", marginTop: 40, marginLeft: 16, marginRight: 16}}/>
                            {user.translate("seal")}</div>
                        <div style={{width: "33%", float: "left", position: "relative"}}> &nbsp;</div>
                        <div style={{width: "33%", float: "left", position: "relative"}}>
                            <hr style={{width: "90%", marginTop: 40, marginLeft: 16, marginRight: 16}}/>
                            {user.translate("authorized_person_signature")}</div>
                    </div>
                </>
            )}
        </div>
    )

}
