import {
  alpha,
  AppBar,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext } from "react";
import config from "../../../../config";
import { UserContext } from "../../../App";
import helper from "../../common/Helper";
import InvoiceItemReceiptView from "./InvoiceItemReceiptView";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
  },
  appBar: {
    position: "relative",
  },
  gridItem: {
    maxWidth: 290,
    minWidth: 240,
    flexGrow: 1,
  },
  itemName: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
      cursor: `pointer`,
    },
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 600,
    height: 40,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InvoiceItemReceiptList(props) {
  const classes = useStyles();
  // Get current user preferences (language)
  const user = useContext(UserContext);
  // const restaurant = useContext(RestaurantContext);
  const itemReceipts = !props.receiptId ? props.itemReceipts : false;
  const receiptId = props.receiptId || false;

  const open = props.openReceiptView ? true : false;

  const onToggleClose = () => {
    props.setOpenReceiptView(false);
  };

  const onCreateInvoice = () => {
    document.location = `${config.apiUrl}/tools/invoiceFromReceipt?receiptId=${receiptId}`;
  };

  return (
    <div className={`${classes.root}`}>
      <Dialog
        open={open}
        // fullScreen
        maxWidth="md"
        onClose={onToggleClose}
        onExit={onToggleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
        fullWidth={true}
      >
        <AppBar className={`${classes.appBar}`}>
          <Toolbar fullWidth={true}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onToggleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {open &&
          !receiptId &&
          itemReceipts.map((receipt) => (
            <InvoiceItemReceiptView
              key={helper.hash(JSON.stringify(receipt))}
              receipt={receipt}
              invoice={props.invoice}
              onCreateInvoice={onCreateInvoice}
              onToggleClose={onToggleClose}
            />
          ))}
        {open && receiptId && (
          <InvoiceItemReceiptView
            key={receiptId}
            receiptId={receiptId}
            onCreateInvoice={onCreateInvoice}
            onToggleClose={onToggleClose}
          />
        )}
      </Dialog>
    </div>
  );
}
