import { gql, useQuery } from "@apollo/client"
import DateFnsUtils from "@date-io/date-fns"
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import SubtitlesIcon from "@material-ui/icons/Subtitles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { DateRangePickerComponent } from "materialui-daterange-picker"
import React, { useContext, useState } from "react"
import { RestaurantContext, UserContext } from "../../App"
import KomitentListDialog from "./KomitentListDialog"

const useStyles = makeStyles((theme) => ({
  gridItem: {
    flexGrow: 0,
    marginBottom: 25,
    textAlign: `center`,
    fontSize: 14,
    justifyContent: `center`,
  },
  formRow: {
    flexGrow: 0,
    marginBottom: 25,
    textAlign: `center`,
    marginTop: 25,
    fontSize: 14,
    justifyContent: `center`,
  },
  formControlKomitents: {
    minWidth: 150,
    width: `auto`,
    margin: `15px ${theme.spacing(2)}px 0`,
    float: "left",
  },
  formControl: {
    minWidth: 150,
    width: `auto`,
    margin: `10px ${theme.spacing(2)}px 0`,
    float: "left",
  },
  formControlDate: {
    minWidth: 150,
    width: `auto`,
    margin: `10px ${theme.spacing(2)}px 0`,
    float: "left",
    paddingLeft: theme.spacing(3),
  },
  formControlAutocomplete: {
    minWidth: 250,
    width: `auto`,
    margin: `10px ${theme.spacing(2)}px 0`,
  },
  formControlPrint: {
    minWidth: 250,
    width: `auto`,
    margin: `0`,
  },
  dateRange: {
    position: `absolute`,
    marginTop: `60px`,
    zIndex: `999`,
  },
}))

export default function InvoiceHeader(props) {
  const classes = useStyles()
  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)

  let statusOptions = [
    {
      status: 0,
      title: user.translate("created"),
    },
    {
      status: 1,
      title: user.translate("open"),
    },
    {
      status: 2,
      title: user.translate("paid"),
    },
    {
      status: 3,
      title: user.translate("storna"),
    },
  ]
  let typeOptions = [
    {
      type: 0,
      title: user.translate("none"),
    },
    {
      type: 1,
      title: user.translate("material"),
    },
    {
      type: 2,
      title: user.translate("immaterial"),
    },
    {
      type: 3,
      title: user.translate("both_group"),
    },
  ]

  const [commissioners, setCommissioners] = useState([])
  const [open, setOpen] = useState(false)
  const [openSelectBox, setOpenSelectBox] = useState(false)
  const [gotIt, setGotIt] = useState(false)

  // GraphQL API request definition (local variables: restaurantId)
  const GET_COMMISSIONERS_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
        getCommissionersByRestaurantId(restaurantId: $restaurantId) {
            commissioner {
                id
                name {
                    ${user.gqlFetchName()}
                }
            }
        }
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data: data_commissioners, loading: loading_commissioners } = useQuery(GET_COMMISSIONERS_BY_RESTAURANT, {
    variables: { restaurantId: restaurant.id },
    skip: !open && !gotIt,
  })

  if (data_commissioners && open && commissioners.length === 0 && !gotIt) {
    let _commissioners = data_commissioners.getCommissionersByRestaurantId.map((item) => ({
      ...item.commissioner,
      name: item.commissioner.name[user.lang],
    }))
    setCommissioners(_commissioners)
    setGotIt(true)
  }

  const [filterState, setFilterState] = useState({ ...props.filterState })

  const [openDateRange, setOpenDateRange] = useState(false)

  const dateRange = {
    startDate: filterState.selectedDateFrom,
    endDate:
      filterState.selectedDateTo &&
      filterState.selectedDateFrom &&
      new Date(Math.max(filterState.selectedDateTo.getTime(), filterState.selectedDateFrom.getTime())),
  }

  const toggleDateRangeOpen = () => setOpenDateRange(!openDateRange)
  const closeDateRange = () => setOpenDateRange(false)

  // const filterOptions = createFilterOptions({
  //     matchFrom: 'any',
  //     stringify: option => option.name.cirilicToLatin(),
  // });

  const filterOptions = (options, params) =>
    options.filter(
      (option) => option.name && option.name.cirilicToLatin().indexOf(params.inputValue.cirilicToLatin()) > -1
    )

  const handleChangeFilter = (event, value) => {
    let _filters = filterState.filters
    if (typeof value !== "undefined") {
      _filters["company"] = value
      setFilterState({ ...filterState, filters: _filters })
    } else {
      _filters[event.target.name] = event.target.value
      setFilterState({ ...filterState, filters: _filters })
    }
    props.setQuery(filterState, commissioners)
  }

  const handleDateFromChange = (date) => {
    if (date != "Invalid Date") {
      let filState = Object.assign(filterState)
      filState.selectedDateFrom = date
      if (filState.selectedDateTo.getTime() < date.getTime() && date.getTime() < 4102441200000) {
        filState.selectedDateTo = new Date(date)
      }
      setFilterState(filState)
      let _range = {
        startDate: date,
        endDate: filState.selectedDateTo,
      }
      // setDateRange(_range);
      // props.setQuery(filState);
    }
  }
  const handleDateToChange = (date) => {
    if (date != "Invalid Date") {
      let filState = Object.assign(filterState)
      let dateTo = new Date(date)
      dateTo.setHours("23")
      dateTo.setMinutes("59")
      dateTo.setSeconds("59")

      filState.selectedDateTo = dateTo
      if (filState.selectedDateFrom.getTime() > dateTo.getTime() && dateTo.getTime() < 4102441200000) {
        filState.selectedDateFrom = new Date(dateTo)
      }
      setFilterState(filState)
      props.setFilterState(filState)
      let _range = {
        startDate: filState.selectedDateFrom,
        endDate: dateTo,
      }
      // setDateRange(_range);
      // props.setQuery(filState);
    }
  }

  const handleDateRangeAccept = (range) => {
    let filState = Object.assign(filterState)
    let dateTo = new Date(range.endDate)
    dateTo.setHours("23")
    dateTo.setMinutes("59")
    dateTo.setSeconds("59")
    filState.selectedDateFrom = range.startDate
    filState.selectedDateTo = dateTo
    setFilterState(filState)
    // setDateRange({
    //     startDate: range.startDate,
    //     endDate: dateTo
    // });
    props.setQuery(filState)
    closeDateRange()
  }

  const onKomissionersClick = () => {
    setOpenSelectBox(true)
  }

  let company =
    typeof filterState.filters["company"] !== "undefined" && filterState.filters["company"] !== null
      ? filterState.filters["company"]
      : ""
  let status =
    typeof filterState.filters["status"] !== "undefined" && filterState.filters["status"] !== null
      ? filterState.filters["status"]
      : ""
  let type =
    typeof filterState.filters["type"] !== "undefined" && filterState.filters["type"] !== null
      ? filterState.filters["type"]
      : ""

  return (
    <>
      <FormGroup row className={classes.formRow}>
        <div className={classes.formControlKomitents}>
          <Button
            style={{ margin: "12px auto" }}
            color="primary"
            component="span"
            onClick={onKomissionersClick}
            startIcon={<SubtitlesIcon />}
          >
            {user.translate("komitents")}
          </Button>
        </div>

        {
          <FormControl className={classes.formControlAutocomplete}>
            <Autocomplete
              id="filter_company"
              name="companyId"
              open={open}
              onOpen={() => {
                setOpen(true)
              }}
              onClose={() => {
                setOpen(false)
              }}
              loading={loading_commissioners}
              selectOnFocus
              handleHomeEndKeys
              options={commissioners}
              filterOptions={filterOptions}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => handleChangeFilter(event, value)}
              value={company}
              renderInput={(params) => {
                params.inputProps.autoComplete = "off"
                return (
                  <TextField
                    {...params}
                    label={user.translate("komitents")}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading_commissioners ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )
              }}
            />
          </FormControl>
        }
        {
          <FormControl className={classes.formControl}>
            <InputLabel id={"filter_status_label"}>{user.translate("status")}</InputLabel>
            <Select
              id="filter_status"
              labelId="filter_status_label"
              name="status"
              fullWidth
              onChange={(event) => handleChangeFilter(event)}
              value={status}
            >
              <MenuItem key={"none"} value={null}>
                <em>None</em>
              </MenuItem>
              {statusOptions.map((option) => (
                <MenuItem key={option.status} value={option.status}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        {
          <FormControl className={classes.formControl}>
            <InputLabel id={"filter_type_label"}>{user.translate("invoice_type_filter")}</InputLabel>
            <Select
              id="filter_type"
              labelId="filter_type_label"
              name="type"
              onChange={(event) => handleChangeFilter(event)}
              value={type}
            >
              {typeOptions.map((option) => (
                <MenuItem key={option.type} value={option.type}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }

        <ClickAwayListener onClickAway={closeDateRange}>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="flex-start" className={classes.formControlDate}>
                <KeyboardDatePicker
                  disableToolbar={false}
                  animateYearScrolling={true}
                  autoOk={true}
                  open={false}
                  variant="inline"
                  format={user.dateFormat}
                  id={"filter_date_from"}
                  label={user.translate("date_from")}
                  value={filterState.selectedDateFrom}
                  onClick={toggleDateRangeOpen}
                  onChange={handleDateFromChange}
                  onClose={(e) => props.setQuery(filterState)}
                  onKeyPress={(e) => {
                    if (e.key == "Enter") props.setQuery(filterState)
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="flex-start" className={classes.formControlDate}>
                <KeyboardDatePicker
                  disableToolbar={false}
                  animateYearScrolling={true}
                  autoOk={true}
                  open={false}
                  variant="inline"
                  format={user.dateFormat}
                  id={"filter_date_to"}
                  label={user.translate("date_to")}
                  value={filterState.selectedDateTo}
                  onClick={toggleDateRangeOpen}
                  onChange={handleDateToChange}
                  onClose={(e) => props.setQuery(filterState)}
                  onKeyPress={(e) => {
                    if (e.key == "Enter") props.setQuery(filterState)
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{ position: "start" }}
                />
              </Grid>
            </MuiPickersUtilsProvider>

            <div className={classes.dateRange}>
              <DateRangePickerComponent
                open={openDateRange}
                toggle={toggleDateRangeOpen}
                initialDateRange={dateRange}
                onChange={(range) => handleDateRangeAccept(range)}
              />
            </div>
          </div>
        </ClickAwayListener>
      </FormGroup>

      {openSelectBox && <KomitentListDialog setOpenSelectBox={setOpenSelectBox} openSelectBox={openSelectBox} />}
    </>
  )
}
