import { gql, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import {
  default as AssessmentIcon,
  default as InventoryIcon,
} from "@material-ui/icons/Assessment";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import InternalTransferIcon from "@material-ui/icons/CompareArrows";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import MeterIcon from "@material-ui/icons/DataUsage";
import PosIcon from "@material-ui/icons/DeviceHub";
import DomainIcon from "@material-ui/icons/Domain";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import FormatIndentDecreaseIcon from "@material-ui/icons/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import {
  default as WarehouseIcon,
  default as WarehousingIcon,
} from "@material-ui/icons/House";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import KitchenIcon from "@material-ui/icons/Kitchen";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalConvenienceStoreIcon from "@material-ui/icons/LocalConvenienceStore";
import KomitentIcon from "@material-ui/icons/LocalDining";
import {
  default as LocalShippingIcon,
  default as SendingIcon,
} from "@material-ui/icons/LocalShipping";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SettingsIcon from "@material-ui/icons/Settings";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import StorageIcon from "@material-ui/icons/Storage";
import StaffIcon from "@material-ui/icons/SupervisorAccount";
import TableChartIcon from "@material-ui/icons/TableChart";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useContext, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { RestaurantContext, UserContext } from "./App";
import "./App.css";
import PrimarySearchAppBarDrawer from "./app/AppNavigation";
import RestaurantConfig from "./restaurant/config/RestaurantConfig";
import InventoryCounting from "./restaurant/inventory/InventoryCounting";
import InventoryCountingList from "./restaurant/inventory/InventoryCountingList";
import InvoiceInputItem from "./restaurant/invoice/InvoiceInputItem";
import InvoiceInputList from "./restaurant/invoice/InvoiceInputList";
import InvoiceOutputItem from "./restaurant/invoice/InvoiceOutputItem";
import InvoiceOutputList, { CashInvoiceOutputList } from "./restaurant/invoice/InvoiceOutputList";
import KomitentList from "./restaurant/komitent/KomitentList";
import MenuList from "./restaurant/menu/MenuList";
import RestaurantMenuList from "./restaurant/menus/RestaurantMenuList";
import MeterList from "./restaurant/meter/MeterList";
import AnaliticalCard from "./restaurant/payment/AnaliticalCard";
import BankStatementList from "./restaurant/payment/BankStatementList";
import FinancialDocumentList from "./restaurant/payment/FinancialDocumentList";
import OpenItemsStatement from "./restaurant/payment/OpenItemsStatement";
import PosList from "./restaurant/pos/PosList";
import Normatives from "./restaurant/product/Normatives";
import ProductList from "./restaurant/product/ProductList";
import ProductionCategoryList from "./restaurant/production/ProductionCategoryList";
import ProductionDepartmentList from "./restaurant/production/ProductionDepartmentList";
import EtReport from "./restaurant/report/EtReport";
import EtuReport from "./restaurant/report/EtuReport";
import InventoryMain from "./restaurant/report/inventory/InventoryMain";
import InventoryOrder from "./restaurant/report/inventory/InventoryOrder";
import InventoryCategoryOrder from "./restaurant/report/inventory/InventoryCategoryOrder";
import InventoryMenuItemOrder from "./restaurant/report/inventory/InventoryMenuItemOrder";
import InventoryOutput from "./restaurant/report/inventory/InventoryOutput";
import KdfiReport from "./restaurant/report/KdfiReport";
import ReceiptsReport from "./restaurant/report/ReceiptsReport";
import ReceivingReport from "./restaurant/report/ReceivingReport";
import StaffList from "./restaurant/staff/StaffList";
import { SendingDocumentPrint } from "./restaurant/stock/DocumentPrint";
import PltReport from "./restaurant/stock/PltReport";
import StockList from "./restaurant/stock/StockList";
import StockReceivingItem from "./restaurant/stock/StockReceivingItem";
import StockReceivingList from "./restaurant/stock/StockReceivingList";
import StockReturningItem from "./restaurant/stock/StockReturningItem";
import StockReturningList from "./restaurant/stock/StockReturningList";
import StockSendingItem from "./restaurant/stock/StockSendingItem";
import StockSendingList from "./restaurant/stock/StockSendingList";
import StockTransferList from "./restaurant/stock/StockTransferList";
import NewStockSupplingList from "./restaurant/supply/NewStockSupplingList";
import SupplingList from "./restaurant/supply/SupplingList";
import TableList from "./restaurant/table/TableList";
import WarehouseList from "./restaurant/warehouse/WarehouseList";
import TurnoverReport from "./restaurant/report/TurnoverReport";
import TurnoverReportDate from "./restaurant/report/TurnoverReportDate";
import InventoryMeterReport from "./restaurant/report/InventoryMeterReport";

const GET_NOTIFICATIONS = gql`
  query ($restaurantId: String!) {
    me {
      employeeRestaurantsCount
    }

    getInventoryCountingsByContextRestaurantId(restaurantId: $restaurantId) {
      state
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Main(props) {
  const classes = useStyles();

  const restaurant = useContext(RestaurantContext);
  const user = useContext(UserContext);
  const { data: notifications, loading: loadingNotifications } = useQuery(
    GET_NOTIFICATIONS,
    {
      pollInterval: 10000,
      variables: {
        restaurantId: restaurant.id,
      },
    }
  );

  const notificationsCount = notifications
    ? notifications.me.employeeRestaurantsCount
    : 1;

  const { restaurantId, drawerMenu } = useParams();

  const [notifCount, setNotifCount] = useState(notificationsCount);
  const [openNotifications, setOpenNotifications] = useState(false);
  const handleCloseNotifications = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotifications(false);
  };

  const onNotifications = () => {
    setOpenNotifications(true);
    setNotifCount(0);
  };

  const alertInventoryCountingStates = ["COUNTING", "REVIEWING", "APPROVING"];
  let pendingInventoryCountings =
    notifications?.getInventoryCountingsByContextRestaurantId?.reduce(
      (a, x) => (a += alertInventoryCountingStates.indexOf(x.state) > -1),
      0
    );
  let pendingSupplies = 0;

  const menus = [
    [
      user.me && user.role > 3
        ? {
            key: "config",
            text: user.translate("config"),
            icon: <SettingsIcon />,
          }
        : {},
      { key: "staff", text: user.translate("staff"), icon: <StaffIcon /> },
      user.me && user.role > 2
        ? {
            key: "warehouse",
            text: user.translate("warehouse"),
            icon: <WarehouseIcon />,
          }
        : {},
      user.me && user.role > 2
        ? {
            key: "meters",
            text: user.translate("meters"),
            icon: <MeterIcon />,
            children: [
              {
                key: "meters_report",
                text: user.translate("report"),
                icon: <AssessmentIcon />,
              },
              {
                key: "meter",
                text: user.translate("setup"),
                icon: <ListAltIcon />,
              },
            ],
          }
        : {},
      user.me && user.role > 2
        ? {
            key: "production",
            text: user.translate("production"),
            icon: <HomeWorkIcon />,
            children: [
              {
                key: "production_category",
                text: user.translate("category"),
                icon: <StorageIcon />,
              },
              {
                key: "production_department",
                text: user.translate("department"),
                icon: <DomainIcon />,
              },
            ],
          }
        : {},
      {
        key: "stock_types",
        text: user.translate("stock_items"),
        icon: <LocalConvenienceStoreIcon />,
      },
      user.me && user.role > 0
        ? {
            key: "inventory",
            text: user.translate("inventory_counting"),
            icon: <InventoryIcon />,
            notifications: pendingInventoryCountings,
          }
        : {},
      user.me && user.role > 1
        ? {
            key: "supplies",
            text: user.translate("supplies"),
            icon: <LocalShippingIcon />,
            notifications: pendingSupplies,
            children: [
              {
                key: "new_supply",
                text: user.translate("new_supply"),
                icon: <OpenInNewIcon />,
              },
              {
                key: "supplies",
                text: user.translate("supplies"),
                icon: <StorageIcon />,
              },
            ],
          }
        : {},
      {
        key: "product",
        text: user.translate("products"),
        icon: <ShoppingBasketIcon />,
      },
      { key: "menu", text: user.translate("menu"), icon: <MenuBookIcon /> },
      user.me && user.role > 2
        ? { key: "pos", text: user.translate("pos"), icon: <PosIcon /> }
        : {},
      {
        key: "table",
        text: user.translate("tables"),
        icon: <TableChartIcon />,
      },
      {
        key: "komitent",
        text: user.translate("komitents"),
        icon: <KomitentIcon />,
      },
      // { key: 'tax_type', text: user.translate("tax_type"), icon: <MergeTypeIcon /> },
      user.me && user.role > 2
        ? {
            key: "warehousing",
            text: user.translate("warehouse_documents"),
            icon: <WarehousingIcon />,
            children: [
              {
                key: "stock_receiving",
                text: user.translate("stock_receiving"),
                icon: <PlayForWorkIcon />,
              },
              {
                key: "stock_sending",
                text: user.translate("stock_sending"),
                icon: <SendingIcon />,
              },
              {
                key: "stock_returning",
                text: user.translate("stock_returning"),
                icon: <AssignmentReturnIcon />,
              },
              {
                key: "stock_transfer",
                text: user.translate("stock_transfer"),
                icon: <InternalTransferIcon />,
              },
            ],
          }
        : {},
      user.me && user.role > 2
        ? {
            key: "invoice_output",
            text: user.translate("output_invoices"),
            icon: <FormatIndentDecreaseIcon />,
          }
        : {},
      user.me && user.role > 2
        ? {
            key: "cash_invoice_output",
            text: user.translate("cash_output_invoices"),
            icon: <FormatIndentDecreaseIcon />,
          }
        : {},
      user.me && user.role > 2
        ? {
            key: "invoice_input",
            text: user.translate("input_invoices"),
            icon: <FormatIndentIncreaseIcon />,
          }
        : {},
      user.me && user.role > 2
        ? {
            key: "finance",
            text: user.translate("finance"),
            icon: <AttachMoneyIcon />,
            notifications: 0,
            children: [
              {
                key: "bank_statement",
                text: user.translate("bank_statements"),
                icon: <AccountBalanceIcon />,
              },
              {
                key: "document",
                text: user.translate("documents"),
                icon: <InsertDriveFileIcon />,
              },
              {
                key: "analytical_card",
                text: user.translate("analytical_card").substring(0, 15) + ".",
                icon: <CreditCardIcon />,
              },
              {
                key: "open_items_statement",
                text: user.translate("open_items_statement"),
                icon: <CreditCardIcon />,
              },
            ],
          }
        : {},
    ],
    [
      {
        key: "report",
        text: user.translate("reportsi"),
        icon: <AssessmentIcon />,
        children: [
          {
            key: "kdfi_report",
            text: user.translate("kdfi"),
            icon: <BlurLinearIcon />,
          },
          {
            key: "etu_report",
            text: user.translate("etu"),
            icon: <LowPriorityIcon />,
          },
          {
            key: "et_report",
            text: user.translate("et"),
            icon: <LowPriorityIcon />,
          },
          {
            key: "output_report",
            text: user.translate("outputs"),
            icon: <FormatIndentDecreaseIcon />,
          },
          {
            key: "receiving_report",
            text: user.translate("receiving"),
            icon: <FormatIndentIncreaseIcon />,
          },
          {
            key: "receipts_report",
            text: user.translate("receipts"),
            icon: <ReceiptIcon />,
          },
          {
            key: "order",
            text: user.translate("orders"),
            icon: <FastfoodIcon />,
          },
          {
            key: "category_order",
            text: user.translate("categoryOrders"),
            icon: <FastfoodIcon />,
          },
          {
            key: "product_order",
            text: user.translate("productOrders"),
            icon: <FastfoodIcon />,
          },
          {
            key: "stock",
            text: user.translate("inventory"),
            icon: <KitchenIcon />,
          },
          {
            key: "turnover_report",
            text: user.translate("turnover"),
            icon: <LocalAtmIcon />,
          },
        ],
      },
    ],
  ];

  if (!drawerMenu) {
    return <Redirect to={{ pathname: `/restaurant/${restaurantId}/staff` }} />;
  }

  let title = restaurant.name[user.lang];
  if (user.me.profile && user.me.profile.name) {
    title += ", " + user.me.profile.name;
  }

  return (
    <div>
      <PrimarySearchAppBarDrawer
        title={title}
        notifications={notifCount}
        onNotifications={onNotifications}
        drawer={menus}
        home={"/home"}
        drawerSelected={drawerMenu}
        onSetLanguage={props.onSetLanguage}
        refetch={props.refetch}
      >
        <div className={classes.content}>
          <Switch>
            <Route
              path="/restaurant/:restaurantId/config"
              component={RestaurantConfig}
            />
            <Route
              path="/restaurant/:restaurantId/staff"
              component={StaffList}
            />
            <Route
              path="/restaurant/:restaurantId/komitent"
              component={KomitentList}
            />
            <Route
              path="/restaurant/:restaurantId/table"
              component={TableList}
            />
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/warehouse"
                component={WarehouseList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/meter"
                component={MeterList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/meters_report/:counterId"
                component={InventoryMeterReport}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/meters_report"
                component={InventoryMeterReport}
              />
            )}
            <Route
              path="/restaurant/:restaurantId/inventory"
              exact
              component={InventoryCountingList}
            />
            <Route
              path="/restaurant/:restaurantId/inventory/:inventoryCountingId"
              exact
              component={InventoryCounting}
            />
            <Route
              path="/restaurant/:restaurantId/stock_types"
              component={StockList}
            />
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/supplies"
                exact
                component={SupplingList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/supplies/:stockSupplyId"
                exact
                component={SupplingList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/new_supply"
                component={NewStockSupplingList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/bank_statement"
                exact
                component={BankStatementList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/bank_statement/:statementId"
                exact
                component={BankStatementList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/bank_statement/:statementId/payment/:paymentId"
                exact
                component={BankStatementList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/bank_statement/new"
                component={BankStatementList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/analytical_card"
                exact
                component={AnaliticalCard}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/open_items_statement"
                exact
                component={OpenItemsStatement}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/open_items_statement/:komitentId"
                exact
                component={OpenItemsStatement}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/document"
                exact
                component={FinancialDocumentList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/document/:documentType/:documentId"
                exact
                component={FinancialDocumentList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/stock_receiving"
                component={StockReceivingList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/stock_sending"
                component={StockSendingList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/stock_returning"
                component={StockReturningList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/stock_receiving/:stockReceivingId"
                component={StockReceivingItem}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/stock_sending/:stockSendingId"
                component={StockSendingItem}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/stock_returning/:stockReceivingId"
                component={StockReturningItem}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/stock_transfer"
                component={StockTransferList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/stock_transfer/:stockTransferId"
                component={StockTransferList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/sending_document/:documentId"
                component={SendingDocumentPrint}
              />
            )}
            <Route
              path="/restaurant/:restaurantId/product"
              component={ProductList}
            />
            <Route
              path="/restaurant/:restaurantId/normatives"
              component={Normatives}
            />
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/production_category"
                component={ProductionCategoryList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/production_department"
                component={ProductionDepartmentList}
              />
            )}
            {/* <Route path="/restaurant/:restaurantId/tax_type" component={TaxTypeList} /> */}
            {user.me && user.role > 2 && (
              <Route path="/restaurant/:restaurantId/pos" component={PosList} />
            )}
            <Route path="/restaurant/:restaurantId/menu" component={MenuList} />
            <Route
              path="/restaurant/:restaurantId/menus"
              component={RestaurantMenuList}
            />

            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/invoice_output"
                component={InvoiceOutputList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/invoice_output/:invoiceId"
                component={InvoiceOutputItem}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/cash_invoice_output"
                component={CashInvoiceOutputList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/cash_invoice_output/:invoiceId"
                component={InvoiceOutputItem}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                exact={true}
                path="/restaurant/:restaurantId/invoice_input"
                component={InvoiceInputList}
              />
            )}
            {user.me && user.role > 2 && (
              <Route
                path="/restaurant/:restaurantId/invoice_input/:invoiceId"
                component={InvoiceInputItem}
              />
            )}

            <Route
              path="/restaurant/:restaurantId/turnover_report/"
              component={TurnoverReport}
              exact
            />
            <Route
              path="/restaurant/:restaurantId/turnover_report/:date"
              component={TurnoverReportDate}
            />
            <Route
              path="/restaurant/:restaurantId/kdfi_report/"
              component={KdfiReport}
            />
            <Route
              path="/restaurant/:restaurantId/etu_report/"
              component={EtuReport}
            />
            <Route
              path="/restaurant/:restaurantId/et_report/"
              component={EtReport}
            />
            <Route
              path="/restaurant/:restaurantId/output_report"
              component={InventoryOutput}
            />
            <Route
              path="/restaurant/:restaurantId/receiving_report"
              component={ReceivingReport}
            />
            <Route
              path="/restaurant/:restaurantId/receipts_report"
              component={ReceiptsReport}
            />
            <Route
              path="/restaurant/:restaurantId/order"
              component={InventoryOrder}
            />
            <Route
              path="/restaurant/:restaurantId/category_order"
              component={InventoryCategoryOrder}
            />
            <Route
              path="/restaurant/:restaurantId/product_order"
              component={InventoryMenuItemOrder}
            />
            <Route
              path="/restaurant/:restaurantId/stock"
              component={InventoryMain}
            />
            <Route
              path="/restaurant/:restaurantId/plt_report/:receivingId"
              component={PltReport}
            />
            <Route path="/restaurant/:restaurantId">
              <p>Not implemented {drawerMenu}!</p>
            </Route>
          </Switch>
        </div>
      </PrimarySearchAppBarDrawer>

      <Snackbar
        open={openNotifications}
        autoHideDuration={5000}
        onClose={handleCloseNotifications}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseNotifications} severity="info">
          Welcome to eBar!
        </Alert>
      </Snackbar>
    </div>
  );
}
