import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useContext } from 'react';
import { UserContext } from '../../App';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paper': {
            width: '90vw',
            maxWidth: 360
        }
    }
}));

export default function ProgressDialog(props) {
    const user = useContext(UserContext);

    const classes = useStyles();

    const onCancel = (e) => {
        if (props.onCancel) {
            props.onCancel();
        }
        e.stopPropagation();
    }

    let variant = props.variant || (props.progress < 0 ? "indeterminate" : "determinate");

    return <Dialog
        open={Boolean(props.show)}
        onClose={onCancel}
        className={classes.root}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            <LinearProgress value={props.progress} variant={variant} color={props.color || "primary"} />
        </DialogContent>
        <DialogActions>
            {
                props.cancelButton && (
                    <Button onClick={onCancel} color="default">
                        {user.translate('cancel')}
                    </Button>
                )
            }
        </DialogActions>

    </Dialog>
}
