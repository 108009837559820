import React, { useContext, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { RestaurantContext, UserContext } from "../../App";
import { makeStyles, CircularProgress, Grid } from '@material-ui/core';
import IconAdd from '@material-ui/icons/Add';
import SpeedDial from "@material-ui/lab/SpeedDial";
import PosItem from "./PosItem"
import Add from "../common/Add";
import Delete from '../common/Delete';
import SnackBarDelete from '../common/SnackBarDelete';
import SnackBarWarning from '../common/SnackBarWarning';
import helper from '../common/Helper';

// CSS
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        padding: theme.spacing(2),
    },
    gridList: {
        justifyContent: 'right',
    },
    gridItem: {
        maxWidth: 320,
        minWidth: 300,
        flexGrow: 1,
        marginBottom: theme.spacing(3)
    },
    speedDial: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
}));


// React Function Component
export default function PosList() {
    const classes = useStyles();

    // Get current restaurant from context
    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const [openManipulateBox, setOpenManipulateBox] = useState(false);
    const [openDeleteBox, setOpenDeleteBox] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false);

    const [deleteState, setDeleteState] = useState({
        name: "",
        deleteItem: ``,
        undeleteItem: ``,
        variables: {},
    });

    const onManipulateClick = (e) => {
        setOpenManipulateBox(true);
    }

    const ADD_ITEM = `
        mutation ($id: String!, $restaurantId: String!, $defOrderType: Long!, ${user.me.profile && user.me.profile.isAdmin ? "$nameMk: String!, $nameEn: String!" : "$name: String!"}) {
            createPos(input: {
                id: $id
                ${user.me.profile && user.me.profile.isAdmin ? user.gqlCreateNameTr("$nameMk", "$nameEn") : user.gqlCreateName("$name")}
                defOrderType: $defOrderType
                restaurantId: $restaurantId
            }) {
                id
            }
        }`;

    // GraphQL API request definition (local variables: restaurantId) 
    const GET_POS_BY_RESTAURANT = gql`
        query ($restaurantId: String!) {
            getPossByRestaurantId(restaurantId: $restaurantId) {
                _id
                id
                name {
                    ${user.gqlFetchName()}
                }
                active
                defOrderType
                syncUrl
                posMenus {
                    _id
                    displayIndex
                    menuId
                }
                posProductionDepartments {
                    productionDepartment {
                        id
                        productionCategoryId
                        name {
                            ${user.gqlFetchName()}
                        }
                    }
                }
                defaultInvoiceTitleId
                restaurantTablesCount

                irOrderType
                taOrderType
                ctOrderType
            }
            getRestaurantMenusByRestaurantId(restaurantId: $restaurantId) {
                id
                name {
                    ${user.gqlFetchName()}
                }
            }
            getProductionCategorysByRestaurantId(restaurantId: $restaurantId) {
                id
                name {
                    ${user.gqlFetchName()}
                }
                productionDepartments{
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
            }
        }`;

    // Make the api request or get cached.
    // This makes the componnet to refresh when new data is available i.e. api finished.
    const { data, loading, error, refetch } = useQuery(GET_POS_BY_RESTAURANT, {
        variables: { restaurantId: restaurant.id },
        pollInterval: 3000,
        fetchPolicy: "network-only"
    });

    // If it is loading, show progress bar
    if (loading) {
        return (<div className="App AppLoading"><CircularProgress /></div>);
    }
    // In case there is an error, just show it for now
    if (!data) { user.consoleLog(error); return <p>Error</p> }

    let productionDepartments, productionDepartmentsFieldOptions = [], tempProductionDepartments = [];
    let productionCategorysFieldOptions = [], tempProductionCategorys = [], firstProductionDepartmentId;
    let alertMessage = `${user.translate("cant_delete_pos")} `,
        autoHideDuration = 3000, linkTo = ``, linkText = "";


    const pos = data?.getPossByRestaurantId || {};
    const productionCategorys = data?.getProductionCategorysByRestaurantId || [];
    const posMenus = data?.getRestaurantMenusByRestaurantId || [];

    const posMenusFieldOptions = posMenus.distinctBy(menu => menu.id).map(menu => ({
        val: menu.id,
        label: menu.name[user.lang],
        langId: menu.name.id
    }));
    const firstPosMenusId = posMenusFieldOptions[0];

    if (posMenusFieldOptions.length == 0) {
        alertMessage = `${user.translate("please_create")} `;
        linkTo = `/restaurant/${restaurant._id}/menu/`;
        linkText = user.translate("menu");
        autoHideDuration = undefined;
        if (!openSnackBarWarning) setOpenSnackBarWarning(true);
    }

    if (productionCategorys.length > 0) {

        for (var jdx in productionCategorys) {
            let id = productionCategorys[jdx].id;
            let name = productionCategorys[jdx].name[user.lang];

            if (tempProductionCategorys.indexOf(id) === -1) {
                productionCategorysFieldOptions.push({
                    val: id,
                    label: name
                });

                let departmens = productionCategorys[jdx].productionDepartments;
                productionDepartmentsFieldOptions[id] = [];
                for (var item in departmens) {
                    if (tempProductionDepartments.indexOf(departmens[item].id) === -1) {
                        productionDepartmentsFieldOptions[id].push({
                            val: departmens[item].id,
                            label: departmens[item].name[user.lang]
                        });
                        tempProductionDepartments.push(departmens[item].id);
                    }
                }

                tempProductionCategorys.push(id);
            }
        };

        firstProductionDepartmentId = tempProductionDepartments.slice(0, 1);
    } else {
        alertMessage = `${user.translate("please_create")} `;
        linkTo = `/restaurant/${restaurant._id}/production_category/`;
        linkText = user.translate("production_category");
        autoHideDuration = undefined;
        if (!openSnackBarWarning) setOpenSnackBarWarning(true);
    }

    let orderTypes = [
        {
            val: "0",
            label: user.translate("in_restaurant")
        },
        {
            val: "1",
            label: user.translate("take_away")
        },
        {
            val: "2",
            label: user.translate("catering")
        },
    ];

    let fieldList = [
        {
            required: true,
            type: "text",
            fieldType: "text",
            fieldName: "name",
            fieldLabel: user.translate("title"),
            fieldValue: "",
        },
        {
            required: true,
            type: "hidden",
            fieldType: "text",
            fieldName: "id",
            fieldLabel: "id",
            fieldValue: helper.uid(),
        },
        {
            required: true,
            type: "select",
            fieldType: "select",
            fieldName: "defOrderType",
            fieldLabel: user.translate("order_type"),
            fieldValue: "0",
            fieldOptions: orderTypes,
            noNone: true
        }
    ];

    if (user.me.profile && user.me.profile.isAdmin) {
        fieldList = [
            {
                required: true,
                type: "text",
                fieldType: "text",
                fieldName: "nameMk",
                fieldLabel: user.translate("titleMk"),
                fieldValue: "",
            },
            {
                required: true,
                type: "text",
                fieldType: "text",
                fieldName: "nameEn",
                fieldLabel: user.translate("titleEn"),
                fieldValue: "",
            },
            {
                required: true,
                type: "hidden",
                fieldType: "text",
                fieldName: "id",
                fieldLabel: "id",
                fieldValue: helper.uid(),
            },
            {
                required: true,
                type: "select",
                fieldType: "select",
                fieldName: "defOrderType",
                fieldLabel: user.translate("order_type"),
                fieldValue: "0",
                fieldOptions: orderTypes,
                noNone: true
            }
        ];
    }


    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                {
                    pos.map(_pos => (
                        <Grid item xs={4} key={_pos.id} className={classes.gridItem}>
                            <PosItem
                                pos={_pos}
                                posMenus={posMenus}
                                productionCategorys={productionCategorys}
                                productionDepartments={productionDepartments}
                                productionDepartmentsFieldOptions={productionDepartmentsFieldOptions}
                                orderTypes={orderTypes}
                                posMenusFieldOptions={posMenusFieldOptions}
                                firstPosMenusId={firstPosMenusId}
                                firstProductionDepartmentId={firstProductionDepartmentId}
                                setOpenDeleteBox={setOpenDeleteBox}
                                setDeleteState={setDeleteState}
                                setOpenSnackBarWarning={setOpenSnackBarWarning}
                                refetch={refetch}
                            />
                        </Grid>
                    ))
                }
            </Grid>
            <SpeedDial
                ariaLabel="Add Point of Sales"
                className={classes.speedDial}
                open={false}
                onClick={onManipulateClick}
                icon={<IconAdd />}
            />

            {openDeleteBox && (
                <Delete
                    name={deleteState.name}
                    variables={deleteState.variables}
                    setOpenSnackBar={setOpenSnackBar}
                    setOpenDeleteBox={setOpenDeleteBox}
                    deleteItem={deleteState.deleteItem}
                    onSuccess={refetch}
                />
            )}

            {openSnackBar && (
                <SnackBarDelete
                    hideUndo={deleteState.hideUndo}
                    message={deleteState.name + ` ${user.translate("deleted")}!`}
                    variables={deleteState.variables}
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                    unDeleteItem={deleteState.undeleteItem}
                    onSuccess={refetch}
                />
            )}

            {openManipulateBox && (
                <Add fieldList={fieldList} openManipulateBox={setOpenManipulateBox} actionType={user.translate("add")} name={user.translate("pos")} restaurant={restaurant} manipulateItem={ADD_ITEM} onSuccess={refetch} />
            )}

            {openSnackBarWarning && (
                <SnackBarWarning
                    message={alertMessage}
                    autoHideDuration={autoHideDuration}
                    linkTo={linkTo}
                    linkText={linkText}
                    openSnackBar={openSnackBarWarning}
                    setOpenSnackBar={setOpenSnackBarWarning}
                />
            )}

        </div>
    )

}
