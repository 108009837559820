import React, { useContext, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { RestaurantContext, UserContext } from "../../App";
import { makeStyles, CircularProgress, Grid, Typography, Divider, IconButton } from '@material-ui/core';
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import IconAdd from '@material-ui/icons/Add';
import IconDelete from '@material-ui/icons/Delete';
import IconEdit from '@material-ui/icons/Edit';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import TableItem from "./TableItem"
import Add from '../common/Add';
import Delete from '../common/Delete';
import SnackBarDelete from '../common/SnackBarDelete';
import SnackBarWarning from '../common/SnackBarWarning';
import helper from '../common/Helper';

// CSS
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        padding: theme.spacing(3),
    },
    gridList: {
        justifyContent: 'right',
        flexGrow: 1
    },
    pos: {
        background: `#eee`,
        marginBottom: theme.spacing(7),
        borderRadius: '10px',
        padding: theme.spacing(2)
    },
    floor: {
        background: `#e9e9e9`,
        marginBottom: theme.spacing(3),
        borderRadius: '10px',
        padding: theme.spacing(2)
    },
    group: {
        background: `#e3e3e3`,
        marginBottom: theme.spacing(3),
        borderRadius: '10px',
        padding: theme.spacing(2),
    },
    gridItem: {
        maxWidth: 245,
        minWidth: 245,
        flexGrow: 1
    },
    speedDialGroup: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(18)
    },
    speedDialFloor: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(10)
    },
    speedDial: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    gridTitle: {
        textAlign: `left`,
    },
    divider: {
        margin: `0 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        padding: `1px`,
        width: `100%`,
    },
    iconButton: {
        float: "right",
        padding: '10px',
        marginTop: '-10px',
        width: '40px',
        "&:hover": {
            color: "#3f51b5"
        }
    },
}));


// React Function Component
export default function TableList() {
    const classes = useStyles();

    // Get current restaurant from context
    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);
    const [openManipulateBox, setOpenManipulateBox] = useState(false);
    const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false);
    const [openDeleteBox, setOpenDeleteBox] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [deleteState, setDeleteState] = useState({
        value: 1,
        floor: null,
        posId: null,
        name: "",
    });
    const [editState, setEditState] = useState({
        pos: "",
        floor: "",
        group: ""
    });

    let trAddTable = user.translate("add_table"),
        trAddTableGroup = "-" + user.translate("add_table"),
        // trEditTable = user.translate("edit_table"),
        trAddPos = user.translate("add_pos"),
        // trEditPos = user.translate("edit_pos"),
        trAddFloor = user.translate("add_floor"),
        trEditFloor = user.translate("edit_floor"),
        trAddGroup = user.translate("add_group"),
        trEditGroup = user.translate("edit_group"),
        trPos = user.translate("pos"),
        // trTable = user.translate("table"),
        trGroup = user.translate("group"),
        trFloor = user.translate("floor");

    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const [actionSpeedDial, setActionSpeedDial] = useState(trAddTable);

    const onDeleteClick = (e, value, name, floor, posId) => {
        setDeleteState({
            value: value,
            floor: typeof floor !== "undefined" ? floor : null,
            posId: typeof posId !== "undefined" ? posId : null,
            name: name,
            hideUndo: true,
            deleteItem: undefined,
            unDeleteItem: undefined
        });
        setOpenDeleteBox(true);
    }

    const handleOpenSpeedDial = () => {
        setOpenSpeedDial(true);
    };

    const handleCloseSpeedDial = () => {
        setOpenSpeedDial(false);
    };


    const onManipulateClick = (action, pos, floor, group) => {
        setActionSpeedDial(action);
        setOpenManipulateBox(true);

        if (typeof pos !== "undefined" && pos !== "") {
            if (typeof group !== "undefined" && group !== "") {
                setEditState({
                    ...editState,
                    pos: pos,
                    floor: floor,
                    group: group
                })
            }
            else if (typeof floor !== "undefined" && floor !== "") {
                setEditState({
                    ...editState,
                    pos: pos,
                    floor: floor,
                    group: ""
                })
            } else {
                setEditState({
                    ...editState,
                    pos: pos,
                    floor: "",
                    group: ""
                })
            }
        }
        else {
            setEditState({
                ...editState,
                pos: "",
                floor: "",
                group: ""
            })
        }
    }

    const ADD_ITEM = `
        mutation ($id: String!, $posId: String!, $userId: String, $code: String!, $name: String!, $group: String!, $floor: Long!) {
            createRestaurantTable(input: {
                id: $id
                posId: $posId
                userId: $userId
                code: $code
                name: $name
                group: $group
                floor: $floor
            }) {
                id
            }
    }`;

    const EDIT_ITEM_FLOOR = `
        mutation ($posId: String!, $oldFloor: Long!, $newFloor: Long!) {
            extras_updateTablesFloor(
                posId: $posId
                oldFloor: $oldFloor
                newFloor: $newFloor
            ) 
    }`;
    const EDIT_ITEM_GROUP = `
        mutation ($posId: String!, $floor: Long!, $oldGroup: String!, $newGroup: String!) {
            extras_updateTablesGroup(
                posId: $posId
                floor: $floor
                oldGroup: $oldGroup
                newGroup: $newGroup
            )
    }`;

    // GraphQL API request definition (local variables: restaurantId)
    const GET_TABLE_BY_RESTAURANT = gql`
        query ($restaurantId: String!) {
            getRestaurantTablesByContextRestaurantId(restaurantId: $restaurantId) {
                id
                posId
                userId
                code
                name
                group
                floor
                active
                displayIndex
                tableReceiptsCount
        }
        getPossByRestaurantId(restaurantId: $restaurantId) {
            id
            name {
                ${user.gqlFetchName()}
            }
        }
    }`;

    // Make the api request or get cached.
    // This makes the componnet to refresh when new data is available i.e. api finished.
    const { data, loading, error, refetch } = useQuery(GET_TABLE_BY_RESTAURANT, {
        variables: { restaurantId: restaurant.id },
        pollInterval: 3000,
    });


    // If it is loading, show progress bar
    // if (loading) return <CircularProgress />
    if (loading) {
        return (<div className="App AppLoading"><CircularProgress /></div>);
    }

    // In case there is an error, just show it for now
    if (!data) { user.consoleLog(error); return <p>Error</p> }

    let pos = {}, allPos = {}, floorGroups = {}, groups = {};
    let alertMessage = `${user.translate("cant_delete_table")} `,
        autoHideDuration = 3000, linkTo = ``, linkText = "";

    if (data) {
        pos = helper.groupBy(data.getRestaurantTablesByContextRestaurantId, 'posId', 'id');
        allPos = data.getPossByRestaurantId;
        // floorGroups = helper.groupBy(data.getRestaurantTablesByContextRestaurantId,    'floor');
    }

    if (allPos.length === 0) {
        alertMessage = `${user.translate("please_create")} `;
        linkTo = `/restaurant/${restaurant._id}/pos/`;
        linkText = user.translate("pos");
        autoHideDuration = undefined;
        if (!openSnackBarWarning) setOpenSnackBarWarning(true);
    }

    let posFieldOptions = [], floorFieldOptions = [], groupFieldOptions = [], _floorGroups = {}, _groups = {}, temp = [], temp1 = [], temp2 = [];

    if (data.getRestaurantTablesByContextRestaurantId.length === 0) {
        allPos.forEach(pos => {
            posFieldOptions.push({
                val: pos.id,
                label: trPos + " " + pos.name[user.lang]
            });
        });
    }
    else {
        for (var _pos in pos) {
            let posItem = allPos.find((c) => c.id === _pos)
            if (temp.indexOf(_pos) === -1) {
                posFieldOptions.push({
                    val: _pos,
                    label: trPos + " " + posItem.name[user.lang]
                });
                temp.push(_pos);
            }

            _floorGroups = helper.groupBy(pos[_pos], 'floor', 'floor');
            for (var _floor in _floorGroups) {
                if (temp1.indexOf(_floor) === -1) {
                    floorFieldOptions.push({
                        val: _floor,
                        label: user.translate("floor") + " " + _floor
                    });
                    temp1.push(_floor);
                }

                _groups = helper.groupBy(_floorGroups[_floor], 'group', 'group', 'letters');
                for (var _group in _groups) {
                    if (temp2.indexOf(_group) === -1) {
                        groupFieldOptions.push({
                            val: _group,
                            label: user.translate("group") + " " + _group
                        });
                        temp2.push(_group);
                    }
                };
            };
        }
    }

    const fieldList = [
        {
            required: true,
            type: "text",
            fieldType: "text",
            fieldName: "name",
            fieldLabel: user.translate("title"),
            fieldValue: "",
        }
        , {
            required: false,
            type: "hidden",
            fieldType: "text",
            fieldName: "code",
            fieldLabel: user.translate("code"),
            fieldValue: "",
        }
        , {
            required: true,
            type: "",
            fieldType: "select",
            fieldName: "posId",
            fieldLabel: user.translate("pos"),
            fieldValue: "" + temp[0],
            fieldOptions: posFieldOptions,
            noNone: true
        }
        , {
            required: true,
            type: "",
            fieldType: "select",
            fieldName: "floor",
            fieldLabel: user.translate("floor"),
            fieldValue: "" + temp1[0],
            fieldOptions: floorFieldOptions
        }
        , {
            required: true,
            type: "",
            fieldType: "select",
            fieldName: "group",
            fieldLabel: user.translate("group"),
            fieldValue: "" + temp2[0],
            fieldOptions: groupFieldOptions,
        }
        , {
            required: true,
            type: "hidden",
            fieldType: "text",
            fieldName: "userId",
            fieldLabel: "userId",
            fieldValue: null,
        }
        , {
            required: true,
            type: "hidden",
            fieldType: "text",
            fieldName: "id",
            fieldLabel: "id",
            fieldValue: "" + helper.uid(),
        }
    ];

    const renderAdd = (fieldList, temp, temp1, temp2, posFieldOptions, floorFieldOptions, groupFieldOptions) => {

        let itemTitle = "";
        let manipulateItem = ADD_ITEM;

        if (actionSpeedDial !== trAddTable && actionSpeedDial !== trAddTableGroup && actionSpeedDial.indexOf(`${user.translate("edit")}`) === -1) {

            let posFieldValue = "", floorFieldValue = "", groupFieldValue = "";

            if (editState.pos !== "") {
                // temp = helper.findMissingNumber(posFieldOptions, 1, `${trPos} `);
                posFieldValue = editState.pos;
                fieldList[2].fieldValue = posFieldValue;
            }

            if (editState.floor === "") {
                temp1 = helper.findMissingNumber(floorFieldOptions, 0, `${trFloor} `);
                floorFieldValue = temp1.newVal;
            }
            else floorFieldValue = editState.floor;

            if (editState.group === "") {
                if (groupFieldOptions.length > 0) {
                    temp2 = helper.findMissingLetter(groupFieldOptions, `${trGroup} `);
                    groupFieldValue = temp2.newVal;
                }

            } else groupFieldValue = editState.group;

            fieldList[3].fieldType = "text";
            fieldList[3].type = "text";
            // fieldList[3].fieldOptions = floorFieldOptions;
            fieldList[3].fieldValue = floorFieldValue;

            fieldList[4].fieldType = "text";
            fieldList[4].type = "text";
            // fieldList[4].fieldOptions = groupFieldOptions;
            fieldList[4].fieldValue = groupFieldValue.toString();

            if (actionSpeedDial === trAddPos) itemTitle = user.translate("pos")
            else if (actionSpeedDial === trAddFloor) itemTitle = user.translate("floor")
            else if (actionSpeedDial === trAddGroup) itemTitle = user.translate("group")

        } else if (actionSpeedDial !== trAddTable && actionSpeedDial !== trAddTableGroup && actionSpeedDial.indexOf(`${user.translate("edit")}`) > -1) {

            if (actionSpeedDial === trEditFloor) {
                fieldList = [
                    {
                        required: true,
                        type: "",
                        fieldType: "text",
                        fieldName: "newFloor",
                        fieldLabel: user.translate("floor"),
                        fieldValue: editState.floor,
                    },
                    {
                        required: true,
                        type: "hidden",
                        fieldType: "text",
                        fieldName: "oldFloor",
                        fieldLabel: "oldFloor",
                        fieldValue: editState.floor,
                    },
                    {
                        required: true,
                        type: "hidden",
                        fieldType: "text",
                        fieldName: "posId",
                        fieldLabel: "posId",
                        fieldValue: editState.pos,
                    }
                ];
                itemTitle = user.translate("floor") + " " + editState.floor;
                manipulateItem = EDIT_ITEM_FLOOR;
            }
            else if (actionSpeedDial === trEditGroup) {
                fieldList = [
                    {
                        required: true,
                        type: "",
                        fieldType: "text",
                        fieldName: "newGroup",
                        fieldLabel: user.translate("group"),
                        fieldValue: editState.group,
                    },
                    {
                        required: true,
                        type: "hidden",
                        fieldType: "text",
                        fieldName: "oldGroup",
                        fieldLabel: "oldGroup",
                        fieldValue: editState.group,
                    },
                    {
                        required: true,
                        type: "hidden",
                        fieldType: "text",
                        fieldName: "floor",
                        fieldLabel: "Floor",
                        fieldValue: editState.floor,
                    },
                    {
                        required: true,
                        type: "hidden",
                        fieldType: "text",
                        fieldName: "posId",
                        fieldLabel: "posId",
                        fieldValue: editState.pos,
                    }
                ];
                itemTitle = user.translate("group") + " " + editState.group;
                manipulateItem = EDIT_ITEM_GROUP;

            }
        }
        else if (actionSpeedDial === trAddTableGroup) {
            fieldList = [
                {
                    required: true,
                    type: "text",
                    fieldType: "text",
                    fieldName: "name",
                    fieldLabel: user.translate("title"),
                    fieldValue: "",
                }
                , {
                    required: false,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "code",
                    fieldLabel: user.translate("code"),
                    fieldValue: "",
                }
                , {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "posId",
                    fieldLabel: "posId",
                    fieldValue: editState.pos
                }
                , {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "floor",
                    fieldLabel: "Floor",
                    fieldValue: editState.floor
                }
                , {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "group",
                    fieldLabel: "Group",
                    fieldValue: editState.group,
                }
                , {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "userId",
                    fieldLabel: "userId",
                    fieldValue: null,
                }
                , {
                    required: true,
                    type: "hidden",
                    fieldType: "text",
                    fieldName: "id",
                    fieldLabel: "id",
                    fieldValue: "" + helper.uid(),
                }
            ];

            itemTitle = user.translate("table");

        }
        else if (actionSpeedDial === trAddTable) {
            itemTitle = user.translate("table");

        }

        let actionType = actionSpeedDial.indexOf(`${user.translate("edit")}`) === -1 ? user.translate("add") : user.translate("edit");

        return (
            <Add fieldList={fieldList} openManipulateBox={setOpenManipulateBox} actionType={actionType} name={itemTitle} restaurant={restaurant} manipulateItem={manipulateItem} onSuccess={refetch} />
        )
    }

    const renderDelete = () => {

        let variables = {}, deleteItem = ``, unDeleteItem = ``;

        // if(deleteState.name.indexOf(trPos) > -1) {
        //     deleteItem = `
        //         mutation ($posId: String!) {
        //             deleteRestaurantTable(posId: $posId)
        //         }
        //     `;
        //     unDeleteItem = `
        //         mutation ($posId: String!) {
        //             undeleteRestaurantTable(posId: $posId)
        //         }
        //     `;
        //     variables = {
        //         posId: deleteState.value,
        //     }
        // }
        // else 
        if (deleteState.name.indexOf(user.translate("floor")) > -1) {
            deleteItem = `
                mutation ($floor: Long!, $posId: String!) {
                    deleteRestaurantTable(floor: $floor, posId: $posId)
                }
            `;
            unDeleteItem = `
                mutation ($floor: Long!, $posId: String!) {
                    undeleteRestaurantTable(floor: $floor, posId: $posId)
                }
            `;
            variables = {
                floor: deleteState.value,
                posId: deleteState.posId
            }
        }
        else if (deleteState.name.indexOf(user.translate("group")) > -1) {
            deleteItem = `
                mutation ($group: String!, $floor: Long!, $posId: String!) {
                    deleteRestaurantTable(group: $group, floor: $floor, posId: $posId)
                }
            `;
            unDeleteItem = `
                mutation ($group: String!, $floor: Long!, $posId: String!) {
                    undeleteRestaurantTable(group: $group, floor: $floor, posId: $posId)
                }
            `;
            variables = {
                group: deleteState.value,
                floor: deleteState.floor,
                posId: deleteState.posId
            }
        }

        return (
            <>
                {openDeleteBox && (
                    <Delete
                        name={deleteState.name}
                        variables={typeof deleteState.variables !== "undefined" ? deleteState.variables : variables}
                        setOpenSnackBar={setOpenSnackBar}
                        setOpenDeleteBox={setOpenDeleteBox}
                        deleteItem={typeof deleteState.deleteItem !== "undefined" ? deleteState.deleteItem : deleteItem}
                        onSuccess={refetch}
                    />
                )}

                {openSnackBar && (
                    <SnackBarDelete
                        hideUndo={typeof deleteState.hideUndo !== "undefined" ? deleteState.hideUndo : true}
                        message={deleteState.name + ` ${user.translate("deleted")}!`}
                        variables={typeof deleteState.variables !== "undefined" ? deleteState.variables : variables}
                        openSnackBar={openSnackBar}
                        setOpenSnackBar={setOpenSnackBar}
                        unDeleteItem={typeof deleteState.unDeleteItem !== "undefined" ? deleteState.unDeleteItem : unDeleteItem}
                        onSuccess={refetch}
                    />
                )}

            </>
        )
    }

    const funSortTables = (a, b) => {
        let cmp = a.displayIndex - b.displayIndex;
        if (cmp != 0) return cmp;
        return a.name.localeCompare(b.name);
    };

    const renderGrid = (pos, allPos) => {
        return Object.keys(pos).map(_pos => {
            let posItem = allPos.find((c) => c.id === _pos)
            floorGroups = helper.groupBy(pos[_pos], 'floor', 'floor');
            return (
                <Grid
                    container
                    direction="row"
                    spacing={1}
                    className={classes.pos}
                    justify="flex-start"
                    alignItems="center"
                    key={"pos_grid_" + _pos}
                >
                    <Grid item xs={12} key={"pos_" + _pos}>
                        <Typography display="block" variant="subtitle1" className={classes.gridTitle}>
                            {user.translate("pos")}: {posItem.name[user.lang]}
                            {/* <IconButton className={classes.iconButton} size="small" onClick={(e) => onDeleteClick(e, _pos, `${trPos} ${_pos}`)}>
                                    <IconDelete />
                                </IconButton> */}
                            <IconButton className={classes.iconButton} size="small" onClick={(e) => onManipulateClick(trAddFloor, _pos)}>
                                <IconAdd />
                            </IconButton>
                        </Typography>
                        <Divider className={classes.divider} />
                    </Grid>
                    {
                        Object.keys(floorGroups).map(floor => {
                            groups = helper.groupBy(floorGroups[floor], 'group', 'group', 'letters');
                            return (
                                <Grid
                                    container
                                    direction="row"
                                    spacing={1}
                                    className={classes.floor}
                                    justify="flex-start"
                                    alignItems="center"
                                    key={"floor_grid_" + floor}
                                >
                                    <Grid item xs={12} key={"floor_" + floor}>
                                        <Typography display="block" variant="subtitle1" className={classes.gridTitle}>
                                            {user.translate("floor")} {floor}:

                                            <IconButton className={classes.iconButton} size="small" onClick={(e) => onDeleteClick(e, floor, `${trFloor} ${floor}`, floor, _pos)}>
                                                <IconDelete />
                                            </IconButton>
                                            <IconButton className={classes.iconButton} size="small" onClick={(e) => onManipulateClick(trEditFloor, _pos, floor)}>
                                                <IconEdit />
                                            </IconButton>
                                            <IconButton className={classes.iconButton} size="small" onClick={(e) => onManipulateClick(trAddGroup, _pos, floor)}>
                                                <IconAdd />
                                            </IconButton>
                                        </Typography>
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    {
                                        Object.keys(groups).map(group => {

                                            return (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    spacing={2}
                                                    className={classes.group}
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    key={"group_grid_" + group}
                                                >
                                                    <Grid item xs={12} key={"group_" + group}>
                                                        <Typography display="block" variant="subtitle1" className={classes.gridTitle}>
                                                            {user.translate("group")} {group}:
                                                            <IconButton className={classes.iconButton} size="small" onClick={(e) => onDeleteClick(e, group, `${trGroup} ${group}`, floor, _pos)}>
                                                                <IconDelete />
                                                            </IconButton>
                                                            <IconButton className={classes.iconButton} size="small" onClick={(e) => onManipulateClick(trEditGroup, _pos, floor, group)}>
                                                                <IconEdit />
                                                            </IconButton>
                                                            <IconButton className={classes.iconButton} size="small" onClick={(e) => onManipulateClick(trAddTableGroup, _pos, floor, group)}>
                                                                <IconAdd />
                                                            </IconButton>
                                                        </Typography>
                                                        <Divider className={classes.divider} />
                                                    </Grid>
                                                    {
                                                        groups[group].sort(funSortTables).map(tableItem => (
                                                            <Grid item xs={4} key={"grid_" + tableItem.id} className={classes.gridItem}>
                                                                <TableItem key={tableItem.id} table={tableItem}
                                                                    posFieldOptions={posFieldOptions} floorFieldOptions={floorFieldOptions} groupFieldOptions={groupFieldOptions}
                                                                    _pos={_pos} pos={pos} floor={floor} floorGroups={floorGroups} group={group} groups={groups}
                                                                    setOpenDeleteBox={setOpenDeleteBox} setDeleteState={setDeleteState} setOpenSnackBarWarning={setOpenSnackBarWarning}
                                                                    refetch={refetch}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            )
                        })
                    }
                </Grid>
            )
        });
    }

    const actions = [
        { icon: <IconAdd />, name: trAddTable },
        // { icon: <AccountBalanceWalletIcon />, name: trAddPos },
        { icon: <DomainDisabledIcon />, name: trAddFloor },
        { icon: <GroupWorkIcon />, name: trAddGroup },
    ];

    return (
        <div className={classes.root}>
            {
                renderGrid(pos, allPos)
            }

            <SpeedDial
                ariaLabel="Add"
                className={classes.speedDial}
                icon={<SpeedDialIcon openIcon={<IconEdit />} />}
                onClose={handleCloseSpeedDial}
                onOpen={handleOpenSpeedDial}
                open={openSpeedDial}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(event) => onManipulateClick(action.name)}
                    />
                ))}
            </SpeedDial>

            {/* <SpeedDial
				ariaLabel="Add Table"
				className={classes.speedDial}
				open={false}
				onClick={(event) => onManipulateClick(trAddTable)}
				icon={<IconAdd />}
			/> */}

            {(openDeleteBox || openSnackBar) && renderDelete()}

            {openManipulateBox && renderAdd(fieldList, temp, temp1, temp2, posFieldOptions, floorFieldOptions, groupFieldOptions)}

            {openSnackBarWarning && (
                <SnackBarWarning
                    message={alertMessage}
                    autoHideDuration={autoHideDuration}
                    linkTo={linkTo}
                    linkText={linkText}
                    openSnackBar={openSnackBarWarning}
                    setOpenSnackBar={setOpenSnackBarWarning}
                />
            )}
        </div>
    )

}
