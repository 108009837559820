import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RestaurantContext, UserContext } from "../../App";
import helper from "../common/Helper";
import InvoiceHeader from "../common/InvoiceHeader";
import SnackBarDelete from "../common/SnackBarDelete";
import tableComponents from "../common/TableComponents";
import TableInvoice from "../common/TableInvoice";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root .MuiIconButton-root": {
      padding: 3,
    },
  },
}));

// React Function Component
export default function InvoiceOutputList({ isCash }) {
  const classes = useStyles();

  const restaurant = useContext(RestaurantContext);
  const user = useContext(UserContext);

  const [refetchIt, setRefetchIt] = useState(false);
  const [page, setPage] = useState(0);

  let location = useLocation();
  let history = useHistory();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [deleteState, setDeleteState] = useState({
    variables: {},
    name: "",
    state: {},
  });

  let firstPayerCompany,
    commissioners = {};

  const [invoiceItems, setInvoiceItems] = useState(false);

  const startDate = new Date();
  const endDate = new Date();
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59);

  const filterState = {
    filters: location.state?.filters ?? [],
    selectedDateFrom: location.state?.selectedDateFrom ?? startDate,
    selectedDateTo: location.state?.selectedDateTo ?? endDate,
  };

  const setFilterState = (state) => {
    history.push({ state });
  };

  const [filterTableTitle, setFilterTableTitle] = useState(``);

  const ADD_ITEM = gql`
    mutation (
      $restaurantId: String!
      $payerCompanyId: String!
      $amount: Float!
      $currency: String!
      $invoiceNumber: String
      $issuingDate: Long
      $dueToDate: Long
      $isMaterial: Boolean
    ) {
      extras_createInvoice(
        fromRestaurantId: $restaurantId
        toRestaurantId: $payerCompanyId
        invoiceNumber: $invoiceNumber
        amount: $amount
        currency: $currency
        issuingDate: $issuingDate
        dueToDate: $dueToDate
        isMaterial: $isMaterial
        isCash: ${Boolean(isCash)}
      )
    }
  `;
  const [addItem, { data: data_add, loading: loading_add }] = useMutation(
    ADD_ITEM,
    {
      skip: !refetchIt,
    }
  );

  const EDIT_ITEM = gql`
    mutation (
      $id: String!
      $payerCompanyId: String!
      $amount: Float!
      $currency: String!
      $invoiceNumber: String!
      $issuingDate: Long!
      $dueToDate: Long!
      $bookDate: Long
      $isMaterial: Long
    ) {
      editInvoice(
        input: {
          id: $id
          payerCompanyId: $payerCompanyId
          amount: $amount
          currency: $currency
          invoiceNumber: $invoiceNumber
          issuingDate: $issuingDate
          dueToDate: $dueToDate
          bookDate: $bookDate
          isMaterial: $isMaterial
        }
      ) {
        id
      }
    }
  `;
  const [editItem, { loading: loading_edit }] = useMutation(EDIT_ITEM, {
    skip: !refetchIt,
  });

  const ADD_INVOICE_ITEM = gql`
        mutation ($id: String!, $invoiceId: String!, $article: String!, $description: String!, $quantity: Float!, $price: Float!, $tax: Float!, $discount: Float! ) {
            createInvoiceItem(input: {
                id: $id
                ${user.gqlCreateName("$article", false, "Article")}
                ${user.gqlCreateName("$description", false, "Description")}
                quantity: $quantity
                price: $price
                tax: $tax
                discount: $discount
                invoiceId: $invoiceId
            }){
                id
            }
        }`;
  const [addInvoiceItem, { loading: loadingInvoiceItem }] =
    useMutation(ADD_INVOICE_ITEM);

  const DELETE_ITEM = gql`
    mutation ($id: String!) {
      deleteInvoice(id: $id)
    }
  `;
  const [deleteItem, { loading: loading_delete }] = useMutation(DELETE_ITEM, {
    skip: !refetchIt,
  });

  const UNDELETE_ITEM = `
        mutation ($id: String!) {
            undeleteInvoice(id: $id)
    }`;

  // GraphQL API request definition (local variables: restaurantId)
  const GET_COMMISSIONERS_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
        getCommissionersByRestaurantId(restaurantId: $restaurantId) {
            commissioner {
                id
                name {
                    ${user.gqlFetchName()}
                }
            }
        }
    }`;

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data: data_commissioners, loading: loading_commissioners } = useQuery(
    GET_COMMISSIONERS_BY_RESTAURANT,
    {
      variables: { restaurantId: restaurant.id },
      // skip: !openCommissioners,
      pollInterval: 3000,
    }
  );

  if (data_commissioners) {
    commissioners = data_commissioners.getCommissionersByRestaurantId.map(
      (item) => ({
        ...item.commissioner,
        name: item.commissioner.name[user.lang],
        id: item.commissioner.id,
      })
    );

    firstPayerCompany = commissioners.slice(0, 1);
  }

  let results = {},
    fieldList = {};
  let filtersString = `, filter: {
        by: "issuingDate"
        gte: ${filterState.selectedDateFrom.getTime()}
        lte: ${filterState.selectedDateTo.getTime()}
        and: {
            by: "isCash"
            eq: ${isCash ? 1 : 0}
        }
    }`; //`, filter: {}`

  let get1 = `getInvoicesByPayeeCompanyId(payeeCompanyId: $restaurantId`;
  let get2 = `${filtersString}`;
  let get3 = `) {
        id
        payerCompanyId
        payeeCompanyId
        type
        status
        created
        updated
        invoiceNumber
        issuingDate
        isMaterial
        dueToDate
        bookDate
        amount
        currency
        discount
        payerCompany {
            imageLogo
            imageLogoBg
            name { ${user.gqlFetchName()} }
        }
        invoiceItems {
            _id
            id
        }
        isCash
    }`;

  const [get, setGet] = useState(
    `query ($restaurantId: String!){${get1}${get2}${get3}}`
  );

  const { data, loading, error, refetch } = useQuery(
    gql`
      ${get}
    `,
    {
      variables: { restaurantId: restaurant.id },
      pollInterval: 3000,
      fetchPolicy: "network-only",
    }
  );

  if (data) {
    results = data.getInvoicesByPayeeCompanyId;
  }

  // let lookupCurrency = {
  //     "MKD": user.translate("mkd"),
  //     "EUR": user.translate("eur"),
  //     "USD": user.translate("usd"),
  // };
  let lookupCurrency = [
    { val: "MKD", label: user.translate("mkd") },
    { val: "EUR", label: user.translate("eur") },
    { val: "USD", label: user.translate("usd") },
  ];
  let lookupIsMaterial = [
    { val: 1, label: user.translate("material") },
    { val: 0, label: user.translate("immaterial") },
  ];

  fieldList["columns"] = [
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "Id",
      field: "id",
      hidden: true,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "payerCompanyId",
      field: "payerCompanyId",
      hidden: true,
      export: false,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "status",
      field: "status",
      hidden: true,
      export: false,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "hasInvoiceItems",
      field: "hasInvoiceItems",
      hidden: true,
      export: false,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "currency",
      field: "currency",
      hidden: true,
      export: false,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "isMaterial",
      field: "isMaterial",
      hidden: true,
      export: false,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "issuingDate",
      field: "issuingDate",
      hidden: true,
      export: false,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "dueToDate",
      field: "dueToDate",
      hidden: true,
      export: false,
    },
    {
      width: "1%",
      cellStyle: { whiteSpace: "nowrap" },
      title: "bookDate",
      field: "bookDate",
      hidden: true,
      export: false,
    },
    {
      title: "",
      field: "count",
      editable: "never",
      cellStyle: {
        width: "1%",
        padding: `0 5px 0 0`,
        fontSize: 12,
        whiteSpace: "pre-line",
      },
    },
    {
      title: user.translate("payer_company"),
      field: "payerCompanyName",
      cellStyle: {
        width: "20%",
        padding: 0,
        fontSize: 14,
        whiteSpace: "pre-line",
      },
      editComponent: (props) =>
        tableComponents.AutoComplete(
          props,
          commissioners,
          "payerCompanyId",
          "payerCompanyName",
          loading_commissioners
        ),
      customFilterAndSearch: (filterValue, row) => {
        return (
          row.payerCompanyName
            .cirilicToLatin()
            .indexOf(filterValue.cirilicToLatin()) > -1 ||
          (row.currency &&
            row.currencyLabel
              .cirilicToLatin()
              .indexOf(filterValue.cirilicToLatin()) > -1) ||
          (row.amount &&
            user.formatNumber(row.amount).indexOf(filterValue) > -1)
        );
      },
      customSort: (a, b) =>
        a.payerCompanyName
          .cirilicLatinCompare(user.lang)
          .localeCompare(
            b.payerCompanyName.cirilicLatinCompare(user.lang),
            user.lang
          ),
    },
    {
      title: user.translate("total"),
      field: "amount",
      render: (rowData) => user.formatNumber(rowData.amount),
      editComponent: (props) =>
        tableComponents.NumericEditComponent(props, "amount"),
    },
    {
      title: user.translate("currency"),
      field: "currencyLabel",
      editComponent: (props) =>
        tableComponents.Select(props, lookupCurrency, "currency"),
      customSort: (a, b) =>
        a.currency
          .cirilicLatinCompare(user.lang)
          .localeCompare(b.currency.cirilicLatinCompare(user.lang), user.lang),
    },
    { title: user.translate("invoice_no"), field: "invoiceNumber" },
    {
      title: user.translate("type"),
      field: "isMaterialLabel",
      editComponent: (props) =>
        tableComponents.Select(props, lookupIsMaterial, "isMaterial"),
      customSort: (a, b) =>
        !a.isMaterial && b.isMaterial
          ? -1
          : a.isMaterial && !b.isMaterial
          ? 1
          : 0,
      defaultGroupOrder: filterState.filters.type === 3 ? 0 : undefined,
    },
    {
      title: user.translate("issuing_date"),
      field: "issuingDateLabel",
      editComponent: (props) =>
        tableComponents.DateTimeEditComponent(props, "issuingDate"),
    },
    {
      title: user.translate("due_to_date"),
      field: "dueToDateLabel",
      editComponent: (props) =>
        tableComponents.DateTimeEditComponent(props, "dueToDate"),
    },
    {
      title: user.translate("book_date"),
      field: "bookDateLabel",
      editComponent: (props) =>
        tableComponents.DateTimeEditComponent(props, "bookDate"),
    },
    {
      title: "",
      field: "items",
      editable: "never",
      render: (rowData) => (
        <Tooltip title={`${user.translate("items")}`} placement="top">
          <IconButton color="primary" onClick={(event) => onRowClick(rowData)}>
            <ArrowForwardIcon />
          </IconButton>
        </Tooltip>
      ),
      sorting: false,
      export: false,
      disableClick: true,
    },
  ];

  const setQuery = (filterState) => {
    filtersString = ``;
    let _filterTableTitle = ``;
    let statusOptions = [
      {
        status: 0,
        title: user.translate("created"),
      },
      {
        status: 1,
        title: user.translate("open"),
      },
      {
        status: 2,
        title: user.translate("paid"),
      },
      {
        status: 3,
        title: user.translate("storna"),
      },
    ];

    setFilterState({ ...filterState });

    if (filterState.selectedDateFrom && filterState.selectedDateTo) {
      filtersString = `{
              by: "issuingDate"
              gte: ${filterState.selectedDateFrom.getTime()}
              lte: ${filterState.selectedDateTo.getTime()}
              and: {
                by: "isCash"
                eq: ${isCash ? 1 : 0}
              }
          }`;
      if (
        filterState.filters.type != null &&
        filterState.filters.type > 0 &&
        filterState.filters.type < 3
      ) {
        filtersString = `{
                      by: "isMaterial"
                      eq: ${filterState.filters.type === 1 ? 1 : 0},
                      and: ${filtersString}
                  }`;

        let typeTitle =
          filterState.filters.type === 1 ? "materials" : "immaterials";
        _filterTableTitle += ", " + user.translate(typeTitle);
      }
      if (filterState.filters.status != null) {
        filtersString = `{
                  by: "status"
                  eq: ${filterState.filters.status},
                  and: ${filtersString}
              }`;
        let statusTitle = statusOptions.find(
          (c) => c.status === filterState.filters.status
        );
        _filterTableTitle +=
          ", " + user.translate("status") + ": " + statusTitle.title;
      }
      if (filterState.filters.company != null) {
        filtersString = `{
              by: "payerCompanyId"
              eq: "${filterState.filters.company.id}"
              and: ${filtersString}
          }`;
        _filterTableTitle +=
          ", " +
          user.translate("komitent") +
          ": " +
          filterState.filters.company.name;
      }

      filtersString = `, filter: ${filtersString}`;
    }

    setFilterTableTitle(_filterTableTitle);

    get2 = `${filtersString}`;
    setGet(`query ($restaurantId: String!){${get1}${get2}${get3}}`);
  };

  useEffect(() => {
    setQuery(filterState);
  }, []);

  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    );
  }

  // In case there is an error, just show it for now
  if (!data) {
    user.consoleLog(error);
    return <p>Error</p>;
  }

  fieldList["data"] = results.map((result, index) => ({
    ...result,
    count: index + 1 + ".",
    amount: result.amount,
    currency: result.currency,
    currencyLabel: result.currency
      ? lookupCurrency.find((c) => c.val === result.currency).label
      : "",
    isMaterialLabel: result.isMaterial
      ? user.translate("material")
      : user.translate("immaterial"),
    status: result.status,
    payerCompanyId: result.payerCompanyId,
    payerCompanyName: result.payerCompany.name[user.lang],
    hasInvoiceItems:
      result.invoiceItems && result.invoiceItems.length > 0 ? true : false,
    issuingDateLabel: result.issuingDate
      ? user.formatDate(result.issuingDate)
      : "",
    dueToDateLabel: result.dueToDate ? user.formatDate(result.dueToDate) : "",
    bookDateLabel: result.bookDate ? user.formatDate(result.bookDate) : "",
  }));

  const setInvoiceItemss = (invoiceId, amount) => {
    let items = {};

    items = {
      id: helper.uid(),
      invoiceId: invoiceId,
      article: user.translate("item") + " 1",
      description: " ",
      quantity: 1,
      price: amount,
      tax: 0,
      discount: 0,
    };

    return items;
  };

  const setItems = (dataObj, action) => {
    let items = {};

    items = {
      ...dataObj,
      id: dataObj.id ? dataObj.id : helper.uid(),
      amount: dataObj.amount ? dataObj.amount : "0",
      currency: dataObj.currency ? dataObj.currency : "MKD",
      restaurantId: restaurant.id,
      payerCompanyName: dataObj.payerCompanyName
        ? dataObj.payerCompanyName
        : firstPayerCompany[0].name,
      payerCompanyId: dataObj.payerCompanyId
        ? dataObj.payerCompanyId
        : firstPayerCompany[0].id,
      issuingDate: new Date(dataObj.issuingDate).getTime(),
      dueToDate: new Date(dataObj.dueToDate).getTime(),
      bookDate: dataObj.bookDate ? new Date(dataObj.bookDate).getTime() : null,
    };

    user.consoleLog(items);

    return items;
  };

  if (invoiceItems && !loading_add && data_add) {
    addInvoiceItem({
      variables: {
        ...invoiceItems,
        invoiceId: data_add.extras_createInvoice,
      },
    });
    setInvoiceItems(false);
  }

  const onAddItem = (newData, state) => {
    let items = setItems(newData, "add");
    setPage(Math.round((data.getInvoicesByPayeeCompanyId.length + 1) / 50));
    addItem({ variables: items });
    setRefetchIt(true);
    if (items.amount !== "0") {
      let _invoiceItems = setInvoiceItemss("", items.amount);
      setInvoiceItems(_invoiceItems);
    }
  };

  const onEditItem = (newData, state) => {
    let items = setItems(newData, "add");
    editItem({ variables: items });
    setRefetchIt(true);
    if (!newData.hasInvoiceItems && items.amount !== "0") {
      let _invoiceItems = setInvoiceItemss(items.id, items.amount);
      addInvoiceItem({ variables: _invoiceItems });
    }
  };

  const onDeleteItem = (oldData, state) => {
    let items = {};
    items["id"] = oldData.id;

    user.consoleLog(items);

    deleteItem({ variables: items });

    setDeleteState({
      variables: items,
      name: oldData["payerCompanyName"],
      state: state,
      hideUndo: true,
    });

    setOpenSnackBar(true);
    setRefetchIt(true);
  };

  const onRowClick = (rowData) => {
    const path = isCash ? "cash_invoice_output" : "invoice_output";
    history.push(`/restaurant/${restaurant._id}/${path}/${rowData.id}`, {
      from: "list",
    });
  };

  if (
    !loading &&
    !loading_add &&
    !loading_edit &&
    !loading_delete &&
    !loading_commissioners &&
    refetchIt
  ) {
    // setGet(`query ($restaurantId: String!){${get1}${get2}${get3}}`);
    refetch();
    user.consoleLog("Refetch Done!");
    setRefetchIt(false);
  }

  let _tableName =
    user.translate(isCash ? "cash_output_invoices" : "output_invoices") +
    filterTableTitle;

  let tableName = (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
    >
      <Grid item key="documentHeaders">
        <Box component="span" bgcolor="background.paper">
          {_tableName}
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <InvoiceHeader
        filterState={filterState}
        setFilterState={setFilterState}
        setQuery={setQuery}
      />

      <div className={classes.table}>
        <TableInvoice
          key={helper.hash(user.lang + page)}
          tableName={tableName}
          exportFileName={_tableName.replaceAll(" ", "").replaceAll(":", "_")}
          fieldList={fieldList}
          pageSize="50"
          pageSizeOptions={[50, 100, 200]}
          onAddItem={onAddItem}
          onEditItem={onEditItem}
          onRowClick={onRowClick}
          onDeleteItem={onDeleteItem}
          page={page}
        />
      </div>

      {openSnackBar && (
        <SnackBarDelete
          hideUndo={deleteState.hideUndo}
          message={deleteState.name + ` ${user.translate("deleted")}!`}
          variables={deleteState.variables}
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
          unDeleteItem={UNDELETE_ITEM}
          onSuccess={refetch}
        />
      )}
    </div>
  );
}

export const CashInvoiceOutputList = () => {
  return <InvoiceOutputList isCash />;
};
