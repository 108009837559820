import React, {useState, useContext} from "react"
import {
    makeStyles,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Tooltip,
} from "@material-ui/core"
import {useQuery, useMutation, gql, useLazyQuery} from "@apollo/client"
import Form from "./Form"
import helper from "./Helper"
import SnackBarAdd from "./SnackBarAdd"
import SnackBarWarning from "./SnackBarWarning"
import Deactivate from "./Deactivate"
import SnackBarDeactivate from "./SnackBarDeactivate"
import ProductDialog from "./ProductDialog"
import IconDialog from "./IconDialog"
import KomitentListDialog from "./KomitentListDialog"
import NotInterestedIcon from "@material-ui/icons/NotInterested"
import DoneIcon from "@material-ui/icons/Done"
import {UserContext, RestaurantContext} from "../../App"

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
        fontSize: 16,
    },
    dialogContent: {
        padding: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
    },
    activate: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
    form: {
        "& .MuiInputBase-root, & .MuiFormLabel-root, & .MuiFormControlLabel-label": {
            fontSize: 14,
        },
    },
}))

export default function Add(props) {
    const classes = useStyles()
    const user = useContext(UserContext)
    const restaurant = useContext(RestaurantContext)
    const fieldList = props.fieldList

    let returnObj = {},
        stateObjVals = {}

    if (props.restaurant) {
        returnObj["restaurantId"] = props.restaurant.id
        stateObjVals["restaurantId"] = props.restaurant.id
    }

    Object.keys(fieldList).forEach((index) => {
        const field = fieldList[index]
        if (field.type === "autocomplete") returnObj[field.fieldName] = field.fieldValue?.val
        else if (field.type === "autocompleteAdd") {
            returnObj[field.fieldName] = field.fieldValue?.val
        } else returnObj[field.fieldName] = field.fieldValue

        if (field.required && field.fieldValue === "") field.error = true
        stateObjVals[index] = field
    })

    let product = props.product || false
    let invoice = props.invoice || false
    let line = props.line || false
    let categoryIcon = props.categoryIcon || false
    let commissioners = props.commissioners || false

    const [stateObj, setStateObj] = useState(stateObjVals)
    const [returnItems, setReturnItems] = useState({...returnObj})
    const [refresh, setRefresh] = useState(props.refresh || false)
    const [manipulateIt, setManipulateIt] = useState(false)

    const [openSnackBar, setOpenSnackBar] = useState(false)

    const [deactivate, setDeactivate] = useState(props.deactivate !== undefined ? props.deactivate : undefined)
    const [openDeactivateBox, setOpenDeactivateBox] = useState(false)
    const [openDeactivateSnackBar, setOpenDeactivateSnackBar] = useState(false)
    const [openSnackBarWarning, setOpenSnackBarWarning] = useState(props.openSnackBarWarning)

    const [openSelectBox, setOpenSelectBox] = useState(false)
    const [onSuccess, setOnSuccess] = useState(props.onSuccess !== undefined)

    const [changeIt, setChangeIt] = useState(props.checkStaff || false)

    const [query, setQuery] = useState(props.manipulateItem)
    const [manipulateItem, {loading: loading_add, data: data_add}] = useMutation(
        gql`
      ${query}
    `
    )

    const [checkEmail, setCheckEmail] = useState(false)
    let checkStaff = props.checkStaff || false

    const CHECK_STAFF = gql`
    query ($email: String!, $restaurantId: String!) {
      extras_getProfileByEmail(email: $email, restaurantId: $restaurantId) {
        _id
        id
        email
        name
        role
      }
    }
  `
    const [getStaff, {data: data_profile, loading: loading_profile}] = useLazyQuery(CHECK_STAFF, {
        fetchPolicy: "cache-and-network",
        skip: !checkEmail,
    })

    let staff = {}

    if (loading_profile) {
        return (
            <div className="App AppLoading">
                <CircularProgress/>
            </div>
        )
    }

    if (data_profile && checkEmail) {
        staff = data_profile.extras_getProfileByEmail
        user.consoleLog(staff)
        if (staff && staff.id) {
            if (staff.role === -1) {
                setReturnItems({
                    ...returnItems,
                    name: staff.name,
                    email: staff.email,
                    employeeId: staff.id,
                })
                setQuery(props.manipulateItem1)
            } else {
                let _stateObj = Object.assign(stateObj)
                _stateObj[1].error = true
                _stateObj[1].helperText = user.translate("restaurant_employee_exists")

                setStateObj(_stateObj)

                setManipulateIt(false)
                setRefresh(true)
            }
        } else {
            setQuery(props.manipulateItem)
        }

        setCheckEmail(false)
    }

    if (manipulateIt && changeIt && props.manipulateItem1 && !loading_profile && checkEmail === false) {
        manipulateItem({variables: returnItems})
        if (props.onSave) props.onSave(returnItems)
        setOpenSnackBar(true)
        setChangeIt(false)
    }

    if (!loading_add && !loading_profile && manipulateIt && onSuccess && !changeIt) {
        user.consoleLog("Manipulation done!")
        if (props.returnParams) {
            props.onSuccess(data_add)
        } else props.onSuccess()
        setOnSuccess(false)
    }

    // Add Dialog Close
    const handleClose = () => {
        if (!openSnackBar) {
            if (props.setOpenSnackBarWarning) {
                if (openSnackBarWarning) {
                    setOpenSnackBarWarning(false)
                }
                if (props.openSnackBarWarning) {
                    props.setOpenSnackBarWarning(false)
                }
            }
            props.openManipulateBox(false)
        }
    }

    if (manipulateIt && !loading_add && !onSuccess) {
        handleClose()
    }

    // If it is loading_add, show progress bar
    if (loading_add) {
        return (
            <div className="App AppLoading">
                <CircularProgress/>
            </div>
        )
    }

    // function renderFontIcon(icon) {
    //     icon = icon.replaceAll("fa_", "");

    //     let solid = "fas"; //far
    //     if (icon.indexOf("_solid") > -1) solid = "fas";

    //     icon = icon.replaceAll("_solid", "");
    //     icon = icon.replaceAll("_alt", "-alt");
    //     icon = icon.split("_").join("-");

    //     return [solid, icon];
    // }

    const setItemsFunction = (name, value, change) => {
        let _returnItems = {...returnItems}

        if (typeof name === "object") {
            for (var i in name) {
                _returnItems[name[i]] = value[i]
            }
            setReturnItems({..._returnItems})
            if (typeof change !== "undefined") {
                if (props.manipulateItem1) setChangeIt(true)
            } else setChangeIt(false)
        } else {
            _returnItems[name] = value
            setReturnItems({..._returnItems})
        }
    }

    const checkErrorFunction = () => {
        for (var index in stateObj) {
            if (stateObj[index].required && stateObj[index].error) {
                return true
            }
        }

        if (openSnackBarWarning) {
            return true
        }

        return false
    }

    const enterManipulateAccept = (event) => {
        event.preventDefault()
        handleManipulateAccept(event)
    }

    const handleManipulateAccept = (e) => {
        e.preventDefault()

        if (!checkErrorFunction()) {
            checkFields()
            setManipulateIt(true)
            if (!changeIt) {
                manipulateItem({variables: returnItems})
                if (props.onSave) props.onSave(returnItems)
                setOpenSnackBar(true)
            } else {
                if (checkStaff) {
                    getStaff({variables: {email: returnItems["email"], restaurantId: restaurant.id}})
                    setCheckEmail(true)
                } else {
                    setQuery(props.manipulateItem1)
                }
            }
        }

        user.consoleLog(returnItems)
    }

    const checkFields = () => {
        let setLangId = false
        let _returnItems = Object.assign(returnItems)

        user.consoleLog(returnItems)

        if (props.setAddGroup) props.setAddGroup(false)

        let multiplier = true

        if (returnItems.uom && returnItems.uom === 2) {
            multiplier = false
        }

        fieldList.forEach((fieldItem) => {
            if (fieldItem.null !== "undefinded" && returnItems.name === "") {
                setLangId = true
            }
        })

        fieldList.forEach((fieldItem) => {
            if (fieldItem.nullLangId !== "undefinded" && setLangId && props.setAddGRoup) {
                _returnItems["langId"] = "-1"
                props.setAddGroup(true)
            }
            if (fieldItem.fieldName === "discount") {
                _returnItems["discount"] = _returnItems["discount"] / 100
            }
            if (fieldItem.fieldName === "tax") {
                _returnItems["tax"] = _returnItems["tax"] / 100
            }
            if (fieldItem.fieldName === "rate") {
                _returnItems["rate"] = _returnItems["rate"] / 100
            }
            if (fieldItem.multiplier && multiplier) {
                _returnItems[fieldItem.fieldName] = _returnItems[fieldItem.fieldName] * fieldItem.multiplier
            }
        })
        setReturnItems(_returnItems)
    }

    const setSelectedFields = (item) => {
        let _stateObj = Object.assign(stateObj)
        let _returnItems = Object.assign(returnItems)

        if (typeof item !== "undefined" && item !== "") {
            if (product) {
                _stateObj[1].fieldValue = item.name
                _stateObj[1].error = false
                _stateObj[2].fieldValue = item.id
                _stateObj[2].error = false

                _returnItems["name"] = item.name
                _returnItems["productId"] = item.id

                if (invoice) {
                    _stateObj[3].fieldValue = item.productionCategory.name[user.lang]
                    _stateObj[3].error = false
                    _returnItems["description"] = item.productionCategory.name[user.lang]
                    _stateObj[6].fieldValue = item.price
                    _stateObj[6].error = false
                    _returnItems["price"] = item.price
                    _stateObj[7].fieldValue = item.discountable && invoice.discount ? invoice.discount * 100 : 0
                    _stateObj[7].error = false
                    _returnItems["discount"] = item.discountable && invoice.discount ? invoice.discount * 100 : 0
                    _stateObj[8].fieldValue = item.taxType ? item.taxType.rate * 100 : 0
                    _stateObj[8].error = false
                    _returnItems["tax"] = item.taxType ? item.taxType.rate * 100 : 0
                }

                setReturnItems(_returnItems)
                setStateObj(_stateObj)
                setRefresh(true)
            } else if (categoryIcon) {
                let icon = item

                if (typeof icon == "string" && typeof icon.replace() != "undefined") {
                    icon = icon.replace("-", "_")
                    icon = "fa_" + icon

                    if (user.me.profile && user.me.profile.isAdmin) {
                        _stateObj[2].fieldIcon = helper.renderFontIcon(icon)
                        _stateObj[2].fieldValue = icon
                        _stateObj[2].error = false
                    } else {
                        _stateObj[1].fieldIcon = helper.renderFontIcon(icon)
                        _stateObj[1].fieldValue = icon
                        _stateObj[1].error = false
                    }

                    _returnItems["icon"] = icon

                    setReturnItems(_returnItems)
                    setStateObj(_stateObj)
                    setRefresh(true)
                }
            } else if (commissioners) {
                if (typeof item === "object" && item.val) {
                    _stateObj[4].fieldValue = item
                    _stateObj[4].error = false

                    _returnItems["senderId"] = item.val

                    setReturnItems(_returnItems)
                    setStateObj(_stateObj)

                    setRefresh(true)
                }
            }
        }
    }

    const onDeactivateClick = () => {
        setOpenDeactivateBox(true)
    }

    return (
        <>
            <Dialog
                open={!manipulateIt}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                aria-describedby="form-dialog-description"
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
                    {props.actionType} {props.name}
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <form encType="multipart/form-data" onSubmit={enterManipulateAccept} className={classes.form}>
                        {fieldList.map((item, index) => (
                            <Form
                                key={"form_field_" + index + refresh}
                                fieldList={stateObj}
                                item={index}
                                setItemsFunction={setItemsFunction}
                                setStateObj={setStateObj}
                                product={product}
                                commissioners={commissioners}
                                line={line}
                                setOpenSelectBox={setOpenSelectBox}
                                categoryIcon={categoryIcon}
                            />
                        ))}
                    </form>

                    {props.actionType === user.translate("edit") && deactivate !== undefined && (
                        <Tooltip
                            title={`${deactivate ? user.translate("deactivate") : user.translate("activate")} ${props.name}`}
                            placement="bottom-start"
                            className={classes.activate}
                        >
                            <Button onClick={onDeactivateClick} color={deactivate ? "primary" : "secondary"}>
                                {deactivate && <NotInterestedIcon/>}
                                {!deactivate && <DoneIcon/>}
                            </Button>
                        </Tooltip>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {user.translate("cancel")}
                    </Button>
                    <Button onClick={handleManipulateAccept} color="primary" autoFocus disabled={checkErrorFunction()}>
                        {props.actionType}
                    </Button>
                </DialogActions>
            </Dialog>

            {manipulateIt && openSnackBar && !props.returnSuccess && (
                <SnackBarAdd
                    message={`${props.name} ${user.translate("saved")}!`}
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            )}
            {manipulateIt && openSnackBar && props.returnSuccess && data_add && (
                <SnackBarAdd
                    message={data_add[props.returnSuccess].message}
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
            )}

            {product && openSelectBox && (
                <ProductDialog restaurant={props.restaurant} setOpenSelectBox={setOpenSelectBox} action={setSelectedFields}/>
            )}
            {commissioners && openSelectBox && (
                <KomitentListDialog
                    setOpenSelectBox={setOpenSelectBox}
                    openSelectBox={openSelectBox}
                    action={setSelectedFields}
                />
            )}
            {categoryIcon && openSelectBox && (
                <IconDialog restaurant={props.restaurant} setOpenSelectBox={setOpenSelectBox} action={setSelectedFields}/>
            )}

            {!manipulateIt && openDeactivateBox && (
                <Deactivate
                    name={props.name}
                    deactivateType={deactivate ? user.translate("deactivate_accept") : user.translate("activate_accept")}
                    variables={returnItems}
                    setOpenDeactivateSnackBar={setOpenDeactivateSnackBar}
                    setOpenDeactivateBox={setOpenDeactivateBox}
                    deactivateItem={deactivate ? props.deactivateItem : props.unDeactivateItem}
                    deactivate={deactivate}
                    setDeactivate={setDeactivate}
                    onSuccess={props.onSuccess}
                />
            )}

            {!manipulateIt && openDeactivateSnackBar && (
                <SnackBarDeactivate
                    message={props.name + ` ${!deactivate ? user.translate("deactivated") : user.translate("activated")}!`}
                    deactivated={!deactivate ? true : false}
                    openDeactivateSnackBar={openDeactivateSnackBar}
                    setOpenDeactivateSnackBar={setOpenDeactivateSnackBar}
                />
            )}

            {openSnackBarWarning && (
                <SnackBarWarning
                    message={props.alertMessageEdit}
                    openSnackBar={openSnackBarWarning}
                    linkTo={props.linkTo}
                    linkText={props.linkText}
                />
            )}
        </>
    )
}
