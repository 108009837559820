import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import React, { useContext, useState } from 'react';
import { RestaurantContext, UserContext } from "../../App";
import Show from '../common/Show';
import MenuItemEditDialog from './MenuItemEditDialog';
import MenuItemsList from './MenuItemsList';

// CSS
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        margin: theme.spacing(1),

        width: 540,
        [theme.breakpoints.down(1200)]: {
            width: '100%',
        },
        paddingBottom: theme.spacing(1),
        background: '#FFF',
        marginTop: theme.spacing(2),
        boxShadow: '3px 3px 6px #0000007a',
        borderRadius: theme.spacing(0.5),
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%'
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold',
        flexGrow: 1,
        textAlign: 'left',
        borderBottom: '#e1e1e1 1px solid',
        marginLeft: theme.spacing(1),
    },
    iconButton: {
        boxShadow: "0 0 2px #9d9d9d",
        // border: "#e3e3e3 1px solid",
        width: 50,
        height: 50,
        margin: 4
    },
    create: {
        margin: "auto",

    }
}));


export default function MenuCategory(props) {
    const category = props.item;

    const classes = useStyles();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const [expanded, setExpanded] = useState(true);
    const [showAdd, setShowAdd] = useState();

    var icon = (category.icon?.replace(/fa_/g, "") || "")
        .replace(/_solid/g, "")
        .replace(/_alt/g, "-alt")
        .split("_").join("-");


    const onEditNameClick = () => {

    }

    return <div className={classes.root} key={category.id}>
        <div className={classes.title}>
            <IconButton className={classes.iconButton}>
                <FontAwesomeIcon size="sm" icon={icon} />
            </IconButton>
            <Typography variant="h6" className={classes.name}>{category.name[user.lang]}</Typography>
            <IconButton size="medium" onClick={onEditNameClick}>
                <Edit fontSize="small" />
            </IconButton>
        </div>
        <Show if={expanded}>
            <MenuItemsList show={expanded} categoryId={category.id} />
        </Show>
        <Button className={classes.create} color="secondary" startIcon={<Add />} onClick={() => setShowAdd(true)}>{user.translate("create_item")}</Button>

        <Show if={showAdd}>
            <MenuItemEditDialog show={showAdd} name={user.translate("new_item")} onClose={() => setShowAdd()} />
        </Show>
    </div>
}

