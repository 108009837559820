import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, CircularProgress, Button } from '@material-ui/core';
import { useMutation, gql } from '@apollo/client';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { UserContext } from "../App";
import config from '../../config';
import helper from '../restaurant/common/Helper';

const useStyles = makeStyles((theme) => ({
	fb: {
		margin: theme.spacing(3, 0, 4),
	},
}));

// React Function Component
export default function RegisterFb(props) {

	const classes = useStyles();

    const width = typeof window !== 'undefined' ? window.innerWidth : null;

	const user = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(false);
	const [addIt, setAddIt] = useState(false);

	let history = useHistory();
	let location = useLocation();

	const ADD_USER = gql`
    mutation($accessToken: String!) {
        auth_loginFacebook(accessToken: $accessToken) {
            message 
            ok
            profile {
				name
				email
				id
			}
        }
    }`;

	const [addUser, { loading: loading_add }] = useMutation(ADD_USER, {
        skip: !addIt
    });

	const responseFacebook = (response) => {

		if (typeof response.accessToken !== "undefined") {
			let items = {
				accessToken: response.accessToken,
			}

			addUser({ variables: items });
			setAddIt(true);
		}
		else {
			setIsLoading(false);
		}

	}

	if (!loading_add && addIt && props.onLogin) {
		setIsLoading(false);
		setAddIt(false);
		props.onLogin();
	}

	if (isLoading) {
		return (<div className="App AppLoading"><CircularProgress /></div>);
	}

	const onRegisterFb = (renderProps, e) => {
		if(width > 768) setIsLoading(true);
		renderProps.onClick();
	}

	return (

        <FacebookLogin
			appId={config.fbAppId}
			autoLoad={false}
			fields="name,email,picture"
			scope="email"
			cssClass={classes.fb}
			callback={responseFacebook}
			isMobile={width < 768 ? true: false}
			disableMobileRedirect={width < 768 ? false : true}
            redirectUri={width < 768 ? window.location.origin+window.location.pathname : undefined}
			returnScopes={true}
			render={(renderProps) => (
				<Button
					fullWidth
					variant="contained"
					color="primary"
					onClick={(e) => onRegisterFb(renderProps, e)}
					className={classes.fb}
				>
					{user.translate('register_fb')}
				</Button>
			)}
		/>
	)

}