import {Button, IconButton} from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import OpenIcon from "@material-ui/icons/OpenInBrowser"
import PrintIcon from "@material-ui/icons/Print"
import React, {useContext, useState} from "react"
import {Link, useHistory, useLocation} from "react-router-dom"
import {RestaurantContext, UserContext} from "../../../App"
import Delete from "../../common/Delete"
import Show from "../../common/Show"
import SnackBarDelete from "../../common/SnackBarDelete"
import StornoInvoiceDialog from "./StornoInvoiceDialog"

export default function InvoiceStatus(props) {
    // Get current user preferences (language)

    const location = useLocation()
    const history = useHistory()

    const user = useContext(UserContext)
    const restaurant = useContext(RestaurantContext)
    const invoice = props.invoice

    const [openDeleteBox, setOpenDeleteBox] = useState(false)
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [openStorna, setOpenStorna] = useState(false)

    const DELETE_ITEM = `
        mutation ($id: String!) {
            deleteInvoice(id: $id)
    }`

    const UNDELETE_ITEM = `
        mutation ($id: String!) {
            undeleteInvoice(id: $id)
    }`

    const updateState = (value) => {
        const field = {
            fieldType: `$status: Long!`,
            field: "status",
        }

        props.onEditInvoice(
            {
                id: invoice.id,
                status: value,
            },
            field,
            false
        )
    }

    const deleteInvoice = () => {
        setOpenDeleteBox(true)
    }

    const onPrint = () => {
        props.onPrint()
    }

    const handleCloseStorno = (refresh) => {
        setOpenStorna(false)
        if (refresh) {
            props.onSuccess()
        }
    }

    const closeItem = () => {
        if (location.state?.from === "list") {
            history.goBack()
        } else {
            history.push("./")
        }
    }

    // 0 - created, 1 - sent, 2 - paid, 3 - cancelled

    //styles={classes.billingStyles}

    return (
        <>
            <IconButton style={{color: "inherit", float: "left", marginTop: "-7px", marginRight: "5px"}}>
                <IconButton
                    style={{display: "contents"}} onClick={closeItem}>
                    <KeyboardBackspaceIcon/>
                </IconButton>
            </IconButton>
            {invoice.status === 0 && (
                <>
                    <Button onClick={(e) => updateState(1)} color="primary">
                        {user.translate("sent")}
                    </Button>
                    <Button onClick={deleteInvoice} color="secondary">
                        {user.translate("delete")}
                    </Button>
                </>
            )}
            {invoice.status === 1 && (
                <>
                    <Button onClick={(e) => updateState(2)} color="primary">
                        {user.translate("paid")}
                    </Button>
                    <Button onClick={(e) => updateState(3)} color="secondary">
                        {user.translate("cancel")}
                    </Button>
                </>
            )}
            {invoice.status === 2 && (
                <>
                    <Button onClick={(e) => updateState(3)} color="primary">
                        {user.translate("cancel")}
                    </Button>
                </>
            )}
            {invoice.status === 3 && (
                <>
                    <Button onClick={(e) => updateState(0)} color="primary">
                        {user.translate("createda")}
                    </Button>
                    <Button onClick={deleteInvoice} color="secondary">
                        {user.translate("delete")}
                    </Button>
                </>
            )}
            <Button
                style={{float: "right"}}
                variant="contained"
                color="primary"
                component="span"
                onClick={onPrint}
                startIcon={<PrintIcon/>}
            >
                {user.translate("print")}
            </Button>

            <Show if={invoice.status > 0 && invoice.status !== 3}>
                <Show if={invoice.refInvoiceInvoices.length === 0}>
                    <Button
                        onClick={(e) => setOpenStorna(true)}
                        color="secondary"
                        startIcon={<CancelIcon/>}
                        style={{float: "right", marginRight: 16}}
                        variant="outlined"
                    >
                        {user.translate("storna")}
                    </Button>
                </Show>
                <Show if={invoice.refInvoiceInvoices.length > 0}>
                    {invoice.refInvoiceInvoices.map((refInvoice) => (
                        <Button
                            key={refInvoice.id}
                            color="secondary"
                            startIcon={<OpenIcon/>}
                            style={{float: "right", marginRight: 16}}
                            variant="contained"
                        >
                            <Link
                                to={`/restaurant/${restaurant._id}/invoice_output/${refInvoice.id}`}
                                style={{textDecoration: "none", color: "#FFF"}}
                            >
                                {user.translate("storna")}
                            </Link>
                        </Button>
                    ))}
                </Show>
            </Show>
            {openDeleteBox && (
                <Delete
                    name={user.translate("invoice") + " " + invoice.invoiceNumber}
                    variables={{id: invoice.id}}
                    setOpenSnackBar={setOpenSnackBar}
                    setOpenDeleteBox={setOpenDeleteBox}
                    deleteItem={DELETE_ITEM}
                    onSuccess={props.onSuccess}
                />
            )}
            {openSnackBar && (
                <SnackBarDelete
                    hideUndo={true}
                    message={user.translate("invoice") + " " + invoice.invoiceNumber + ` ${user.translate("deleted")}!`}
                    variables={{id: invoice.id}}
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                    unDeleteItem={UNDELETE_ITEM}
                    onSuccess={props.onSuccess}
                />
            )}
            {openStorna && <StornoInvoiceDialog invoiceId={invoice.id} onClose={handleCloseStorno}/>}
        </>
    )
}
