import { Button, CircularProgress, Fade, IconButton, LinearProgress, makeStyles } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../../App';
import { useCustomQuery, useEntityQuery } from '../../common/useEntityQuery';
import helper from '../Helper';
import { callOrGet, ifNull } from './utils';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 'auto',
        bottom: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff80',
        zIndex: 1
    },
    footer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1)
    },
    spacer: {
        flex: 1
    }
}));

function useFormQuery(props) {
    const entityName = props.entityName;
    const customQuery = props.customQuery;
    const entityRelFieldName = props.entityRelFieldName;
    const entityRelFieldValue = props.entityRelFieldValue;

    const fieldsMap = [];

    const keys = props.keys || ["id"];

    const [relationValue, setRelationValue] = useState(entityRelFieldValue)

    const relValue = relationValue || entityRelFieldValue || helper.uid();

    keys.forEach(key => {
        fieldsMap[key] = { name: key, gql: "String!", id: key == "id", value: (entityRelFieldName == key) ? relValue : helper.uid() };
    });

    React.Children.forEach(props.children, (child) => {
        const props = child.props;
        const gqlType = props.gql || child.type.gql;
        if (gqlType == undefined) console.warn("gql-type not defined in ", child.type.name)

        fieldsMap[props.name] = {
            name: props.name,
            default: props.default,
            value: props.value,
            hidden: callOrGet(props.hidden),
            gql: props.nullable ? gqlType : `${gqlType}!`
        };
    });

    const fields = Object.values(fieldsMap);

    const factory = customQuery ? useCustomQuery : useEntityQuery;

    const query = factory(customQuery || entityName, fields, entityRelFieldName, relValue, {
        skip: relationValue == undefined || props.isLoading || props.item != undefined,
        dateRangeFilter: props.dateRangeFilter,
        dateRangeField: props.dateRangeField,
        filter: props.filter,
        args: props.args,
        onLoad: props.onLoad,
        single: true
    });

    return { fields, fieldsMap, query, updateRelValue: setRelationValue };

}


export function ItemFormChildren(props) {
    const { fields, fieldsMap, query, updateRelValue } = useFormQuery(props);

    const data = query.item || props.item || fields.toMapBy(field => field.name, field => ifNull(callOrGet(field.value), callOrGet(field.default)));
    const [itemData, setItemData] = useState();
    const item = itemData || data;

    const onChange = (name) => (value) => {
        setItemData({ ...item, [name]: value, _orgState: item._orgState || item });
    }

    const doSubmit = () => {
        const data = fields.toMapBy(field => field.name, field => ifNull(item[field.name], field.value));
        const propsResult = props?.onSubmit && props.onSubmit(data);
        if (propsResult instanceof Promise) return { data, promise: propsResult };
        if (propsResult) return { data };

        return { data, promise: query.saveItem && query.saveItem(data) };
    }

    const onSubmit = () => {
        const { data, promise } = doSubmit();
        setItemData({ ...item, _orgState: undefined });
        if (promise) {
            promise.then(() => {
                if (props.onSave) props.onSave(data);
                if (props.refresh) props.refresh(data);
                updateRelValue(data[props.entityRelFieldName]);
            })
        } else {
            if (props.onSave) props.onSave(data);
            if (props.refresh) props.refresh(data);
            updateRelValue(data[props.entityRelFieldName]);
        }
    }

    const onDelete = () => {
        query.deleteItem(item).then(() => {
            if (props.onDelete) props.onDelete(item);
            if (props.refresh) props.refresh(item);
        });
    }

    const onEdit = (data) => {
        setItemData(data);
    }

    const isLoading = query.isLoading || query.isLoadingAction;

    let hasError = false;
    const children = React.Children.map(props.children, child => {
        if (!React.isValidElement(child)) return child;
        if (child.type == Field) return null;

        const childProps = child.props;
        const fieldName = childProps.name;
        if (fieldName == undefined) return child;

        const field = fieldsMap[fieldName];
        if (field == undefined) return child;

        const value = ifNull(item[fieldName], field.value);

        if (callOrGet(childProps.hidden, value, item) == true) return null;

        const error = value == undefined || value?.length == 0 || callOrGet(child.type.validate, childProps, value);

        if (error) hasError = true;

        return React.cloneElement(child, {
            key: childProps.key || fieldName,
            value: value,
            hidden: false,
            item: item,
            disabled: childProps.disabled || isLoading,
            error: error,
            size: props.dense ? "small" : "medium",
            onChange: onChange(fieldName),
            onSubmit: onSubmit,
            style: {
                flexGrow: 1
            }
        });

    });

    const isItemCreated = (props.item || query.item) != undefined;

    if (props.content) {
        children.push(callOrGet(props.content, item, isItemCreated));
    }

    if (props.footer) {
        children.push(callOrGet(props.footer, item, {
            hasError, onSubmit, onDelete, onEdit,
            isCreated: isItemCreated,
            isChanged: item._orgState && fields.find(field => item._orgState[field.name] != item[field.name]) != undefined
        }));
    }

    if (typeof props.loading == 'function') {
        children.push(callOrGet(props.loading, isLoading));
    } else if (isLoading) {
        children.push(callOrGet(props.loading, isLoading));
    }
    return children;
}

const getProgressBar = (props) => {
    if (props.loadingBar != undefined) return props.loadingBar;
    if (props.loadingVariant == "circular") return <CircularProgress />
    return <LinearProgress variant="indeterminate" style={{ width: 220 }} />;
}

export default function ItemForm(props) {
    const classes = useStyles();

    const user = useContext(UserContext);

    const loading = <Fade in={true} enter exit >
        <div key="loading" className={classes.loading} style={{ top: props.topOffset || 64 }}>{getProgressBar(props)}</div>
    </Fade>;

    const footer = (item, status) => (
        <div key="footer" className={classes.footer}>
            {props.noDelete != true && <IconButton disabled={!status.isCreated} ><Delete onClick={status.onDelete} /></IconButton>}
            <div className={classes.spacer} />
            <Button disabled={status.hasError || !status.isChanged} onClick={status.onSubmit}>{user.translate('save')}</Button>
        </div>
    )

    const rootClases = [classes.root];
    if (props.className) rootClases.push(props.className);
    return <form className={rootClases} noValidate autoComplete="off">
        <ItemFormChildren {...props} loading={props.loading || loading} content={props.content} footer={props.footer || footer} />
    </form>
}


ItemForm.propTypes = {
    topOffset: PropTypes.number,
    loadingVariant: PropTypes.oneOf(["linear", "circular"]),
    loadingBar: PropTypes.element,
    footer: PropTypes.element,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onLoad: PropTypes.func,
    entityName: PropTypes.string.isRequired,
    customQuery: PropTypes.string,
    entityRelFieldName: PropTypes.string,
    entityRelFieldValue: PropTypes.string,

    isLoading: PropTypes.bool,
    dateRangeFilter: PropTypes.object,
    filter: PropTypes.object,
    args: PropTypes.object,
    dense: PropTypes.bool
};




export function Field(props) {
    return null;

}

Field.propTypes = {
    gql: PropTypes.oneOf(["String", "Long", "Float", "Boolean"]).isRequired,
    nullable: PropTypes.bool,
    value: PropTypes.any.isRequired
};