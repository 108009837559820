import { Button, IconButton, makeStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Delete from '@material-ui/icons/Delete';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import axios from 'axios';
import React, { useContext } from 'react';
import config from '../../../config';
import { UserContext } from '../../App';
import Show from './Show';

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
    image: {
        maxWidth: 150,
        maxHeight: 150,
        float: `left`,
        marginRight: theme.spacing(3)
    },
    img: {
        width: '100%',
        height: 200,
        backgroundPosition: 'center',
    },
    labelUpload: {
        width: '100%'
    },
    uploadActionButton: {
        position: "absolute", bottom: 0,
        transition: theme.transitions.create('background', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.standard,
        }),
        '@media (pointer:fine)': {
            '& :hover': {
                background: 'radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0) 80%)'
            },
            '& :hover svg': {
                opacity: 1
            }
        },
        '@media (pointer:coarse)': {
            background: 'radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0) 80%)',
            '& svg': {
                opacity: 1
            }
        }
    },
    uploadActionIcon: {
        // color: '#FFFFFF11',
        color: '#FFF',
        opacity: 0,
        transition: theme.transitions.create('opacity', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.shortest,
        }),

    }
}));

export default function ImageUpload(props) {

    const classes = useStyles();
    const user = useContext(UserContext);

    const imageUrl = props.imageUrl && props.imageUrl.replace(" ", "%20");
    const isFitIn = imageUrl && imageUrl.indexOf("#fitin") > -1;

    const onStretch = () => {
        const url = imageUrl.split("#")[0];
        if (isFitIn) {
            props.onImageUploaded(url);
        } else {
            props.onImageUploaded(url + "#fitin");
        }
    }

    const uploadFile = (event) => {
        const el = event.target;

        const formData = new FormData();
        formData.append('file', event.target.files[0])

        axios.post(`${config.apiUrl}/uploadImage`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: 'include'
        }).then(response =>
            props.onImageUploaded(response.data[0])
        ).catch(error => {
            if (error.response) {
                user.consoleLog(error.response);
            }
            user.consoleLog(error.message);

        }).finally(() => {
            el.value = "";
        });
    }

    return <div className={props.className} style={props.style}>
        <Show if={Boolean(imageUrl)}>
            <div
                className={classes.img}
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: isFitIn ? 'contain' : 'cover',
                    backgroundRepeat: 'no-repeat'

                }}
                alt={user.translate("upload_image")} />
            {/* <IconButton style={{ position: "absolute", right: 0, bottom: 0, color: '#FFF' }} onClick={onImageSelect}><CloudUploadIcon /></IconButton> */}
            <IconButton className={classes.uploadActionButton} style={{ left: 0 }} onClick={props.onRemove}>
                <Delete className={classes.uploadActionIcon} />
            </IconButton>
            <IconButton className={classes.uploadActionButton} style={{ right: 0 }} onClick={onStretch}>
                <FullscreenIcon className={classes.uploadActionIcon} />
            </IconButton>
        </Show>
        <input
            accept="image/*"
            className={classes.input}
            id={`contained-button-file-${props.id}`}
            type="file"
            name={"webImage"}
            onChange={(event) => uploadFile(event)}
        />
        <label htmlFor={`contained-button-file-${props.id}`} className={classes.labelUpload}>
            <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
                style={{ display: Boolean(imageUrl) ? "none" : "flex" }}
            >
                {user.translate("upload")}
            </Button>
        </label>
    </div>
}