import React, { useContext, useState } from 'react';
import { makeStyles, Card, Typography, IconButton, CardActionArea, CardContent, CardActions } from '@material-ui/core';
import IconDelete from '@material-ui/icons/Delete';
import IconEdit from '@material-ui/icons/Edit';
import { UserContext } from '../../App';
import { RestaurantContext } from '../../App';
import Add from '../common/Add';

const useStyles = makeStyles((theme) => ({
	itemCard: {
		display: 'flex'
	},
	itemName: {
		margin: theme.spacing(1),
		flexGrow: 1
	}
}));

export default function ProductionCategory(props) {
	const classes = useStyles();
	// Get current user preferences (language)
	const user = useContext(UserContext);
	const restaurant = useContext(RestaurantContext);
	const productionCategory = props.productionCategory;

	// Get ProductionCategory name localized
	let productionCategoryName = productionCategory.name[user.lang];

	let fieldList = [
		{
			required: true,
			type: "text",
			fieldType: "text",
			fieldName: "name",
			fieldLabel: user.translate("title"),
			fieldValue: productionCategoryName,
        }, 
        {
			required: true,
			type: "hidden",
			fieldType: "text",
			fieldName: "langId",
			fieldLabel: "langId",
			fieldValue: productionCategory.name.id
		},
        {
			required: true,
			type: "hidden",
			fieldType: "text",
			fieldName: "id",
			fieldLabel: "id",
			fieldValue: productionCategory.id
        },
        {
            required: true,
            type: "select",
            fieldType: "select",
            fieldName: "irTaxTypeId",
            fieldLabel: user.translate("irTaxType"),
            fieldValue: productionCategory.irTaxType.id,
            fieldOptions: props.taxTypes,
        },
        {
            required: true,
            type: "select",
            fieldType: "select",
            fieldName: "taTaxTypeId",
            fieldLabel: user.translate("taTaxType"),
            fieldValue: productionCategory.taTaxType.id,
            fieldOptions: props.taxTypes,
        },
        {
            required: true,
            type: "select",
            fieldType: "select",
            fieldName: "ctTaxTypeId",
            fieldLabel: user.translate("ctTaxType"),
            fieldValue: productionCategory.ctTaxType.id,
            fieldOptions: props.taxTypes,
        },
    ];
    
    if(user.me.profile && user.me.profile.isAdmin)
    {
        fieldList = [
            {
                required: true,
                type: "text",
                fieldType: "text",
                fieldName: "nameMk",
                fieldLabel: user.translate("titleMk"),
                fieldValue: productionCategory.name["mk"],
            },
            {
                required: true,
                type: "text",
                fieldType: "text",
                fieldName: "nameEn",
                fieldLabel: user.translate("titleEn"),
                fieldValue: productionCategory.name["en"],
            },
            {
                required: true,
                type: "hidden",
                fieldType: "text",
                fieldName: "langId",
                fieldLabel: "langId",
                fieldValue: productionCategory.name.id
            },
            {
                required: true,
                type: "hidden",
                fieldType: "text",
                fieldName: "id",
                fieldLabel: "id",
                fieldValue: productionCategory.id
            },
            {
                required: true,
                type: "select",
                fieldType: "select",
                fieldName: "irTaxTypeId",
                fieldLabel: user.translate("irTaxType"),
                fieldValue: productionCategory.irTaxType.id,
                fieldOptions: props.taxTypes,
            },
            {
                required: true,
                type: "select",
                fieldType: "select",
                fieldName: "taTaxTypeId",
                fieldLabel: user.translate("taTaxType"),
                fieldValue: productionCategory.taTaxType.id,
                fieldOptions: props.taxTypes,
            },
            {
                required: true,
                type: "select",
                fieldType: "select",
                fieldName: "ctTaxTypeId",
                fieldLabel: user.translate("ctTaxType"),
                fieldValue: productionCategory.ctTaxType.id,
                fieldOptions: props.taxTypes,
            },
        ];
    }

	const EDIT_ITEM = `
	mutation (
        $restaurantId: String!, 
        $id: String!, 
        $langId: String!, 
        ${user.me.profile && user.me.profile.isAdmin ? "$nameMk: String!, $nameEn: String!" : "$name: String!"},
        $irTaxTypeId: String!,
        $taTaxTypeId: String!,
        $ctTaxTypeId: String!,
    ) {
		createProductionCategory(input: {
			id: $id,
			${user.me.profile && user.me.profile.isAdmin ? user.gqlEditNameTr("$nameMk", "$nameEn", "$langId") : user.gqlEditName("$name", "$langId")}
            restaurantId: $restaurantId
            irTaxTypeId: $irTaxTypeId
            taTaxTypeId: $taTaxTypeId
            ctTaxTypeId: $ctTaxTypeId
		}) {
			id
		}
	}`;

	const [openManipulateBox, setOpenManipulateBox] = useState(false);

	const onManipulateClick = (e) => {
		setOpenManipulateBox(true);
	}

	const onDeleteClick = (e) => {
        if(productionCategory.productsCount === 0 && productionCategory.productionDepartmentsCount === 0)
        {
            props.setDeleteState({
                name: productionCategoryName,
                variables: {id: productionCategory.id},
                hideUndo: true
            })
            props.setOpenDeleteBox(true);
        }
        else {
            props.setOpenSnackBarWarning(true);
        }
		
	}

	return (
		<React.Fragment>
			<Card className={classes.itemCard}>
				<CardActionArea>
					<CardContent onClick={onManipulateClick}>
						<Typography variant="subtitle1" className={classes.itemName}>
							{productionCategoryName}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<IconButton size="small" color="primary" onClick={onManipulateClick}>
						<IconEdit />
					</IconButton>
					<IconButton size="small" color="primary" onClick={onDeleteClick}>
						<IconDelete />
					</IconButton>
				</CardActions>
			</Card>

			{ openManipulateBox && (
				<Add fieldList={fieldList} openManipulateBox={setOpenManipulateBox} actionType={user.translate("edit")} restaurant={restaurant} name={productionCategoryName} manipulateItem={EDIT_ITEM} onSuccess={props.refetch} />
			)}
		</React.Fragment>
	);

}
