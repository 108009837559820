import React, { useState, useRef, useContext } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
	makeStyles,
	CircularProgress,
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Grid,
	Box,
	Typography,
	Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Link, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../App";
import RegisterFb from './RegisterFb';

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" to="/">
				E-Bar
      		</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		width: "100%",
		height: "100vh"
	},
	paper: {
		paddingTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
        backgroundColor: "transparent",//theme.palette.secondary.main,
        width: 72,
        height: 72,
        borderRadius: "unset",
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(1, 0, 2),
	},
	loading: {

	},
	hidden: {
		display: 'none'
	},
	error: {
		color: 'red'
	},
	copyright: {
		position: 'fixed',
		bottom: 0,
		margin: "0 auto",
		width: "100%"
	}
}));

export default function Register(props) {
	const classes = useStyles();

	const user = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	let history = useHistory();
	let location = useLocation();

	const emailEl = useRef(null);
	const nameEl = useRef(null);

	const REGISTER = gql`
    mutation($email: String!, $name: String!) {
        auth_register(email: $email, name: $name) {
            message
            ok
        }
    }`;

	const [registerUser, { loading: loading_register, data: data_register }] = useMutation(REGISTER);

	if (data_register && isLoading) {
		setIsLoading(false);

		if (data_register.ok) {
			props.onLogin();
			let { from } = location.state || { from: { pathname: "/home" } };
			history.replace(from);
		} else {
			setIsLoading(false);
			setErrorMessage(data_register.auth_register.message);
		}
	}

	let register = (e) => {
		e.preventDefault();
		setIsLoading(true);
		setErrorMessage(null);

		let items = {
			name: nameEl.current.value,
			email: emailEl.current.value
		}

		registerUser({ variables: items });
	}

	const loadingClass = isLoading ? classes.loading : classes.hidden;

	return (
		<Container component="main" maxWidth="xs" className={classes.root}>
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
                    <img src="/android-icon-72x72.png" alt={user.translate("e_bar")} width="72px"/>
				</Avatar>
				<Typography component="h1" variant="h5">
					{user.translate("register")}
				</Typography>
				<form className={classes.form} noValidate>
					<RegisterFb
						onLogin={props.onLogin}
						setErrorMessage={setErrorMessage}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="name"
						label={user.translate("name")}
						name="name"
						autoComplete="name"
						autoFocus
						inputRef={nameEl}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label={user.translate("email_address")}
						name="email"
						autoComplete="email"
						inputRef={emailEl}
					/>
					<Box style={{ display: "flex", justifyContent: "center" }}>
						<p className={classes.error}>{errorMessage}</p>
						<CircularProgress className={loadingClass} />
					</Box>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						disabled={isLoading}
						onClick={register}
						className={classes.submit} >
						{user.translate("register")}
					</Button>
					<Grid container>
						<Grid item xs>
							<Link to="/login" variant="body2">
								{user.translate("have_account")}
							</Link>
						</Grid>
					</Grid>
				</form>
				<div className={classes.copyright}>
					<Copyright />
				</div>
			</div>

		</Container>
	);
}
