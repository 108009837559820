import React, { useState, useRef, useContext } from 'react';
import {
	makeStyles,
	CircularProgress,
	Avatar,
	Button,
	CssBaseline,
	TextField,
	FormControlLabel,
	Checkbox,
	Grid,
	Box,
	Typography,
	Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { setTimeout } from 'timers';
import { Link, useHistory, useLocation } from "react-router-dom";
import config from '../../config';
import { UserContext } from "../App";
import RegisterFb from './RegisterFb';
import helper from '../restaurant/common/Helper';

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" to="/">
				E-Bar
      		</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		width: "100%",
		height: "100vh"
	},
	paper: {
		paddingTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
        backgroundColor: "transparent",//theme.palette.secondary.main,
        width: 72,
        height: 72,
        borderRadius: "unset",
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(1, 0, 2),
	},
	loading: {

	},
	hidden: {
		display: 'none'
	},
	error: {
		color: 'red'
	},
	copyright: {
		position: 'fixed',
		bottom: 0,
		margin: "0 auto",
		width: "100%"
	}
}));

export default function SignIn(props) {
	const classes = useStyles();

	const user = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	let history = useHistory();
	let location = useLocation();

	const emailEl = useRef(null);
	const passwordEl = useRef(null);

	let login = (e) => {
		e.preventDefault();
		setIsLoading(true);
		setErrorMessage(null);

		let email = emailEl.current.value;
		let password = passwordEl.current.value;

		let data = new URLSearchParams();
		data.append('email', email);
		data.append('password', password);

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
			body: data,
			cache: 'no-cache',
			credentials: 'include'
		};

		fetch(`${config.apiUrl}/loginEmployee`, requestOptions)
			.then(response => {
				if (response.ok) {
					return response.json()
				}
				return response.json().then(body => Promise.reject(body));
			})
			.then((data) => {
				props.onLogin();
				let { from } = location.state || { from: { pathname: "/home" } };
				history.replace(from);
			})
			.catch(error => {
                if(error.error)
                {
                    user.consoleLog(error)
                }
                setIsLoading(false);
                setErrorMessage(error.message);
			});
	}

	const loadingClass = isLoading ? classes.loading : classes.hidden;

	return (
		<Container component="main" maxWidth="xs" className={classes.root}>
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<img src="/android-icon-72x72.png" alt={user.translate("e_bar")} width="72px"/>
				</Avatar>
				<Typography component="h1" variant="h5">
					{user.translate("signin")}
				</Typography>
				<form className={classes.form} noValidate>
					<RegisterFb
						onLogin={props.onLogin}
						setErrorMessage={setErrorMessage}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label={user.translate("email_address")}
						name="email"
						autoComplete="email"
						autoFocus
						inputRef={emailEl}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label={user.translate("password")}
						type="password"
						id="password"
						autoComplete="current-password"
						inputRef={passwordEl}
					/>
					<FormControlLabel
						control={<Checkbox value="remember" color="primary" />}
						label={user.translate("remember_me")}
					/>
					<Box style={{ display: "flex", justifyContent: "center" }}>
						<p className={classes.error}>{errorMessage}</p>
						<CircularProgress className={loadingClass} />
					</Box>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						disabled={isLoading}
						onClick={login}
						className={classes.submit} >
						{user.translate("signin")}
					</Button>
					<Grid container>
						<Grid item xs>
							<Link to="/forgot-password" variant="body2">
								{user.translate("forgot_password")}?
              				</Link>
						</Grid>
						<Grid item>
							<Link to="/register" variant="body2">
								{user.translate("no_account")}
							</Link>
						</Grid>
					</Grid>
				</form>
				<div className={classes.copyright}>
					<Copyright />
				</div>
			</div>
		</Container>
	);
}
