import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import React from 'react';
import Switch, { Case, Default } from 'react-switch-case';

export default function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" size={34} value={100} style={{ color: '#CACACA', position: "absolute" }} />
            <CircularProgress variant="determinate" size={34} {...props} />

            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Switch condition={props.value}>
                    <Case value={100} >
                        <DoneIcon />
                    </Case>
                    <Default>
                        <Typography variant="caption" component="div" color={props.textColor || "textSecondary"}>{`${Math.round(
                            props.value,
                        )}%`}</Typography>
                    </Default>
                </Switch>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};
