import React, { useContext, useState } from 'react';
import { UserContext } from "../../../App";
import {
    makeStyles,
    Tabs,
    Tab,
    Grid,
    AppBar
} from '@material-ui/core';
import InventoryReport from './InventoryReport';
import InventoryHistory from './InventoryHistory';
import InventoryOutput from './InventoryOutput';
import InventoryOrder from './InventoryOrder';
import MenuTabPanel from '../../common/MenuTabPanel.js'

// CSS
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        width: "100%"
    },
    children: {
        padding: 0,
        lineHeight: `1.5`
    },
    tabs: {
        // display: `grid`,
    },
    gridList: {
        flexGrow: 1,
        width: `100%`
    },
    gridItem: {
        display: `block`,
    },
    speedDialAction: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}));

// React Function Component
export default function InventoryMain() {

    const classes = useStyles();

    // Get current restaurant from context
    const user = useContext(UserContext);

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    return (
        <div className={classes.root}>

            <AppBar className={classes.tabs} position="static" color="default">
                <Tabs
                    value={selectedTab}
                    onChange={handleChangeTab}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    scrollButtons="auto"
                    justify="center"
                >
                    <Tab label={user.translate("reportc")} {...a11yProps(0)} />
                    <Tab label={user.translate("history")} {...a11yProps(1)} />
                    {/* <Tab label={user.translate("inventory_output")} {...a11yProps(2)} />
                    <Tab label={user.translate("inventory_order")} {...a11yProps(3)} /> */}
                </Tabs>
            </AppBar>

            <MenuTabPanel
                value={selectedTab}
                index={0}
                className={classes.gridList}
                style={{padding: 0}}
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.gridItem}
                >
                    {selectedTab === 0 && (
                        <InventoryReport />
                    )}

                </Grid>

            </MenuTabPanel>

            <MenuTabPanel
                value={selectedTab}
                index={1}
                className={classes.gridList}
                style={{padding: 0}}
            >

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.gridItem}
                >
                    {selectedTab === 1 && (
                        <InventoryHistory />
                    )}

                </Grid>

            </MenuTabPanel>

            {/* <MenuTabPanel
                value={selectedTab}
                index={2}
                className={classes.gridList}
                style={{padding: 0}}
            >

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.gridItem}
                >
                    {selectedTab === 2 && (
                        <InventoryOutput />
                    )}

                </Grid>

            </MenuTabPanel>

            <MenuTabPanel
                value={selectedTab}
                index={3}
                className={classes.gridList}
                style={{padding: 0}}
            >

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.gridItem}
                >
                    {selectedTab === 3 && (
                        <InventoryOrder />
                    )}

                </Grid>

            </MenuTabPanel> */}

        </div>
    );

}
