import DateFnsUtils from "@date-io/date-fns"
import "@fortawesome/fontawesome-svg-core/styles.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField, Typography,
} from "@material-ui/core"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import ClearIcon from "@material-ui/icons/Clear"
import SubtitlesIcon from "@material-ui/icons/Subtitles"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import axios from "axios"
import clsx from "clsx"
import {ColorPicker} from "material-ui-color"
import PropTypes from "prop-types"
import React, {useContext, useEffect, useState} from "react"
import NumberFormat from "react-number-format"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import config from "../../../config"
import {UserContext} from "../../App"
import helper from "./Helper"
import ImageUpload from "./ImageUpload"

const useStyles = makeStyles((theme) => ({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 120,
        clear: `both`,
    },
    formControlButton: {
        marginTop: theme.spacing(1),
        minWidth: 120,
        clear: `both`,
        float: "right",
    },
    formControlLine: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        display: `flex`,
        float: `left`,
    },
    formColorPicker: {
        "& > div": {
            width: "100%",
            "& .MuiFormControl-root": {
                width: "100%",
            },
            "& .muicc-colorpicker-button": {
                margin: "6px 6px 0 0",
            },
        },
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    inputHidden: {
        margin: 0,
        padding: 0,
        border: 0,
        width: `0%`,
        whiteSpace: "nowrap",
        visibility: `hidden`,
        display: `block`,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectHeight: {
        maxHeight: 196,
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3",
        },
    },
    helperText: {
        fontStyle: "italic",
    },
    input: {
        display: "none",
    },
    formControlUpload: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        minWidth: 120,
        minHeight: 120,
        clear: `both`,
        display: `inline-block`,
        position: "relative",
    },
    image: {
        maxWidth: 150,
        maxHeight: 150,
        float: `left`,
        marginRight: theme.spacing(3),
    },
    img: {
        margin: "auto",
        display: "block",
        width: 200,
        maxWidth: "100%",
        maxHeight: "100%",
    },
    labelUpload: {
        position: "absolute",
        top: 0,
        left: 160,
    },
}))

export function NumberFormatCustom(props) {
    const user = useContext(UserContext)

    const {inputRef, onChange, decimalScale, ...other} = props

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onKeyDown={(event) => {
                switch (event.key) {
                    case "ArrowDown":
                        onChange({
                            target: {
                                name: props.name,
                                value: user.parseStringNumber(event.target.value || "0") - 1,
                            },
                        })
                        break
                    case "ArrowUp":
                        onChange({
                            target: {
                                name: props.name,
                                value: user.parseStringNumber(event.target.value || "0") + 1,
                            },
                        })
                        break
                    default:
                        break
                }
            }}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            thousandSeparator={user.restLang === "mk" ? "." : ","}
            decimalSeparator={user.restLang === "mk" ? "," : "."}
            decimalScale={decimalScale}
            isNumericString
            // prefix={props.name === 'price' || props.name === 'amount' ? user.icu.getCurrencySymbol() : undefined}
        />
    )
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    decimalScale: PropTypes.number.isRequired,
}

export default function Form(props) {
    const classes = useStyles()
    const user = useContext(UserContext)

    let fieldList = {...props.fieldList}
    let fieldItem = {...props.fieldList[props.item]}

    const [value, setValue] = useState(fieldItem.fieldValue)

    const data = new FormData()

    const onOpenList = () => {
        props.setOpenSelectBox(true)
    }

    function StyledRadio(props) {
        const classes = useStyles()

        return (
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
                icon={<span className={classes.icon}/>}
                {...props}
            />
        )
    }

    const [errorObj, setErrorObj] = useState({
        error: fieldItem.error ? fieldItem.error : false,
        // helperText: ""
    })
    const [showPassword, setShowPassword] = useState(false)

    const handleDateChange = (date) => {
        if (date !== `Invalid Date` && fieldItem.fieldValue !== date) {
            setValue(date)
            setErrorObj({
                error: false,
                // helperText: ""
            })

            if (fieldItem.formatDate) props.setItemsFunction(fieldItem.fieldName, user.formatDate(date, false, "yyyy-MM-DD"))
            else props.setItemsFunction(fieldItem.fieldName, new Date(date).getTime())

            fieldList[props.item] = {...fieldItem, fieldValue: date, error: false}
            props.setStateObj({...fieldList})
        } else {
            fieldList[props.item] = {
                ...fieldItem,
                fieldValue: fieldItem.fieldValue,
                error: fieldItem.required ? true : undefined,
            }
            props.setStateObj({...fieldList})
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const uploadFile = (fieldItem, event) => {
        data.append("file", event.target.files[0])

        axios
            .post(`${config.apiUrl}/uploadImage`, data, {
                headers: {"Content-Type": "multipart/form-data"},
                withCredentials: "include",
            })
            .then((response) => {
                // then print response status
                user.consoleLog(response.data[0])
                fieldItem.fieldValue = response.data[0]

                fieldList[props.item] = fieldItem
                props.setStateObj({...fieldList})

                setValue(response.data[0])

                props.setItemsFunction(fieldItem.fieldName, fieldItem.fieldValue)
            })
            .catch((error) => {
                if (error.response) {
                    user.consoleLog(error.response)
                }
                user.consoleLog(error.message)

                fieldList[props.item] = {...fieldItem, fieldValue: "", error: fieldItem.required ? true : undefined}
                props.setStateObj({...fieldList})
            })
    }

    const onImageUploaded = (fieldItem, imageUrl) => {
        fieldItem.fieldValue = imageUrl

        fieldList[props.item] = fieldItem
        props.setStateObj({...fieldList})

        setValue(imageUrl)

        props.setItemsFunction(fieldItem.fieldName, fieldItem.fieldValue)
    }

    const clearImage = (fieldItem) => {
        fieldItem.fieldValue = ""

        fieldList[props.item] = fieldItem
        props.setStateObj({...fieldList})

        setValue("")

        props.setItemsFunction(fieldItem.fieldName, fieldItem.fieldValue)
    }

    // const filterOptions = createFilterOptions({
    //     matchFrom: 'any',
    //     stringify: option => option.label.cirilicToLatin(),
    // });

    const filterOptions = (options, params) =>
        options.filter((option) => option.label.cirilicToLatin().indexOf(params.inputValue.cirilicToLatin()) > -1)

    const checkErrorsFunctionAutoselect = (fieldItem, value) => {
        if (value !== null) {
            if (typeof value.inputValue !== "undefined" && value.inputValue !== "") {
                //add new autocompleteAdd
                fieldItem.fieldValue = {
                    val: value.inputValue,
                    label: value.inputValue,
                }

                if (fieldItem.wholeValueId === true) {
                    let fieldId = fieldItem.fieldName
                    let fieldName = fieldId.replace("Id", "Name")
                    props.setItemsFunction([fieldId, fieldName], [helper.uid(), value.inputValue], true)
                } else props.setItemsFunction(fieldItem.fieldName, helper.uid())
            } else if (typeof value.val !== "undefined" && value.val !== "") {
                fieldItem.fieldValue = value

                if (fieldItem.wholeValueId === true) {
                    let fieldId = fieldItem.fieldName
                    let fieldName = fieldId.replace("Id", "Name")
                    props.setItemsFunction([fieldId, fieldName], [value.val, value.label])
                } else if (fieldItem.fieldName === "stockType") {
                    props.setItemsFunction(
                        ["stockType", "uom"],
                        [value.val, value.label.indexOf(user.translate("piece")) > -1 ? 2 : 0]
                    )
                } else props.setItemsFunction(fieldItem.fieldName, value.val)
            }
            fieldList[props.item] = {...fieldItem, fieldValue: fieldItem.fieldValue, error: false}
        } else {
            fieldList[props.item] = {
                ...fieldItem,
                fieldValue: {val: "", label: ""},
                error: fieldItem.required ? true : undefined,
            }
            props.setItemsFunction(fieldItem.fieldName, fieldItem.nullValue ?? "")
        }

        props.setStateObj({...fieldList})
    }

    const checkErrorsFunction = (fieldItem, event) => {
        let error = false

        var value = fieldItem.fieldType == "checkbox" ? (event.target.checked ? 1 : 0) : event.target.value
        setValue(value)

        fieldItem.fieldValue = value

        if (event.target.required && value === "") {
            fieldItem.error = true
            error = true

            setErrorObj({
                error: true,
                // helperText: "Please fill out this field"
            })
        }

        if (event.target.type === "email") {
            let re = /\S+@\S+\.\S+/

            if (value !== "" && !re.test(String(value).toLowerCase())) {
                fieldItem.error = true
                error = true

                setErrorObj({
                    error: true,
                    // helperText: "Please enter a valid email address"
                })
            }
        }

        if (!error) {
            fieldItem.error = false
            fieldItem.helperText = ""

            setErrorObj({
                error: false,
                // helperText: ""
            })
        }

        props.setItemsFunction(fieldItem.fieldName, fieldItem.fieldValue)
        fieldList[props.item] = fieldItem
        props.setStateObj({...fieldList})
    }

    const changeColor = (fieldItem, color) => {
        setValue(color)

        props.setItemsFunction(color, fieldItem.fieldName, "#" + color.hex)

        fieldItem.fieldValue = "#" + color.hex
        fieldList[props.item] = fieldItem
        props.setStateObj({...fieldList})
    }

    const changeValue = (fieldItem, value) => {
        props.setItemsFunction(fieldItem.fieldName, value)

        fieldItem.fieldValue = value
        fieldList[props.item] = fieldItem
        props.setStateObj({...fieldList})
    }

    const renderForm = (fieldItem) => {
        if (typeof fieldItem !== "undefined") {
            let labelId = "label_" + fieldItem.fieldLabel
            let fieldId = "id_" + fieldItem.fieldName

            if (fieldItem.fieldType === "text") {
                if (fieldItem.type !== "hidden") {
                    if (props.product && fieldItem.fieldName === "name") {
                        return (
                            <div>
                                <TextField
                                    required={fieldItem.required}
                                    type={fieldItem.type}
                                    className={classes.textField}
                                    name={fieldItem.fieldName}
                                    label={fieldItem.fieldLabel}
                                    fullWidth
                                    value={fieldItem.fieldValue}
                                    onClick={(event) => onOpenList()}
                                    onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                    error={errorObj.error}
                                    // helperText={errorObj.helperText}
                                />
                                <FormHelperText className={classes.helperText}>
                                    {user.translate("click_to_select_product")}
                                </FormHelperText>
                            </div>
                        )
                    } else if (props.categoryIcon && fieldItem.fieldName === "icon") {
                        return (
                            <div>
                                <TextField
                                    required={fieldItem.required}
                                    type={fieldItem.type}
                                    className={classes.textField}
                                    name={fieldItem.fieldName}
                                    label={fieldItem.fieldLabel}
                                    fullWidth
                                    value={fieldItem.fieldValue}
                                    onClick={(event) => onOpenList()}
                                    error={errorObj.error}
                                    // helperText={errorObj.helperText}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {fieldItem.fieldIcon && <FontAwesomeIcon icon={fieldItem.fieldIcon}/>}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FormHelperText className={classes.helperText}>{user.translate("click_to_select_icon")}</FormHelperText>
                            </div>
                        )
                    } else if (fieldItem.type === "password_show") {
                        return (
                            <div>
                                <TextField
                                    required={fieldItem.required}
                                    type={showPassword ? "text" : "password"}
                                    className={classes.textField}
                                    name={fieldItem.fieldName}
                                    label={fieldItem.fieldLabel}
                                    fullWidth
                                    defaultValue={fieldItem.fieldValue}
                                    onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                    onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                    error={errorObj.error}
                                    // helperText={errorObj.helperText}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        )
                    } else if (fieldItem.type === "number") {
                        return (
                            <FormGroup className={props.line ? classes.formControlLine : ""}>
                                <FormControl className={!props.line ? classes.formControl : ""}>
                                    <TextField
                                        required={fieldItem.required}
                                        name={fieldItem.fieldName}
                                        // type={fieldItem.type}
                                        label={fieldItem.fieldLabel}
                                        fullWidth
                                        value={fieldItem.fieldValue}
                                        onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                        // onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                        error={errorObj.error}
                                        helperText={fieldItem.helperText || undefined}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                            autoComplete: "off",
                                        }}
                                        inputProps={{decimalScale: fieldItem.decimalScale || 2}}
                                    />
                                </FormControl>
                            </FormGroup>
                        )
                    } else {
                        return (
                            <FormGroup
                                className={
                                    (props.product && fieldItem.type === "select") || fieldItem.line ? classes.formControlLine : ""
                                }
                            >
                                <FormControl className={!props.line ? classes.formControl : ""}>
                                    <TextField
                                        required={fieldItem.required}
                                        type={fieldItem.type}
                                        disabled={fieldItem.type === "disabled" ? true : undefined}
                                        name={fieldItem.fieldName}
                                        label={fieldItem.fieldLabel}
                                        fullWidth
                                        defaultValue={fieldItem.fieldValue}
                                        onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                        onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                        error={errorObj.error}
                                        helperText={fieldItem.helperText || undefined}
                                    />
                                </FormControl>
                            </FormGroup>
                        )
                    }
                } else {
                    return (
                        <div>
                            <Input
                                required={fieldItem.required}
                                type={fieldItem.type}
                                className={classes.inputHidden}
                                name={fieldItem.fieldName}
                                label={fieldItem.fieldLabel}
                                multiline={fieldItem.multiline}
                                fullWidth
                                value={fieldItem.fieldValue}
                                onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                error={errorObj.error}
                                // helperText={errorObj.helperText}
                            />
                        </div>
                    )
                }
            } else if (fieldItem.fieldType === "textarea") {
                return (
                    <div>
                        <TextField
                            required={fieldItem.required}
                            type={fieldItem.type}
                            name={fieldItem.fieldName}
                            className={classes.textField}
                            id={fieldId}
                            label={fieldItem.fieldLabel}
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            defaultValue={fieldItem.fieldValue}
                            onChange={(event) => checkErrorsFunction(fieldItem, event)}
                            onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                            error={errorObj.error}
                            // helperText={errorObj.helperText}
                        />
                    </div>
                )
            } else if (fieldItem.fieldType === "richText") {
                return (
                    <div className={classes.formControlLine}>
                        <RichTextEditor
                            style={{width: "100%"}}
                            theme="snow"
                            name={fieldItem.fieldName}
                            label={fieldItem.fieldLabel}
                            value={fieldItem.fieldValue}
                            onChange={(value) => changeValue(fieldItem, value)}
                        />
                    </div>
                )
            } else if (fieldItem.fieldType === "select") {
                return (
                    <FormGroup className={props.line ? classes.formControlLine : ""}>
                        <FormControl className={!props.line ? classes.formControl : ""}>
                            <InputLabel id={labelId} required={fieldItem.required}>
                                {fieldItem.fieldLabel}
                            </InputLabel>
                            <Select
                                labelId={labelId}
                                id={fieldId}
                                name={fieldItem.fieldName}
                                fullWidth
                                MenuProps={{classes: {paper: classes.selectHeight}}}
                                value={fieldItem.fieldValue}
                                onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                            >
                                {typeof fieldItem.noNone === "undefined" && (
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                )}

                                {fieldItem.fieldOptions.map(
                                    (val) =>
                                        val.label !== "" && (
                                            <MenuItem key={val.val} value={val.val}>
                                                {val.label}
                                            </MenuItem>
                                        )
                                )}
                            </Select>
                            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
                        </FormControl>
                    </FormGroup>
                )
            } else if (fieldItem.fieldType === "autocomplete") {
                return (
                    <>
                        {props.commissioners && fieldItem.fieldName === "senderId" && (
                            <Button
                                className={classes.formControlButton}
                                color="primary"
                                component="span"
                                onClick={onOpenList}
                                startIcon={<SubtitlesIcon/>}
                            >
                                {user.translate("komitents")}
                            </Button>
                        )}
                        <FormGroup className={props.line ? classes.formControlLine : ""}>
                            <FormControl className={!props.line ? classes.formControl : ""}>
                                <Autocomplete
                                    id={fieldId}
                                    name={fieldItem.fieldName}
                                    fullWidth
                                    autoHighlight
                                    freeSolo
                                    selectOnFocus
                                    handleHomeEndKeys
                                    options={fieldItem.fieldOptions}
                                    value={fieldItem.fieldValue}
                                    filterOptions={filterOptions}
                                    onChange={(event, value) => checkErrorsFunctionAutoselect(fieldItem, value)}
                                    getOptionSelected={(option, value) => option.label === value.label}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => {
                                        params.inputProps.autoComplete = "off"
                                        return (
                                            <TextField
                                                {...params}
                                                label={fieldItem.fieldLabel}
                                                required={fieldItem.required}
                                                error={fieldItem.error}
                                            />
                                        )
                                    }}
                                />
                            </FormControl>
                        </FormGroup>
                    </>
                )
            } else if (fieldItem.fieldType === "autocompleteAdd") {
                return (
                    <FormGroup className={props.line ? classes.formControlLine : ""}>
                        <FormControl className={!props.line ? classes.formControl : ""}>
                            <Autocomplete
                                id={fieldId}
                                name={fieldItem.fieldName}
                                fullWidth
                                autoHighlight
                                freeSolo
                                selectOnFocus
                                handleHomeEndKeys
                                options={fieldItem.fieldOptions}
                                value={fieldItem.fieldValue}
                                onChange={(event, value) => checkErrorsFunctionAutoselect(fieldItem, value)}
                                getOptionSelected={(option, value) => option.label === value.label}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === "string") {
                                        return option
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue
                                    }
                                    // Regular option
                                    return option.label
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filterOptions(options, params)

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== "") {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            label: `Add "${params.inputValue}"`,
                                        })
                                    }

                                    return filtered
                                }}
                                renderOption={(option) => option.label}
                                renderInput={(params) => {
                                    params.inputProps.autoComplete = "off"
                                    return (
                                        <TextField
                                            {...params}
                                            label={fieldItem.fieldLabel}
                                            required={fieldItem.required}
                                            error={fieldItem.error}
                                        />
                                    )
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                )
            } else if (fieldItem.fieldType === "checkbox") {
                return (
                    <FormGroup row className={props.line ? classes.formControlLine : ""}>
                        <FormControlLabel
                            key={"checkbox_" + fieldItem.name}
                            label={fieldItem.fieldLabel}
                            control={
                                <Checkbox
                                    required={fieldItem.required}
                                    name={fieldItem.fieldName}
                                    color="primary"
                                    fullWidth
                                    checked={fieldItem.fieldValue}
                                    onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                    onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                    error={errorObj.error}
                                    // helperText={errorObj.helperText}
                                />
                            }
                        />
                    </FormGroup>
                )
            } else if (fieldItem.fieldType === "radio") {
                return (
                    <FormGroup row className={classes.formControl}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">{fieldItem.fieldLabel}</FormLabel>
                            <RadioGroup
                                row
                                required={fieldItem.required}
                                aria-label={fieldItem.fieldName}
                                name={fieldItem.fieldName}
                                defaultValue={fieldItem.fieldValue}
                                onChange={(event) => checkErrorsFunction(fieldItem, event)}
                                onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                                // helperText={errorObj.helperText}
                            >
                                {fieldItem.fieldOptions.map((val, indexx) => (
                                    <FormControlLabel
                                        key={"radio_" + indexx}
                                        value={val.val}
                                        control={<StyledRadio/>}
                                        label={val.label}
                                    />
                                ))}
                            </RadioGroup>
                            {/*<FormHelperText>Choose wisely helper text</FormHelperText>*/}
                        </FormControl>
                    </FormGroup>
                )
            } else if (fieldItem.fieldType === "date" && fieldItem.type !== "hidden") {
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.formControl}>
                        <Grid container justify="flex-start">
                            <KeyboardDatePicker
                                disableToolbar={fieldItem.type === "dateyear" ? false : true}
                                autoOk
                                variant="inline"
                                format={fieldItem.fieldFormat ? fieldItem.fieldFormat : user.dateFormat}
                                required={fieldItem.required}
                                name={fieldItem.fieldName}
                                className={classes.textField}
                                id={fieldId}
                                label={fieldItem.fieldLabel}
                                value={value}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                error={errorObj.error}
                                // helperText={errorObj.helperText}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                )
            } else if (fieldItem.fieldType === "datetime" && fieldItem.type !== "hidden") {
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.formControl}>
                        <Grid container justify="flex-start">
                            <KeyboardDateTimePicker
                                disableToolbar={fieldItem.type === "dateyear" ? false : true}
                                autoOk
                                variant="inline"
                                format={fieldItem.fieldFormat ? fieldItem.fieldFormat : user.dateTimeFormat}
                                required={fieldItem.required}
                                name={fieldItem.fieldName}
                                className={classes.textField}
                                id={fieldId}
                                label={fieldItem.fieldLabel}
                                value={value}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                error={errorObj.error}
                                // helperText={errorObj.helperText}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                )
            } else if (fieldItem.fieldType === "upload" && fieldItem.type !== "hidden") {
                return (
                    <div className={classes.formControlUpload}>
                        <Grid item className={classes.image}>
                            <img
                                className={classes.img}
                                alt={user.translate("upload_image")}
                                src={`${value !== null && value.indexOf("http") === -1 ? config.apiImagesUrl + "/" : ""}${value}`}
                            />
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            name={fieldItem.fieldName}
                            onChange={(event) => uploadFile(fieldItem, event)}
                            onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                        />
                        <div className={classes.labelUpload}>
                            <label htmlFor="contained-button-file">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{width: 130}}
                                    component="span"
                                    startIcon={<CloudUploadIcon/>}
                                >
                                    {user.translate("upload")}
                                </Button>
                            </label>
                            <label>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => clearImage(fieldItem)}
                                    style={{marginTop: 8, width: 130}}
                                    component="span"
                                    startIcon={<ClearIcon/>}
                                >
                                    {user.translate("remove")}
                                </Button>
                            </label>
                        </div>
                    </div>
                )
            } else if (fieldItem.fieldType === "imageUpload" && fieldItem.type !== "hidden") {
                return (
                    <div className={classes.formControlUpload}>
                        <Typography>{user.translate(fieldItem.fieldLabel)}</Typography>
                        <ImageUpload
                            className={classes.img}
                            id={fieldId}
                            imageUrl={
                                value !== null &&
                                value.length > 3 &&
                                `${value.indexOf("http") === -1 ? config.apiImagesUrl + "/" : ""}${value}`
                            }
                            onImageUploaded={(imageUrl) => onImageUploaded(fieldItem, imageUrl)}
                            onRemove={() => onImageUploaded(fieldItem, "")}
                        />

                        {/*
                        <Grid item className={classes.image}>
                            <img className={classes.img} alt={user.translate("upload_image")} src={`${value !== null && value.indexOf("http") === - 1 ? config.apiImagesUrl + "/" : ""}${value}`} />
                        </Grid>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            name={fieldItem.fieldName}
                            onChange={(event) => uploadFile(fieldItem, event)}
                            onBlur={(event) => checkErrorsFunction(fieldItem, event)}
                        />
                        <label htmlFor="contained-button-file" className={classes.labelUpload}>
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                            >
                                {user.translate("upload")}
                            </Button>
                        </label> */}
                    </div>
                )
            } else if (fieldItem.fieldType === "colorPicker") {
                const paletteObj = {
                    red: "#ff0000",
                    blue: "#0000ff",
                    green: "#00ff00",
                    yellow: "#FFFF00",
                    cyan: "#00FFFF",
                    lime: "#00FF00",
                    gray: "#808080",
                    orange: "#FFA500",
                    purple: "#800080",
                    black: "#000000",
                    white: "#FFFFFF",
                    pink: "#FFC0CB",
                    darkblue: "#00008B",
                }

                return (
                    <FormGroup>
                        <FormControl className={`${classes.formControl} ${classes.formColorPicker}`}>
                            <ColorPicker
                                name={fieldItem.fieldName}
                                required={fieldItem.required}
                                fullWidth
                                autoOk
                                value={value}
                                palette={paletteObj}
                                onChange={(color) => changeColor(fieldItem, color)}
                            />
                            <FormHelperText>{fieldItem.fieldLabel}</FormHelperText>
                        </FormControl>
                    </FormGroup>
                )
            }
        }

        console.error("Nothing to render", fieldItem.fieldType)
    }

    return renderForm(fieldItem)
}

function RichTextEditor(props) {
    const [value, setValue] = useState()

    const onChange = (content, delta, source, editor) => {
        if (source == "api") return
        setValue({content, html: editor.getHTML()})
    }

    const onSubmit = () => {
        if (value?.html == undefined) return
        props.onChange(value?.html)
        setValue()
    }

    useEffect(() => {
        if (value == undefined || value?.content == props.value) return

        const timer = setTimeout(() => {
            onSubmit()
        }, 700)

        return () => {
            clearTimeout(timer)
        }
    }, [value])

    return (
        <ReactQuill
            {...props}
            value={value?.content != undefined ? value?.content : props.value}
            style={{width: "100%"}}
            theme="snow"
            modules={{
                toolbar: [
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    ["blockquote", "code-block"],

                    [{list: "ordered"}, {list: "bullet"}],
                    [{script: "sub"}, {script: "super"}], // superscript/subscript
                    [{indent: "-1"}, {indent: "+1"}], // outdent/indent
                    [{direction: "rtl"}], // text direction

                    [{size: ["small", false, "large", "huge"]}], // custom dropdown
                    [{header: [1, 2, 3, 4, 5, 6, false]}],

                    [{color: []}, {background: []}], // dropdown with defaults from theme
                    [{font: []}],
                    [{align: []}],

                    ["clean"],
                ],
            }}
            onBlur={onSubmit}
            onChange={onChange}
        />
    )
}
