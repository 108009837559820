import {
    AppBar,
    Box, makeStyles,
    Tab,
    Tabs
} from '@material-ui/core';
import React, { useContext } from 'react';
import { RestaurantContext, UserContext } from "../../App";
import RestaurantConfigInt from './RestaurantConfigInt';
import RestaurantConfigTxt from './RestaurantConfigTxt';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: 3
        }
    },
    tabs: {
        display: `grid`,
    },
    gridList: {
        flexGrow: 1,
        width: `100%`
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function RestaurantConfig(props) {
    const classes = useStyles();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.tabs} position="static" color="default">
                <Tabs value={value} onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    scrollButtons="auto"
                    aria-label="Restaurant configs">
                    <Tab label={user.translate('MAIN_SETTINGS')} {...a11yProps(0)} />
                    <Tab label={user.translate('TEXT_SETTINGS')} {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.gridList}>
                <RestaurantConfigInt {...props} />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.gridList}>
                <RestaurantConfigTxt {...props} />
            </TabPanel>
        </div>
    );

}



