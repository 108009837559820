import React, { useContext, useState } from "react"
import { UserContext } from "../../App"
import Report from "../common/Report"

// React Function Component
export default function EtReport(props) {
  const user = useContext(UserContext)
  const [page, setPage] = useState(0)

  const startDate = new Date();
  const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(5, 0, 0, 0);

  let params = {
    query: {
      name: "report_retailRecords",
      dateInit: "$dateFrom: Long!, $dateTo: Long!",
      date: "dateFrom: $dateFrom, dateTo: $dateTo",
      dateVars: {
        dateFrom: startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      results: `
                id
                bookDate
                documentType
                documentTypeName
                documentNum
                documentDate
                dealerCost
                totalSaleValue
                totalSalesAmount
            `,
      columnsPush: ["id"],
    },
    title: user.translate("et_report"),
    titleName: user.translate("etreport"),
    key: "et_report_",
    page: page,
    setPage: setPage,
    datetime: true,
    skipRowNum: (item) =>
      item.documentType === "INITIAL" ||
      item.documentType === "TOTAL" ||
      item.documentType === "PERIOD_TOTAL" ||
      item.documentType === "STATE",
    onItem: (item) => {
      if (item.documentType === "TOTAL" || item.documentType === "PERIOD_TOTAL") {
        item.bookDate = ""
        item.documentDate = ""
        // item.documentTypeName = <b>{item.documentTypeName}</b>
        // item.dealerCost = <b>{item.dealerCost}</b>
        // item.totalSaleValue = <b>{item.totalSaleValue}</b>
        // item.totalSalesAmount = <b>{item.totalSalesAmount}</b>
      } else if (item.documentType === "STATE") {
        item.bookDate = ""
        item.documentDate = ""
        item.dealerCost = ""
        item.totalSaleValue = ""
        // item.documentTypeName = <b>{item.documentTypeName}</b>
        // item.totalSalesAmount = <b>{item.totalSalesAmount}</b>
      } else if (item.documentType === "INITIAL") {
        item.bookDate = ""
      }
    },
  }

  return <Report {...params} />
}
