import { gql, useQuery } from "@apollo/client";
import {
  alpha,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { RestaurantContext, UserContext } from "../../../App";
import { If } from "../../common/If";
import Show from "../../common/Show";
import TableReport from "../../common/TableReport";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0, // display: 'inline-flex',
    flexWrap: "wrap",
    justifyContent: "space-around", // overflow: 'hidden',
  },
  appBar: {
    position: "relative",
  },
  gridItem: {
    maxWidth: 290,
    minWidth: 240,
    flexGrow: 1,
  },
  itemName: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
      cursor: `pointer`,
    },
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 600,
    height: 40,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));

export default function InvoiceItemReceiptView(props) {
  const classes = useStyles();
  // Get current user preferences (language)
  const user = useContext(UserContext);
  const restaurant = useContext(RestaurantContext);
  const [receiptId, setReceiptId] = useState(
    props.receiptId || props.receipt.receipt.id
  );
  let receipt = {};

  const history = useHistory();

  // GraphQL API request definition (local variables: restaurantId)
  const GET_RECEIPT_BY_ID = gql`
    query ($receiptId: String!, $restaurantId: String!) {
      getReceiptById(id: $receiptId) {
        receiptNum
        totalPrice
        closed
        discount
        orderType
        comment
        status
        canceledBy
        closedBy
        customerId
        commissionerId
        receiptInvoiceItems {
          invoiceItem {
            invoiceId
          }
        }
        orders {
          quantity
          status
          employee {
            profile {
              name
            }
          }
          orderItems {
            product {
              name
            }
            quantity
            taxRate
            price
            discount
            refOrderItemId
            created
            updated
          }
        }
      }
      getRestaurantEmployeesByContextRestaurantId(restaurantId: $restaurantId) {
        employee {
          id
          profile {
            name
          }
        }
      }
    }
  `;

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const {
    data: data_receipt,
    loading: loading_receipt,
    error: error_receipt,
  } = useQuery(GET_RECEIPT_BY_ID, {
    variables: { receiptId: receiptId, restaurantId: restaurant.id },
    pollInterval: 3000,
    skip: !receiptId,
    fetchPolicy: "no-cache",
  });

  if (loading_receipt) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    );
  }

  // In case there is an error, just show it for now
  if (!data_receipt) {
    user.consoleLog(error_receipt);
    return <p>Error</p>;
  }

  let orders = {},
    receiptReport = {};

  if (data_receipt) {
    receipt = data_receipt.getReceiptById;
    orders = receipt.orders;
  }

  receiptReport["columns"] = [];
  receiptReport["data"] = [];

  receiptReport["columns"] = [
    {
      title: user.translate("title"),
      field: "name",
      cellStyle: {
        width: `100%`,
        padding: `0 1px`,
        fontSize: 14,
        whiteSpace: "pre-line",
      },
      editable: "never",
    },
    {
      width: "85px",
      title: user.translate("quantity"),
      field: "quantity",
      editable: "never",
    },
    {
      width: "65px",
      title: user.translate("tax_rate"),
      field: "taxRate",
      editable: "never",
      cellStyle: { padding: `0 6px`, fontSize: 12, whiteSpace: "nowrap" },
    },
    {
      width: "65px",
      title: user.translate("amount"),
      field: "price",
      editable: "never",
    },
    {
      width: "65px",
      title: user.translate("discount"),
      field: "discount",
      editable: "never",
    },
    {
      width: "65px",
      title: user.translate("employee"),
      field: "employee",
      editable: "never",
    },
    {
      width: "120px",
      title: user.translate("time"),
      field: "time",
      editable: "never",
      cellStyle: { padding: `0 6px`, fontSize: 12, whiteSpace: "nowrap" },
    }, // {
    //     width: "65px",
    //     title: user.translate("updated"),
    //     field: "updated",
    //     editable: "never",
    //     render: (item) => item.updated && new Date(item.updated).toLocaleString()
    // },
    {
      width: "65px",
      title: user.translate("status"),
      field: "status",
      editable: "never",
    },
  ];

  const statuses = [
    user.translate("order_ordering"),
    user.translate("order_ordered"),
    user.translate("order_prepairing"),
    user.translate("order_ready"),
    user.translate("order_finished"),
    user.translate("order_canceled"),
  ];

  const statusBgColor = [
    "#CCF",
    "#f6f6fb",
    "#f8f9ee",
    "#e3fbf5",
    "#FFF",
    "#fff9f9",
  ];
  const statusTxColor = [
    "#00F",
    "#004",
    "#585e00",
    "#00422a",
    "#000",
    "#f10000",
  ];

  let totalPrice = 0;
  orders.forEach((order) => {
    const status = statuses[order.status];
    const employeeName = order.employee?.profile?.name ?? "";
    const employee = employeeName.split(" ");

    let orderItems = order.orderItems.map((orderItem) => {
      const price = orderItem.price * orderItem.quantity * order.quantity;
      if (order.status !== 5) {
        totalPrice += price;
      }
      return {
        ...orderItem,
        status: status,
        statusCode: order.status,
        name:
          orderItem.refOrderItemId !== null
            ? "+ " + orderItem.product.name
            : orderItem.product.name,
        quantity: order.quantity * orderItem.quantity,
        taxRate: user.formatNumber(orderItem.taxRate, true),
        price: user.formatNumber(price),
        discount: If(orderItem.discount === -1, "/")
          .elseIf(orderItem.discount === 0, "")
          .elseIf(
            Math.abs(orderItem.discount) < 1,
            user.formatNumber(orderItem.discount * 100) + "%"
          )
          .else(orderItem.discount + "ден"),
        employee:
          employee.length > 1
            ? employee[0] + " " + employee[1].substring(0, 3)
            : employeeName,
        time: orderItem.created && new Date(orderItem.created).toLocaleString(),
      };
    });
    receiptReport["data"] = receiptReport["data"].concat(orderItems);
  });
  if (receipt.discount !== 0) {
    receiptReport["data"].push({
      taxRate: `${user.translate("totalNoDiscount")}:`,
      price: user.formatNumber(totalPrice), //<b>{receipt.totalPrice}</b>
    });
    receiptReport["data"].push({
      taxRate: `${user.translate("discount")}:`,
      price: user.formatNumber(receipt.discount, true), //<b>{receipt.totalPrice}</b>
    });
  }
  receiptReport["data"].push({
    statusCode: receipt.status == 2 ? 5 : 4,
    taxRate: `${user.translate("total")}:`,
    price: user.formatNumber(receipt.totalPrice), //<b>{receipt.totalPrice}</b>
  });

  let orderTypes = {
    0: user.translate("in_restaurant"),
    1: user.translate("take_away"),
    2: user.translate("catering"),
  };

  let issuingDate = ``;
  var exportFileName = `${user.translate("receipt")}_${
    receipt.receiptNum
  }_${user.translate("total")}_${receipt.totalPrice}_${user
    .translate("order_type")
    .replaceAll(" ", "_")}_${orderTypes[receipt.orderType].replaceAll(
    " ",
    "_"
  )}`;
  let tableName = `${user.translate("receipt")} ${
    receipt.receiptNum
  } / ${user.translate("total")}: ${user.formatNumber(
    receipt.totalPrice
  )} / ${user.translate("order_type")}: ${orderTypes[receipt.orderType]}`;

  if (receipt.status == 2) {
    tableName = (
      <>
        <span style={{ fontSize: 16 }}>{tableName}</span>
        &nbsp;&nbsp;&nbsp;
        <span
          style={{
            color: "red",
            fontVariant: "all-small-caps",
            fontWeight: 600,
          }}
        >
          {user.translate("order_canceled")}
        </span>
      </>
    );
  }

  if (!props.receiptId) {
    issuingDate = `${user.translate("issuing_date")}: ${user.formatDate(
      parseInt(receipt.closed),
      true
    )}`;
    tableName += `/ ${issuingDate}`;
    exportFileName += `_${issuingDate.replaceAll(" ", "_")}`;
  }

  const employees =
    data_receipt?.getRestaurantEmployeesByContextRestaurantId?.toMapBy(
      (item) => item.employee.id,
      (item) => item.employee.profile.name
    ) ?? [];

  const invoiceIds =
    receipt?.receiptInvoiceItems?.flatMap(
      (receiptInvoiceItem) => receiptInvoiceItem.invoiceItem.invoiceId
    ) ?? [];

  console.log("invoiceIds", invoiceIds);

  return (
    <>
      <DialogContent>
        <div className={classes.root}>
          {!loading_receipt && (
            <TableReport
              tableName={tableName}
              exportFileName={exportFileName}
              fieldReport={receiptReport}
              rowStyle={(rowData) => ({
                backgroundColor: statusBgColor[rowData.statusCode],
                color: statusTxColor[rowData.statusCode],
                textDecoration:
                  rowData.statusCode == 5 ? "line-through" : "none",
              })}
              pageSize="100"
              pageSizeOptions={[50, 100, 200]}
              search={false}
              export={true}
              paging={true}
            />
          )}
          <Show if={parseInt(receipt?.closedBy ?? "0")}>
            <Typography
              variant="caption"
              color="secondary"
              style={{ marginRight: 24, lineHeight: 1.5 }}
            >
              <b>{user.translate("closedBy")}</b>:{" "}
              {employees[receipt?.closedBy]}
            </Typography>
          </Show>
          <Show if={parseInt(receipt?.canceledBy ?? "0")}>
            <Typography
              variant="caption"
              color="error"
              style={{ lineHeight: 1.5 }}
            >
              <b>{user.translate("canceledBy")}</b>:{" "}
              {employees[receipt?.canceledBy]}
            </Typography>
          </Show>
          <Show if={receipt?.comment != undefined && receipt?.comment != ""}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ display: "block" }}
            >
              {user.translate("note")}:
            </Typography>
            <Typography variant="subtitle2">{receipt?.comment}</Typography>
          </Show>
        </div>
      </DialogContent>

      <DialogActions>
        {(receipt?.customerId || receipt?.commissionerId) && !invoiceIds?.length && (
          <Button onClick={props.onCreateInvoice} color="primary">
            {user.translate("create_invoice")}
          </Button>
        )}
        {!!invoiceIds?.length && (
          <Button
            onClick={() => {
              history.push(
                `/restaurant/${restaurant._id}/cash_invoice_output/${
                  invoiceIds[invoiceIds.length - 1]
                }`,
                { from: "list" }
              );
            }}
            color="primary"
          >
            {user.translate("open_invoice")}
          </Button>
        )}
        <Button onClick={props.onToggleClose} color="primary">
          {user.translate("cancel")}
        </Button>
      </DialogActions>
    </>
  );
}
