import {gql, useMutation, useQuery} from '@apollo/client';
import {AppBar, Badge, CircularProgress, IconButton, makeStyles, Snackbar, Toolbar, Tooltip, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from '@material-ui/core/styles';
import AddBox from '@material-ui/icons/AddBox';
import MenuBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AlignWithWarehouseIcon from '@material-ui/icons/AssignmentReturn';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import DoneIcon from '@material-ui/icons/Done';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import InventoryAlignIcon from '@material-ui/icons/PlaylistAddCheck';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable, {MTableHeader} from 'material-table';
import PropTypes from 'prop-types';
import React, {forwardRef, useContext, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import Switch, {Case, Default} from 'react-switch-case';
import {RestaurantContext, UserContext} from "../../App";
import useWindowSize from '../../restaurant/common/useWindowSize';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import CustomTheme from '../common/CustomTheme';
import InventoryCountingActionDialog from './InventoryCountingActionDialog';
import InventoryCountingItemCountingsDialog from './InventoryCountingItemCountingsDialog';
import InventoryCountingItemDialog from './InventoryCountingItemDialog';

const useStyles = makeStyles((theme) => ({
    roots: {
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: theme.spacing(1)
        }
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'left',
        padding: 0
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        padding: 0,
        justifyContent: 'space-evenly',
    },
    item: {
        // width: '100%',
        maxWidth: 320,
        minWidth: 40,
        margin: theme.spacing(1)
    },
    itemTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        '& > *': {
            height: 22,
            display: 'flex',
            alignItems: 'flex-end'
        }
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    iconInactive: {
        marginRight: theme.spacing(1),
        opacity: 0.4
    },
    checkedLabel: {
        marginRight: 0,
        "& .MuiFormControlLabel-label": {
            fontSize: 12
        }
    },
    progress: {
        // paddingLeft: theme.spacing(1),
    },
    stateSection: {
        marginLeft: theme.spacing(2)
    },
    speedDial: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    loading: {
        position: "absolute",
        right: 0,
        top: 0,
        margin: theme.spacing(1)
    },
    tableCellText: {
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1,
        }
    },
    tableCellQuantity: {
        lineHeight: 0.6,
        fontSize: '1.13rem'
    },
    tableCellQuantityDiff: {
        display: 'flex',
        fontWeight: 'bold'
        // lineHeight: 0.6,
        // fontSize: '1.15rem'
    },
    tableCellDiffText: {
        lineHeight: 1,
    },
    tableCellSubText: {},
    tableCell: {
        padding: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        },
    },
    optionButton: {
        flex: 1,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        justifyContent: 'start'
    }
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 18,
        top: 40,
        padding: 0,
        color: '#FFF',
        opacity: 0.3,
        borderRadius: 16,
        width: 12,
        height: 14,
        fontSize: 10
    },
}))(Badge);

const StyledBadgeDot = withStyles((theme) => ({
    badge: {
        right: 18,
        top: 40,
        padding: 0,
        color: '#FFF',
        opacity: 0.3
    },
}))(Badge);

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const DELETE_ITEM = `
mutation ($id: String!) {
    deleteInventoryCountingItem(id: $id)
}`;

const GQL_INVENTORY_COUNTING_SUGGESTION = gql`
    query($inventoryCountingId: String!) {
        inventoryCounting_suggestions(inventoryCountingId: $inventoryCountingId) {
            stockTypes {
                id
            }
        }
    }
`

const GQL_INVENTORY_COUNTING_DIFF = gql`
    query($inventoryCountingId: String!) {
        inventoryCounting_diffReport(inventoryCountingId: $inventoryCountingId) {
            stockTypes {
                id
                stockTypeId
                quantity
                avgPrice
                countedQuantity
            }
        }
    }
`

function ApplyInventoryCountingOptionsDialog(props) {
    const {onClose, onOptionSelected, onApprove, onReject, open, ...other} = props;

    const user = useContext(UserContext);
    const classes = useStyles();


    const onOptionClick = (option) => {
        onOptionSelected(option);
        onClose();
    }

    const onRejectClick = (option) => {
        onReject(option);
        onClose();
    }

    const onApproveClick = (option) => {
        onApprove(option);
        onClose();
    }

    const handleCancel = () => {
        onClose();
    };


    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={handleCancel}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">{user.translate("inventory_counting_actions")}</DialogTitle>
            <DialogContent dividers style={{display: 'flex', flexDirection: 'column'}}>
                {
                    props.options.map((option) => (
                        <Button
                            className={classes.optionButton}
                            fullWidth={true}
                            size="medium"
                            textAlign="left"
                            aria-label={option.label}
                            onClick={() => onOptionClick(option)}
                            startIcon={option.icon}
                        >{option.label}</Button>
                    ))
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onRejectClick} color="primary" startIcon={<CloseIcon color="error"/>}>
                    {user.translate("reject")}
                </Button>

                <Button onClick={onApproveClick} color="primary" startIcon={<Check color="primary"/>}>
                    {user.translate("finish")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ApplyInventoryCountingOptionsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

// React Function Component
export default function InventoryCounting() {
    const classes = useStyles();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const history = useHistory();

    const size = useWindowSize();

    const {restaurantId, inventoryCountingId} = useParams();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openItem, setOpenItem] = useState(false);
    const [openItemCountings, setOpenItemCountings] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [changeState, setChangeState] = useState(false);

    const anchorRef = useRef(null);

    const GQL_STOCK_TYPES = gql`
        query($restaurantId: String!, $inventoryCountingId: String!) {
            inventoryCounting_getStockTypes(inventoryCountingId: $inventoryCountingId) {
                id
                stockTypeId
                stockName
                uom
                tax
                piece
                package
                productCount
                avgPrice
            }
            getWarehousesByContextRestaurantId(restaurantId: $restaurantId) {
                id
                name { 
                    ${user.lang}
                }
            }
        }
    `
    const GQL_INVENTORY_COUNTING = gql`
        query($inventoryCountingId: String!) {
            getInventoryCountingById(id: $inventoryCountingId) {
                id
                state
                date
                counted
                created
                updated
                countingInventoryCountingItemsCount
                warehouse {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                }
                employee {
                    profile {
                        _id
                        name
                    }
                }
                reviewed
                reviewer {
                    profile {
                        _id
                        name
                    }
                }
                approve
                approver {
                    profile {
                        _id
                        name
                    }
                }
                countingInventoryCountingItems {
                    id
                    stockTypeId
                    inventoryId
                    quantity
                    price
                    uomOpts
                    created
                    updated
                }
            }
        }
    `

    const GQL_INVENTORY_COUNTING_APPLY = gql`
        mutation($id: String!, $withWarehouseId: String!) {
            inventoryCounting_apply(inventoryCountingId: $id, withWarehouseId: $withWarehouseId)
        }
    `

    const {loading: loadingInventory, error: errorInventory, data: dataInventory, refetch: refetchInventory} = useQuery(GQL_INVENTORY_COUNTING, {
        pollInterval: 10000,
        variables: {
            restaurantId: restaurant.id,
            inventoryCountingId: inventoryCountingId
        }
    });

    let countingState = dataInventory?.getInventoryCountingById?.state;

    let isCountingOrRejected = ["COUNTING", "REJECTED"].indexOf(countingState) !== -1;

    const {loading: loadingStockTypes, error: errorStockTypes, data: dataStockTypes, refetch: refetchStockTypes} = useQuery(GQL_STOCK_TYPES, {
        pollInterval: 100000,
        variables: {
            restaurantId: restaurant.id,
            inventoryCountingId: inventoryCountingId
        }
    });

    const {loading: loadingSuggestion, error: errorSuggestion, data: dataSuggestion, refetch: refetchSuggestion} = useQuery(GQL_INVENTORY_COUNTING_SUGGESTION, {
        pollInterval: 100000,
        skip: countingState == undefined || !isCountingOrRejected,
        variables: {
            restaurantId: restaurant.id,
            inventoryCountingId: inventoryCountingId
        }
    });

    const {loading: loadingDiff, error: errorDiff, data: dataDiff, refetch: refetchDiff} = useQuery(GQL_INVENTORY_COUNTING_DIFF, {
        pollInterval: 10000,
        skip: countingState == undefined || isCountingOrRejected,
        variables: {
            restaurantId: restaurant.id,
            inventoryCountingId: inventoryCountingId
        }
    });

    const [applyItem, {loading: loadingApply}] = useMutation(GQL_INVENTORY_COUNTING_APPLY);

    if (dataInventory == undefined || dataStockTypes == undefined) {
        return <CircularProgress/>
    }
    if (errorInventory || errorStockTypes) {
        return <p><p>{errorInventory}</p><p>{errorStockTypes}</p></p>
    }

    let warehouses = dataStockTypes.getWarehousesByContextRestaurantId;


    const onBackClick = () => {
        history.push('../inventory');
    }

    const onRowClick = (item) => {
        if (item.counting.length > 0) {
            setOpenItemCountings(item.id);
        } else {
            setOpenItem(item.id);
        }
    }

    const lookupUom = [
        {val: 0, name: "LITER", label: user.translate("liter")},
        {val: 1, name: "KILOGRAM", label: user.translate("kilogram")},
        {val: 2, name: "PIECE", label: user.translate("piece")},
    ];

    let tableColumns = [
        {width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: 'Id', field: 'id', hidden: true},
        {
            width: '7%', cellStyle: {whiteSpace: 'nowrap', padding: 0}, title: user.translate("stock"), field: 'stockName',
            render: rowData => (
                <div className={classes.tableCell}>
                    <Typography variant="subtitle2" className={classes.tableCellText}>
                        {rowData.stockName}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" className={classes.tableCellText}>
                        {rowData.productCount} &nbsp;
                        {user.translate(rowData.productCount == 1 ? "product" : "products").toLocaleLowerCase()}
                    </Typography>
                </div>
            ),
            customFilterAndSearch: (filterValue, row) => {
                return (row.stockSearch && row.stockSearch.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1);
            },
        },
        {
            hidden: !isCountingOrRejected,
            width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: user.translate("quantity"), field: 'quantity',
            render: rowData => (
                <div style={{textAlign: "right"}}>
                    <Typography variant="h6" className={classes.tableCellQuantity} align="right">
                        {
                            rowData.counting.length == 1 ?
                                <StyledBadgeDot color="primary" variant="dot">
                                    {user.formatQuantity(parseFloat(rowData.quantity))}
                                </StyledBadgeDot>
                                : rowData.counting.length > 0
                                    ? <StyledBadge badgeContent={rowData.counting.length} color="primary">
                                        {user.formatQuantity(parseFloat(rowData.quantity))}
                                    </StyledBadge>
                                    : user.formatQuantity(parseFloat(rowData.quantity))
                        }
                    </Typography>
                    <Typography variant="caption" color="textSecondary" className={classes.tableCellText} align="right">
                        {rowData.uomLabel}
                    </Typography>
                </div>
            ),
        },
        {
            hidden: isCountingOrRejected,
            width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: user.translate("quantity"), field: 'quantity',
            render: rowData => (
                <div style={{textAlign: "right"}}>
                    <Typography variant="h6" className={classes.tableCellQuantity} align="right">
                        {user.formatQuantity(parseFloat(rowData.quantity))}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" className={classes.tableCellText} align="right">
                        {rowData.uomLabel}
                    </Typography>
                </div>
            ),
        },
        {
            hidden: isCountingOrRejected,
            width: '1%', cellStyle: {whiteSpace: 'nowrap'}, title: user.translate("diff"), field: 'inventoryDiff',
            render: rowData => rowData.inventory.quantity !== undefined ? (
                <div style={{float: "center"}}>
                    <Typography variant="body2" className={classes.tableCellQuantityDiff}>
                        <div>
                            {rowData.quantity === rowData.inventory.quantity
                                ? <Check/>
                                : (rowData.quantity > rowData.inventory.quantity ? '+' : '-') +
                                user.formatQuantity(parseFloat(Math.abs(rowData.quantity - rowData.inventory.quantity)))
                            }
                        </div>
                    </Typography>
                    <Typography variant="caption" color="textSecondary" className={classes.tableCellDiffText} align="right">
                        ({user.formatQuantity(parseFloat(rowData.inventory.quantity))})
                    </Typography>
                </div>
            ) : <div></div>,
        },
        {
            hidden: isCountingOrRejected,
            width: 20, cellStyle: {whiteSpace: 'nowrap'}, title: user.translate("price_with_vat"), field: 'avgPrice',
            render: (item) => item.avgPrice ? user.formatQuantity(item.avgPrice) : ""
        },
    ];

    let countingByStock = dataInventory.getInventoryCountingById.countingInventoryCountingItems.groupBy(x => x.stockTypeId);
    let suggestion = dataSuggestion?.inventoryCounting_suggestions?.stockTypes?.map(x => x.id) || [];
    let inventoryDiff = dataDiff?.inventoryCounting_diffReport?.stockTypes?.toMapBy(x => x.stockTypeId) || {};

    let stockTypesCounted = 0;
    let stockTypesCount = 0;

    let lastItems = [];
    let tableDataRaw = dataStockTypes.inventoryCounting_getStockTypes.map(item => {
        let counting = countingByStock[item.stockTypeId] || [];
        let quantity = counting.reduce((acc, val) => acc + val.quantity, 0);
        let position = suggestion.indexOf(item.stockTypeId);
        let inventory = inventoryDiff[item.stockTypeId] || {loading: true};

        let itemData = {
            ...item,
            id: item.stockTypeId,
            index: position > -1 ? position : suggestion.length + quantity,
            suggestion: position,
            counting: counting,
            stockName: item.stockName,
            stockSearch: `${item.stockName} ${item.stockName}`,
            uomLabel: lookupUom.find(c => c.name === item.uom).label.toLocaleLowerCase(),
            quantity: quantity,
            inventory: inventory,
            avgPrice: inventory.avgPrice,
            inventoryDiff: inventory.quantity ? user.formatQuantity(quantity - inventory.quantity) : 0
        }

        stockTypesCount++;
        if (counting.length > 0) {
            stockTypesCounted++;
        }

        const MAX_LAST_ITEMS = 3;

        let lastCounting = counting.reduce((acc, x) => x.updated > acc ? x.updated : acc, 0);
        if (lastCounting > 0) {
            var index = lastItems.findIndex(lastItem => lastCounting > lastItem.lastCounting);
            if (index == -1 && lastItems.length < MAX_LAST_ITEMS) {
                index = lastItems.length;
            }
            if (index > -1) {
                itemData.lastCounting = lastCounting;
                lastItems.splice(index, 0, itemData);
                if (lastItems.length > MAX_LAST_ITEMS) {
                    lastItems.pop();
                }
            }
        }

        return itemData
    })
    lastItems.forEach((lastItem, index) => lastItem.index = index - lastItems.length);

    let tableData = tableDataRaw.sort((a, b) => a.index - b.index);

    let showItemCountings = openItemCountings && tableData.find(x => x.id == openItemCountings);
    let showItem = openItem && tableData.find(x => x.id == openItem);

    let item = dataInventory?.getInventoryCountingById || {};

    let actionOptions = [{id: '0', label: user.translate("align_inventory"), icon: <InventoryAlignIcon/>}];
    warehouses.forEach(warehouse => {
        if (warehouse.id != dataInventory.getInventoryCountingById.warehouse.id) {
            actionOptions.push({
                id: warehouse.id,
                label: user.translate('align_with') + ' ' + warehouse.name[user.lang],
                icon: <AlignWithWarehouseIcon/>
            });
        }
    });

    const progress = (stockTypesCounted * 100 / stockTypesCount).roundDownBy(1);


    let refresh = (newItem, forceRefreshDiff) => {
        refetchInventory().then(() => {
            if (newItem) refetchSuggestion();
        });
        if (!isCountingOrRejected || forceRefreshDiff) refetchDiff();
    }

    // const renderApplyButtons = () =>
    //     <>
    //         <IconButton onClick={() => setChangeState({ ...item, newState: "APPLIED" })} >
    //             <DoneAllIcon color="primary" />
    //         </IconButton>
    //         <IconButton onClick={() => setChangeState({ ...item, newState: "REJECTED" })} >
    //             <CloseIcon color="secondary" />
    //         </IconButton>
    //     </>

    // const renderApplyButton = (fullWidth) =>
    //     <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
    //         <Button
    //             color="primary"
    //             size="small"
    //             fullWidth={fullWidth}
    //             style={fullWidth ? { flex: 1, justifyContent: 'space-between' } : {}}
    //             aria-controls={showActions ? 'split-button-menu' : undefined}
    //             aria-expanded={showActions ? 'true' : undefined}
    //             aria-label="select merge strategy"
    //             aria-haspopup="menu"
    //             onClick={() => setShowActions(!showActions)}
    //         >
    //             {actionOptions.find(option => option.id == actionOption).label}
    //             <ArrowDropDownIcon />
    //         </Button>
    //     </ButtonGroup>


    return <div className={classes.root}>
        <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={onBackClick}>
                    <MenuBackIcon/>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {dataInventory.getInventoryCountingById.warehouse.name[user.lang]}
                </Typography>
                {/* <Typography variant="subtitle2">
                    {data.getInventoryCountingById.warehouse.name[user.lang]}
                </Typography> */}

                <CustomTheme>
                    <Switch condition={countingState}>
                        <Case value="REVIEWING">
                            <IconButton onClick={() => setChangeState({...item, newState: "APPROVING"})}>
                                <DoneIcon color="primary"/>
                            </IconButton>
                            <IconButton onClick={() => setChangeState({...item, newState: "REJECTED"})}>
                                <CloseIcon color="secondary"/>
                            </IconButton>
                        </Case>
                        <Case value="APPROVING">
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                color="primary"
                                disabled={loadingApply}
                                aria-controls={showActions ? 'split-button-menu' : undefined}
                                aria-expanded={showActions ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={() => setShowActions(!showActions)}
                            >
                                {user.translate('align')}
                                {loadingApply ? <CircularProgress size={18}/> : <ArrowRightIcon/>}
                            </Button>
                            {/* {actionOptions.length > 2 ? (size.isMobile != true && renderApplyButton(false)) : renderApplyButtons()} */}

                        </Case>
                        <Default>
                            <Tooltip title={`Попишани се ${stockTypesCounted} артикли од вкупно ${stockTypesCount}`} aria-label="Inventory"
                                     className={classes.progress}>
                                <CircularProgressWithLabel value={progress} color="secondary" textColor="#EEE"/>
                            </Tooltip>
                        </Default>
                    </Switch>
                </CustomTheme>

            </Toolbar>
        </AppBar>

        {Boolean(changeState) && (
            <InventoryCountingActionDialog
                item={changeState}
                onClose={setChangeState}
                onSuccess={() => refresh(false, true)}
            />
        )}

        {showActions && (
            <ApplyInventoryCountingOptionsDialog
                classes={{
                    paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={showActions}
                options={actionOptions}
                onClose={() => {
                    setShowActions(false);
                }}
                onApprove={() => {
                    setChangeState({...item, newState: "APPLIED"});
                }}
                onReject={() => {
                    setChangeState({...item, newState: "REJECTED"});
                }}
                onOptionSelected={(option) => {
                    applyItem({
                        variables: {
                            id: inventoryCountingId,
                            withWarehouseId: option.id
                        }
                    }).then(() => {
                        refresh(false, true);
                    });
                }}
            />
        )}

        {(Boolean(loadingInventory) || Boolean(loadingStockTypes) || Boolean(loadingSuggestion)) && <CircularProgress size={24} className={classes.loading}/>}


        {
            // size.isMobile == true && actionOptions.length > 2 && countingState == "APPROVING" && (
            //     <div style={{ display: 'flex', justifyContent: 'stretch', padding: 8, flexDirection: 'column' }}>
            //         <ThemeProvider theme={buttonTheme}>
            //             {renderApplyButton(true)}
            //         </ThemeProvider>
            //     </div>)
        }

        <MaterialTable
            icons={tableIcons}
            key={`inventory_${isCountingOrRejected}`}
            isCountingOrRejected={isCountingOrRejected}
            title={<div id={"inventory_counting_title"}
                        style={{
                            lineHeight: 1,
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: 'Roboto',
                            fontStyle: "normal",
                            textAlign: "left",
                            minWidth: 50,
                        }}>{`Попис ${dataInventory.getInventoryCountingById.date}`}</div>}
            columns={tableColumns}
            data={tableData}
            localization={{
                header: {
                    actions: user.translate("actions"),
                },
                body: {
                    emptyDataSourceMessage: user.translate("no_records_display"),
                    addTooltip: user.translate("add"),
                    deleteTooltip: user.translate("delete"),
                    editTooltip: user.translate("edit"),
                    editRow: {
                        deleteText: user.translate("delete_text_row"),
                        cancelTooltip: user.translate("cancel"),
                        saveTooltip: user.translate("save"),
                    }
                },
                toolbar: {
                    page: user.translate("page"),
                    of: user.translate("of"),
                    seal: user.translate("seal"),
                    authorized_person_signature: user.translate("authorized_person_signature"),
                    exportCSVName: user.translate("export_csv_name"),
                    exportPDFName: user.translate("export_pdf_name"),
                    exportTitle: user.translate("export"),
                    searchAriaLabel: user.translate("search"),
                    searchTooltip: user.translate("search"),
                    searchPlaceholder: user.translate("search"),
                },
                pagination: {
                    firstTooltip: user.translate("first_page"),
                    lastTooltip: user.translate("last_page"),
                    previousTooltip: user.translate("previous_page"),
                    nextTooltip: user.translate("next_page"),
                }
            }}
            options={{
                tableLayout: "fixed",
                fixedColumns: {},
                emptyRowsWhenPaging: false,
                initialPage: 0,
                paging: tableData.length < 50 ? false : true,
                pageSize: 50,
                pageSizeOptions: [50, 100, stockTypesCounted],
                exportButton: true,
                exportFileName: `${dataInventory.getInventoryCountingById.warehouse.name[user.lang]} ${dataInventory.getInventoryCountingById.date}`,
                exportAllData: true,
                exportFontName: "Roboto",
                exportFontSize: 7,
                exportFontOptions: {
                    styles: {font: 'Roboto', fontStyle: 'normal', fontSize: 8},
                    headStyles: {font: 'RobotoBold', fontStyle: 'normal', fontSize: 8, fillColor: 227, textColor: 32, lineWidth: 1}
                },
                exportOrientation: "portrait",
                cellStyle: rowData => {
                    return {
                        padding: `0`,
                        fontSize: 12,
                        // width: `${100 / countColumns}%`,
                        width: '100px',
                        whiteSpace: 'pre-line',
                    }
                },
                headerStyle: {
                    padding: `5px 5px`,
                    lineHeight: 1.2,
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#e3e3e3',
                },
                actionsCellStyle: {
                    padding: 0,
                    width: "5%"
                },
                rowStyle: rowData => {
                    if (rowData.counting.length > 0) {
                        return {backgroundColor: 'rgb(213 255 245)'};
                    }
                    if (rowData.suggestion > -1) {
                        return {backgroundColor: '#f3fff2'};
                    } else {
                        return {};
                    }

                }
            }}
            // onChangePage={(page, pageSize) => onPageChange(page)}
            onRowClick={(event, rowData) => onRowClick(rowData)}
            components={{
                Header: _props => <MTableHeader {..._props} />,
            }}
        />
        {
            Boolean(showItem) && <InventoryCountingItemDialog
                open={Boolean(showItem)}
                onClose={setOpenItem}
                onSuccess={() => refresh(true)}
                item={showItem}/>
        }

        {
            Boolean(showItemCountings) && <InventoryCountingItemCountingsDialog
                open={Boolean(showItemCountings)}
                onClose={setOpenItemCountings}
                onSuccess={() => refresh(false)}
                item={showItemCountings}/>
        }

        {
            openSnackBar && <Snackbar
                key={"item_deleted"}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackBar(false)}
                onExited={() => setOpenSnackBar(false)}
                message={"Пописот е избришан"}
                action={
                    <>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            className={classes.close}
                            onClick={() => setOpenSnackBar(false)}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </>
                }
            />
        }
    </div>

}
