import { gql, useMutation } from "@apollo/client"
import React, { useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { UserContext } from "../../App"
import helper from "../common/Helper"
import Report from "../common/Report"

export function SendingDocumentPrint(props) {
  return <DocumentPrint {...props} title="stockSending" />
}
export default function DocumentPrint(props) {
  const user = useContext(UserContext)

  const { restaurantId, documentId } = useParams()

  const [page, setPage] = useState(0)
  const [print, setPrint] = useState(false)
  const [editIt, setEditIt] = useState(false)
  const [printIt, setPrintIt] = useState(false)
  const [refetchIt, setRefetchIt] = useState(false)

  let startDate = new Date(),
    endDate = new Date()
  startDate.setMonth(startDate.getMonth(), 1) //the first of this month
  // endDate.setMonth(endDate.getMonth(), 0); //- the last day of last month
  startDate.setHours("00")
  startDate.setMinutes("00")
  startDate.setSeconds("00")
  endDate.setHours("23")
  endDate.setMinutes("59")
  endDate.setSeconds("59")

  const EDIT_RECEIVING_STOCK = gql`
    mutation ($receivingStockId: String!, $lastTimePrinted: String!) {
      editReceivingStock(input: { id: $receivingStockId, lastTimePrinted: $lastTimePrinted }) {
        id
      }
    }
  `
  const [editReceivingStock, { loading: loading_edit }] = useMutation(EDIT_RECEIVING_STOCK)

  const onPrintPlt = () => {
    let items = {
      receivingStockId: documentId,
      lastTimePrinted: new Date().getTime(),
    }

    editReceivingStock({ variables: items })

    setEditIt(true)
  }

  if (!loading_edit && editIt) {
    setEditIt(false)
    setPrintIt(true)
    setRefetchIt(true)
  }

  if (printIt && !editIt && !refetchIt) {
    setPrintIt(false)
    setPrint(helper.printdiv("printDiv", "no_print"))
  }

  let params = {
    query: {
      name: "report_stockReceivingPLT",
      receivingIdInit: "$receivingId: String!, ",
      receivingId: "receivingId: $receivingId, ",
      dateInit: "$dateFrom: Long!, $dateTo: Long!",
      date: "dateFrom: $dateFrom, dateTo: $dateTo",
      dateVars: {
        dateFrom: "0", //startDate.getTime(),
        dateTo: endDate.getTime(),
      },
      receivingIdVars: {
        receivingId: documentId,
      },
      results: `
                id
                stockTypeId
                stockType
                uom
                quantity
                itemPrice
                totalPrice
                totalTax
                unitTax
                tax
                taxRate
                itemSellPrice
                totalSellPrice
                totalSellTax
                totalPriceWithTax
                totalSellPriceWithTax
                totalFee
                totalFeeWithTax
                totalTaxDiff
                receivingCreated
                itemCreated
                sendingNumber
                warehouseId
                warehouse
                senderId
                sender
                invoiceNumber
                receivingNumber
            `,
      columnsPush: ["id", "stockTypeId", "senderId", "warehouseId"],
    },
    title: user.translate("plt_report"),
    titleName: user.translate("pltreport"),
    page: page,
    setPage: setPage,
    dataLimit: 200,
    print: print,
    setPrint: setPrint,
    receivingId: documentId,
    onPrintPlt: onPrintPlt,
    refetchIt: refetchIt,
    setRefetchIt: setRefetchIt,
    loading_edit: loading_edit,
    key: "plt_report_" + refetchIt,
  }

  if (print) {
    window.location.reload()
  }

  return (
    <div id="printDiv">
      {!loading_edit && (
        <>
          <Report {...params} pageTitle={props.title} noTax />

          <div style={{ width: "100%", clear: "both", marginTop: 60, display: "inline-block", textAlign: "center" }}>
            <div style={{ width: "33%", float: "left", position: "relative" }}>
              {" "}
              <hr style={{ width: "90%", marginTop: 40, marginLeft: 16, marginRight: 16 }} /> {user.translate("seal")}
            </div>
            <div style={{ width: "33%", float: "left", position: "relative" }}> &nbsp;</div>
            <div style={{ width: "33%", float: "left", position: "relative" }}>
              {" "}
              <hr style={{ width: "90%", marginTop: 40, marginLeft: 16, marginRight: 16 }} />{" "}
              {user.translate("authorized_person_signature")}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
