import { gql, useQuery } from '@apollo/client';
import { Button, ButtonGroup, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import NoteAdd from '@material-ui/icons/NoteAdd';
import OpenInNew from '@material-ui/icons/OpenInNew';
import React, { useContext, useMemo, useState } from 'react';
import { RestaurantContext, UserContext } from "../../App";
import Show from '../common/Show';
import SnackBarWarning from '../common/SnackBarWarning';
import Table from '../common/Table';
import tableComponents from '../common/TableComponents';
import useWindowSize from '../common/useWindowSize';
import useImperativeQuery from '../config/useImperativeQuery';
import { useStockReceivingDialog } from '../stock/useStockReceiving';
import StockSupplyAction from './StockSupplyAction';
import { useSupply } from './useSupply';
import { red, green, blue, amber } from '@material-ui/core/colors';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import helper from '../common/Helper';
var hash = require('object-hash');

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#EEF',
        paddingBottom: theme.spacing(2),
        padding: theme.spacing(1),
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: theme.spacing(1)
        }
    },
    actions: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexFlow: 'wrap'
    },
    actionsButton: {
        flex: 1,
        width: '100%',
        minWidth: 230,
        maxWidth: 320,
        margin: 'auto',
        marginTop: theme.spacing(2),
    },
    splitButton: {
        flex: 1,
    },
    actionsDivider: {
        [theme.breakpoints.up(480)]: {
            width: theme.spacing(1)
        }
    },
    title: {
        paddingBottom: theme.spacing(1)
    },
    detail: {
        paddingBottom: theme.spacing(2)
    }
}));

const stateColors = {
    OPEN: '#f1efe9',
    CLOSED: '#f4f4fa',
    SENT: '#faf8f4',
    RECEIVED: '#f4f8fa',
    CANCELED: '#F2f2f2',

}

const stateTextColors = {
    OPEN: amber[900],
    CLOSED: blue.A700,
    SENT: red.A700,
    RECEIVED: green.A700,
    CANCELED: red[200],

}


export default function SupplingListItem(props) {

    const item = props.item;

    const classes = useStyles();

    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const size = useWindowSize();

    const stockReceivingDialog = useStockReceivingDialog();

    const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false);

    // props.setOnAction(() => {
    //     let elements = Array.from(document.querySelectorAll('[class*="MTableToolbar-searchField"],[class*="MTableToolbar-actions"]'));
    //     let elementsDisplay = elements.map(el => el.style.display);
    //     elements.forEach(el => el.style.display = 'none');

    //     helper.printdiv("stock_supply_order_content", "no_print", true);

    //     elements.forEach((el, idx) => {
    //         el.style.display = elementsDisplay[idx] || 'block';
    //     });

    // });

    const GQL_GET_STOCK_SUPPLY_ITEMS = gql`
        query($stockSupplyId: String!, $restaurantId: String!) {
            getStockSupplyItemsByStockSupplyId(stockSupplyId: $stockSupplyId) {
                _id
                id
                stockTypeId
                quantity
                uomOpts
                comment
                warehouseId
                stockSupplyId
            }
            getStockTypesByRestaurantId(restaurantId: $restaurantId) {
                id
                name {
                    ${user.gqlFetchName()}
                }
                uom
                piece
                package
                taxType {
                    rate
                }
            }
        }
    `;
    const { data, loading, error, refetch } = useQuery(GQL_GET_STOCK_SUPPLY_ITEMS, {
        variables: {
            stockSupplyId: item.id,
            restaurantId: restaurant.id
        }
    });

    const stockSupplyItems = data?.getStockSupplyItemsByStockSupplyId || [];

    const GQL_GET_WAREHOUSES = gql`
        query($restaurantId: String!) {
            getWarehousesByContextRestaurantId(restaurantId: $restaurantId) {
                id
                name { 
                    ${user.lang}
                }
            }
        }
    `
    const { get: getWarehouses } = useImperativeQuery(GQL_GET_WAREHOUSES, {
        variables: {
            restaurantId: restaurant.id
        }
    });


    const manageSupply = useSupply({
        orders: { ...item, stockSupplyItems },
        refetch: props.refetch
    })

    let stockTypes = data?.getStockTypesByRestaurantId || [];
    let stockTypesHash = hash(stockTypes.map(item => item.id + item.uom));
    let stockTypeOptions = useMemo(() => stockTypes.map(item => ({ ...item, name: item.name[user.lang] })), [stockTypesHash]);

    let indexedStockTypes = useMemo(() => stockTypeOptions.map(stockType => ({
        ...stockType,
        search: `${stockType.name} ${stockType.name.cirilicToLatin()}`
    })), [stockTypesHash]);

    let stockTypesById = useMemo(() => indexedStockTypes.toMapBy(item => item.id), [stockTypesHash]);

    if (!data) return <div className="App AppLoading"><CircularProgress /></div>;

    const onSaveItem = (itemData) => manageSupply.saveSupplyItem({
        ...itemData, stockSupplyId: item.id,
        stockTypeId: itemData.stockTypeId || itemData._stockTypeId
    }).then(refetch);

    const onDeleteItem = (itemData) => manageSupply.deleteSupplyItem(itemData.id).then(refetch);

    const onCreateReceiving = async () => {
        var receivingStockId = false;
        try {
            const itemsCount = 3 + stockSupplyItems.length;
            var itemsProgress = 0;
            stockReceivingDialog.show(itemsProgress++ * 100 / itemsCount);
            let warehouses = await getWarehouses({ restaurantId: restaurant.id });
            let warehouseId = warehouses?.getWarehousesByContextRestaurantId?.get(0)?.id;
            if (warehouseId == undefined) throw Error("No warehouses");

            stockReceivingDialog.show(itemsProgress++ * 100 / itemsCount);

            let stockReceiving = await stockReceivingDialog.saveStockReceiving({
                companyId: restaurant.id,
                senderId: item.supplierId,
                employeeId: user.me.id,
                sendingNumber: item.supplyingNumber,
                invoiceNumber: item.supplyingNumber,
                created: new Date().getTime(),
                updated: new Date().getTime()
            });
            receivingStockId = stockReceiving.createReceivingStock.id;

            stockReceivingDialog.show(itemsProgress++ * 100 / itemsCount);

            for (const item of stockSupplyItems) {
                let stockType = stockTypesById[item.stockTypeId];
                await stockReceivingDialog.saveStockReceivingItem({
                    receivingStockId, warehouseId,
                    stockTypeId: item.stockTypeId,
                    quantity: stockType.uom == 2 ? item.quantity : (item.quantity * 1000),
                    uomOpts: item.uomOpts,
                    price: 0,
                    tax: 0,
                    discount: 0,
                    created: new Date().getTime(),
                    updated: new Date().getTime()
                });
                stockReceivingDialog.show(itemsProgress++ * 100 / itemsCount);

            }

            await manageSupply.saveSupply({ ...item, receivingId: receivingStockId });
            stockReceivingDialog.show(itemsProgress++ * 100 / itemsCount);

            await props.refetch();
            stockReceivingDialog.done();

        } catch (err) {
            console.error(err);
            if (receivingStockId) {
                stockReceivingDialog.deleteStockReceiving(receivingStockId).then(props.refetch)
            }
            alert(`Failed to create receiving`);
        }
    }

    const onDeleteReceivingClick = async () => {
        try {
            stockReceivingDialog.show(20);
            await stockReceivingDialog.deleteStockReceiving(item.receivingId);
            stockReceivingDialog.show(50);
            await manageSupply.saveSupply({ ...item, receivingId: null });
            stockReceivingDialog.show(80);
            await props.refetch();
            stockReceivingDialog.done();
        } catch (err) {
            console.error(err);
            alert(`Failed to delete receiving`);
            props.refetch();
        }

    }

    const uomUnit = ["L", "Kg", "p"];

    const uomTitles = [
        user.translate("liter"),
        user.translate("kilogram"),
        user.translate("piece")
    ];

    const getUomUoptions = function (stockTypeId) {
        let stockType = stockTypesById[stockTypeId];

        if (stockType == undefined) return [];
        let uom = stockType.uom;

        const pieceQuantity = `${stockType.piece} ${uomUnit[uom]}`;
        const uomOptionPiece = [
            { label: `${user.translate("dose")} (${pieceQuantity})`, val: 1 },
            { label: `${user.translate("dose")} (${pieceQuantity})`, val: 1 },
            { label: `${user.translate("dose")} (${pieceQuantity})`, val: 1 }
        ];

        const packageQuantity = `${stockType.package}${uomUnit[uom]}`;
        const uomOptionPackage = [
            { label: `${user.translate("bottle")} ${packageQuantity}`, val: 2 },
            { label: `${user.translate("bag")} ${packageQuantity}`, val: 2 },
            { label: `${user.translate("box")} ${packageQuantity}`, val: 2 },
        ];

        const basicUomUoption = { label: uomTitles[uom].capitalize(), val: 0 };

        return [basicUomUoption, uomOptionPiece[uom], uomOptionPackage[uom]];

    }

    const getUomQuantities = function (itemData) {
        let stockTypeId = itemData.stockTypeId || itemData._stockTypeId;
        let stockType = stockTypesById[stockTypeId];
        if (stockType == undefined) return [1];
        return stockType ? [1, stockType.piece, stockType.package] : [1];
    }

    let fieldList = {
        columns: [
            { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'Id', field: 'id', hidden: true, },
            {
                title: user.translate("stock_type"), field: 'stockType',
                cellStyle: { height: 30, padding: 12, fontSize: 12, whiteSpace: 'pre-line' },
                editComponent: (props) => tableComponents.AutoComplete(props, stockTypeOptions, "stockTypeId", "stockType", false, () => stockTypeOptions[0]),
                customSort: (a, b) => a.stockType.cirilicLatinCompare(user.lang).localeCompare(b.stockType.cirilicLatinCompare(user.lang), user.lang)
            },
            {
                title: user.translate("uomOpts"), field: 'uomOptsLabel',
                editComponent: (props) => tableComponents.Select(props, getUomUoptions(props.rowData._stockTypeId || props.rowData.stockTypeId), "uomOpts", () => 0),
            },
            {
                title: user.translate("quant"), field: 'quantity',
                cellStyle: { width: "2%", padding: 1, fontSize: 12, whiteSpace: 'pre-line' },
                render: (rowData) => user.formatQuantity(rowData.convertUom(rowData.quantity, 0, rowData._uomOpts != undefined ? rowData._uomOpts : (rowData.uomOpts || 0))),
                editComponent: (props) => tableComponents.NumericUomEditComponent(props, "quantity", 3, false, itemData => getUomQuantities(itemData)),
            },
            {
                title: user.translate("comment"), field: 'comment', mobile: false
            },
        ],
        data: stockSupplyItems.map(item => {
            let stockType = stockTypesById[item.stockTypeId];
            let uomOptions = getUomUoptions(stockType.id);

            const unitQuantities = stockType ? [1, stockType.piece, stockType.package] : [];
            const convertUom = (value, fromUnit, toUnit = 0, roundTo = 3) => {
                if (unitQuantities[fromUnit] == undefined) return undefined;
                if (unitQuantities[toUnit] == undefined) return undefined;
                return (value * unitQuantities[fromUnit] / unitQuantities[toUnit]).round(roundTo);
            }

            return {
                ...item,
                stockType: stockType.name,
                unitQuantities: unitQuantities,
                convertUom: convertUom,
                uomOptions: uomOptions,
                uomOptsLabel: uomOptions.find(opts => opts.val == item.uomOpts).label
            }
        })
    }

    const details = [
        { name: 'supplier', value: item.supplier },
        { name: 'supplyOrderNumber', value: item.supplyingNumber },
        { name: 'date', value: new Date(item.date).toLocaleDateString() },
    ]

    return <div className={classes.root} style={{ backgroundColor: stateColors[item.state] }}>
        <div id="stock_supply_order_content">
            <Typography variant="h5" className={classes.title} style={{ color: stateTextColors[item.state] }} >{user.translate(`supply_state_${item.state}`)}</Typography>
            <div style={{ display: 'table', opacity: item.state == 'CANCELED' && 0.6 }} className={classes.detail}>
                {
                    details.map(item => (
                        <div key={item.value} style={{ display: 'table-row' }}>
                            <Typography noWrap variant="caption" style={{ display: 'table-cell' }}>{user.translate(item.name)}</Typography>
                            <Typography noWrap variant="overline" style={{ display: 'table-cell', lineHeight: 1.9 }}>:&nbsp;{item.value}</Typography>
                        </div>
                    ))
                }
            </div>
            <Table
                tableName={user.translate("supplies")}
                exportFileName={user.translate("stockitems")}
                fieldList={fieldList}
                pageSize="50"
                paginationPosition={false}
                onAddItem={item.state == 'OPEN' && onSaveItem}
                onEditItem={item.state == 'OPEN' && onSaveItem}
                onDeleteItem={item.state == 'OPEN' && onDeleteItem}
                backgroundColor="#f3feff"
                headerBackgroundColor="#d1e8ea"
                itemBackgroundColor="#f3f8f9"

            />
        </div>

        <div className={classes.actions}>
            <Show if={item.receivingId == undefined && item.state == 'RECEIVED'}>
                <Button
                    className={classes.actionsButton}
                    variant="outlined"
                    color="primary"
                    onClick={onCreateReceiving}
                    startIcon={<NoteAdd />}>{user.translate('createStockReceiving')}</Button>
            </Show>
            <Show if={item.receivingId != undefined && item.state == 'RECEIVED'}>
                <ButtonGroup variant="contained" color="primary" aria-label="split button" className={classes.actionsButton}>
                    <Button
                        className={classes.splitButton}
                        style={{ width: '100%', marginEnd: -1 }}
                        disableElevation
                        variant="contained"
                        color="primary"
                        startIcon={<OpenInNew />}>
                        <Link to={`/restaurant/${restaurant._id}/stock_receiving/${item.receivingId}`} key={item.receivingId} style={{ textDecoration: 'none', color: '#FFF' }}>{user.translate('stockReceiving')}</Link>
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={'split-button-menu'}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={onDeleteReceivingClick}
                    >
                        <DeleteOutline color="error" />
                    </Button>
                </ButtonGroup>

            </Show>

            <span className={classes.actionsDivider} />

            <StockSupplyAction
                className={classes.actionsButton}
                item={item}
                manageSupply={manageSupply}
                outlined={true}
                size="medium" />
        </div>

        {openSnackBarWarning && (
            <SnackBarWarning
                message={openSnackBarWarning.alertMessage}
                linkTo={openSnackBarWarning.linkTo}
                linkText={openSnackBarWarning.linkText}
                autoHideDuration={openSnackBarWarning.autoHideDuration || 3000}
                openSnackBar={true}
                setOpenSnackBar={setOpenSnackBarWarning}
            />
        )}
        {stockReceivingDialog.render()}
    </div>
}
