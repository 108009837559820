import { gql, useMutation } from "@apollo/client"
import { Button, Divider, Grid, makeStyles, Typography } from "@material-ui/core"
import SplitIcon from "@material-ui/icons/CallSplit"
import CheckIcon from "@material-ui/icons/Check"
import React, { useContext, useMemo, useState } from "react"
import { RestaurantContext, UserContext } from "../../App"
import AmountInputFieldToggle from "../common/AmountInputFieldToggle"
import useConfirmDialog from "../common/ConfirmDialog"
import generateGql from "../common/generateGql"
import ItemsChooserDialog from "../common/ItemsChooserDialog"
import Show from "../common/Show"
import { useEntityRelationQuery } from "../common/useEntityQuery"
import InvoiceLinkButton from "./InvoiceLinkButton"

const useStyles = makeStyles((theme) => ({
  footerCreators: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
      alignItems: "flex-end",
      flexDirection: "column",
    },
    flexWrap: "wrap",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontStyle: "italic",
  },
  creator: {
    fontWeight: 600,
    color: "#4e6e6f",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(1),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    "& .MuiButton-label": {
      justifyContent: "start",
    },
    backgroundColor: "#FFF",
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  title: {
    // fontSize: 14,
    // fontWeight: 'bold',
    color: "#666",
  },
  status: {
    display: "flex",
    fontSize: 12,
    color: "#0A0",
    alignItems: "center",
  },
  button: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    marginRight: theme.spacing(1),
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonIcon: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
  },
  buttonContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    whiteSpace: "nowrap",
  },
  buttonFirst: {
    display: "flex",
    alignItems: "baseline",
    "& > span": {
      lineHeight: "unset",
      fontSize: 12,
    },
  },
  buttonSecond: {
    width: "50vw",
    maxWidth: 320,
    fontSize: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "unset",
    color: "#454545",
  },
  amountField: {
    maxWidth: 120,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 100,
    },
    marginLeft: 4,
    paddingRight: theme.spacing(1),
    flex: 1,
  },
}))

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    fontSize: 14,
    textTransform: "uppercase",
    background: "repeating-linear-gradient(315deg, #dadada, #cecece 13%)",
    color: "#2d2d2d",
    marginLeft: -8,
    marginRight: -8,
    padding: 2,
  },
}))

export function PaymentDistributionHeader() {
  const classes = useHeaderStyles()
  const user = useContext(UserContext)
  return <div className={classes.root}>{user.translate("distribution")}:</div>
}

export const invoiceGqlSelection = (user) => ({
  payeeCompanyId: true,
  payerCompanyId: true,
  amount: true,
  currency: true,
  invoiceNumber: true,
  issuingDate: true,
  payerCompany: {
    name: {
      [user.lang]: true,
    },
  },
  payeeCompany: {
    name: {
      [user.lang]: true,
    },
  },
})

const mapPlus = (items, callbackfun, gluefun) => {
  const result = []
  for (var i = 0; i < items.length; i++) {
    const item = items[i]
    if (i > 0) result.push(gluefun(item))
    result.push(callbackfun(item))
  }
  return result
}

export default function PaymentDistribution(props) {
  const classes = useStyles()

  const isExpense = Boolean(props.isExpense)

  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)

  const [showInvoiceChooser, setShowInvoiceChooser] = useState()

  const item = props.item
  const invoicePayments = props.invoicePayments
  const invoicePaymentsQuery = props.invoicePaymentsQuery
  const paymentCurrency = restaurant.currencies.find((opt) => opt.val == item.currency).label

  const distributedAmount = invoicePayments.sumOf((item) => item.amount)
  const undistributedAmount = item.amount - distributedAmount

  const confirmDialog = useConfirmDialog()

  const INVOICE_FIELDS = [
    { name: "id", gql: "String!", id: true, hidden: true },
    { name: "payerCompanyId", gql: "String!", hidden: true },
    { name: "payerCompany", gql: "Restaurant!", subSelection: { name: { [user.gqlFetchName()]: true } }, hidden: true },
    { name: "payeeCompanyId", gql: "String!", hidden: true },
    { name: "payeeCompany", gql: "Restaurant!", subSelection: { name: { [user.gqlFetchName()]: true } }, hidden: true },
    { name: "issuingDate", gql: "Long!" },
    { name: "amount", gql: "Float!" },
    { name: "currency", gql: "String!" },
    { name: "invoiceNumber", gql: "String!" },
    { name: "status", gql: "String!" },
    { name: "invoicePayments", gql: "String!", subSelection: { amount: true } },
  ]

  const invoiceRefField = isExpense ? "payerCompanyId" : "payeeCompanyId"
  const invoicesQuery = useEntityRelationQuery("Invoice", INVOICE_FIELDS, invoiceRefField, restaurant.id, {
    filter: {
      by: "status",
      gt: 0,
    },
    skip: showInvoiceChooser != true,
  })

  const [checkInvoicePayed] = useMutation(
    gql(
      generateGql(
        {
          finance_checkInvoicePayed: {
            args: {
              $invoiceId: "String!",
            },
          },
        },
        "mutation"
      )
    )
  )

  const green = "#069a87"
  const red = "#644"

  const getInvoiceTitle = (invoice, payed, rest) => {
    if (payed == 0) {
      return (
        <>
          <span style={{ color: "#800" }}>
            {invoice.invoiceNumber}
            &#8901;
            {user.formatDate(invoice.issuingDate)}
          </span>
          <span style={{ color: "#800", float: "right" }}>
            <span style={{ color: "#A00", fontWeight: "bold" }}>{user.formatQuantity(invoice.amount)}</span>{" "}
            {invoice.currency}
          </span>
        </>
      )
    }
    if (rest == 0) {
      return (
        <>
          <span style={{ color: green }}>
            {invoice.invoiceNumber}
            &#8901;
            {user.formatDate(invoice.issuingDate)}
          </span>
          <span style={{ color: green, float: "right" }}>
            &#10003; {user.formatQuantity(invoice.amount)} {invoice.currency}
          </span>
        </>
      )
    }

    return (
      <>
        <span style={{ color: red }}>
          {invoice.invoiceNumber}
          &#8901;
          {user.formatDate(invoice.issuingDate)}
        </span>
        <span style={{ color: red, float: "right" }}>
          <span style={{ color: "#A00", fontWeight: "bold" }}>{user.formatQuantity(rest)}</span>/
          {user.formatQuantity(invoice.amount)} {invoice.currency}
        </span>
      </>
    )
  }

  const invoices = useMemo(() => {
    const invoiceItems = invoicesQuery?.items
    if (invoiceItems == undefined) return []

    return invoiceItems
      .map((invoice) => {
        const komitent = isExpense ? invoice.payeeCompany.name[user.lang] : invoice.payerCompany.name[user.lang]

        const payed = invoice.invoicePayments.sumOf((payment) => payment.amount)
        const rest = Math.max(invoice.amount - payed, 0)

        const komitentId = isExpense ? item.payeeId : item.payerId
        const invoiceKomitentId = isExpense ? invoice.payeeCompanyId : invoice.payerCompanyId
        const sameKomitent = komitentId == invoiceKomitentId
        console.log(komitent, item, invoiceKomitentId)
        return {
          id: invoice.id,
          name: getInvoiceTitle(invoice, payed, rest),
          search: `${invoice.invoiceNumber} ${invoice.issuingDate} ${komitent}`,
          description: sameKomitent ? <b style={{ color: rest > 0 ? "#000" : green }}>{komitent}</b> : komitent,
          amount: invoice.amount,
          payed: payed,
          rest: rest,
          sameKomitent: sameKomitent,
        }
      })
      .orderByDesc((invoice) => (invoice.sameKomitent ? invoice.rest : -1))
  }, [invoicesQuery?.items, item, isExpense])

  const saveItem = (item) => {
    return invoicePaymentsQuery
      .saveItem(item)
      .then(() => checkInvoicePayed({ variables: { invoiceId: item.invoiceId } }))
  }

  const onSelectInvoice = (invoice) => {
    return saveItem({
      compensationId: item.id,
      invoiceId: invoice.id,
      amount: Math.min(undistributedAmount, invoice.rest),
      currency: paymentCurrency,
      date: item.date,
      note: "",
      updated: new Date().getTime(),
    }).then(() => invoicesQuery.refresh())
  }

  const title = isExpense ? "input_invoices_abbrev" : "output_invoices_abbrev"
  const actionIcon = isExpense ? <SplitIcon /> : <SplitIcon style={{ transform: "rotate(180deg)" }} />

  const classNames = [classes.list]
  if (props.className) classNames.push(props.className)

  return (
    <Grid container className={classNames.join(" ")}>
      <Grid item className={classes.titleRow} key="header">
        <Typography className={classes.title}>{user.translate(title)}</Typography>
        <Show if={undistributedAmount == 0}>
          <div className={classes.status}>
            <CheckIcon style={{ marginRight: 4, fontSize: 16 }} />
            <Typography style={{ fontSize: 13 }}>{user.translate("distributed")}</Typography>
          </div>
        </Show>
        <Show if={undistributedAmount != 0}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.actionButton}
            startIcon={actionIcon}
            onClick={() => setShowInvoiceChooser(true)}
          >
            {user.translate("distribute")}&nbsp;{" "}
            <b>
              {user.formatQuantity(undistributedAmount)} {paymentCurrency}
            </b>
          </Button>
        </Show>
      </Grid>
      {mapPlus(
        invoicePayments,
        (item) => (
          <Grid item className={classes.row} key={item.invoiceId}>
            <InvoiceLinkButton
              item={{ ...item.invoice, id: item.invoiceId }}
              currency={paymentCurrency}
              isExpense={isExpense}
            />
            <AmountInputFieldToggle
              label={`${user.translate("amount")} (${paymentCurrency})`}
              value={item.amount}
              className={classes.amountField}
              onSubmit={(amount) => {
                if (amount == 0) {
                  confirmDialog.showConfirm(
                    "delete",
                    "distribution",
                    () => {
                      invoicePaymentsQuery
                        .hardDeleteItem(item)
                        .then(() => checkInvoicePayed({ variables: { invoiceId: item.invoiceId } }))
                        .then(() => invoicePaymentsQuery.refresh())
                    },
                    `${item.amount} ${paymentCurrency}`,
                    item.invoice.invoiceNumber
                  )
                } else {
                  saveItem({ ...item, amount: amount }).then(() => invoicePaymentsQuery.refresh())
                }
              }}
              enableClear
              enableAccept
            />
          </Grid>
        ),
        (nextItem) => (
          <Divider key={`div_${nextItem.invoiceId}`} />
        )
      )}
      {showInvoiceChooser != undefined && (
        <ItemsChooserDialog
          show={showInvoiceChooser}
          title={user.translate("select_invoice")}
          items={invoices}
          onSelect={onSelectInvoice}
          onClose={setShowInvoiceChooser}
        />
      )}
      {confirmDialog.render()}
    </Grid>
  )
}
