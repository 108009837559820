import DateFnsUtils from '@date-io/date-fns';
import {ClickAwayListener, FormGroup, Grid, makeStyles} from '@material-ui/core';
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {DateRangePickerComponent} from "materialui-daterange-picker";
import React, {useContext, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {UserContext} from "../../App";
import useWindowSize from './useWindowSize';

const useStyles = makeStyles((theme) => ({
    formRow: {
        flexGrow: 0,
        textAlign: `center`,
        fontSize: 14,
        justifyContent: `center`,
    },
    inputContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'baseline'

    },
    formControlDate: {
        maxWidth: 160,
        width: `auto`,
        margin: theme.spacing(1)
    },
    dateRange: {
        position: `absolute`,
        marginTop: `60px`,
        zIndex: 999999
    },
    datePickerRoot: {
        zIndex: 999999
    }
}));


export function DateRangeHeader(props) {

    const classes = useStyles();
    const user = useContext(UserContext);

    const size = useWindowSize();

    const [tempDateRange, setTempDateRange] = useState();
    const [openDateRange, setOpenDateRange] = useState(false);

    const toggleDateRangeOpen = () => setOpenDateRange(!openDateRange);
    const closeDateRange = () => setOpenDateRange(false);

    const dateRange = tempDateRange || props.filter;

    const handleDateFromChange = (date) => {
        if (date != "Invalid Date") {
            setTempDateRange({...dateRange, startDate: date});
        }
    }

    const handleDateToChange = (date) => {
        if (date != "Invalid Date") {
            let dateTo = new Date(date.getTime() + 24 * 60 * 60 * 1000);
            dateTo.setHours(5, 0, 0, 0);

            setTempDateRange({...dateRange, endDate: dateTo});
        }
    }

    const onAccept = () => {
        closeDateRange();
        if (dateRange.startDate.getTime() > dateRange.endDate.getTime()) {
            let newRange = {...dateRange, endDate: dateRange.startDate};
            props.setFilter(newRange);
            setTempDateRange(undefined);
        } else {
            props.setFilter(dateRange);
            setTempDateRange(undefined);
        }
    }

    const handleDateRangeAccept = (range) => {
        let dateTo = new Date(range.endDate.getTime() + 24 * 60 * 60 * 1000 - 1);
        dateTo.setHours(5, 0, 0, 0);

        props.setFilter({...dateRange, startDate: range.startDate, endDate: dateTo});
        setTempDateRange(undefined);
        closeDateRange();
    }

    return (
        <>
            <FormGroup row className={classes.formRow}>

                <ClickAwayListener onClickAway={closeDateRange}>
                    <div className={classes.inputContainer}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="flex-start"
                                  className={classes.formControlDate}>
                                <KeyboardDatePicker
                                    disableToolbar={false}
                                    animateYearScrolling={true}
                                    autoOk={true}
                                    open={false}
                                    variant="inline"
                                    format={user.dateFormat}
                                    id={"filter_date_from"}
                                    value={dateRange.startDate}
                                    onClick={toggleDateRangeOpen}
                                    onChange={handleDateFromChange}
                                    onKeyPress={e => {
                                        if (e.key == "Enter") onAccept();
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>


                        {
                            !size.isMobile && (
                                <>
                                    <div> -</div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="flex-start" className={classes.formControlDate}>
                                            <KeyboardDatePicker
                                                disableToolbar={false}
                                                animateYearScrolling={true}
                                                autoOk={true}
                                                open={false}
                                                variant="inline"
                                                format={user.dateFormat}
                                                id={"filter_date_to"}
                                                value={dateRange.endDate}
                                                onClick={toggleDateRangeOpen}
                                                onChange={handleDateToChange}
                                                onKeyPress={e => {
                                                    if (e.key == "Enter") onAccept();
                                                }}
                                                onBlur={onAccept}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                InputProps={{position: "start"}}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </>
                            )
                        }


                        <div className={classes.dateRange}>
                            <DateRangePickerComponent
                                className={classes.datePickerRoot}
                                open={openDateRange}
                                toggle={toggleDateRangeOpen}
                                initialDateRange={dateRange}
                                onChange={(range) => handleDateRangeAccept(range)}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            </FormGroup>

        </>

    );
}


export default function useDateRangeFilter(defaultFilter = 'today' || 'yesterday' || 'this_month' || 'last_and_this_month' || 'this_year') {

    const location = useLocation()
    const history = useHistory()

    let startDate = new Date(), endDate = new Date();
    if (defaultFilter === 'yesterday') {
        startDate.setDate(startDate.getDate() - 1);
    } else if (defaultFilter === 'past_week') {
        startDate.setDate(startDate.getDate() - 7);
    } else if (defaultFilter === 'this_month') {
        startDate.setMonth(startDate.getMonth(), 1);
    } else if (defaultFilter === 'last_and_this_month') {
        startDate.setMonth(startDate.getMonth() - 1, 1);
    } else if (defaultFilter === 'this_year') {
        startDate.setMonth(0, 1);
    }

    startDate.setHours(5, 0, 0, 0);

    endDate.setTime(endDate.getTime() + 24 * 60 * 60 * 1000);
    endDate.setHours(5, 0, 0, 0);

    const filterState = {
        startDate: location.state?.startDate || startDate,
        endDate: location.state?.endDate || endDate
    }

    const setFilterState = (state) => {
        history.push({...location.state, state: state});
    }

    return {
        ...filterState,
        renderHeader: () => <DateRangeHeader filter={filterState} setFilter={setFilterState}/>
    };
}
