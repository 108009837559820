import {gql, useMutation, useQuery} from "@apollo/client"
import {Button, CircularProgress, IconButton, makeStyles, Tooltip} from "@material-ui/core"
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation"
import DescriptionIcon from "@material-ui/icons/Description"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import OpenAgainIcon from "@material-ui/icons/LockOpen"
import PrintIcon from "@material-ui/icons/Print"
import SubtitlesIcon from "@material-ui/icons/Subtitles"
import React, {useContext, useMemo, useState} from "react"
import {useHistory, useLocation, useParams} from "react-router-dom"
import {RestaurantContext, UserContext} from "../../App"
import helper from "../common/Helper"
import ProgressDialog from "../common/ProgressDialog"
import SnackBarDelete from "../common/SnackBarDelete"
import SnackBarWarning from "../common/SnackBarWarning"
import Table from "../common/Table"
import tableComponents from "../common/TableComponents"
import useWindowSize from "../common/useWindowSize"
import StockReceivingItemDialog from "./StockReceivingItemDialog"

var hash = require("object-hash")

const useStyles = makeStyles((theme) => ({
    roots: {
        position: "relative",
    },
    root: {
        "& .MuiPaper-root": {
            marginTop: theme.spacing(1),
        },
    },
    back: {
        color: "inherit",
    },
    addItem: {
        left: 0,
    },
    tableNameTitle: {
        fontSize: 14,
        fontWeight: "bold",
    },
    extraTableName: {
        color: "inherit",
        zIndex: 99,
    },
    invoiceTableName: {
        color: "inherit",
        display: "flex",
        justifyContent: "space-evenly",
        zIndex: 99,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3",
        },
    },
}))

const REOPEN_RECEIVING_STOCK = gql`
  mutation ($receivingStockId: String!) {
    editReceivingStock(input: { id: $receivingStockId, receivingNumber: null }) {
      receivingNumber
    }
  }
`
const FINISH_RECEIVING_STOCK = gql`
  mutation ($receivingStockId: String!) {
    extras_finishReceivingStock(receivingStockId: $receivingStockId)
  }
`
const GQL_DELETE_RECEIVING_ITEM = gql`
  mutation ($id: String!) {
    deleteReceivingStockItem(id: $id)
  }
`
const GQL_DELETE_RECEIVING_INVOICE_ITEM = gql`
  mutation ($id: String!, $invoiceItemId: String!, $invoiceId: String!, $invoiceAmount: Float!) {
    deleteReceivingStockItem(id: $id)
    deleteInvoiceItemReceivingItem(receivingItemId: $id)
    deleteInvoiceItem(id: $invoiceItemId)
    editInvoice(input: { id: $invoiceId, amount: $invoiceAmount }) {
      id
    }
  }
`

// React Function Component
export default function StockReturningItem(props) {
    const classes = useStyles()

    const restaurant = useContext(RestaurantContext)
    const user = useContext(UserContext)

    let history = useHistory()
    let location = useLocation()

    const {restaurantId, stockReceivingId} = useParams()

    const size = useWindowSize()

    const [openAdd, setOpenAdd] = useState(false)

    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [openSnackBarWarning, setOpenSnackBarWarning] = useState(false)

    const [progress, setProgress] = useState(-1)

    let isCompact = size.isCompact

    const UNDELETE_ITEM = gql`
    mutation ($id: String!) {
      undeleteReceivingStockItem(id: $id)
    }
  `

    const EDIT_INVOICE_ITEM = gql`
        mutation (
            $id: String!, 
            $invoiceItemId: String!, 
            $invoiceId: String!, 
            $invoiceAmount: Float!, 
            $receivingStockId: String!, 
            $warehouseId: String!, 
            $stockTypeId: String!, 
            $article: String!, 
            $description: String!, 
            $quantity: Float!, 
            $uomOpts: Long!,
            $invoiceQuantity: Float!,
            $price: Float!, 
            $tax: Float!, 
            $discount: Float!, 
            $created: Long!, 
            $updated: Long!
            ) {
            createReceivingStockItem(input: {
                id: $id
                receivingStockId: $receivingStockId
                warehouseId: $warehouseId
                stockType: $stockTypeId
                quantity: $quantity
                uomOpts: $uomOpts
                price: $price
                tax: $tax
                discount: $discount
                created: $created
                updated: $updated
            }) {
                id
            }
            createInvoiceItem(input: {
                id: $invoiceItemId
                ${user.gqlCreateName("$article", false, "Article")}
                ${user.gqlCreateName("$description", false, "Description")}
                quantity: $invoiceQuantity
                price: $price
                tax: $tax
                discount: $discount
                invoiceId: $invoiceId
            }){
                id
            }
            createInvoiceItemReceivingItem(input: {
                invoiceItemId: $invoiceItemId,
                receivingItemId: $id
            }) {
                invoiceItemId
            }
            editInvoice(input: {
                id: $invoiceId
                amount: $invoiceAmount
            }) {
                id
            }
    }`
    const EDIT_ITEM = gql`
    mutation (
      $id: String!
      $receivingStockId: String!
      $warehouseId: String!
      $stockTypeId: String!
      $quantity: Float!
      $uomOpts: Long!
      $price: Float!
      $tax: Float!
      $discount: Float!
      $created: Long!
      $updated: Long!
    ) {
      createReceivingStockItem(
        input: {
          id: $id
          receivingStockId: $receivingStockId
          warehouseId: $warehouseId
          stockType: $stockTypeId
          quantity: $quantity
          uomOpts: $uomOpts
          price: $price
          tax: $tax
          discount: $discount
          created: $created
          updated: $updated
        }
      ) {
        id
      }
    }
  `
    const [saveItem, {loading: loadingSave}] = useMutation(EDIT_ITEM)
    const [saveInvoiceItem, {loading: loadingInvoiceSave}] = useMutation(EDIT_INVOICE_ITEM)

    // GraphQL API request definition (local variables: restaurantId)
    const GET_STOCK_RECEIVING_ITEM = gql`
    query ($receivingStockId: String!,$restaurantId: String!) {
        getReceivingStockById(id: $receivingStockId) {
			id
			company {
				id
				name {
					${user.gqlFetchName()}
				}
			}
			sender {
				id
				name {
					${user.gqlFetchName()}
				}
			}
			employee {
				id
				profile {
                    _id
                    name
                    email
				}
			}
            sendingNumber
            invoiceNumber
            receivingNumber
            invoiceId
			created
            updated
            lastTimePrinted
            issuingDate
		}
        getReceivingStockItemsByReceivingStockId(receivingStockId: $receivingStockId) {
            id
            receivingStockId
            warehouse {
                id
                name {
                    ${user.gqlFetchName()}
                }
            }
            stockType {
                id
                uom
                name {
                    ${user.gqlFetchName()}
                }
                ingredient
            }
            quantity
            price
            uomOpts
            tax
            discount
            created
            updated
            receivingItemInvoiceItems {
                invoiceItemId
            }
        }
        getWarehousesByContextRestaurantId(restaurantId: $restaurantId) {
            id
            name {
                ${user.gqlFetchName()}
            }
        }
        getStockTypesByRestaurantId(restaurantId: $restaurantId) {
            id
            name {
                ${user.gqlFetchName()}
            }
            uom
            piece
            package
            taxType {
                rate
            }
        }
    }`

    const {
        data: dataItem,
        loading: loadingItem,
        error,
        refetch,
    } = useQuery(GET_STOCK_RECEIVING_ITEM, {
        variables: {receivingStockId: stockReceivingId, restaurantId: restaurant.id},
        errorPolicy: "ignore",
        pollInterval: 5000,
    })

    const [finishReceivingStock, {loading: loadingFinish}] = useMutation(FINISH_RECEIVING_STOCK)
    const [reopenReceivingStock, {loading: loadingReopen}] = useMutation(REOPEN_RECEIVING_STOCK)

    const [deleteItem, {loading: loadingDeleteItem}] = useMutation(GQL_DELETE_RECEIVING_ITEM)
    const [deleteInvoiceItem, {loading: loadingDeleteInvoiceItem}] = useMutation(GQL_DELETE_RECEIVING_INVOICE_ITEM)

    let stockTypes = dataItem?.getStockTypesByRestaurantId || []
    let stockTypesHash = hash(stockTypes)
    let stockTypeOptions = useMemo(
        () => stockTypes.map((item) => ({...item, name: item.name[user.lang]})),
        [stockTypesHash]
    )

    let indexedStockTypes = useMemo(
        () =>
            stockTypeOptions.map((stockType) => ({
                ...stockType,
                search: `${stockType.name} ${stockType.name.cirilicToLatin()}`,
            })),
        [stockTypesHash]
    )

    // let lookupStockTypes = stockTypeOptions.reduce((acc, item) => ({ ...acc, [item.id]: item.name + " (" + lookupUom[item.uom] + ")" }), {});
    // let fistStockTypeId = stockTypes.length > 0 && stockTypes[0];

    let isLoading = loadingItem || loadingFinish || loadingDeleteItem || loadingDeleteInvoiceItem

    if (!dataItem) {
        return (
            <div className="App AppLoading">
                <CircularProgress/>
            </div>
        )
    }

    // In case there is an error, just show it for now
    // if (!dataItem) { return <div></div> }

    let lookupUom = {
        0: user.translate("liter"),
        1: user.translate("kilogram"),
        2: user.translate("piece"),
    }

    let stockReceiving = dataItem.getReceivingStockById
    let sendingNumber = dataItem.getReceivingStockById.sendingNumber
    let invoiceNumber = dataItem.getReceivingStockById.invoiceNumber
    let invoiceId = dataItem.getReceivingStockById.invoiceId
    let receivingNumber = dataItem.getReceivingStockById.receivingNumber

    let stockReceivingItems = dataItem.getReceivingStockItemsByReceivingStockId
    let warehouses = dataItem.getWarehousesByContextRestaurantId

    let warehouseOptions = warehouses.map((item) => ({...item, name: item.name[user.lang]}))
    let lookupWarehouses = warehouseOptions.reduce((acc, item) => ({...acc, [item.id]: item.name}), {})
    let firstWarehouseId = warehouses.length > 0 && warehouses[0].id

    let alertMessage, linkTo, linkText
    if (stockTypes.length === 0) {
        alertMessage = `${user.translate("please_create")} `
        linkTo = `/restaurant/${restaurant._id}/stock_types/`
        linkText = user.translate("stock_type")
        if (!openSnackBarWarning) setOpenSnackBarWarning(true)
    }

    if (warehouses.length === 0) {
        alertMessage = `${user.translate("please_create")} `
        linkTo = `/restaurant/${restaurant._id}/warehouse/`
        linkText = user.translate("warehouse")
        if (!openSnackBarWarning) setOpenSnackBarWarning(true)
    }

    // let taxOptions = {
    //     0: "0",
    //     0.05: "5%",
    //     0.18: "18%",
    // }

    let taxOptions = [
        {val: 0, label: "0"},
        {val: 0.05, label: "5"},
        {val: 0.1, label: "10"},
        {val: 0.18, label: "18"},
    ]

    const uomUnit = ["L", "Kg", "p"]

    const uomTitles = [user.translate("liter"), user.translate("kilogram"), user.translate("piece")]

    const getUomUoptions = function (stockTypeId) {
        let stockType = indexedStockTypes.find((item) => item.id == stockTypeId)

        if (stockType == undefined) return []
        let uom = stockType.uom

        const pieceQuantity = `${stockType.piece} ${uomUnit[uom]}`
        const uomOptionPiece = [
            {label: `${user.translate("dose")} (${pieceQuantity})`, val: 1},
            {label: `${user.translate("dose")} (${pieceQuantity})`, val: 1},
            {label: `${user.translate("dose")} (${pieceQuantity})`, val: 1},
        ]

        const packageQuantity = `${stockType.package}${uomUnit[uom]}`
        const uomOptionPackage = [
            {label: `${user.translate("bottle")} ${packageQuantity}`, val: 2},
            {label: `${user.translate("bag")} ${packageQuantity}`, val: 2},
            {label: `${user.translate("box")} ${packageQuantity}`, val: 2},
        ]

        const basicUomUoption = {label: uomTitles[uom].capitalize(), val: 0}

        return [basicUomUoption, uomOptionPiece[uom], uomOptionPackage[uom]]
    }

    const getUomQuantities = function (itemData) {
        let stockTypeId = itemData.stockTypeId || itemData._stockTypeId
        let stockType = indexedStockTypes.find((item) => item.id == stockTypeId)
        if (stockType == undefined) return [1]
        return stockType ? [1, stockType.piece, stockType.package] : [1]
    }

    let fieldList = {}
    fieldList["columns"] = [
        {width: "1%", cellStyle: {whiteSpace: "nowrap"}, title: "Id", field: "id", hidden: true},
        {width: "1%", cellStyle: {whiteSpace: "nowrap"}, title: "warehouseId", field: "warehouseId", hidden: true},
        {width: "1%", cellStyle: {whiteSpace: "nowrap"}, title: "stockTypeId", field: "stockTypeId", hidden: true},
        {
            width: "1%",
            cellStyle: {whiteSpace: "nowrap"},
            title: "invoiceId",
            field: "invoiceId",
            hidden: true,
            export: false,
        },
        {
            width: "1%",
            cellStyle: {whiteSpace: "nowrap"},
            title: "invoiceNumber",
            field: "invoiceNumber",
            hidden: true,
            export: false,
        },
        {width: "1%", cellStyle: {whiteSpace: "nowrap"}, title: "uom", field: "uom", hidden: true, export: false},
        {width: "1%", cellStyle: {whiteSpace: "nowrap"}, title: "tax", field: "tax", hidden: true, export: false},
        {
            width: "1%",
            cellStyle: {whiteSpace: "nowrap"},
            title: "created",
            field: "created",
            hidden: true,
            export: false,
        },
        {
            title: "",
            field: "count",
            editable: "never",
            cellStyle: {width: "1%", padding: `0px 5px 0 5px`, fontSize: 12, whiteSpace: "pre-line"},
        },
        {
            title: user.translate("warehouse"),
            field: "warehouse",
            mobile: false,
            cellStyle: {width: "15%", padding: 0, fontSize: 12, whiteSpace: "pre-line"},
            editComponent: (props) =>
                tableComponents.AutoComplete(
                    props,
                    warehouseOptions,
                    "warehouseId",
                    "warehouse",
                    false,
                    () => warehouseOptions[0]
                ),
            customFilterAndSearch: (filterValue, row) => {
                return (
                    (row.warehouse && row.warehouse.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                    (row.stockType && row.stockType.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                    (row.quantity && user.formatQuantity(row.quantity).indexOf(filterValue) > -1) ||
                    (row.price && user.formatNumber(row.price).indexOf(filterValue) > -1) ||
                    (row.discount && user.formatNumber(row.discount).indexOf(filterValue) > -1) ||
                    (row.price_with_vat && user.formatNumber(row.price_with_vat).indexOf(filterValue) > -1)
                )
            },
            customSort: (a, b) =>
                a.warehouse.cirilicLatinCompare(user.lang).localeCompare(b.warehouse.cirilicLatinCompare(user.lang), user.lang),
        },
        {
            title: user.translate("stock_type"),
            field: "stockType",
            cellStyle: {width: "15%", height: 30, padding: 0, fontSize: 12, whiteSpace: "pre-line"},
            editComponent: (props) =>
                tableComponents.AutoComplete(
                    props,
                    stockTypeOptions,
                    "stockTypeId",
                    "stockType",
                    false,
                    () => stockTypeOptions[0]
                ),
            customSort: (a, b) =>
                a.stockType.cirilicLatinCompare(user.lang).localeCompare(b.stockType.cirilicLatinCompare(user.lang), user.lang),
        },
        {
            title: user.translate("vat") + " %",
            field: "taxLabel",
            mobile: false,
            editComponent: (props) =>
                tableComponents.Select(
                    props,
                    taxOptions,
                    "tax",
                    (itemData) =>
                        (stockTypeOptions.find((st) => st.id == itemData.stockTypeId) || stockTypeOptions[0]).taxType.rate
                ),
        },
        {
            title: user.translate("uomOpts"),
            field: "uomOptsLabel",
            editComponent: (props) =>
                tableComponents.Select(
                    props,
                    getUomUoptions(props.rowData._stockTypeId || props.rowData.stockTypeId),
                    "uomOpts",
                    () => 0
                ),
        },
        {
            title: user.translate("quant"),
            field: "quantity",
            cellStyle: {width: "2%", padding: `0`, fontSize: 12, whiteSpace: "pre-line"},
            render: (rowData) =>
                user.formatQuantity(
                    rowData.convertUom(
                        rowData.quantity,
                        0,
                        rowData._uomOpts != undefined ? rowData._uomOpts : rowData.uomOpts || 0
                    )
                ),
            editComponent: (props) =>
                tableComponents.NumericUomEditComponent(props, "quantity", 3, false, (itemData) => getUomQuantities(itemData)),
        },
        {
            title: user.translate("price_with_vat"),
            field: "price_with_vat",
            mobile: false,
            render: (rowData) =>
                user.formatNumber(
                    rowData.convertUom(
                        rowData.price_with_vat,
                        rowData._uomOpts != undefined ? rowData._uomOpts : rowData.uomOpts || 0,
                        0
                    )
                ),
            editComponent: (props) =>
                tableComponents.NumericUomEditComponent(props, "price_with_vat", 3, true, (itemData) =>
                    getUomQuantities(itemData)
                ),
        },
        {
            title: user.translate("price_without_vat"),
            field: "price",
            mobile: false,
            cellStyle: {width: "2%", padding: `0`, fontSize: 12, whiteSpace: "pre-line"},
            render: (rowData) => user.formatNumber(rowData.convertUom(rowData.price, rowData.uomOpts || 0, 0)),
            editable: "never",
        },
        {
            title: user.translate("discount") + " %",
            field: "discount",
            mobile: false,
            render: (rowData) => user.formatNumber(rowData.discount),
            editComponent: (props) => tableComponents.NumericEditComponent(props, "discount"),
        },
        {
            title: user.translate("total"),
            field: "total",
            render: (rowData) => user.formatNumber(rowData.total),
            editComponent: (props) => tableComponents.NumericEditComponent(props, "total"),
        },
        {
            title: user.translate("created"),
            field: "createdLabel",
            mobile: false,
            editComponent: (props) => tableComponents.DateTimeEditComponent(props, "created", stockReceiving.created),
            cellStyle: {width: "15%", padding: `0`, fontSize: 12, whiteSpace: "pre-line"},
            render: (rowData) => (
                <Tooltip title={rowData.updatedLabel} placement="bottom">
                    <div>{rowData.createdLabel}</div>
                </Tooltip>
            ),
        },
    ]
    fieldList.options = {search: false}

    fieldList["data"] = stockReceivingItems.map((result, index) => {
        let stockType = indexedStockTypes.find((item) => item.id == result.stockType.id)
        let quantity = -(stockType.uom === 2 ? result.quantity || 0 : result.quantity / 1000)
        let priceWithTax = result.price && result.tax ? result.price * (1 + result.tax) : result.price ? result.price : 0
        let uomOpts = result.uomOpts

        let uomOptions = getUomUoptions(stockType.id)

        const unitQuantities = stockType ? [1, stockType.piece, stockType.package] : []
        const convertUom = (value, fromUnit, toUnit = 0, roundTo = 3) => {
            if (unitQuantities[fromUnit] == undefined) return undefined
            if (unitQuantities[toUnit] == undefined) return undefined
            return ((value * unitQuantities[fromUnit]) / unitQuantities[toUnit]).round(roundTo)
        }
        console.log("result.quantity", result.quantity, quantity)

        return {
            ...result,
            count: index + 1 + ".",
            id: result.id,
            unitQuantities: unitQuantities,
            convertUom: convertUom,
            price_with_vat: priceWithTax,
            warehouseId: result.warehouse.id,
            warehouse: result.warehouse.name[user.lang],
            stockTypeId: stockType.id,
            stockType: stockType.name,
            uom: stockType.uom,
            uomOpts: uomOpts || 0,
            uomOptsLabel: uomOptions.find((opts) => opts.val == uomOpts).label,
            uomOptions: uomOptions,
            quantity: quantity,
            tax: result.tax ? result.tax : 0,
            taxLabel: result.tax ? taxOptions.find((c) => c.val === result.tax)?.label || result.tax.toString() : "0",
            discount: result.discount ? result.discount * 100 : 0,
            total: (priceWithTax * quantity).round(3),
            invoiceId: invoiceId,
            invoiceNumber: invoiceNumber,
            createdLabel: result.created ? user.formatDate(result.created, true) : "",
            updatedLabel: result.updated ? user.formatDate(result.updated, true) : "",
        }
    })

    const calcTotalAmount = (items) =>
        items
            .sumOf((item) => {
                let stockTypeId = item.stockTypeId || item.stockType.id
                let stockType = indexedStockTypes.find((stockType) => stockType.id == stockTypeId)
                let quantity = stockType.uom === 2 ? item.quantity || 0 : item.quantity / 1000
                let tax = item.tax
                let price = item.price * (1 - item.discount)
                let subTotal = price * quantity
                let totalVat = tax * price * quantity
                return subTotal + totalVat
            })
            .round(0) * -1

    let totalAmount = calcTotalAmount(stockReceivingItems)

    const onAddClick = (e) => {
        setOpenAdd(!openAdd)
    }

    const onClosePltClick = () => {
        if (isLoading) return
        finishReceivingStock({variables: {receivingStockId: stockReceivingId}}).then(() => {
            refetch()
        })
    }

    const onReopenClick = () => {
        if (isLoading) return
        reopenReceivingStock({variables: {receivingStockId: stockReceivingId}}).then(() => {
            refetch()
        })
    }

    const closeItem = () => {
        if (location.state?.from == "receivingList") {
            history.goBack()
        } else {
            history.push("../stock_returning")
        }
    }

    const onSaveItem = (itemData) => {
        setProgress(0)
        let stockTypeId = itemData.stockTypeId || itemData._stockTypeId
        let stockType = indexedStockTypes.find((item) => item.id == stockTypeId)

        let quantity = itemData.quantity != undefined ? itemData.quantity : itemData._quantity
        let systemQuantity = stockType.uom == 2 ? quantity : quantity * 1000

        let tax = itemData.tax != undefined ? itemData.tax : itemData._tax
        let priceWithTax = itemData.price_with_vat

        let variables = {
            id: itemData?.id || helper.uid(),
            receivingStockId: stockReceivingId,
            warehouseId: itemData.warehouseId || itemData._warehouseId,
            stockTypeId: stockTypeId,
            quantity: -systemQuantity,
            uomOpts: itemData.uomOpts != undefined ? itemData.uomOpts : itemData._uomOpts || 0,
            price: priceWithTax && !isNaN(priceWithTax) ? priceWithTax / (1 + tax) : 0,
            tax: tax,
            discount: itemData.discount ? itemData.discount / 100 : 0,
            created: itemData.created ? new Date(itemData.created).getTime() : stockReceiving.created,
            updated: itemData.updated ? new Date(itemData.updated).getTime() : stockReceiving.updated,
        }

        var invoiceItemIds =
            itemData.receivingItemInvoiceItems?.map((receivingInvoiceItem) => receivingInvoiceItem.invoiceItemId) || []
        if (invoiceItemIds.length == 0 && invoiceId) {
            invoiceItemIds = [helper.uid()]
        }

        if (invoiceItemIds.length == 0) {
            return saveItem({variables: variables}).then((e) => {
                setProgress(100)
                refetch().then(() => {
                    setProgress(-1)
                })
                setOpenAdd(false)
            })
        } else {
            variables.invoiceId = invoiceId
            variables.article = stockType.name
            variables.description = ""
            variables.invoiceQuantity = -itemData.quantity
            variables.invoiceAmount = calcTotalAmount([variables, ...stockReceivingItems].distinctBy((item) => item.id))

            let count = 1
            return invoiceItemIds
                .reducePromise((invoiceItemId) => {
                    return saveInvoiceItem({
                        variables: {
                            ...variables,
                            invoiceItemId: invoiceItemId,
                            invoiceId: invoiceId,
                        },
                    }).then((value) => {
                        setProgress(5 + (count++ * 95) / invoiceItemIds.length)
                        return value
                    })
                })
                .then((e) => {
                    setProgress(100)
                    refetch().then(() => {
                        setProgress(-1)
                    })
                    setOpenAdd(false)
                })
        }
    }

    const onDeleteItem = (oldData, state) => {
        let invoiceItemIds =
            oldData.receivingItemInvoiceItems?.map((receivingInvoiceItem) => receivingInvoiceItem.invoiceItemId) || []

        if (invoiceItemIds.length == 0) {
            deleteItem({
                variables: {id: oldData.id},
            }).then((e) => {
                refetch()
                setOpenAdd(false)
                setOpenSnackBar(oldData)
            })
        } else {
            let invoiceVars = {
                invoiceId: invoiceId,
                invoiceAmount: calcTotalAmount(stockReceivingItems.filter((item) => item.id != oldData.id)),
            }
            invoiceItemIds
                .reduce((acc, invoiceItemId) => {
                    let opts = {variables: {...invoiceVars, id: oldData.id, invoiceItemId: invoiceItemId}}
                    if (acc == false) return deleteInvoiceItem(opts)
                    return acc.then(() => deleteInvoiceItem(opts))
                }, false)
                .then((e) => {
                    refetch()
                    setOpenAdd(false)
                    setOpenSnackBar(oldData)
                })
        }
        // let items = {};

        // if (invoiceId && invoiceNumber) {
        //     setNewData(oldData);
        //     setDeleteIt(true);
        //     getInvoiceItemId({ variables: { receivingItemId: oldData.id } });
        // }
        // else {
        //     items['id'] = oldData.id;

        //     deleteItem({ variables: items });

        //     setDeleteState({
        //         variables: items,
        //         name: `${oldData['warehouse']}, ${oldData['stockType']}`,
        //         state: state
        //     })
        //     setOpenSnackBar(true);
        //     setRefetchIt(true);
        // }
    }

    let printTitle = (
        <div>
            <span className={classes.tableNameTitle}>{user.translate("stock_returning_doc")}</span>
            <br/>
            {receivingNumber && (
                <div style={{fontSize: 12}}>
                    {user.translate("returning_no")}: {receivingNumber}
                </div>
            )}
            <div style={{fontSize: 12}}>
                {user.translate("to_pay")}: {totalAmount}
            </div>
        </div>
    )

    let tableName = (
        <>
            <IconButton className={classes.back} onClick={closeItem}>
                <KeyboardBackspaceIcon/>
            </IconButton>

            {!receivingNumber && (
                <Button
                    className={classes.addItem}
                    color="primary"
                    component="span"
                    size={size.isCompact ? "small" : "medium"}
                    onClick={onAddClick}
                    startIcon={<SubtitlesIcon/>}
                >
                    {user.translate("add_item")}
                </Button>
            )}
        </>
    )

    let extraTableName = (
        <div className={classes.extraTableName}>
            {!receivingNumber && (
                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size={size.isCompact ? "small" : "medium"}
                    disabled={isLoading}
                    onClick={onClosePltClick}
                    startIcon={<CancelPresentationIcon/>}
                >
                    {user.translate("close")}
                </Button>
            )}

            {receivingNumber && (
                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size={size.isCompact ? "small" : "medium"}
                    disabled={isLoading}
                    onClick={onReopenClick}
                    style={{marginRight: 16}}
                    startIcon={<OpenAgainIcon/>}
                >
                    {user.translate("reopen")}
                </Button>
            )}

            {receivingNumber && (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    component="span"
                    size={size.isCompact ? "small" : "medium"}
                    onClick={(e) => history.push(`/restaurant/${restaurant._id}/plt_report/${stockReceivingId}`)}
                    startIcon={<PrintIcon/>}
                >
                    {user.translate("plt_report")}
                </Button>
            )}
        </div>
    )

    return (
        <div className={classes.roots}>
            <div className={classes.invoiceTableName}>
                {extraTableName}

                {invoiceId && invoiceNumber && (
                    <Tooltip title={`${user.translate("open_invoice")}`} placement="bottom">
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            size={size.isCompact ? "small" : "medium"}
                            onClick={(e) => history.push(`/restaurant/${restaurant._id}/invoice_input/${invoiceId}/`)}
                            startIcon={<DescriptionIcon/>}
                        >
                            {user.translate("open_invoice")}
                        </Button>
                    </Tooltip>
                )}
            </div>
            <div className={classes.root}>
                <Table
                    key={helper.hash(user.lang + printTitle)}
                    isLoading={isLoading}
                    onRowClick={isCompact && setOpenAdd}
                    tableName={tableName}
                    printTitle={printTitle}
                    exportFileName={user.translate("stockreturning")}
                    fieldList={fieldList}
                    tableColumnsPadding={"24px 0 0 0"}
                    pageSize="50"
                    pageSizeOptions={[50, 100, 200]}
                    onAddItem={isCompact || receivingNumber ? undefined : onSaveItem}
                    onEditItem={!isCompact && onSaveItem}
                    onDeleteItem={!isCompact && onDeleteItem}
                    openAdd={!isCompact && openAdd}
                    setOpenAdd={!isCompact && setOpenAdd}
                />
            </div>

            {Boolean(openAdd) && isCompact && (
                <StockReceivingItemDialog
                    item={openAdd === true ? {receivingStockId: dataItem.getReceivingStockById.id} : openAdd}
                    invoiceId={invoiceId}
                    stockTypes={stockTypeOptions}
                    warehouses={warehouseOptions}
                    onClose={setOpenAdd}
                    onDelete={setOpenSnackBar}
                    onSuccess={refetch}
                />
            )}

            {Boolean(openSnackBar) && (
                <SnackBarDelete
                    hideUndo={true}
                    message={`${openSnackBar.stockType} 
                    ${user.translate("deleted")}!`}
                    variables={openSnackBar}
                    openSnackBar={Boolean(openSnackBar)}
                    setOpenSnackBar={setOpenSnackBar}
                    unDeleteItem={UNDELETE_ITEM}
                />
            )}

            {openSnackBarWarning && (
                <SnackBarWarning
                    message={alertMessage}
                    linkTo={linkTo}
                    linkText={linkText}
                    openSnackBar={openSnackBarWarning}
                    setOpenSnackBar={setOpenSnackBarWarning}
                />
            )}
            <ProgressDialog show={progress >= 0} progress={progress}/>
        </div>
    )
}
