import React, { useState, useContext, useEffect } from 'react';
import {
    CircularProgress,
    makeStyles,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    DialogContentText,
    Typography,
    FormGroup,
    ClickAwayListener,
    Grid,
} from "@material-ui/core";
import { useMutation, gql } from '@apollo/client';
import { UserContext } from "../../App";
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDateTimePicker,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { DateRangePickerComponent } from "materialui-daterange-picker";
import helper from "./Helper";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        flexGrow: 1,
        height: "100vh"
    },
    formRow: {
        marginBottom: 25,
        marginTop: 10,
        fontSize: 14,
        justifyContent: `center`,
    },
    formControlDate: {
        minWidth: 150,
        width: `auto`,
        margin: `15px ${theme.spacing(3)}px 0`,
        float: "left",
        paddingLeft: theme.spacing(3),
    },
    dateRange: {
        position: `absolute`,
        marginTop: `36px`,
        zIndex: `999`
    },
}));

export default function ProductAdjustments(props) {

    const classes = useStyles();

    const user = useContext(UserContext);

    const UPDATE_ORDERS_BY_PRODUCT = gql`
        mutation($productId: String!, $startDate: Long!, $endDate: Long!, $dryRun: Boolean!) {
            dangerZone_updateOrdersByProduct(productId: $productId, fromTime: $startDate, toTime: $endDate, dryRun:$dryRun)
    }`;

    const [runAdjustItem, { data: data_adjust, loading: loading_adjust }] = useMutation(UPDATE_ORDERS_BY_PRODUCT);

    let startDate = new Date(), endDate = new Date();
    startDate.setMonth(startDate.getMonth(), 1); //the first of this month
    // endDate.setMonth(endDate.getMonth(), 0); //- the last day of last month
    startDate.setHours("00"); 
    startDate.setMinutes("00"); 
    startDate.setSeconds("00");
    endDate.setHours("23"); 
    endDate.setMinutes("59"); 
    endDate.setSeconds("59");

    const [openDateRange, setOpenDateRange] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: startDate,
        endDate: endDate
    });

    const toggleDateRangeOpen = () => setOpenDateRange(!openDateRange);
    const closeDateRange = () =>  setOpenDateRange(false);

    const handleDateFromChange = (date) => {
        if (date != "Invalid Date") {
            let dateFrom = new Date(date); 
            dateFrom.setHours("00"); 
            dateFrom.setMinutes("00")
            dateFrom.setSeconds("00");
            setDateRange({...dateRange, startDate: dateFrom});
        }
    }
    const handleDateToChange = (date) => {
        if (date != "Invalid Date") {
            let dateTo = new Date(date); 
            dateTo.setHours("23"); 
            dateTo.setMinutes("59")
            dateTo.setSeconds("59");
            setDateRange({...dateRange, endDate: dateTo});
        }
    }

    const handleDateRangeAccept = (range) => {
        let dateFrom = new Date(range.startDate); 
            dateFrom.setHours("00"); 
            dateFrom.setMinutes("00")
            dateFrom.setSeconds("00");
        let dateTo = new Date(range.endDate); 
            dateTo.setHours("23"); 
            dateTo.setMinutes("59")
            dateTo.setSeconds("59");
        setDateRange({
            startDate: dateFrom,
            endDate: dateTo
        });
        closeDateRange();
    }

    // Adjust Dialog
    const handleClose = () => {
        props.setOpenAdjust(false);
    };

    const handleAdjustAccept = () => {
        let items = {
            ...props.variables,
            startDate: dateRange.startDate.getTime(),
            endDate: dateRange.endDate.getTime(),
            dryRun: false
        }
        runAdjustItem({
            variables: items
        });
        user.consoleLog(items)
    }
    
    const handleRawAdjustAccept = () => {
        let items = {
            ...props.variables,
            startDate: dateRange.startDate.getTime(),
            endDate: dateRange.endDate.getTime(),
            dryRun: true
        }
        runAdjustItem({
            variables: items
        });
    }

    return (
        <>
            <Dialog
                open={true}
                fullWidth
                maxWidth="md"
                scroll={"paper"}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`${user.translate("adjust_accept")} ${props.name}`}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <FormGroup row className={classes.formRow}>
                        <ClickAwayListener
                            className={classes.formRow}
                            onClickAway={closeDateRange}
                            >
                            <div >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="flex-start" className={classes.formControlDate}>
                                        <KeyboardDatePicker
                                            disableToolbar={false}
                                            animateYearScrolling={true}
                                            autoOk={true}
                                            open={false}
                                            variant="inline"
                                            format={user.dateFormat}
                                            id={"filter_date_from"}
                                            label={user.translate("date_from")}
                                            value={dateRange.startDate}
                                            onClick={toggleDateRangeOpen}
                                            onChange={handleDateFromChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="flex-start" className={classes.formControlDate}>
                                        <KeyboardDatePicker
                                            disableToolbar={false}
                                            animateYearScrolling={true}
                                            autoOk={true}
                                            open={false}
                                            variant="inline"
                                            format={user.dateFormat}
                                            id={"filter_date_to"}
                                            label={user.translate("date_to")}
                                            value={dateRange.endDate}
                                            onClick={toggleDateRangeOpen}
                                            onChange={handleDateToChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            InputProps={{ position: "start" }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>

                                <div className={classes.dateRange}>
                                    <DateRangePickerComponent
                                        open={openDateRange}
                                        toggle={toggleDateRangeOpen}
                                        initialDateRange={dateRange}
                                        onChange={(range) => handleDateRangeAccept(range)}
                                    />
                                </div>
                            </div>
                        </ClickAwayListener>
                    </FormGroup>

                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography
                            variant="body1"
                            style={{ whiteSpace: 'pre-line' }}
                        >
                            {data_adjust && data_adjust.dangerZone_updateOrdersByProduct}
                        </Typography>
                    </DialogContentText>

                    {loading_adjust && (<CircularProgress />)}

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {user.translate("cancel")}
                    </Button>
                    <Button onClick={handleAdjustAccept} color="primary">
                        {user.translate("adjust")}
                    </Button>
                    <Button onClick={handleRawAdjustAccept} color="primary" autoFocus>
                        {user.translate("dry_adjust")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}