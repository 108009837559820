import { Button, CircularProgress, Tooltip } from "@material-ui/core"
import React, { useContext } from "react"
import Moment from "react-moment"
import { UserContext } from "../../App"
import { useEntityRelationQuery } from "../common/useEntityQuery"

export default function InventoryCounterState(props) {
  const user = useContext(UserContext)

  const inventoryCounterCountersQuery = useEntityRelationQuery(
    "InventoryCounterItem",
    [
      { name: "id", gql: "String!", readOnly: true },
      { name: "inventoryCounterId", gql: "String!" },
      { name: "time", gql: "String!" },
      { name: "updated", gql: "String!" },
      { name: "state", gql: "Float!" },
      { name: "quantity", gql: "Float!" },
      { name: "employeeId", gql: "String" },
      { name: "employee", gql: "Employee!", readOnly: true, subSelection: { profile: { name: true } } },
    ],
    "inventoryCounterId",
    props.inventoryCounter.id,
    {
      orderByDesc: "time",
      limit: 1,
    }
  )

  const item = inventoryCounterCountersQuery.items && inventoryCounterCountersQuery.items[0]

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const newValue = prompt(user.translate("enter_new_state", item?.state?.toString() ?? ""))
    if (newValue === null || newValue === undefined) return
    try {
      const newState = parseFloat(newValue)
      inventoryCounterCountersQuery.saveItem({
        employeeId: user.me.id,
        state: newState,
        quantity: props.inventoryCounter.quantity,
        time: Date.now(),
        updated: Date.now(),
        inventoryCounterId: props.inventoryCounter.id,
      })
    } catch (e) {}
  }

  const style = { minWidth: 48 }

  if (inventoryCounterCountersQuery.isLoading) {
    return (
      <Button style={style} onClick={onClick}>
        <CircularProgress size={16} />
      </Button>
    )
  }
  if (!item) {
    return (
      <Button style={style} onClick={onClick}>
        0
      </Button>
    )
  }
  return (
    <Tooltip
      title={
        <span>
          <Moment format="llll" locale={user.lang}>
            {parseInt(item.time)}
          </Moment>{" "}
          - {item.employee?.profile?.name}
        </span>
      }
    >
      <Button style={style} onClick={onClick} color="secondary">
        <b>{item.state}</b>
      </Button>
    </Tooltip>
  )
}
