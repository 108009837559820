import React, { useContext, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { RestaurantContext, UserContext } from "../../App";
import { makeStyles, CircularProgress, Grid } from '@material-ui/core';
import IconAdd from '@material-ui/icons/Add';
import SpeedDial from "@material-ui/lab/SpeedDial";
import StaffItem from "./StaffItem"
import Add from '../common/Add';
import helper from '../common/Helper';
import Delete from '../common/Delete';
import SnackBarDelete from '../common/SnackBarDelete';

// CSS
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'inline-flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		padding: theme.spacing(3),
	},
	gridItem: {
		maxWidth: 290,
		minWidth: 290,
		flexGrow: 1
	},
	speedDial: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2)
	}
}));


// React Function Component
export default function StaffList() {
	const classes = useStyles();

	// Get current restaurant from context
	const restaurant = useContext(RestaurantContext);
	const user = useContext(UserContext);

	const [openManipulateBox, setOpenManipulateBox] = useState(false);
	const [openDeleteBox, setOpenDeleteBox] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);

	const [deleteState, setDeleteState] = useState({
		name: "",
		variables: {},
		hideUndo: false
	});

	const onManipulateClick = (e) => {
		setOpenManipulateBox(true);
    }
    
    let roleFieldOptions = [
        { val: "0", label: user.translate("deactivated") },
        { val: "1", label: user.translate("employee") },
        { val: "2", label: user.translate("shift_manager") },
        { val: "3", label: user.translate("manager") },
        { val: "4", label: user.translate("owner") }
    ].filter((c) => c.val < user.role || user.role === 4);

	const fieldList = [
		{
			required: true,
			type: "text",
			fieldType: "text",
			fieldName: "name",
			fieldLabel: user.translate("name"),
			fieldValue: "",
        },
        {
			required: false,
			type: "email",
			fieldType: "text",
			fieldName: "email",
			fieldLabel: user.translate("email"),
            fieldValue: "",
        },
        {
			required: false,
			type: "hidden",
			fieldType: "text",
			fieldName: "code",
			fieldLabel: user.translate("code"),
			fieldValue: ""
        },
        {
			required: false,
			type: "password_show",
			fieldType: "text",
			fieldName: "pinCode",
			fieldLabel: user.translate("pin_code"),
			fieldValue: ""
        },
        {
			required: false,
			type: "password_show",
			fieldType: "text",
			fieldName: "patternCode",
			fieldLabel: user.translate("pattern_code"),
			fieldValue: ""
        },
        {
			required: false,
			type: "password_show",
			fieldType: "text",
			fieldName: "nfcTag",
			fieldLabel: user.translate("nfc_tag"),
			fieldValue: ""
        },
        {
			required: true,
			fieldType: "radio",
			fieldName: "role",
			fieldLabel: user.translate("role"),
			fieldValue: "0",
			fieldOptions: roleFieldOptions
        }, 
        {
			required: true,
			type: "hidden",
			fieldType: "text",
			fieldName: "lang",
			fieldLabel: user.lang,
			fieldValue: user.lang
        },
        {
			required: true,
			type: "hidden",
			fieldType: "text",
			fieldName: "employeeId",
			fieldLabel: "employeeId",
			fieldValue: helper.uid(),
		}
	];

	const DELETE_ITEM = `
		mutation ($employeeId: String!, $restaurantId: String!) {
			deleteRestaurantEmployee(employeeId: $employeeId, restaurantId: $restaurantId)
    }`;
	const UNDELETE_ITEM = `
		mutation ($employeeId: String!) {
			undeleteRestaurantEmployee(employeeId: $employeeId)
    } `;

	const ADD_ITEM = `
    mutation ($restaurantId: String!, $employeeId: String!, $code: String, $pinCode: String, $patternCode: String, $nfcTag: String, $role: Long!, $name: String!, $email: String, $lang: String!) {
        newUser(id: $employeeId){id}
		createProfile(input: {
			userId: $employeeId
			name: $name
			email: $email
		}){
			userId
        }
		createRestaurantEmployee(input: {
			employeeId: $employeeId
			restaurantId: $restaurantId
			role: $role
			code: $code
			pinCode: $pinCode
			patternCode: $patternCode
			nfcTag: $nfcTag
			lang: $lang
		}){
			employeeId
		}
    }`;

	const ADD_ITEM1 = `
    mutation ($restaurantId: String!, $employeeId: String!, $code: String, $pinCode: String, $patternCode: String, $nfcTag: String, $role: Long!, $name: String!, $email: String, $lang: String!) {
        createUser(input: {
            id: $employeeId
            lang: "${user.lang}"
        }){
            id
        }
		createProfile(input: {
			userId: $employeeId
			name: $name
			email: $email
		}){
			userId
        }
		createRestaurantEmployee(input: {
			employeeId: $employeeId
			restaurantId: $restaurantId
			role: $role
			code: $code
			pinCode: $pinCode
			patternCode: $patternCode
			nfcTag: $nfcTag
			lang: $lang
		}){
			employeeId
		}
    }`;

	// GraphQL API request definition (local variables: restaurantId)
	const GET_STAFF_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
		getRestaurantEmployeesByContextRestaurantId(restaurantId: $restaurantId) {
			employee {
                id
				profile {
                    _id
					name
                    email
                    picture
				}
			}
			role
			code
			pinCode
			patternCode
			nfcTag
			lang
		}
	}`;

	// Make the api request or get cached.
	// This makes the componnet to refresh when new data is available i.e. api finished.
	const { data, loading, error, refetch } = useQuery(GET_STAFF_BY_RESTAURANT, {
		variables: { restaurantId: restaurant.id },
        pollInterval: 3000,
        errorPolicy: "ignore"
	});


	// If it is loading, show progress bar
	// if (loading) return <CircularProgress />
	if (loading) {
		return (<div className="App AppLoading"><CircularProgress /></div>);
	}

	// In case there is an error, just show it for now
    if (!data) { user.consoleLog(error); return <p>Error</p> }
    
    let employees = {};

    if(data)
    {
        employees = data.getRestaurantEmployeesByContextRestaurantId;
    }

	return (
		<div className={classes.root}>
			<Grid
				container
				spacing={2}
				direction="row"
				justify="center"
				alignItems="center"
			>
				{
					employees && employees.map(staff => staff && (
						<Grid item xs={4} key={staff.employee.id} className={classes.gridItem}>
							<StaffItem 
								staff={staff} 
								setOpenDeleteBox={setOpenDeleteBox} 
								setDeleteState={setDeleteState}
								refetch={refetch} 
								/>
						</Grid>
					))
				}
			</Grid>
			<SpeedDial
				ariaLabel={`${user.translate("add")} ${user.translate("staff")}`}
				className={classes.speedDial}
				open={false}
				onClick={onManipulateClick}
				icon={<IconAdd />}
			/>

			{ openDeleteBox && (
				<Delete 
					name={deleteState.name} 
					variables={deleteState.variables} 
					setOpenSnackBar={setOpenSnackBar}
					setOpenDeleteBox={setOpenDeleteBox}
					deleteItem={DELETE_ITEM} 
					onSuccess={refetch} 
					/>
			)}

			{openSnackBar && (
				<SnackBarDelete 
					hideUndo={deleteState.hideUndo} 
					message={deleteState.name + ` ${user.translate("deleted")}!`} 
					variables={deleteState.variables} 
					openSnackBar={openSnackBar} 
					setOpenSnackBar={setOpenSnackBar} 
					unDeleteItem={UNDELETE_ITEM} 
					onSuccess={refetch} 
					/>
            )}

			{ openManipulateBox && (
				<Add 
					fieldList={fieldList} 
					openManipulateBox={setOpenManipulateBox} 
					actionType={user.translate("add")} 
					name={user.translate("staff")} 
					restaurant={restaurant} 
					manipulateItem={ADD_ITEM} 
					manipulateItem1={ADD_ITEM1} 
                    onSuccess={refetch} 
                    checkStaff={true}
					/>
			)}
		</div>
	)

}
