import React, { useContext, useState } from 'react';
import { 
    makeStyles, 
 } from '@material-ui/core';
import { UserContext } from '../../../App';
import FormInvoice from '../../common/FormInvoice';
import helper from '../../common/Helper';

const useStyles = makeStyles((theme) => ({
	root: {
        display: 'inline-flex',
        flexDirection: "column",
        position: "relative",
        width: "70%",
        top: theme.spacing(2)
	},
	itemName: {
        position: "absolute",
        right: 0,
        top: -32,
	},
	footer: {
		display: `inline-flex`,
	}
}));

export default function InvoiceComment(props) {
	const classes = useStyles();
	// Get current user preferences (language)
	const user = useContext(UserContext);
    const invoice = props.invoice;
    
    let footerText = invoice.comment && invoice.comment[user.lang] !== "" ? invoice.comment[user.lang] : "";
    
    const [fieldListComment, setFieldListComment] = useState([
        {
            required: false,
            type: "text",
            fieldType: "textarea",
            fieldName: "comment",
            fieldLabel: user.translate("comment"),
            fieldValue: footerText,
            fieldTextValue: footerText,
        },
        {
            required: true,
            type: "hidden",
            fieldType: "text",
            fieldName: "commentLangId",
            fieldLabel: "commentLangId",
            fieldValue: invoice.comment ? invoice.comment.id : "",
        },
    ]);

	const setItems = (event, fieldName, fieldValue) => {

        let items = {}, field = {};
        let langId = fieldListComment.find(c => c.fieldName === `${fieldName}LangId`);

        field = {
            fieldType: `$${fieldName}: String!`,
            field: fieldName
        };

        if(typeof langId !== "undefined")
        {
            items = {
                id: langId.fieldValue,
                [fieldName]: fieldValue,
            }
            props.onEditInvoice(items, field, true);
        }
        else
        {
            items = {
                id: invoice.id,
                [fieldName]: fieldValue,
            }
            props.onEditInvoice(items, field, false);
        }

        user.consoleLog(items);
    }

    //styles={classes.billingStyles}

	return (
		<div className={classes.root}>

            {
                fieldListComment.map((item, index) => (
                    <FormInvoice 
                        key={"invoice_billing_"+item.fieldValue+index} 
                        fieldList={fieldListComment} 
                        item={index} 
                        tabIndex={35}
                        setFieldList={setFieldListComment} 
                        setItems={setItems} 
                        left={true}
                        footer={true}
                        noprint="no_print"
                        styles={classes.footer}
                        />
                ))
            }

		</div>
	);

}
