import React, {useContext, useEffect} from "react";
import {RestaurantContext, UserContext} from "../../../App";
import {useCustomQuery} from "../../common/useEntityQuery";
import {DynamicTable} from "../../common/DynamicTable";

const RESULTS = {
    pos: true,
    taxAmountDomesticA: true,
    taxAmountDomesticB: true,
    taxAmountDomesticC: true,
    taxAmountDomesticD: true,
    totalIncomeDomestic: true,
    totalIncome: true,
    totalIncomeByTaxA: true,
    totalIncomeByTaxB: true,
    totalIncomeByTaxC: true,
    totalIncomeByTaxD: true,
    totalTax: true,
    totalTaxByRateA: true,
    totalTaxByRateB: true,
    totalTaxByRateC: true,
    totalTaxByRateD: true,
    totalTaxDomestic: true
};

export default function DateKdfiReport(props) {
    const restaurant = useContext(RestaurantContext);
    const user = useContext(UserContext);

    const {date} = props
    const startDate = new Date(date)
    const endDate = new Date(date)
    startDate.setHours(0, 0, 0, 0)
    endDate.setHours(23, 59, 59)


    const FIELDS = [
        {name: "documentHeaders", gql: "DocumentHeaders", subSelection: {value: true, title: true}},
        {name: "headers", gql: "Headers", subSelection: {name: true, title: true, hidden: true}},
        {name: "results", gql: "Results", subSelection: RESULTS}
    ]
    const query = useCustomQuery("report_dailyFinancialReport", FIELDS, "restaurantId", restaurant.id, {
        args: {dateFrom: startDate.getTime(), dateTo: endDate.getTime(), filters: [{name: "grouping", selection: ["byPos"]}], lang: user.lang},
        single: true
    })

    useEffect(() => {
        if (props.setIsLoading) props.setIsLoading(query.isLoading)
        if(!query.isLoading) {
            props.setDocumentHeaders(query.item?.documentHeaders)
        }
    }, [query.isLoading])

    const columns = [
        {name: "pos", title: "pos", gql: "String!"},
        {name: "totalIncome", title: "turnover", gql: "String!", render: (item) => user.formatNumber(item.totalIncome) + " ден."},
    ]

    const items = query.item?.results ?? []
    const tableItems = [...items, {pos: user.translate("total"), totalIncome: items.sumOf(it => it.totalIncome)}]

    return (<DynamicTable
        name={user.translate("fiscal_revenue")}
        items={tableItems}
        columns={columns}
        search={false}
        nowrap={props.nowrap}
        onRowClick={props.onRowClick}
        paging={false}
        noPaging
        noEdit
    />)
}