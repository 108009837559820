import React, { useContext, useState } from 'react';
import { useMutation, gql } from "@apollo/client";
import { UserContext } from "../App";
import { useParams, useHistory } from "react-router-dom";
import {
    CircularProgress,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
} from '@material-ui/core';

export default function ChangeEmail(props) {

    const { code } = useParams();
    
    const user = useContext(UserContext);

	const EDIT_ITEM = gql`
    mutation($code: String!) {
        auth_verifyEmail(codeWithVerifier: $code) {
            message
            ok
        }
    }`;

    const [editMail, { loading: loading_editMail, data: data_editMail}] = useMutation(EDIT_ITEM);
    
    let history = useHistory();

    if (loading_editMail) {
		return <div className="App AppLoading"><CircularProgress /></div>;
    }

    if(!loading_editMail && code && !data_editMail)
    {
        editMail({variables: {code: code}});
    }

    let status = "", message="";

    if(data_editMail && code)
    {
        status = data_editMail.auth_verifyEmail.ok;
        message = data_editMail.auth_verifyEmail.message;
    }

    const goHome = () => {
        props.refetch();
		history.push('/home');
    }
    
    return (
        <Dialog
            open={data_editMail && code}
            onClose={goHome}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
            <DialogActions>
                <Button onClick={goHome} color="primary">
                    {user.translate("home")}
                </Button>
            </DialogActions>
        </Dialog>
    );

}
