import {
    alpha, AppBar, Badge, CardMedia, Collapse, Divider, Drawer, IconButton, InputBase, List, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Toolbar, Typography
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import config from '../../config';
import { UserContext } from "../App";
import useWindowSize from '../restaurant/common/useWindowSize';
import ConnectFb from './ConnectFb';
import GenerateOTP from './GenerateOTP';
import Profile from './Profile';
import RequestEmail from './RequestEmail';
import RequestPassword from './RequestPassword';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {

    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 72,
        [theme.breakpoints.up('sm')]: {
            width: 72,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    drawerContent: {
        display: 'flex',
        "& .MuiDrawer-paperAnchorDockedLeft,": {
            borderColor: theme.palette.primary.light,
        },
        "& .MuiListItemIcon-root": {
            // color: theme.palette.primary.main,
            color: "white",
        },
        "& .MuiDrawer-paper": {
            // backgroundColor: theme.palette.primary.dark,
            backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.medium} , ${theme.palette.primary.dark})`,
            color: "white",
        },
        "& ul a .MuiTypography-root": {
            color: "white",
        },
        "& ul a .Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
    },
    content: {
        maxWidth: '100%',
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    drawerItem: {
        transitionProperty: 'padding',
        transitionDuration: theme.transitions.duration.enteringScreen,
        transitionEasing: theme.transitions.easing.sharp
    }
}));

export default function PrimarySearchAppBarDrawer(props) {

    const classes = useStyles();
    const user = useContext(UserContext);
    const size = useWindowSize();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElLanguage, setAnchorElLanguage] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [mobileMoreAnchorElLanguage, setMobileMoreAnchorElLanguage] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isLangaugeOpen = Boolean(anchorElLanguage);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isMobileLanguageOpen = Boolean(mobileMoreAnchorElLanguage);

    const isMobile = size.isCompact;

    const [open, setOpen] = useState(isMobile === false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openConnectFb, setOpenConnectFb] = useState(false);
    const [openRequestEmail, setOpenRequestEmail] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [openGenerateOTP, setOpenGenerateOTP] = useState(false);
    const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
    const [picture, setPicture] = useState(user.me.profile && user.me.profile.picture && user.me.profile.picture.indexOf("http") > - 1 ? user.me.profile.picture : `${config.apiImagesUrl}/${user.me.profile.picture}`);

    useEffect(() => {
        if (isMobile === false) {
            setOpen(true);
        } else if (isMobile === true) {
            setOpen(false);
        }
    }, [isMobile]);


    useLayoutEffect(() => {
        if (picture) {
            var img = new Image();
            img.onerror = () => {
                setPicture(false);
            };
            img.src = picture;
        }
    });

    let selectedDrawerMenu = props.drawerSelected;

    let history = useHistory();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const openHome = () => {
        window.location.href = props.home;
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuOpen = (event) => {
        setAnchorElLanguage(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileLanguageClose = () => {
        setMobileMoreAnchorElLanguage(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleLanguageClose = () => {
        setAnchorElLanguage(null);
        handleMobileLanguageClose();
    };

    const handleLanguageEnglishClose = () => {
        setAnchorElLanguage(null);
        handleMobileLanguageClose();
        props.onSetLanguage("en");
    };

    const handleLanguageMacedonianClose = () => {
        setAnchorElLanguage(null);
        handleMobileLanguageClose();
        props.onSetLanguage("mk");
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    //   const handleMobileLanguageOpen = (event) => {
    //     setMobileMoreAnchorElLanguage(event.currentTarget);
    //   };

    const handleLogOut = (e) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            cache: 'no-cache',
            credentials: 'include'
        };
        fetch(`${config.apiUrl}/logout`, requestOptions)
            .then(response => {
                document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString()); });
                props.refetch();
                history.push('/login');
                // window.location.reload();
            });
        handleMenuClose(e);
    }

    const handleProfile = (e) => {
        setOpenProfile(true);
        handleMenuClose(e);
    }

    // const handleConnectFb = (e) => {
    // 	setOpenConnectFb(true);
    // 	handleMenuClose(e);
    // }

    const handleChangeEmail = (e) => {
        setOpenRequestEmail(true);
        handleMenuClose(e);
    }

    const handleChangePassword = (e) => {
        setOpenPassword(true);
        handleMenuClose(e);
    }

    const handleGenerateOTP = (e) => {
        setOpenGenerateOTP(true);
        handleMenuClose(e);

    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleProfile}>{user.translate("my_profile")}</MenuItem>
            {(user.me.profile.fbId === null || user.me.profile.fbId === "") && (
                // <MenuItem onClick={handleConnectFb}>{user.translate("connect_fb")}</MenuItem>
                <ConnectFb refetch={props.refetch} />
            )}
            <MenuItem onClick={handleChangeEmail}>{user.translate("change_email")}</MenuItem>
            {user.me.profile.email !== "" && user.me.profile.isEmailVerified === 1 && (
                <MenuItem onClick={handleChangePassword}>{user.translate("change_password")}</MenuItem>
            )}
            {user.me.profile.isAdmin && (
                <MenuItem onClick={handleGenerateOTP}>{user.translate("generate_otp")}</MenuItem>
            )}
            <MenuItem onClick={handleLogOut}>{user.translate("logout")}</MenuItem>
        </Menu>
    );

    const renderProfile = (
        <Profile setOpenProfile={setOpenProfile} refetch={props.refetch} />
    );
    const renderEmail = (
        <RequestEmail setOpenRequestEmail={setOpenRequestEmail} refetch={props.refetch} />
    );
    const renderPassword = (
        <RequestPassword setOpenPassword={setOpenPassword} message={user.translate("request_password")} refetch={props.refetch} />
    );
    const renderVerifyEmail = (
        <RequestEmail setOpenRequestEmail={props.setVerifyEmail} message={user.translate("request_resend_mail")} refetch={props.refetch} />
    );

    const languageId = 'primary-language-account-menu';
    const renderLanguage = (
        <Menu
            anchorEl={anchorElLanguage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={languageId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isLangaugeOpen}
            onClose={handleLanguageClose}
        >
            <MenuItem onClick={handleLanguageEnglishClose} selected={user.lang === "en"}>English</MenuItem>
            <MenuItem onClick={handleLanguageMacedonianClose} selected={user.lang === "mk"}>Macedonian</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem style={{ display: props.notifications !== undefined ? 'block' : 'none' }}>
                <IconButton aria-label={`show ${props.notifications} new notifications`} color="inherit" onClick={props.onNotifications}>
                    <Badge badgeContent={props.notifications} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
        </Menu>
    );

    const mobileLanguageId = 'primary-search-account-language-mobile';
    const renderMobileLanguage = (
        <Menu
            anchorEl={mobileMoreAnchorElLanguage}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileLanguageId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileLanguageOpen}
            onClose={handleMobileLanguageClose}
        >
            <MenuItem style={{ display: props.notifications !== undefined ? 'block' : 'none' }}>
                <IconButton aria-label={`show ${props.notifications} new notifications`} color="inherit" onClick={props.onNotifications}>
                    <Badge badgeContent={props.notifications} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
        </Menu>
    );

    const closeDrawer = () => (event) => {
        if (!isMobile || !open || (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))) {
            return;
        }

        setOpen(false);
    };

    let { restaurantId } = useParams();

    const renderDrawerMenu = (root, nested) => {
        if (!root) return;

        if (!root.key) root.key = Math.random();
        return <List key={root.key}>
            {
                root.map((item, index) => {
                    if (item.length) {
                        if (index > 0) {
                            const dividerKey = root.key + '_d';
                            const fragmentKey = root.key + '_f';
                            return <React.Fragment key={fragmentKey}>
                                <Divider key={dividerKey} />
                                {renderDrawerMenu(item)}
                            </React.Fragment>
                        }
                        return renderDrawerMenu(item);
                    }

                    const selected = selectedDrawerMenu === item.key;

                    if (item.children) {
                        const handleClick = (e) => {
                            item.open = !item.open;
                            setDrawerMenuOpen(!drawerMenuOpen);
                            e.stopPropagation();
                        }
                        const collapseKey = item.key + '_c';

                        return (
                            <React.Fragment key={item.key}>
                                <ListItem dense={isMobile || open} button onClick={handleClick} className={classes.drawerItem} selected={selected}>
                                    <ListItemIcon><Badge badgeContent={item.notifications} color="secondary">{item.icon}</Badge></ListItemIcon>
                                    <ListItemText primary={item.text} />
                                    {item.open ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse key={collapseKey} in={!item.open} timeout="auto" unmountOnExit>
                                    {renderDrawerMenu(item.children, true)}
                                </Collapse>
                            </React.Fragment>
                        );
                    }

                    let itemClasses = nested ? [classes.drawerItem, classes.nested] : [classes.drawerItem];

                    if (!item.key) {
                        return;
                    }

                    return (
                        <Link to={`/restaurant/${restaurantId}/${item.key}`} key={item.key} style={{ textDecoration: 'none' }}>
                            <ListItem
                                dense={isMobile || open}
                                button
                                className={itemClasses.join(' ')}
                                selected={selected}>
                                {!isMenuOpen && (
                                    <Tooltip title={item.text} placement="bottom" aria-label={item.text}>
                                        <ListItemIcon>
                                            <Badge badgeContent={item.notifications} color="error">{item.icon}</Badge>
                                        </ListItemIcon>
                                    </Tooltip>
                                )}
                                {isMenuOpen && (
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                )}
                                <ListItemText primary={item.text} style={{ color: 'rgba(0, 0, 0, 0.87)' }} />
                            </ListItem>
                        </Link>
                    );
                })
            }
        </List >
    }

    const renderDrawer = (
        <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            anchor="left"
            open={open}
            onClose={closeDrawer()}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}></div>
            {renderDrawerMenu(props.drawer)}
        </Drawer>
    );

    return (
        <div className={classes.root}
            onClick={closeDrawer()}
            onKeyDown={closeDrawer()}>
            {config.apiUrl.indexOf('dev') > -1 && (
                <span style={{ backgroundColor: "#ffc6d6" }}>DEV SERVER</span>
            )}
            {config.apiUrl.indexOf('dev') === -1 && config.isTest === true && (
                <span style={{ backgroundColor: "#ffe599" }}>TEST SERVER</span>
            )}
            {config.isTest === false && user.me.profile.isAdmin === 1 && (
                <span style={{ backgroundColor: "#a72bbd", color: "#ffffff" }}>PRODUCTION SERVER</span>
            )}
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        onClick={toggleDrawer}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        style={{ display: props.drawer ? 'block' : 'none' }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        onClick={openHome}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open home"
                        style={{ display: props.home ? 'block' : 'none' }}
                    >
                        <HomeIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                        {props.title}
                    </Typography>
                    <div className={classes.search} style={{ display: props.search ? 'block' : 'none' }}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <IconButton aria-label={`show ${props.notifications} new notifications`} color="inherit" style={{ display: props.notifications ? 'block' : 'none' }} onClick={props.onNotifications}>
                            <Badge badgeContent={props.notifications} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </div>
                    <IconButton
                        edge="end"
                        aria-label="language of current user"
                        aria-controls={languageId}
                        aria-haspopup="true"
                        onClick={handleLanguageMenuOpen}
                        color="inherit"
                    >
                        <LanguageIcon />
                    </IconButton>
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        {picture && (
                            <CardMedia
                                component="img"
                                style={{ width: 24, height: 24, borderRadius: "100%" }}
                                image={picture}
                                title={"Profile Image"}
                            />
                        )}
                        {!picture && (
                            <AccountCircle />
                        )}
                    </IconButton>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            style={{ display: props.notifications ? 'block' : 'none' }}
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            {renderMobileLanguage}
            {renderLanguage}
            {openProfile && renderProfile}
            {openRequestEmail && renderEmail}
            {openPassword && renderPassword}
            {openGenerateOTP && <GenerateOTP onClose={setOpenGenerateOTP} message={user.translate("generate_otp")} refetch={props.refetch} />}
            {props.verifyEmail && renderVerifyEmail}
            <div className={classes.drawerContent}>
                {props.drawer && renderDrawer}
                <div className={classes.content}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}
