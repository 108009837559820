import { Button, Card, CardActions, CardContent, IconButton, Link, makeStyles, Typography } from "@material-ui/core"
import IconDelete from "@material-ui/icons/Delete"
import IconEdit from "@material-ui/icons/Edit"
import React, { useContext, useState } from "react"
import { RestaurantContext, UserContext } from "../../App"
import Add from "../common/Add"
import Delete from "../common/Delete"
import Show from "../common/Show"
import { useEntityRelationQuery } from "../common/useEntityQuery"
import InventoryCounterState from "./InventoryCounterState"

const useStyles = makeStyles((theme) => ({
  info: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    paddingTop: 0,
    paddingBottom: 0,
  },
  inventoryCounterItem: {
    display: "flex",
    padding: 0,
    alignItems: "center",
  },
  inventoryCounterItemDisabled: {
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    alignItems: "center",
    padding: 0,
  },
  itemName: {
    // margin: theme.spacing(1),
    flexGrow: 1,
    whiteSpace: "nowrap",
  },
}))

export default function InventoryCounter(props) {
  const classes = useStyles()
  // Get current user preferences (language)
  const user = useContext(UserContext)
  const restaurant = useContext(RestaurantContext)
  const inventoryCounter = props.inventoryCounter

  let fieldList = [
    {
      required: true,
      type: "hidden",
      fieldType: "text",
      fieldName: "id",
      fieldLabel: "id",
      fieldValue: inventoryCounter.id,
    },
    {
      required: true,
      type: "text",
      fieldType: "text",
      fieldName: "name",
      fieldLabel: user.translate("title"),
      fieldValue: inventoryCounter.name[user.lang],
    },
    {
      required: true,
      type: "hidden",
      fieldType: "text",
      fieldName: "langId",
      fieldLabel: "langId",
      fieldValue: inventoryCounter.name.id,
    },
  ]

  const EDIT_ITEM = `mutation ($id: String, $langId: String!, $name: String!) {
    createInventoryCounter(input: {
		id: $id,
		${user.gqlEditName("$name", "$langId")}
	}) {
		id
	}
}`

  const DEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editInventoryCounter(input: {
                id: $id,
                active: 0
            }) {
                id
            }
        }`
  const UNDEACTIVATE_ITEM = `
        mutation ($id: String!) {
            editInventoryCounter(input: {
                id: $id,
                active: 1
            }) {
                id
            }
        }`

  const [openManipulateBox, setOpenManipulateBox] = React.useState(false)

  const onManipulateClick = () => {
    setOpenManipulateBox(true)
  }

  const onDeleteClick = () => {
    if (inventoryCounter.inventoryCounterItemsCount === 0) {
      props.setDeleteState({
        name: inventoryCounter.name[user.lang],
        variables: { id: inventoryCounter.id },
      })
      props.setOpenDeleteBox(true)
    } else {
      props.setOpenSnackBarWarning(true)
    }
  }

  const inventoryCounterCountersQuery = useEntityRelationQuery(
    "InventoryCounter",
    [
      { name: "id", gql: "String!" },
      { name: "name", gql: "String!", subSelection: { id: true, [user.lang]: true } },
      { name: "stockTypeId", gql: "String!" },
      { name: "stockType", gql: "StockType!", subSelection: { name: { [user.lang]: true } } },
      { name: "quantity", gql: "Float!" },
    ],
    "inventoryCounterId",
    inventoryCounter.id
  )
  const stockTypesQuery = useEntityRelationQuery(
    "StockType",
    [
      { name: "id", gql: "String!" },
      { name: "name", gql: "String!", subSelection: { [user.lang]: true } },
      { name: "uom", gql: "Long!" },
    ],
    "restaurantId",
    restaurant.id
  )

  const unitOfMeasures = {
    0: user.translate("liter"),
    1: user.translate("kilogram"),
    2: user.translate("piece"),
  }

  const stockTypeOptions =
    stockTypesQuery.items?.map((it) => ({
      val: it.id,
      label: it.name[user.lang] + " (" + unitOfMeasures[it.uom] + ")",
    })) ?? []

  const [showCounter, setShowCounter] = useState()
  const [deleteCounter, setDeleteCounter] = useState()

  const ADD_COUNTER_ITEM = `mutation ($restaurantId: String!, $inventoryCounterId: String!, $name: String!, $stockTypeId: String!, $quantity: Float!) {
    createInventoryCounter(input: {
		${user.gqlCreateName("$name")}
		restaurantId: $restaurantId
		inventoryCounterId: $inventoryCounterId
        stockTypeId: $stockTypeId
        quantity: $quantity
	}) {
		id
	}
}`
  const EDIT_COUNTER_ITEM = `mutation ($restaurantId: String!, $inventoryCounterId: String!, $id: String, $langId: String!, $name: String!, $stockTypeId: String!, $quantity: Float!) {
    createInventoryCounter(input: {
		id: $id,
		restaurantId: $restaurantId
		${user.gqlEditName("$name", "$langId")}
		inventoryCounterId: $inventoryCounterId
        stockTypeId: $stockTypeId
        quantity: $quantity
	}) {
		id
	}
}`

  const DELETE_COUNTER = `mutation ($id: String!) {
        deleteInventoryCounter(id: $id)
    }`

  const counterFieldList = [
    {
      required: false,
      type: "hidden",
      fieldType: "text",
      fieldName: "id",
      fieldLabel: "id",
      fieldValue: showCounter?.id,
    },
    {
      required: true,
      type: "hidden",
      fieldType: "text",
      fieldName: "restaurantId",
      fieldLabel: "restaurantId",
      fieldValue: restaurant.id,
    },
    {
      required: true,
      type: "hidden",
      fieldType: "text",
      fieldName: "inventoryCounterId",
      fieldLabel: "inventoryCounterId",
      fieldValue: inventoryCounter.id,
    },
    {
      required: false,
      type: "hidden",
      fieldType: "text",
      fieldName: "langId",
      fieldLabel: "langId",
      fieldValue: showCounter?.name ? showCounter.name.id : undefined,
    },
    {
      required: true,
      type: "text",
      fieldType: "text",
      fieldName: "name",
      fieldLabel: user.translate("title"),
      fieldValue: showCounter?.name ? showCounter.name[user.lang] : "",
    },
    {
      required: true,
      type: "autocomplete",
      fieldType: "autocomplete",
      fieldName: "stockTypeId",
      fieldLabel: user.translate("stock_type"),
      fieldValue: stockTypeOptions.find((it) => it.val === showCounter?.stockTypeId) ?? stockTypeOptions[0] ?? {},
      fieldOptions: stockTypeOptions,
    },
    {
      required: true,
      type: "number",
      fieldType: "text",
      fieldName: "quantity",
      fieldLabel: user.translate("quantity"),
      fieldValue: showCounter?.quantity || 1,
    },
  ]

  return (
    <React.Fragment>
      <Card>
        <CardContent
          className={inventoryCounter.active ? classes.inventoryCounterItem : classes.inventoryCounterItemDisabled}
        >
          <CardActions className={classes.itemName}>
            <Button onClick={onManipulateClick}>
              <b>{inventoryCounter.name[user.lang]}</b>
            </Button>
          </CardActions>
          <CardActions>
            <IconButton size="small" color="primary" onClick={() => props.onEditClick(inventoryCounter)}>
              <IconEdit />
            </IconButton>
            <IconButton size="small" color="primary" onClick={onDeleteClick}>
              <IconDelete />
            </IconButton>
          </CardActions>
        </CardContent>
        <Show if={inventoryCounter.warehouse}>
          <CardContent className={classes.info}>
            <Typography variant="body1" style={{ fontSize: 12 }}>
              {user.translate("warehouse")}:&nbsp;
            </Typography>
            <Typography variant="body1" style={{ fontSize: 12 }} color="textSecondary">
              {inventoryCounter.warehouse && inventoryCounter.warehouse.name[user.lang]}
            </Typography>
          </CardContent>
        </Show>
        <Show if={inventoryCounter.productionDepartment}>
          <CardContent className={classes.info}>
            <Typography variant="body1" style={{ fontSize: 12 }}>
              {user.translate("production_department")}:&nbsp;
            </Typography>
            <Typography variant="body1" style={{ fontSize: 12 }} color="textSecondary">
              {inventoryCounter.productionDepartment && inventoryCounter.productionDepartment.name[user.lang]}
            </Typography>
          </CardContent>
        </Show>

        <Show if={inventoryCounter.pos}>
          <CardContent className={classes.info}>
            <Typography variant="body1" style={{ fontSize: 12 }}>
              {user.translate("pos")}:&nbsp;
            </Typography>
            <Typography variant="body1" style={{ fontSize: 12 }} color="textSecondary">
              {inventoryCounter.pos && inventoryCounter.pos.name[user.lang]}
            </Typography>
          </CardContent>
        </Show>
        <CardContent className={classes.info} style={{ padding: 16 }}>
          <Typography variant="h6" style={{ fontSize: 14 }}>
            {user.translate("last_state")}:&nbsp;
          </Typography>
          <InventoryCounterState inventoryCounter={inventoryCounter} />
        </CardContent>
        <CardActions className={classes.itemName}>
          <Button variant="outlined" color="secondary" style={{ flex: 1 }}>
            <Link href={`/restaurant/${restaurant._id}/meters_report/${inventoryCounter.id}`}>
              {user.translate("show_report")}
            </Link>
          </Button>
        </CardActions>
      </Card>

      {showCounter && (
        <Add
          fieldList={counterFieldList}
          openManipulateBox={setShowCounter}
          actionType={user.translate("edit")}
          restaurant={restaurant}
          name={showCounter?.name ? showCounter?.name[user.lang] : undefined}
          manipulateItem={showCounter?.id ? EDIT_COUNTER_ITEM : ADD_COUNTER_ITEM}
          onSuccess={inventoryCounterCountersQuery.refresh}
        />
      )}

      {openManipulateBox && (
        <Add
          fieldList={fieldList}
          openManipulateBox={setOpenManipulateBox}
          actionType={user.translate("edit")}
          restaurant={restaurant}
          name={inventoryCounter.name[user.lang]}
          manipulateItem={EDIT_ITEM}
          deactivate={inventoryCounter.active ? true : false}
          deactivateItem={DEACTIVATE_ITEM}
          unDeactivateItem={UNDEACTIVATE_ITEM}
          onSuccess={props.refetch}
        />
      )}
      {Boolean(deleteCounter) && (
        <Delete
          name={deleteCounter.name}
          variables={deleteCounter.variables}
          setOpenDeleteBox={setDeleteCounter}
          deleteItem={DELETE_COUNTER}
          onSuccess={inventoryCounterCountersQuery.refresh}
        />
      )}
    </React.Fragment>
  )
}
