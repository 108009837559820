import {gql, useMutation, useQuery} from "@apollo/client"
import {resolve} from "path"
import {useContext, useEffect, useMemo, useRef, useState} from "react"
import {RestaurantContext} from "../../App"
import generateGql from "./generateGql"
import helper from "./Helper"

function cleanJson(obj) {
    var cleaned = JSON.stringify(obj, null, 2)

    return cleaned.replace(/^[\t ]*"[^:\n\r]+":/gm, function (match) {
        return match.replace(/"/g, "")
    })
}

function keyOnlyJson(obj) {
    var cleaned = JSON.stringify(obj, null, 2)

    return cleaned.replace(/^[\t ]*"[^:\n\r]+":\s*([^:\n\r]+)/gm, function (match) {
        const keyVal = match.split(":")
        if (keyVal.length > 1 && keyVal[1].trim().indexOf("{") == 0) {
            return match.replace(/"/g, "").replace(/:\s*/, " ")
        }
        return match.split(":")[0].replace(/"/g, "")
    })
}

export function useEntityQuery(entity, fields, relField, relValue, options) {
    const restaurant = useContext(RestaurantContext)
    let field = relField != undefined ? relField : "restaurantId"
    let value = relField != undefined ? relValue : restaurant.id
    return useEntityRelationQuery(entity, fields, field, value, options)
}

export function useEntityContextQuery(entity, fields, options) {
    const restaurant = useContext(RestaurantContext)
    return useEntityRelationQuery(entity, fields, "restaurantId", restaurant.id, options)
}

export function useEntityRelationQuery(entity, fields, relField = "restaurantId", relValue, options = {}) {
    const promiseRef = useRef()
    const [action, setAction] = useState()

    const allFields = fields || []
    const gqlFields = allFields.filter((field) => field.gql != undefined)

    const entityName = options.single ? entity : `${entity}s`
    const getItemsMethodName =
        relField == "restaurantId"
            ? `get${entityName}ByContextRestaurantId`
            : `get${entityName}By${relField.capitalize(false)}`

    const gqlGetList = useMemo(() => {
        if (options.mode == "editOnly" || gqlFields.length == 0) return gql``

        var filter = undefined
        const addFilter = (filterOptions) => {
            if (filter) {
                filterOptions.and = filter
            }
            filter = filterOptions
        }
        if (options.dateRangeFilter)
            addFilter({
                by: options.dateRangeField || "created",
                gte: options.dateRangeFilter.startDate.getTime() || 0,
                lte: options.dateRangeFilter.endDate.getTime() || new Date().getTime(),
            })
        if (options.filter) addFilter(options.filter)

        const args = {
            ...options.args,
            ["$" + relField]: "String!",
        }

        if (filter) {
            args.filter = filter
        }

        if (options.orderBy) args.orderBy = options.orderBy
        if (options.orderByDesc) args.orderByDesc = options.orderByDesc
        if (options.offset) args.offset = options.offset
        if (options.limit) args.limit = options.limit

        const gqlContent = generateGql({
            [getItemsMethodName]: {
                args,
                select: gqlFields.toMapBy(
                    (field) => field.name,
                    (field) => field.subSelection || true
                ),
            },
        })

        return gql(gqlContent)
    }, [entity, fields])

    const saveGqlFields = gqlFields.filter((field) => field.readOnly != true)

    const gqlCreateItem = useMemo(() => {
        if (options.mode == "readOnly" || saveGqlFields.length == 0) return gql``

        const gqlContent = generateGql(
            {
                [`create${entity}`]: {
                    args: {
                        input: saveGqlFields.toMapBy(
                            (field) => `$${field.name}`,
                            (field) => field.gql
                        ),
                    },
                    select: (options.keys || ["id", "_id"]).toMapBy(
                        (key) => key,
                        (key) => true
                    ),
                },
            },
            "mutation"
        )

        return gql(gqlContent)
    }, [entity, fields])

    const gqlDeleteItem = useMemo(() => {
        if (options.mode == "readOnly" || gqlFields.length == 0) return gql``
        return gql`mutation ($id: String!) { delete${entity}(id: $id)}`
    })

    const gqlHardDeleteItem = useMemo(() => {
        if (options.mode == "readOnly" || gqlFields.length == 0) return gql``

        const queryContent = generateGql(
            {
                [`hardDelete${entity}`]: {
                    args: (options.keys || ["id"]).toMapBy(
                        (key) => "$" + key,
                        () => "String!"
                    ),
                },
            },
            "mutation"
        )
        return gql(queryContent)
    })

    const gqlUnDeleteItem = useMemo(() => {
        if (options.mode == "readOnly" || gqlFields.length == 0) return gql``

        const queryContent = generateGql(
            {
                [`undelete${entity}`]: {
                    args: (options.keys || ["id"]).toMapBy(
                        (key) => "$" + key,
                        () => "String!"
                    ),
                },
            },
            "mutation"
        )
        return gql(queryContent)
    })

    const {
        data: dataItems,
        loading: loadingItems,
        refetch: refetchItems,
    } = useQuery(gqlGetList, {
        pollInterval: options.pollInterval || 5000,
        skip: options.skip || relValue == undefined || relValue == null,
        onCompleted:
            options.onLoad &&
            ((data) => {
                options.onLoad(data, getItemsMethodName)
            }),
        variables: {
            [relField]: relValue,
        },
    })

    const [saveItem, {loading: loadingSave}] = useMutation(gqlCreateItem)
    const [deleteItem, {loading: loadingDelete}] = useMutation(gqlDeleteItem)
    const [hardDeleteItem, {loading: loadingHardDelete}] = useMutation(gqlHardDeleteItem)
    const [unDeleteItem, {loading: loadingUnDelete}] = useMutation(gqlUnDeleteItem)

    useEffect(() => {
        if (action?.type == undefined) return
        if (promiseRef.current == undefined) return

        const promise = promiseRef.current
        promiseRef.current = undefined

        const params = action.params || {}
        const autoRefetch = action.autoRefetch != undefined ? action.autoRefetch : true

        if (action.type == "save") {
            saveItem({
                variables: gqlFields.toMapBy(
                    (field) => field.name,
                    (field) => {
                        let data = params[field.name]
                        if (data != undefined) return data
                        if (field.id) return helper.uid()
                        if (field.value != undefined) return field.value
                        if (field.default) {
                            if (field.default instanceof Function) {
                                return field.default(params)
                            } else {
                                return field.default
                            }
                        }
                        return undefined
                    }
                ),
            })
                .then((e) => {
                    if (autoRefetch) return refetchItems()
                    return e.data[`create${entity}`]
                })
                .then(promise.resolve)
                .catch(promise.reject)
        } else if (action.type == "delete") {
            deleteItem({
                variables: params,
            })
                .then((e) => {
                    if (autoRefetch) return refetchItems()
                    return e.data
                })
                .then(promise.resolve)
                .catch(promise.reject)
        } else if (action.type == "hardDelete") {
            hardDeleteItem({
                variables: params,
            })
                .then((e) => {
                    if (autoRefetch) return refetchItems()
                    return e.data
                })
                .then(promise.resolve)
                .catch(promise.reject)
        } else if (action.type == "unDelete") {
            unDeleteItem({
                variables: params,
            })
                .then((e) => {
                    if (autoRefetch) return refetchItems()
                    return e.data
                })
                .then(promise.resolve)
                .catch(promise.reject)
        } else {
            console.error("Unknown action", action)
        }

        setAction()
    }, [action])

    const doAction = (action, params, autoRefetch) => {
        const promise = new Promise((resolve, reject) => {
            promiseRef.current = {resolve, reject}
        })
        setAction({type: action, params: params, autoRefetch: autoRefetch})
        return promise
    }

    const dataField = options.single ? "item" : "items"

    return {
        isLoading: loadingItems,
        isLoadingAction: loadingSave || loadingDelete || loadingHardDelete || loadingUnDelete,
        [dataField]: dataItems && dataItems[getItemsMethodName],
        refresh: refetchItems,
        saveItem: (itemData, autoRefetch) => doAction("save", itemData, autoRefetch),
        deleteItem: (itemData) => doAction("delete", itemData),
        hardDeleteItem: (itemData) => doAction("hardDelete", itemData),
        unDeleteItem: (itemData) => doAction("unDelete", itemData),
    }
}

export function useCustomQuery(methodName, fields, argField = "restaurantId", argValue, options = {}) {
    const allFields = fields || []
    const gqlFields = allFields.filter((field) => field.gql != undefined)

    const gqlGetList = useMemo(() => {
        var filter = undefined
        const addFilter = (filterOptions) => {
            if (filter) {
                filterOptions.and = filter
            }
            filter = filterOptions
        }
        if (options.dateRangeFilter)
            addFilter({
                by: options.dateRangeField || "created",
                gte: options.dateRangeFilter.startDate.getTime() || 0,
                lte: options.dateRangeFilter.endDate.getTime() || new Date().getTime(),
            })
        if (options.filter) addFilter(options.filter)

        const args = {
            ...options.args,
            ["$" + argField]: "String!",
        }

        if (filter) {
            args.filter = filter
        }

        try {
            return gql(
                generateGql({
                    [methodName]: {
                        args,
                        select: gqlFields.toMapBy(
                            (field) => field.name,
                            (field) => field.subSelection || true
                        ),
                    },
                })
            )
        } catch (e) {
            console.log("Invalid GQL: " + generateGql({
                [methodName]: {
                    args,
                    select: gqlFields.toMapBy(
                        (field) => field.name,
                        (field) => field.subSelection || true
                    ),
                },
            }))
            throw e
        }
    }, [methodName, fields])

    const {
        data: dataItems,
        loading: loadingItems,
        refetch: refetchItems,
    } = useQuery(gqlGetList, {
        pollInterval: options.pollInterval != undefined ? options.pollInterval : 5000,
        skip: options.skip,
        onCompleted:
            options.onLoad &&
            ((data) => {
                options.onLoad(data, methodName)
            }),
        variables: {
            [argField]: argValue,
        },
    })

    const dataField = options.single ? "item" : "items"

    return {
        isLoading: loadingItems,
        isLoadingAction: false,
        [dataField]: dataItems && dataItems[methodName],
        refresh: refetchItems,
    }
}

export function useDynamicGqlQuery(method, args, select, options = {}) {
    const query = useMemo(() => {
        gql(
            generateGql({
                [method]: {args, select},
            })
        )
    }, [method, args, select])

    const {data, loading, error} = useQuery(query, {
        ...options,
        errorPolicy: "ignore",
    })

    return {data: data && data[method], error, loading}
}

export function useDynamicGqlQueries(queries, options = {}) {
    const query = useMemo(() => {
        gql(generateGql(queries))
    }, [queries])

    return useQuery(query, {
        ...options,
        errorPolicy: "ignore",
    })
}
