import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SnackBarAdd(props) {

    const handleClose = () => {
        props.setOpenSnackBar(false);
    };

    return (
        <Snackbar 
            open={props.openSnackBar} 
            autoHideDuration={5000} 
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onExited={handleClose}
            onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
                {props.message}
            </Alert>
        </Snackbar>
    )
}
