import React, { useState, useContext } from "react"
import { useQuery, gql } from "@apollo/client"
import {
  alpha,
  makeStyles,
  CircularProgress,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Slide,
  Paper,
  InputBase,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import MenuIcon from "@material-ui/icons/Menu"
import SearchIcon from "@material-ui/icons/Search"
import $ from "jquery"
import { UserContext } from "../../App"

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  gridItem: {
    maxWidth: 290,
    minWidth: 240,
    flexGrow: 1,
  },
  itemName: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
      cursor: `pointer`,
    },
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 600,
    height: 40,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ProductDialog(props) {
  const classes = useStyles()
  const user = useContext(UserContext)

  const [selectIt, setSelectIt] = useState(false)

  // GraphQL API request definition (local variables: restaurantId)
  const GET_PRODUCTS_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
        getProductsByContextRestaurantId(restaurantId: $restaurantId) {
            id
            name
            taxType {
                id
                rate
            }
            productionCategory {
                id
                name {
                    ${user.gqlFetchName()}
                }
            }
            code
            price
            image
            prepTime
            discountable
            isAddition
            isDomestic
            active
        }
    }`

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading } = useQuery(GET_PRODUCTS_BY_RESTAURANT, {
    variables: { restaurantId: props.restaurant.id },
    pollInterval: 3000,
  })

  // If it is loading, show progress bar
  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  let products = {}
  if (data) {
    products = data.getProductsByContextRestaurantId.map((result) => {
      return {
        ...result,
        price: result.price && result.taxType ? Math.round(result.price / (1 + result.taxType.rate)) : result.price,
      }
    })
  }

  // Add Dialog Close
  const handleClose = () => {
    setSelectIt(true)
    props.setOpenSelectBox(false)
  }

  const handleSelectAccept = (e, item) => {
    if (props.setSelectedItem)
      props.setSelectedItem({
        product: item,
      })

    if (props.action) props.action(item)

    handleClose()
  }

  function search(event) {
    if (event.target.value.length > 0) {
      $(".MuiDialog-container .MuiGrid-container > div").hide()
      let name = "",
        idClass = ""
      for (var item in products) {
        name = products[item].name.cirilicToLatin()
        idClass = products[item].id
        if (name.indexOf(event.target.value.cirilicToLatin()) > -1) {
          $(".MuiDialog-container .MuiGrid-container > div." + idClass).show()
        }
      }
    } else {
      $(".MuiDialog-container .MuiGrid-container > div").show()
    }
  }

  return (
    <Dialog
      open={!selectIt}
      fullScreen
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      fullWidth
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Paper component="form" className={classes.search}>
            <IconButton className={classes.iconButton} aria-label="menu">
              <MenuIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={user.translate("search_product")}
              inputProps={{ "aria-label": "search product" }}
              onChange={search}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid xs={12} container spacing={2} direction="row" justify="center" alignItems="center">
            {products.map((item) => (
              <Grid
                item
                xs={4}
                key={item.id}
                className={`${classes.gridItem} ${item.id}`}
                onClick={(e) => handleSelectAccept(e, item)}
              >
                <Paper elevation={2} className={classes.itemName}>
                  {item.name}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {user.translate("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
