import React, {forwardRef, useState, useLayoutEffect, useContext} from 'react';
import {
    TableHead,
    TableRow,
    TableCell,
    makeStyles,
} from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable, {MTableHeader} from 'material-table'
import {UserContext} from "../../App";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = makeStyles((theme) => ({
    tableRow: {
        border: '2px solid #333'
    },
    tableCell: {
        padding: 5,
        backgroundColor: '#e3e3e3',
        lineHeight: 1.2
    },
    tableCellBordered: {
        padding: 5,
        backgroundColor: '#e3e3e3',
        lineHeight: 1.2,
        border: '1px solid #666'
    },
}));

export default function TableReport(props) {

    const classes = useStyles();

    const user = useContext(UserContext);

    const tableData = {...props.fieldReport};
    // const [page, setPage] = useState(props.page);

    let pageSize = parseInt(props.pageSize);
    let pageSizeOptions = props.pageSizeOptions;
    let tableLayout = "auto";
    let fixedColumns = {};

    if (typeof props.fixedColumns != "undefined") {
        fixedColumns = props.fixedColumns;
    }

    useLayoutEffect(() => {

        let searchBtn = document.querySelectorAll("[aria-label='Search']");
        let exportBtn = document.querySelectorAll("[aria-label='Export']");
        let svg = document.querySelectorAll(".MuiTableCell-head .MuiTableSortLabel-icon ");

        if (searchBtn && exportBtn) {

            searchBtn.forEach(node => {
                if (typeof node != "undefined")
                    node.parentNode.parentNode.classList.add("no_print");
            })
            exportBtn.forEach(node => {
                if (typeof node != "undefined")
                    node.parentNode.parentNode.parentNode.parentNode.classList.add("no_print");
            })
            svg.forEach(node => {
                if (typeof node != "undefined")
                    node.classList.add("no_print");
            })
        }
    }, []);

    let exportFileName = `${props.exportFileName}_${user.formatDate(new Date())}`;
    let dataLimit = props.dataLimit ? props.dataLimit : 50;

    const onPageChange = (page) => {
        // props.setPage(page);
        // setPage(page);
    }

    let countColumns = tableData.columns.filter((x) => !x.hidden).length - 1;

    return (
        <>
            {props.printTitle && (
                <div
                    id={props.tableTitleId ? props.tableTitleId : "table-title"}
                    style={{
                        fontSize: 10,
                        fontStyle: "normal",
                        margin: 10,
                        marginBottom: 0,
                        fontFamily: 'Roboto',
                        lineHeight: 1.3,
                    }}
                >
                    {props.printTitle}
                </div>
            )}
            <MaterialTable
                style={{display: 'grid', margin: "25px 0", clear: "both"}}
                icons={tableIcons}
                title={props.tableName}
                columns={tableData.columns}
                data={tableData.data}
                localization={{
                    header: {
                        actions: user.translate("actions"),
                    },
                    body: {
                        emptyDataSourceMessage: user.translate("no_records_display"),
                        addTooltip: user.translate("add"),
                        deleteTooltip: user.translate("delete"),
                        editTooltip: user.translate("edit"),
                        editRow: {
                            deleteText: user.translate("delete_text_row"),
                            cancelTooltip: user.translate("cancel"),
                            saveTooltip: user.translate("save"),
                        }
                    },
                    toolbar: {
                        page: user.translate("page"),
                        of: user.translate("of"),
                        seal: user.translate("seal"),
                        authorized_person_signature: user.translate("authorized_person_signature"),
                        exportCSVName: user.translate("export_csv_name"),
                        exportPDFName: user.translate("export_pdf_name"),
                        exportTitle: user.translate("export"),
                        searchAriaLabel: user.translate("search"),
                        searchTooltip: user.translate("search"),
                        searchPlaceholder: user.translate("search"),
                    },
                    pagination: {
                        firstTooltip: user.translate("first_page"),
                        lastTooltip: user.translate("last_page"),
                        previousTooltip: user.translate("previous_page"),
                        nextTooltip: user.translate("next_page"),
                    }
                }}
                options={{
                    addRowPosition: "first",
                    tableLayout: tableLayout,
                    fixedColumns: fixedColumns,
                    emptyRowsWhenPaging: false,
                    // initialPage: page,
                    paging: typeof props.paging != "undefined" ? (tableData.data.length < dataLimit ? false : props.paging) : true,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizeOptions,
                    exportButton: typeof props.export != "undefined" ? (!!props.export) : true,
                    search: typeof props.search != "undefined" ? props.search : true,
                    exportFileName: exportFileName,
                    exportAllData: true,
                    cellStyle: {
                        padding: `0px 5px 0px 5px`,
                        fontSize: props.plt ? 10 : 12,
                        width: `${100 / countColumns}%`,
                        whiteSpace: 'pre-line',
                        lineHeight: 1.3,
                    },
                    headerStyle: {
                        padding: `5px`,
                        position: 'sticky',
                        fontSize: props.plt ? 12 : 14,
                        lineHeight: 1.2,
                        top: 0,
                        backgroundColor: '#e3e3e3',
                    },
                    toolbarButtonAlignment: "right",
                    exportFontName: "Roboto",
                    exportFontSize: 7,
                    exportFontOptions: {
                        styles: {font: 'Roboto', fontStyle: 'normal', fontSize: 8},
                        headStyles: {font: 'RobotoBold', fontStyle: 'normal', fontSize: 8, fillColor: 227, textColor: 32, lineWidth: 1}
                    },
                    exportOrientation: props.exportOrientation ? props.exportOrientation : "portrait",
                    rowStyle: props.rowStyle || (rowData => {
                        if (props.onRowClick && props.invoiceId > 0) {
                            if (rowData.invoiceId && rowData.invoiceId === props.invoiceId) return {backgroundColor: '#fff4d0'}
                            else if (rowData.invoiceId) return {backgroundColor: '#d0d0d0'};
                            else return {};
                        }

                        return {};
                    })
                }}
                onRowClick={props.onRowClick ? (event, rowData) => props.onRowClick(rowData) : undefined}
                cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => (
                        new Promise((resolve, reject) => {

                            if (rowData) {

                                // let newData = props.onEditItem(rowData, columnDef.field, newValue, tableData);
                                props.onEditItem(rowData, columnDef.field, newValue, tableData);

                                // setState((prevState) => {
                                //     const data = [...prevState.data];
                                //     data[data.indexOf(rowData)] = newData;
                                //     return { ...prevState, data };
                                // });

                                resolve();
                            }

                        })
                    )
                }}
                editable={props.editable && {
                    isDeletable: rowData => rowData.type === 'INVENTORY' || rowData.type === 'TRANSFER_IN' || rowData.type === 'TRANSFER_OUT',
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            resolve();

                            props.onDeleteItem(oldData, tableData);

                            // setState((prevState) => {
                            //     const data = [...prevState.data];
                            //     data.splice(data.indexOf(oldData), oldData.type === 'INVENTORY' ? 1 : 2);
                            //     return { ...prevState, data };
                            // });

                        }),
                }}
                components={{
                    Header: _props => {
                        return <>
                            {props.header && (
                                <>
                                    <TableRow className={classes.tableRow}>
                                        {tableData.columnParents.map((header, index) => {
                                            const className = header.title.length > 0 ? classes.tableCellBordered : classes.tableCell
                                            return (
                                                <TableCell key={"extra_row_" + index} align="center" className={className}
                                                           colSpan={header.colSpan} rowSpan={header.rowSpan}>{header.title}</TableCell>
                                            );
                                        })}
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        {tableData.columns.filter(it => !it.hidden).map((header, index) => {
                                            const className = header.title.length > 0 ? classes.tableCellBordered : classes.tableCell
                                            return (
                                                <TableCell key={"extra_row_" + index} align="center" className={className}
                                                           colSpan={header.colSpan} rowSpan={header.rowSpan}>{header.title}</TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </>
                            )}
                            {!props.header && <MTableHeader {..._props} />}
                            {props.headerNumbers && (
                                <TableRow className={classes.tableRow}>
                                    {tableData.columnNumbers.map(index => {
                                        const align = index === 1 || index === 2 ? "left" : "right"
                                        return <TableCell key={"extra_number_row_" + index} align={align}
                                                          className={classes.tableCell}>{index}</TableCell>
                                    })}
                                </TableRow>
                            )}
                        </>
                    },
                }}
            />
        </>
    );
}