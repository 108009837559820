import React, { useContext, useState } from 'react';
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import { RestaurantContext, UserContext } from "../../App";
import {
	CircularProgress,
	makeStyles,
    IconButton,
    Button,
} from '@material-ui/core';
import Table from '../common/Table';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SearchIcon from '@material-ui/icons/Search';
import SnackBarDelete from '../common/SnackBarDelete';
import helper from '../common/Helper';
import tableComponents from '../common/TableComponents'
import KomitentBillingSearch from '../common/KomitentBillingSearch'

const useStyles = makeStyles((theme) => ({
	roots: {
		// display: 'inline-flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
        overflow: 'hidden',
	},
	root: {
        position: "relative",
		'&:hover': {
			backgroundColor: 'transparent',
		},
    },
    back: { 
        color: "inherit", 
        position: "absolute",
        top: 68,
        left: 6,
        zIndex: 1
    },
    extraTableName: { 
        display: "grid",
        margin: "0 auto"
    },
}));

// React Function Component
export default function KomitentBilling(props) {

	const classes = useStyles();

	const user = useContext(UserContext);
	const restaurant = useContext(RestaurantContext);
	let commissionerName, commissionerId, history = useHistory();

	if (props.rowData) {
		commissionerName = props.rowData.name;
		commissionerId = props.rowData.id;
	}

	const [refetchIt, setRefetchIt] = useState(false);
	const [page, setPage] = useState(0);

	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [deleteState, setDeleteState] = useState({
		variables: {},
		name: "",
		state: {}
    });
    
    const [searchBilling, setSearchBilling] = useState(false);

	const EDIT_ITEM = gql`
    mutation ($restaurantId: String!, $id: String!, $cityId: String!, $city: String!, $countryId: String!, $country: String!, $currency: String!, $name: String!, $address: String!, $bankName: String!, $bankAddress: String!, $bankAccount: String!, $swift: String!, $contactPerson: String!, $contactPhone: String!, $contactEmail: String!, $vat: String!, $regNumber: String! ) {
        createCity(input: {
            id: $cityId
            ${user.gqlCreateName("$city")}
                createCountry: {
                    id: $countryId
                    ${user.gqlCreateName("$country")}
                }
        }) {
            id
        }
        createRestaurantBilling(input:{
          id: $id
          name: $name
          city: $cityId
          address: $address
          bankName: $bankName
          bankAddress: $bankAddress
          bankAccount: $bankAccount
          swift: $swift
          contactPerson: $contactPerson
          contactPhone: $contactPhone
          contactEmail: $contactEmail
          vat: $vat
          regNumber: $regNumber
          currency: $currency
          restaurantId: $restaurantId
        }){
          id
        }
    }`;
	const [editItem, { loading: loading_billing_edit }] = useMutation(EDIT_ITEM, {
        skip: !refetchIt
    });

	const DELETE_ITEM = gql`
        mutation ($id: String!) {
            deleteRestaurantBilling(id: $id)
    }`;
	const [deleteItem, { loading: loading_billing_delete }] = useMutation(DELETE_ITEM, {
        skip: !refetchIt
    });

	const UNDELETE_ITEM = `
        mutation ($id: String!) {
            undeleteRestaurantBilling(id: $id)
    }`;

	// GraphQL API request definition (local variables: restaurantId)
	const GET_KOMITENT_BILLING_BY_RESTAURANT = gql`
    query ($restaurantId: String!){
        getRestaurantById(id: $restaurantId) {
            restaurantBillings {
                id
                name
                city {
                  id
                  name {
                    ${user.gqlFetchName()}
                  }
                  country {
                    id
                    name {
                        ${user.gqlFetchName()}
                    }
                  }
                }
                address
                bankName
                bankAddress
                bankAccount
                swift
                contactPerson
                contactPhone
                contactEmail
                vat
                regNumber
                currency
            }
        }
        getAllCitys {
            id
            name{
                ${user.gqlFetchName()}
            }
            country {
                id
                name{
                    ${user.gqlFetchName()}
                }
            }
        }
        getAllCountrys {
            id
            name{
                ${user.gqlFetchName()}
            }
        }
    }`;

	// Make the api request or get cached.
	// This makes the componnet to refresh when new data is available i.e. api finished.
	const { data: data_billing, loading: loading_billing, error: error_billing, refetch } = useQuery(GET_KOMITENT_BILLING_BY_RESTAURANT, {
		variables: { restaurantId: commissionerId },
        skip: !props.rowData,
		errorPolicy: "ignore",
		fetchPolicy: "network-only"
	});

	// If it is loading_billing, show progress bar
	// if(loading_billing) {
	//     return (<div className="App AppLoading"><CircularProgress /></div>);
    // }
    
	// In case there is an error, just show it for now
	// if (!data_billing) { user.consoleLog(error); return <p>Error</p> }
	if (!data_billing) { return <div></div> }

	let fieldList = {}, cities = [], countries = [];

	cities = data_billing.getAllCitys.map(result => (
		{
			...result,
            name: result.name[user.lang],
            countryId: result.country.id
		}
	));

	countries = data_billing.getAllCountrys.map(result => (
		{
			...result,
            name: result.name[user.lang],
		}
	));

	let firstCity = cities.slice(0, 1);
    let firstCountry = countries.slice(0, 1);
    
    // let lookupCurrency = {
    //     "MKD": user.translate("mkd"),
    //     "EUR": user.translate("eur"),
    //     "USD": user.translate("usd"),
    // };
    let lookupCurrency = [
        {val: "MKD", label: user.translate("mkd")},
        {val: "EUR", label: user.translate("eur")},
        {val: "USD", label: user.translate("usd")},
    ];

	fieldList['columns'] = [
		{ width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'Id', field: 'id', hidden: true, export: false },
		{ width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'commissionerId', field: 'commissionerId', hidden: true, export: false },
		{ width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'cityId', field: 'cityId', hidden: true, export: false },
        { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'countryId', field: 'countryId', hidden: true, export: false },
        { width: '1%', cellStyle: { whiteSpace: 'nowrap' }, title: 'currency', field: 'currency', hidden: true, export: false },
        { title: "", field: 'count', editable: "never", cellStyle: { width: "1%", padding: `0 5px 0 0`, fontSize: 12, whiteSpace: 'pre-line' } },
		{ title: user.translate("title"), field: 'name',
			customFilterAndSearch: (filterValue, row) => {
                let currency = lookupCurrency.find(c => c.val === row.currency).label;

				return (row.name && row.name.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
					(row.address && row.address.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                    (row.city && row.city.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
                    (row.currency && row.currencyLabel.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
					(row.country && row.country.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
					(row.bank_name && row.bank_name.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
					(row.bank_address && row.bank_address.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
					(row.contact_person && row.contact_person.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
					(row.contact_phone && row.contact_phone.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1) ||
					(row.contact_email && row.contact_email.cirilicToLatin().indexOf(filterValue.cirilicToLatin()) > -1)
					;
            },
            customSort: (a, b) => a.name.cirilicLatinCompare(user.lang).localeCompare(b.name.cirilicLatinCompare(user.lang), user.lang)
		},
		{ title: user.translate("address"), field: 'address', customSort: (a, b) => a.address.cirilicLatinCompare(user.lang).localeCompare(b.address.cirilicLatinCompare(user.lang), user.lang) },
		{ title: user.translate("country"), field: 'country', editComponent: (props) => {
            return tableComponents.AutoCompleteAdd(props, countries, "countryId", "country", firstCountry);
        }, customSort: (a, b) => a.country.cirilicLatinCompare(user.lang).localeCompare(b.country.cirilicLatinCompare(user.lang), user.lang)},
		{ title: user.translate("city"), field: 'city', editComponent: (props) => {
            return tableComponents.AutoCompleteAdd(props, cities, "cityId", "city", firstCity);
        }, customSort: (a, b) => a.city.cirilicLatinCompare(user.lang).localeCompare(b.city.cirilicLatinCompare(user.lang), user.lang)},
        { title: user.translate("currency"), field: 'currencyLabel', 
            editComponent: (props) => tableComponents.Select(props, lookupCurrency, "currency"), 
            customSort: (a, b) => a.currency.cirilicLatinCompare(user.lang).localeCompare(b.currency.cirilicLatinCompare(user.lang), user.lang) },
		{ title: user.translate("bank_name"), field: 'bankName', customSort: (a, b) => a.bankName.cirilicLatinCompare(user.lang).localeCompare(b.bankName.cirilicLatinCompare(user.lang), user.lang) },
		{ title: user.translate("bank_address"), field: 'bankAddress', customSort: (a, b) => a.bankAddress.cirilicLatinCompare(user.lang).localeCompare(b.bankAddress.cirilicLatinCompare(user.lang), user.lang) },
		{ title: user.translate("iban"), field: 'bankAccount' },
		{ title: user.translate("swift"), field: 'swift' },
		{ title: user.translate("contact_person"), field: 'contactPerson', customSort: (a, b) => a.contactPerson.cirilicLatinCompare(user.lang).localeCompare(b.contactPerson.cirilicLatinCompare(user.lang), user.lang) },
		{ title: user.translate("contact_phone"), field: 'contactPhone', customSort: (a, b) => a.contactPhone.cirilicLatinCompare(user.lang).localeCompare(b.contactPhone.cirilicLatinCompare(user.lang), user.lang) },
		{ title: user.translate("contact_email"), field: 'contactEmail', customSort: (a, b) => a.contactEmail.cirilicLatinCompare(user.lang).localeCompare(b.contactEmail.cirilicLatinCompare(user.lang), user.lang) },
		{ title: user.translate("vat_no"), field: 'vat' },
		{ title: user.translate("reg_number"), field: 'regNumber' },
	];

	fieldList['data'] = data_billing.getRestaurantById.restaurantBillings.map((result, index) => (
		{
            ...result,
            count: (index+1)+".",
			city: result.city.name[user.lang],
			cityId: result.city.id,
			country: result.city.country.name[user.lang],
			countryId: result.city.country.id,
			commissionerId: commissionerId,
            currencyLabel: result.currency ? lookupCurrency.find(c => c.val === result.currency).label : ""
		}
	));

	const setItems = (dataObj, action) => {
		let items = {};

		items = {
            ...dataObj,
            id: dataObj.id ? dataObj.id : helper.uid(),
			name: dataObj.name,
            address: dataObj.address,
            currency: dataObj.currency ? dataObj.currency : "MKD",
			bankName: dataObj.bankName,
			bankAddress: dataObj.bankAddress,
			bankAccount: dataObj.bankAccount,
			swift: dataObj.swift,
			contactPerson: dataObj.contactPerson,
			contactPhone: dataObj.contactPhone,
			contactEmail: dataObj.contactEmail,
			vat: dataObj.vat,
			regNumber: dataObj.regNumber,
			restaurantId: commissionerId
		};

		for (var key in items) {
			if (typeof items[key] == "undefined") {
				items[key] = "";
			}
		}

		return items;
	};

	const onAddItem = (newData, state) => {
		let items = setItems(newData, 'add');
		user.consoleLog(items);
		setPage(Math.round((data_billing.getRestaurantById.restaurantBillings.length + 1) / 50));
		editItem({ variables: items });
		setRefetchIt(true);
	};

	const onEditItem = (newData, state) => {

		let items = setItems(newData, 'edit');
		user.consoleLog(items);
		editItem({ variables: items });
		setRefetchIt(true);
	};

	const onDeleteItem = (oldData, state, setState) => {

		let items = {};
		items['id'] = oldData.id;

		deleteItem({ variables: items });

		setDeleteState({
			variables: items,
			name: oldData.name,
			state: state
		})

		setOpenSnackBar(true);
		setRefetchIt(true);
	};

	const closeBilling = () => {
		if (!props.handleSelectAccept) {
			history.push(history.location.pathname, {
				search: ""
			})
		}
		props.setRowData(false);
    }
    
	const onSearchBilling = () => {
        setSearchBilling(true);
	}

	if (!loading_billing && !loading_billing_edit && !loading_billing_delete && refetchIt) {
		refetch();
		user.consoleLog("Refetch Done!");
		setRefetchIt(false);
	}

    let tableName = `${commissionerName} ${user.translate("billing")}`;
    
    let extraTableName = 
        <div className={classes.extraTableName}>
            <Button
                style={{margin: "12px auto"}}
                color="primary" 
                component="span"
                onClick= {onSearchBilling}
                startIcon={<SearchIcon />}
            >
                {user.translate("search_company")}
            </Button>
        </div>;

	return (
		<div className={classes.root}>
            
            {extraTableName}

            <IconButton className={classes.back} onClick={closeBilling}>
				<KeyboardBackspaceIcon />
			</IconButton>

            {!loading_billing && !loading_billing_edit && !loading_billing_delete && (
                <Table
                    key={helper.hash(user.lang + page)}
                    tableTitleId={"table-title1"}
                    tableName={tableName}
                    exportFileName={`${helper.trim(commissionerName)}${user.translate("billing")}`}
                    fieldList={fieldList}
                    paddingColumns={`0 0 0 5px`}
                    pageSize="10"
                    pageSizeOptions={[10, 20, 50]}
                    onAddItem={onAddItem}
                    onEditItem={onEditItem}
                    onDeleteItem={onDeleteItem}
                    onRowClick={props.handleSelectAccept}
                    page={page}
                />
            )}

			{openSnackBar && (
                <SnackBarDelete 
                    message={`${deleteState.name} ${user.translate("deleted")}!`} 
                    variables={deleteState.variables} 
                    openSnackBar={openSnackBar} 
                    setOpenSnackBar={setOpenSnackBar} 
                    unDeleteItem={UNDELETE_ITEM} 
                    onSuccess={refetch} 
                />
			)}

			{searchBilling && (
                <KomitentBillingSearch 
                    companyId={commissionerId}
                    searchBilling={searchBilling}
                    setSearchBilling={setSearchBilling}
                    onSuccess={refetch} 
                />
			)}
		</div>
	)
}
