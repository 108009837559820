import {makeStyles} from '@material-ui/core';
import React, {useContext} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {RestaurantContext, UserContext} from "../../App";
import Table from '../common/Table';
import tableComponents from '../common/TableComponents';
import useWindowSize from '../common/useWindowSize';
import EntityAutoComplete from './form/EntityAutoComplete';
import KomitentAutoComplete from './form/KomitentAutoComplete';
import SelectCurrency from './form/SelectCurrency';
import SelectPaymentDirection from './form/SelectPaymentDirection';
import StockTypeCostInput from './form/StockTypeCostInput';
import {callOrGet} from './form/utils';

const useStyles = makeStyles((theme) => ({
    root: {},

}));

// function tableComponentWrapper(component) {
//     let value = props.rowData[fieldId];
//     let selected = value != undefined
//         ? {
//             id: value,
//             name: props.rowData[fieldName],
//         }
//         : getDefault(props, selectDefault)
//     if (selected != undefined) props.rowData[`_${fieldId}`] = selected.id;

// }

const EDIT_COMPONENTS = {
    AutoComplete: (field) => (props) => tableComponents.AutoComplete(props, field.options, field.name, field.editName),
    KomitentAutoComplete: (field) => (props) => <KomitentAutoComplete {...props} size="small" variant="standard"/>,
    EntityAutoComplete: (field) => (props) => <EntityAutoComplete entity={field.entity} editField={field.editField} {...props} size="small" variant="standard"
                                                                  subSelection={field.subSelection} fieldName={field.name}/>,
    SelectCurrency: (field) => (props) => <SelectCurrency {...props} size="small" variant="standard"/>,
    SelectPaymentDirection: (field) => (props) => <SelectPaymentDirection {...props} size="small" variant="standard"/>,
    StockTypeCostInput: (field) => (props) => <StockTypeCostInput {...props} size="small" variant="standard" name={field.name}/>,
    Date: (field) => (props) => tableComponents.DateEditComponent(props, field.name, (field.default instanceof Function ? field.default() : field.default) || new Date()),
    DateMilis: (field) => (props) => tableComponents.DateMilisEditComponent(props, field.name, (field.default instanceof Function ? field.default() : field.default) || new Date()),
    DateTime: (field) => (props) => tableComponents.DateTimeEditComponent(props, field.name, (field.default instanceof Function ? field.default() : field.default) || new Date()),
    Select: (field) => (props) => tableComponents.Select(props, field.options, field.name, field.value != undefined ? field.value : field.default),
    Numeric: (field) => (props, a, b) => tableComponents.NumericEditComponent(props, field.name, false, field.decimals ?? 2, field.default, field.converter),
    NumericUom: (field) => (props) => tableComponents.NumericUomEditComponent(props, field.name, false, 2, field.default)
}

const RENDER_COMPONENTS = {
    SelectCurrency: (user, field) => (itemData) => SelectCurrency.renderView({...field, value: itemData[field.name]}, user),
    SelectPaymentDirection: (user, field) => (itemData) => SelectPaymentDirection.renderView({...field, value: itemData[field.name]}, user),
    DateMilis: (user, field) => (itemData) => user.formatDate(parseInt(itemData[field.name])),
    DateTime: (user, field) => (itemData) => user.formatDate(parseInt(itemData[field.name]), true),
    Numeric: (user, field) => (itemData) => user.formatQuantity(parseFloat(itemData[field.name])),
}

export function DynamicTable(props) {
    const classes = useStyles();

    const user = useContext(UserContext);
    const restaurant = useContext(RestaurantContext);
    const size = useWindowSize();

    const history = useHistory();
    const location = useLocation();

    const urlPath = props.path;

    const widthSum = props.columns.sumOf(field => {
        const hidden = field.hidden || (field.mobile == false && size.isMobile) || (field.tablet == false && size.isCompact);
        return hidden ? 0 : (field.width || 1);
    });

    var lastVisibleColumn = undefined;

    let tableData = {
        columns: props.columns.map(field => {
            const edit = field.editComponent || EDIT_COMPONENTS[field.edit];
            const render = RENDER_COMPONENTS[field.edit];
            const hidden = field.hidden || (field.mobile == false && size.isMobile) || (field.tablet == false && size.isCompact);

            const width = hidden ? 0 : ((field.width || 1) * 100 / widthSum) + "%";
            let title = user.translate(field.title || field.name);
            if (field.titleSufix) {
                title = <>{field.titleSufix}{title}</>;
            }
            if (field.titlePostfix) {
                title = <>{title}{field.titlePostfix}</>;
            }

            const column = {
                title, width, field: field.name,
                cellStyle: {
                    width,
                    padding: 6,
                    fontSize: field.fontSize || 12,
                    whiteSpace: field.nowrap ? 'nowrap' : 'pre-line',
                    overflow: 'hidden',
                    maxWidth: field.maxWidth,
                    minWidth: field.minWidth,
                    textOverflow: 'ellipsis'
                },
                render: field.render || callOrGet(render, user, field),
                disableClick: field.disableClick,
                align: field.align,
                editComponent: edit && edit(field),
                editable: (edit || field.readOnly !== true) ? undefined : 'never',
                hidden: field.hidden, mobile: field.mobile, tablet: field.tablet,
                initialEditValue: field.value !== undefined ? field.value : (field.default instanceof Function ? field.default() : field.default || field.defaultVal)
            };
            if (!hidden) {
                lastVisibleColumn = column;
            }
            return column;
        }),
        data: props.items.map(item => ({...item})).orderByDesc(a => a.created),
        options: {
            nowrap: props.nowrap,
            tableLayout: props.tableLayout || "auto"
        }
    };

    if (lastVisibleColumn) {
        lastVisibleColumn.align = "right";
        lastVisibleColumn.cellPadding = 0;
    }

    if (props.detailPanel) {
        tableData.options.detailPanelColumnAlignment = 'right';
        tableData.options.detailPanelType = 'single'
    }


    const onRowClick = (rowData, toggle) => {
        const path = callOrGet(urlPath, rowData);
        history.push(`/restaurant/${restaurant._id}/${path}/${rowData.id}`, {...location.state, from: `list-${path}`})
    }

    const onRowClickToggle = (rowData, toggle) => toggle();

    const handleRowClick = props.onRowClick !== false &&
        (props.onRowClick || (props.detailPanel != undefined ? onRowClickToggle : onRowClick));

    return <Table
        tableName={user.translate(props.name) + (props.nameSufix || "")}
        exportFileName={user.translate(props.name)}
        fieldList={tableData}
        pageSize="50"
        headerPadding="4px 8px 4px 0px"
        pageSizeOptions={props.paging ? [50, 100, 200] : false}
        onAddItem={!props.noEdit && props.query.saveItem}
        className={classes.root}
        onEditItem={!props.noEdit && props.query.saveItem}
        onDeleteItem={!props.noEdit && props.query.deleteItem}
        onRowClick={handleRowClick}
        detailPanel={props.detailPanel}
        search={props.search != undefined ? props.search : !size.isMobile}
        header={props.header}
        exportAllData={props.exportAllData}
        headlines={props.headlines}
        columnParents={props.columnParents}
        columnNumbers={props.columnNumbers}
        showDetailPanel={props.showDetailPanel}
        noPaging={props.noPaging}
    />
}
