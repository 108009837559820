import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { UserContext } from "../../App";
import EditText from '../common/form/EditText';
import ItemForm, { Field } from '../common/form/ItemForm';
import MobileDialog from '../common/MobileDialog';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#EEE',
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        // padding: theme.spacing(1),
        '& .MuiTableCell-root .MuiIconButton-root': {
            padding: theme.spacing(1)
        },
        paddingBottom: theme.spacing(0.5)
    },
    distributions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function EditNameDialog(props) {
    const classes = useStyles();

    const user = useContext(UserContext);

    const LANGS = Array.from(new Set([user.lang, "en", "mk"]));

    return <MobileDialog open={true} onClose={props.onClose} title={user.translate("title")}>
        <div className={classes.root} id="print_content">
            <ItemForm
                entityName="Translation"
                entityRelFieldName="id"
                entityRelFieldValue={props.id}
                refresh={props.refresh}
                noDelete
            >

                {
                    LANGS.map(lang => <EditText name={lang} label={user.translate(lang)} default="" fullWidth />)
                }

                <Field
                    name="id"
                    gql="String"
                />
            </ItemForm>
        </div>

    </MobileDialog>
}
