import { useQuery } from '@apollo/client';
import { useRef, useState } from 'react';

export default function useImperativeQuery(query) {
    const promiseRef = useRef();
    const [vars, setVars] = useState();

    const { data, loading, error, refetch: refetch } = useQuery(query, {
        skip: promiseRef.current == undefined || vars == undefined,
        errorPolicy: "ignore",
        variables: vars,
        onCompleted: (data) => {
            if (promiseRef.current) {
                promiseRef.current.resolve(data);
                promiseRef.current = undefined;
            }
            setVars();
        },
        onError: (err) => {
            if (promiseRef.current) {
                promiseRef.current.reject(err);
                promiseRef.current = undefined;
            }
            setVars();
        }
    });

    const get = (variables) => {
        return new Promise((resolve, reject) => {
            promiseRef.current = { resolve, reject };
            setVars(variables || null);
        })
    }

    return { data, loading, error, get };

}
