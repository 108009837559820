import { gql, useQuery } from "@apollo/client";
import { Button, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import Print from "@material-ui/icons/Print";
import React, { useContext } from "react";
import { RestaurantContext, UserContext } from "../../App";
import generateGql from "../common/generateGql";
import helper from "../common/Helper";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-root .MuiIconButton-root.MuiIconButton-colorInherit, & .MuiTableCell-root .MuiIconButton-root.MuiIconButton-colorPrimary": {
      padding: 4,
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
    paddingTop: theme.spacing(1),
  },
  checkedLabel: {
    marginRight: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: 12,
    },
  },
  content: {
    backgroundColor: "white",
    margin: theme.spacing(1),
    maxWidth: 1024,
    padding: theme.spacing(1),
    borderRadius: 2,
    boxShadow: "ltgray 0px 0px 4px",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    margin: theme.spacing(0.5),
    marginBottom: 0,
    marginTop: 0,
    flexDirection: "row",
    ["&> :first-child"]: {
      width: "auto",
      minWidth: 110,
      whiteSpace: "nowrap",
    },
  },
  rowProduct: {
    display: "flex",
    margin: theme.spacing(0.5),
    marginBottom: 0,
    flexDirection: "row",
    ["&> :first-child"]: {
      width: "auto",
      minWidth: 25,
      textAlign: "right",
      paddingRight: 6,
      whiteSpace: "nowrap",
    },
    ["&> :nth-child(2)"]: {
      width: "auto",
      minWidth: 260,
      textAlign: "left",
      paddingRight: 6,
      whiteSpace: "nowrap",
      borderBottom: "1px dashed #d7d7d7"
    },
    ["&> :nth-child(3)"]: {
      width: "auto",
      minWidth: 32,
      textAlign: "right",
      paddingRight: 6,
      whiteSpace: "nowrap",
    },
  },
  category: {
    marginTop: theme.spacing(2),
  },
}));

// React Function Component
export default function Normatives(props) {
  const classes = useStyles();

  const restaurant = useContext(RestaurantContext);
  const user = useContext(UserContext);

  const gqlProducts = gql(
    generateGql(
      {
        getProductsByContextRestaurantId: {
          args: {
            $restaurantId: "String!",
            filter: {
              by: "active",
              eq: 1,
            },
          },
          select: {
            name: true,
            productionCategoryId: true,
            productStockTypes: {
              quantity: true,
              stockType: {
                id: true,
                uom: true,
                name: {
                  mk: true,
                },
              },
            },
          },
        },
        getProductionCategorysByRestaurantId: {
          args: {
            $restaurantId: "String!",
          },
          select: {
            id: true,
            name: {
              mk: true,
            },
          },
        },
      },
      "query"
    )
  );

  // Make the api request or get cached.
  // This makes the componnet to refresh when new data is available i.e. api finished.
  const { data, loading, error, refetch } = useQuery(gqlProducts, {
    variables: { restaurantId: restaurant.id },
    pollInterval: 3000,
  });

  if (loading || !data) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    );
  }

  const products = data.getProductsByContextRestaurantId.filter((p) => p.productStockTypes.length > 0).groupBy((p) => p.productionCategoryId);
  const categories = data.getProductionCategorysByRestaurantId
    .filter((c) => products[c.id] && products[c.id].length > 0)
    .map((c) => ({ ...c, title: c.name.mk }));

  const legalInfo = restaurant.legalInfo ? JSON.parse(restaurant.legalInfo) : {};
  const billing = restaurant.restaurantBillings.find((b) => b.bankAccount.length > 0);

  const piece = user.translate("piece");
  const piecePl = user.translate("pieces");
  const uomOptions = [user.translate("liter"), user.translate("kilogram")];
  const uomOptionsPl = [user.translate("liters"), user.translate("kilograms")];

  const miliUomOptions = [user.translate("milliliter"), user.translate("gram")];
  const miliUomOptionsPl = [user.translate("milliliters"), user.translate("grams")];

  const formatQuantity = (quantity, uom) => {
    if (uom == 2) {
      if (quantity == 1) {
        return `${quantity} ${piece}`;
      } else {
        return `${quantity} ${piecePl}`;
      }
    }
    if (quantity < 1000) {
      if (quantity == 1) {
        return `${quantity} ${miliUomOptions[uom]}`;
      } else {
        return `${quantity} ${miliUomOptionsPl[uom]}`;
      }
    }
    if (quantity == 1000) {
      return `${user.formatQuantity(quantity / 1000)} ${uomOptions[uom]}`;
    } else {
      return `${user.formatQuantity(quantity / 1000)} ${uomOptionsPl[uom]}`;
    }
  };

  const onPrintClick = () => {
    helper.printdiv("printDiv", "no_print");
  };

  let counter = 1;

  return (
    <div className={classes.root}>
      <div style={{ width: "100%", textAlign: "center" }}>
        <Button variant="contained" startIcon={<Print />} color="primary" onClick={onPrintClick}>
          {user.translate("print")}
        </Button>
      </div>
      <div className={classes.content} id="printDiv">
        <Typography variant="h5">{legalInfo.name}</Typography>

        <div className={classes.row}>
          <Typography variant="body2">Даночен број:</Typography>
          <Typography variant="body2">{legalInfo.vatin}</Typography>
        </div>

        <div className={classes.row}>
          <Typography variant="body2">Жиро сметка:</Typography>
          <Typography variant="body2">{billing?.bankAccount}</Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="body2">Телефон:</Typography>
          <Typography variant="body2">{restaurant.phone || "-"}</Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="body2">Датум:</Typography>
          <Typography variant="body2">{new Date().toLocaleDateString()}</Typography>
        </div>

        {categories.map((category) => (
          <div className={classes.category} key={`category_${category.id}`}>
            <br />
            <Typography variant="h5">Нормативи за {category.title}</Typography>
            {products[category.id]?.map((product) => (
              <>
                <div className={classes.rowProduct} key={`product_${product.id}`}>
                  <Typography variant="subtitle1">{counter++}.</Typography>
                  <Typography variant="subtitle1">{product.name}</Typography>
                  <Typography variant="subtitle1"></Typography>
                </div>

                {product.productStockTypes.map((item) => (
                  <div className={classes.rowProduct} key={`product_${product.id}_${item.stockType.id}`}>
                    <Typography variant="body2"></Typography>
                    <Typography variant="caption">- {item.stockType.name.mk}</Typography>
                    <Typography variant="caption">{formatQuantity(item.quantity, item.stockType.uom)}</Typography>
                  </div>
                ))}
                <br />
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
