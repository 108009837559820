import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SnackBarWarning(props) {

    const handleClose = () => {
        if (props.setOpenSnackBar) {
            props.setOpenSnackBar(false);
        }
    };

    return (
        <Snackbar
            autoHideDuration={props.autoHideDuration}
            open={props.openSnackBar}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onExited={handleClose}
            onClose={handleClose}>
            <Alert onClose={handleClose} severity="warning">
                {props.message}
                {props.linkTo && props.linkText && (
                    <Link style={{ color: "inherit" }} to={props.linkTo}>{props.linkText}</Link>
                )}
            </Alert>
        </Snackbar>
    )
}
