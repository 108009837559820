import React, { useState, useContext } from 'react';
import {
    makeStyles,
    Grid,
    FormGroup,
    ClickAwayListener,
    Button
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { DateRangePickerComponent } from "materialui-daterange-picker";
import { RestaurantContext, UserContext } from "../../App";
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import KomitentListDialog from "./KomitentListDialog";

const useStyles = makeStyles((theme) => ({
    formRow: {
        flexGrow: 0,
        marginBottom: 25,
        textAlign: `center`,
        marginTop: 25,
        fontSize: 14,
        justifyContent: `center`,
    },
    formControlKomitents: {
        minWidth: 150,
        width: `auto`,
        margin: `15px ${theme.spacing(2)}px 0`,
        float: "left",
    },
    formControlDate: {
        minWidth: 150,
        width: `auto`,
        margin: `10px ${theme.spacing(2)}px 0`,
        float: "left",
        paddingLeft: theme.spacing(3)
    },
    dateRange: {
        position: `absolute`,
        marginTop: `60px`,
        zIndex: `999`
    },
}));

export default function ReceivingHeader(props) {

    const classes = useStyles();
    const user = useContext(UserContext);
    const restaurant = useContext(RestaurantContext);

    const [filterState, setFilterState] = useState({ ...props.filterState });

    const [openDateRange, setOpenDateRange] = useState(false);
    const dateRange = {
        startDate: filterState.selectedDateFrom,
        endDate: (filterState.selectedDateTo && filterState.selectedDateFrom) && new Date(Math.max(filterState.selectedDateTo.getTime(), filterState.selectedDateFrom.getTime()))
    };

    const [openSelectBox, setOpenSelectBox] = useState(false);

    const toggleDateRangeOpen = () => setOpenDateRange(!openDateRange);
    const closeDateRange = () => setOpenDateRange(false);

    const handleDateFromChange = (date) => {
        if (date != "Invalid Date") {
            let filState = Object.assign(filterState);
            filState.selectedDateFrom = date;
            if (filState.selectedDateTo.getTime() < date.getTime() && date.getTime() < 4102441200000) {
                filState.selectedDateTo = new Date(date);
            }
            setFilterState(filState);
            // let _range = {
            //     startDate: date,
            //     endDate: filState.selectedDateTo,
            // }
            // setDateRange(_range);
            // if (props.setQuery) {
            //     props.setQuery(filState);
            // }
        }
    }
    const handleDateToChange = (date) => {
        if (date != "Invalid Date") {
            let filState = Object.assign(filterState);
            let dateTo = new Date(date);
            dateTo.setHours("23");
            dateTo.setMinutes("59")
            dateTo.setSeconds("59");

            filState.selectedDateTo = dateTo;
            if (filState.selectedDateFrom.getTime() > dateTo.getTime() && dateTo.getTime() < 4102441200000) {
                filState.selectedDateFrom = new Date(dateTo);
            }

            setFilterState(filState);
            props.setFilterState(filState);
            // let _range = {
            //     startDate: filState.selectedDateFrom,
            //     endDate: dateTo
            // }
            // setDateRange(_range);
            // if (props.setQuery) {
            //     props.setQuery(filState);
            // }
        }
    }

    const handleDateRangeAccept = (range) => {
        let filState = Object.assign(filterState);
        let dateTo = new Date(range.endDate);
        dateTo.setHours("23");
        dateTo.setMinutes("59")
        dateTo.setSeconds("59");
        filState.selectedDateFrom = range.startDate;
        filState.selectedDateTo = dateTo;
        setFilterState(filState);
        // setDateRange({
        //     startDate: range.startDate,
        //     endDate: dateTo
        // });
        if (props.setQuery) {
            props.setQuery(filState);
        }
        closeDateRange();
    }

    const onKomissionersClick = () => {
        setOpenSelectBox(true);
    }

    return (
        <>
            <FormGroup row className={classes.formRow}>

                <div className={classes.formControlKomitents}>
                    <Button
                        style={{ margin: "12px auto" }}
                        color="primary"
                        component="span"
                        onClick={onKomissionersClick}
                        startIcon={<SubtitlesIcon />}
                    >
                        {user.translate("komitents")}
                    </Button>
                </div>

                <ClickAwayListener onClickAway={closeDateRange}>
                    <div>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="flex-start" className={classes.formControlDate}>
                                <KeyboardDatePicker
                                    disableToolbar={false}
                                    animateYearScrolling={true}
                                    autoOk={true}
                                    open={false}
                                    variant="inline"
                                    format={user.dateFormat}
                                    id={"filter_date_from"}
                                    label={user.translate("date_from")}
                                    value={filterState.selectedDateFrom}
                                    onClick={toggleDateRangeOpen}
                                    onChange={handleDateFromChange}
                                    onClose={(e) => props.setQuery(filterState)}
                                    onKeyPress={(e) => { if (e.key == "Enter") props.setQuery(filterState) }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="flex-start" className={classes.formControlDate}>
                                <KeyboardDatePicker
                                    disableToolbar={false}
                                    animateYearScrolling={true}
                                    autoOk={true}
                                    open={false}
                                    variant="inline"
                                    format={user.dateFormat}
                                    id={"filter_date_to"}
                                    label={user.translate("date_to")}
                                    value={filterState.selectedDateTo}
                                    onClick={toggleDateRangeOpen}
                                    onChange={handleDateToChange}
                                    onClose={(e) => props.setQuery(filterState)}
                                    onKeyPress={(e) => { if (e.key == "Enter") props.setQuery(filterState) }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{ position: "start" }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>

                        <div className={classes.dateRange}>
                            <DateRangePickerComponent
                                open={openDateRange}
                                toggle={toggleDateRangeOpen}
                                initialDateRange={dateRange}
                                onChange={(range) => handleDateRangeAccept(range)}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            </FormGroup>

            {openSelectBox && (
                <KomitentListDialog
                    setOpenSelectBox={setOpenSelectBox}
                    openSelectBox={openSelectBox}
                />
            )}

        </>

    );
}
