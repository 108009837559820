import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import helper from '../common/Helper';
import useWindowSize from './useWindowSize';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    content: {

    },
    appBar: {
        position: 'relative',
        minWidth: 320
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    closeIcon: {
        width: theme.spacing(5),
        height: 35,
        position: 'absolute',
        zIndex: 99999,
        right: 0
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var contentId = 1;

export default function MobileDialog(props) {
    const classes = useStyles();

    const size = useWindowSize();
    const id = useState(contentId++);
    const actionRef = useRef(() => helper.printdiv(`dialog_content_${id}`, "no_print", true));

    const handleClose = () => {
        props.onClose();
    }

    if (props.inlineOnDesktop && !size.isCompact) {
        return props.open ? props.children : null;
    }

    return (
        <Dialog open={props.open} maxWidth={props.maxWidth} fullScreen={size.isMobile} onClose={handleClose} TransitionComponent={Transition} className={classes.root} scroll="body">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>

                    <Typography variant="h6" className={classes.title}>{props.title || 'Dialog'}</Typography>

                    {
                        props.actionIcon && (
                            <IconButton edge="start" color="inherit" onClick={() => props?.onAction() || actionRef.current()} aria-label="close">
                                {props.actionIcon}
                            </IconButton>
                        )
                    }
                </Toolbar>
            </AppBar>
            <div className={classes.content} id={`dialog_content_${id}`}>
                {
                    React.Children.map(props.children, child => child != null ? React.cloneElement(child, { setOnAction: (callback) => { actionRef.current = callback } }) : null)
                }
            </div>
        </Dialog>
    );
}

MobileDialog.propTypes = {
    inlineOnDesktop: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
};